<template>
  <wrapper>
    <div v-if="debugbtn">
      <span>頁面編號調試：</span>
      <input v-model="page" />
    </div>
    <div v-if="page == 1" class="box">
      <div v-if="!repeat">
        <div class="title">社會支持</div>
        <p>
          <span class="bu blue">社會支持</span
          >對於我哋參與同堅持運動鍛煉起到重要嘅作用！跟住落黎我哋一齊睇下，你覺得邊啲人可以幫你更加積極去參與運動鍛煉。
        </p>
      </div>
      <div v-else>
        <p class="red">
          請你再次諗下邊啲人可以幫到你參與同堅持運動，並繼續完成啱先揀“否”嘅條目。
        </p>
      </div>

      <div v-show="!repeat || reList.indexOf(1) != -1">
        <p>
          1．你通常會<span class="bu blue">同邊個一齊</span>去做運動？ （可多選）
        </p>
        <cube-checkbox-group
          v-model="support.q1"
          :options="allSupport2"
          @input="checkNone"
        />
        <p v-if="haveOther(support.q1)">
          &nbsp;&nbsp;其他人喺指:&nbsp;
          <cube-input
            class="other_input"
            v-model="support.q1_other"
          ></cube-input>
        </p>
        <br />
      </div>

      <div v-show="!repeat || reList.indexOf(2) != -1">
        <p>
          2．邊個可以<span class="bu blue">負責同確保</span
          >令你進行有規律嘅運動鍛煉？（可多選）
        </p>
        <cube-checkbox-group
          v-model="support.q2"
          :options="allSupport2"
          @input="checkNone"
        />
        <p v-if="haveOther(support.q2)">
          &nbsp;&nbsp;其他人喺指:&nbsp;
          <cube-input
            class="other_input"
            v-model="support.q2_other"
          ></cube-input>
        </p>
        <br />
      </div>

      <div v-show="!repeat || reList.indexOf(3) != -1">
        <p>
          3．如果你盡力達到咗WHO推薦嘅運動鍛煉標準，邊個會<span class="bu blue"
            >讚賞</span
          >你？（可多選）
        </p>
        <cube-checkbox-group
          v-model="support.q3"
          :options="allSupport2"
          @input="checkNone"
        />
        <p v-if="haveOther(support.q3)">
          &nbsp;&nbsp;其他人喺指:&nbsp;
          <cube-input
            class="other_input"
            v-model="support.q3_other"
          ></cube-input>
        </p>
        <br />
      </div>

      <div v-show="!repeat || reList.indexOf(4) != -1">
        <p>
          4．邊個會替你分擔一啲其他嘅事（比如，做家務等），進而<span class="bu blue"
            >幫助</span
          >你進行運動鍛煉？（可多選）
        </p>
        <cube-checkbox-group
          v-model="support.q4"
          :options="allSupport"
          @input="checkNone"
        />
        <p v-if="haveOther(support.q4)">
          &nbsp;&nbsp;其他人喺指:&nbsp;
          <cube-input
            class="other_input"
            v-model="support.q4_other"
          ></cube-input>
        </p>
        <br />
      </div>

      <div v-show="!repeat || reList.indexOf(5) != -1">
        <p>
          5．當你進行運動鍛煉嘅時候，邊個會俾你一啲<span class="bu blue">建議</span
          >（如，做咩運動，點樣做運動等）？（可多選）
        </p>
        <cube-checkbox-group
          v-model="support.q5"
          :options="allSupport"
          @input="checkNone"
        />
        <p v-if="haveOther(support.q5)">
          &nbsp;&nbsp;其他人喺指:&nbsp;
          <cube-input
            class="other_input"
            v-model="support.q5_other"
          ></cube-input>
        </p>
        <br />
      </div>
    </div>
    <div v-if="page == 2" class="box">
      <div v-if="debugbtn">
        <span>頁面反饋調試：</span>
        <input v-model="feedback" />
      </div>

      <div v-if="feedback == 1 || feedback == 2">
        <div v-if="debugbtn">
          <span>頁面故事調試：</span>
          <input v-model="story" />
        </div>
        <p v-if="feedback == 1" class="blue">
          你似乎唔太清楚邊啲人可以幫你參與同堅持運動。唔緊要，其他人嘅例子可能會對你有所啟發。
        </p>
        <p v-if="feedback == 2" class="dark_green">
          你已經諗到咗一啲可以幫你嘅人。但是仲有一啲情況你唔清楚邊個可以幫到你。唔緊要，其他人嘅例子可能會對你有所啟發。
        </p>
        <div v-if="story == 1">
          <audio
            class="gaudio"
            controls
            controlsList="nodownload"
            preload="auto"
            src="@/assets/guide/pa/Week_1_YANG_72.mp3"
          ></audio>
          <p>
            楊先生（72歲）：我現在每星期都會參加三次老人中心舉辦的“長者健康行”活動。進行有規律的運動鍛煉，一方面提高心肺功能和身體體質，另一方面令我有機會認識新朋友。大家一起參加這個活動，我覺得好有趣。有時我們亦會相約一起做運動，互相鼓勵。
          </p>
          <img
            class="fw8"
            alt="支持反饋1"
            src="../../assets/img/pa_spstory1.png"
          />
        </div>

        <div v-if="story == 2">
          <audio
            class="gaudio"
            controls
            controlsList="nodownload"
            preload="auto"
            src="@/assets/guide/pa/Week_2_Social_Support.mp3"
          ></audio>
          <p>
            李女士（67歲）：我不太喜歡運動，而且我發現一個人運動很難堅持。所以我現在經常相約和我住一區的姐妹們一起運動。平時我們大家會一起去公園健步行，有時週末，大家會一起去遠足或者爬山。
          </p>
          <p>
            慢慢有越來越多人開始加入我們。我很喜歡和大家一起運動，我覺得非常開心，精神也比以前更好了，大家的陪伴也讓我更容易堅持進行運動。
          </p>
          <img
            class="fw8"
            alt="支持反饋2"
            src="../../assets/img/pa_spstory2.png"
          />
        </div>

        <div v-if="story == 3">
          <audio
            class="gaudio"
            controls
            controlsList="nodownload"
            preload="auto"
            src="@/assets/guide/pa/Week_3_WANG_65.mp3"
          ></audio>
          <p>
            王先生（65歲）：我總是覺得沒有時間運動，而且覺得年齡大，運動很容易受傷。我兒子説他晚上回家經常看到一些年長的街坊在附近公園慢跑。為了鼓勵我進行鍛煉，他也經常陪我一起去公園跑步。
          </p>
          <p>
            起初我只能慢跑十分鐘，還感覺很累，後來慢慢地可以跑更長時間。有了兒子的陪伴和鼓勵，我對自己堅持運動更有信心了。因為在一起運動，我們父子之間的關喺也變得越來越好。我現在非常開心做運動。
          </p>
          <img
            class="fw8"
            alt="支持反饋3"
            src="../../assets/img/pa_spstory3.png"
          />
        </div>

        <div v-if="story == 4">
          <audio
            class="gaudio"
            controls
            controlsList="nodownload"
            preload="auto"
            src="@/assets/guide/pa/Week_4_Social_Support.mp3"
          ></audio>
          <p>
            高女士（68歲）：我和老伴年紀大了都不愛運動，平時我們喜歡待在家裡看電視。有一次我在洗手間跌倒了，去了醫院醫生説我肌肉力量不足，建議我加強鍛煉。
          </p>
          <p>
            後來我的老伴晚上總是拉著我去公園快步行，有時候也會在健身公園鍛煉。老伴為了讓我多運動，特意陪我步行去離家遠一點的街市買菜。現在我感覺提重物或者行樓梯都沒有以前那麼辛苦，走路也有力了。非常感謝老伴的支持和陪伴。
          </p>
          <img
            class="fw8"
            alt="支持反饋4"
            src="../../assets/img/pa_spstory4.jpg"
          />
        </div>
        <div v-if="story == 5">
          <audio
            class="gaudio"
            controls
            controlsList="nodownload"
            preload="auto"
            src="@/assets/guide/pa/Week_5_Social_Support.mp3"
          ></audio>
          <p>
            王先生（65歲）：我經常和老友記們一起去公園做運動。通常每週運動2-3次，已經堅持好多年了。運動已經成為我日常生活中的一部分，我和老友們還組建了一個運動羣，大家經常會在裡面分享一些運動養生的小貼士。
          </p>
          <p>
            在堅持運動的過程中，也遇到了好多困難。比如遇到天氣不好的時候，我會偷懶，不想做運動，這個時候我的老友們就會互相提醒，互相督促。去不了公園，大家就會找個室內地方，做拉伸和瑜伽。在老友們的幫助下，現在我已經養成了堅持運動鍛煉的習慣，整個人也越來越健康，越來越年輕。
          </p>
          <img
            class="fw8"
            alt="支持反饋5"
            src="../../assets/img/pa_spstory5.png"
          />
        </div>
        <div v-if="story == 6">
          <audio
            class="gaudio"
            controls
            controlsList="nodownload"
            preload="auto"
            src="@/assets/guide/pa/Week_6_Social_Support.mp3"
          ></audio>
          <p>
            王女士（70歲）：我剛剛退休的時候，本來覺得自己有很多自由時間，但是後來由於需要照顧孫子孫女，每天還要煮飯做家務，這些事情就佔了我很多時間。
          </p>
          <p>
            後來我跟老伴説很多街坊在老年社區參加了健身操和太極拳學習班，我也想去。他就和兒女一起支持我，幫我分擔很多家務，讓我有足夠的時間參與這些活動。和大家一起參加運動使我很開心，我很享受自己現在的老年生活。
          </p>
          <img
            class="fw8"
            alt="支持反饋6"
            src="../../assets/img/pa_spstory6.jpg"
          />
        </div>
        <div v-if="story == 7">
          <audio
            class="gaudio"
            controls
            controlsList="nodownload"
            preload="auto"
            src="@/assets/guide/pa/Week_7_Social_Support.mp3"
          ></audio>
          <p>
            歐先生（67歲）：我有遺傳性高血壓。醫生説，除了服用藥物，適當的體育鍛煉也能夠幫助降低血壓。我每天去公園健步行，還會用健身公園的運動器械做拉伸。每次運動回來，我的家人都説我氣色很好，誇獎我有毅力，能夠完成當天的鍛煉計劃。
          </p>
          <p>
            有時候，家人還會買運動鞋和運動服給我獎勵我堅持運動。雖然我的家人工作忙，沒時間陪我一起運動，但是他們鼓勵和支持讓我更有信心堅持運動。我現在也十分滿意自己的健康狀況。
          </p>
          <img
            class="fw8"
            alt="支持反饋7"
            src="../../assets/img/pa_spstory7.png"
          />
        </div>
        <div v-if="story == 8">
          <audio
            class="gaudio"
            controls
            controlsList="nodownload"
            preload="auto"
            src="@/assets/guide/pa/Week_8_Social_support.mp3"
          ></audio>
          <p>
            張先生（70歲）：我之前體能比較差，只能做少量運動，如去公園散步。除此之外，沒做過其他運動。後來我在公園裡認識了張生，他是一個退休教師，也是一個練太極20多年的太極拳師傅。
          </p>
          <p>
            張生告訴我，像我這種體質較差的人，練習太極拳對我最合適。我跟著張生在公園裡練習太極已經3年，明顯感覺精力充沛，心情也開朗了很多。我很感謝張生教我練太極，並幫助我堅持運動！我們也成為了生活中的朋友！
          </p>
          <img
            class="fw8"
            alt="支持反饋8"
            src="../../assets/img/pa_spstory8.jpg"
          />
        </div>
        <div v-if="story == 9">
          <audio
            class="gaudio"
            controls
            controlsList="nodownload"
            preload="auto"
            src="@/assets/guide/pa/Week_9_Social_Support.mp3"
          ></audio>
          <p>
            林先生（60歲）：我的腿以前受過傷，行樓梯或者走路太長時間都會感覺很辛苦。但是我知道運動對我保持健康是很重要的，可是不清楚像我這種情況，做什麼樣的運動更合適。後來我的孫子告訴我在家就可以做一些簡單的運動。他給我下載了很多長者健身運動視頻，比如説椅子操和毛巾操，每個視頻的時間都在十分鐘左右，很容易學會。
          </p>
          <p>
            孫子總是鼓勵我，告訴我要想身體健康，一定要堅持運動。有時候孫子還會陪我一起做。每次我跟著視頻完成運動，就覺得好有成就感。
          </p>
          <img
            class="fw8"
            alt="支持反饋9"
            src="../../assets/img/pa_spstory9.jpg"
          />
        </div>
        <div v-if="story == 10">
          <audio
            class="gaudio"
            controls
            controlsList="nodownload"
            preload="auto"
            src="@/assets/guide/pa/Week_10_Social_support.mp3"
          ></audio>
          <p>
            鍾先生（75歲）：從前我總是覺得散步是最適合我的運動方式。後來我瞭解到長者也可以做強度略微大一點的運動，但是我不知道什麼運動適合我。
          </p>
          <p>
            後來我的孩子們給我和我老伴報了一個長者羽毛球班。我們非常開心，因為有專業教練指導我們，而且給我們建議。在那裡我們也認識了很多球友。日常生活中，我們也經常一起打球，非常開心。
          </p>
          <img
            class="fw8"
            alt="支持反饋10"
            src="../../assets/img/pa_spstory10.jpg"
          />
        </div>
      </div>
      <div v-if="feedback == 3">
        <p>你清楚地知道邊啲人可以支持你實現運動嘅目標。非常好！繼續保持！</p>
        <p class="b">
          希望你可以充分藉助身邊屋企人同朋友嘅幫助與同支持，堅持進行運動鍛煉。
        </p>
        <p></p>
        <cube-button @click="review">社會支持總結</cube-button>
        <p></p>
        <p></p>
        <img class="fw8" alt="支持反饋2" src="../../assets/img/pa_spfb3.jpg" />
      </div>
    </div>
    <div v-if="page == 3" class="box">
      <div class="title">社會支持總結</div>

      <div v-if="revType == 1">
        <p>
          1. 我可以同呢個人（呢啲人）<span class="bu">一齊</span>去做運動：<span class="red b">{{
            showcp(1)
          }}</span>
        </p>
        <p>
          2. 呢個人（呢啲人）可以<span class="bu">負責以確保</span
          >我進行有規律嘅運動鍛煉：<span class="red b">{{ showcp(2) }}</span>
        </p>
        <p>
          3. 當我盡力達到咗WHO推薦嘅運動鍛煉標準，呢個人（呢啲人）會<span
            class="bu"
            >鼓勵同讚賞</span
          >我：<span class="red b">{{ showcp(3) }}</span>
        </p>
        <p>
          4. 呢個人（呢啲人）為咗令我參加運動鍛煉會<span class="bu"
            >幫我分擔</span
          >一啲野：<span class="red b">{{ showcp(4) }}</span>
        </p>
        <p>
          5. 呢個人（呢啲人）可以為我進行運動鍛煉提出一啲<span class="bu"
            >建議</span
          >：<span class="red b">{{ showcp(5) }}</span>
        </p>
      </div>
      <div v-if="revType == 2">
        <p>
          1．你通常會<span class="bu">同邊個一齊</span>去做運動：<span class="red b">{{ showcp(1) }}</span>
        </p>
        <p>
          2．邊個可以<span class="bu">負責同確保</span
          >令你進行有規律嘅運動鍛煉：<span class="red b">{{ showcp(2) }}</span>
        </p>
        <p>
          3．如果你盡力達到咗WHO推薦嘅運動鍛煉標準，邊個會<span class="bu"
            >讚賞</span
          >你：<span class="red b">{{ showcp(3) }}</span>
        </p>
        <p>
          4．邊個會幫你分擔一啲其他嘅事（比如，做家務等），進而<span class="bu"
            >幫</span
          >你進行運動鍛煉：<span class="red b">{{ showcp(4) }}</span>
        </p>
        <p>
          5．當你進行運動鍛煉嘅時候，邊個會俾你一啲<span class="bu">建議</span
          >（比如，做d咩運動，點樣做運動等）：<span class="red b">{{ showcp(5) }}</span>
        </p>
      </div>
    </div>
    <div v-if="page == 4" class="box">
      <div class="title">社會支持</div>
      <p>
        上個禮拜你指出喺進行運動鍛煉嘅時候應該可以得到下低嘅社會支持，請你回憶你今個星期喺咪得到呢啲社會支持：
      </p>
      <p>
        1．你通常會<span class="bu">同邊個一齊</span>去參加運動鍛煉：<span class="red b">{{
          showcp(1)
        }}</span>
      </p>
      <cube-radio-group v-model="support.perEval.q1">
        <cube-radio key="1" :option="{ value: '是' }"> 是</cube-radio>
        <cube-radio key="2" :option="{ value: '否' }"> 否</cube-radio>
      </cube-radio-group>
      <p>
        2．邊個可以<span class="bu">負責同確保</span
        >令你進行有規律嘅運動鍛煉：<span class="red b">{{ showcp(2) }}</span>
      </p>
      <cube-radio-group v-model="support.perEval.q2">
        <cube-radio key="1" :option="{ value: '是' }"> 是</cube-radio>
        <cube-radio key="2" :option="{ value: '否' }"> 否</cube-radio>
      </cube-radio-group>
      <p>
        3．如果你盡力達到咗WHO推薦嘅運動鍛煉標準，邊個會<span class="bu"
          >讚賞</span
        >你：<span class="red b">{{ showcp(3) }}</span>
      </p>
      <cube-radio-group v-model="support.perEval.q3">
        <cube-radio key="1" :option="{ value: '是' }"> 是</cube-radio>
        <cube-radio key="2" :option="{ value: '否' }"> 否</cube-radio>
      </cube-radio-group>
      <p>
        4．邊個會替你分擔一啲其他嘅事（如，做家務等），進而<span class="bu">幫</span
        >你進行運動鍛煉：<span class="red b">{{ showcp(4) }}</span>
      </p>
      <cube-radio-group v-model="support.perEval.q4">
        <cube-radio key="1" :option="{ value: '是' }"> 是</cube-radio>
        <cube-radio key="2" :option="{ value: '否' }"> 否</cube-radio>
      </cube-radio-group>
      <p>
        5．當你進行運動鍛煉嘅時候，邊個會俾你一啲<span class="bu">建議</span
        >（比如，做d咩運動，點樣做運動等）：<span class="red b">{{ showcp(5) }}</span>
      </p>
      <cube-radio-group v-model="support.perEval.q5">
        <cube-radio key="1" :option="{ value: '是' }"> 是</cube-radio>
        <cube-radio key="2" :option="{ value: '否' }"> 否</cube-radio>
      </cube-radio-group>
    </div>
  </wrapper>
</template>



<script>
import wrapper from "@/components/wrapper";
import { allSupport, allSupport2, validErr } from "@/data/data";
import tools from "@/data/tools";

export default {
  name: "pa_support",
  data() {
    return {
      page: 0,
      feedback: 1,
      story: 1,
      revType: 1,
      allSupport: allSupport,
      allSupport2: allSupport2,
      repeat: false,
      reList: [],
      support: {
        perEval: {
          q1: "",
          q2: "",
          q3: "",
          q4: "",
          q5: "",
        },
        q1: [],
        q1_other: "",
        q2: [],
        q2_other: "",
        q3: [],
        q3_other: "",
        q4: [],
        q4_other: "",
        q5: [],
        q5_other: "",
      },

      perweek: {},
    };
  },
  computed: {
    type: function () {
      return this.$store.state.type;
    },
    num: function () {
      return this.$store.state.week;
    },
    user: function () {
      return this.$store.state.user;
    },
    pa: function () {
      return this.$store.state.pa;
    },
    debugbtn: function () {
      return this.$store.state.debugbtn;
    },
  },
  created() {
    if (!this.page) {
      if (this.num == 1) this.page = 1;
      else if (this.num >= 2 && this.num <= 10) this.page = 4;
    }

    if (this.$store.state.pa[this.num] != null) {
      this.support = tools.copy(this.$store.state.pa[this.num].support);
    } else {
      //編輯，先查看是否有緩存
      if (this.$store.state.pa[0].support) {
        this.support = this.$store.state.pa[0].support;
      }
    }
    // 無論查看還是編輯， 初始化上週內容
    if (this.$store.state.pa[this.num - 1] != null) {
      this.perweek = tools.copy(this.$store.state.pa[this.num - 1].support);
    }
    if (!this.$store.state.review && this.num != 1) {
      this.support = tools.copy(this.perweek);
      this.support.perEval.q1 = 0;
      this.support.perEval.q2 = 0;
      this.support.perEval.q3 = 0;
      this.support.perEval.q4 = 0;
      this.support.perEval.q5 = 0;
    }
  },
  methods: {
    checkNone: function (val) {
      if (this.haveNone(val)) {
        while (val.length > 1) val.shift();
      }
    },
    haveOther: function (arr) {
      for (let item of arr) {
        if (item == "其他人") return true;
      }
      return false;
    },
    haveNone: function (arr) {
      for (let item of arr) {
        if (item == "沒有") return true;
      }
      return false;
    },
    showcp: function (no) {
      let cps = "";
      let arr = this.support["q" + no];
      if (arr.length == 0 || this.haveNone(arr)) return "沒有";
      for (let val of arr) {
        if (cps) cps = cps + "和";
        if (val != "其他人") {
          cps = cps + val;
        } else {
          cps = cps + this.support["q" + no + "_other"];
        }
      }
      return cps;
    },
    countNo: function () {
      let num = 0;
      if (this.support.perEval.q1 == "否") num += 1;
      if (this.support.perEval.q2 == "否") num += 1;
      if (this.support.perEval.q3 == "否") num += 1;
      if (this.support.perEval.q4 == "否") num += 1;
      if (this.support.perEval.q5 == "否") num += 1;
      return num;
    },
    countNone: function () {
      let num = 0;
      if (this.support.q1.length == 0 || this.haveNone(this.support.q1))
        num += 1;
      if (this.support.q2.length == 0 || this.haveNone(this.support.q2))
        num += 1;
      if (this.support.q3.length == 0 || this.haveNone(this.support.q3))
        num += 1;
      if (this.support.q4.length == 0 || this.haveNone(this.support.q4))
        num += 1;
      if (this.support.q5.length == 0 || this.haveNone(this.support.q5))
        num += 1;
      return num;
    },
    getFeedback: function (byper) {
      this.story = this.num;
      if (byper) {
        let no = this.countNo();
        if (no == 0) return 3;
        else if (no >= 1 && no <= 3) return 2;
        else if (no > 3) return 1;
      } else {
        let none = this.countNone();
        if (none == 0) return 3;
        else if (none >= 1 && none <= 3) return 2;
        else if (none > 3) return 1;
      }
    },
    review: function () {
      this.page = 3;
    },
    save: function () {
      if (this.$store.state.review) return;
      let xml = {};
      xml.key = [
        "社會支持1",
        "社會支持2",
        "社會支持3",
        "社會支持4",
        "社會支持5",
        "上週獲得支持1",
        "上週獲得支持2",
        "上週獲得支持3",
        "上週獲得支持4",
        "上週獲得支持5",
      ];
      xml.data = [];
      xml.data.push(this.showcp(1));
      xml.data.push(this.showcp(2));
      xml.data.push(this.showcp(3));
      xml.data.push(this.showcp(4));
      xml.data.push(this.showcp(5));
      xml.data.push(this.support.perEval.q1);
      xml.data.push(this.support.perEval.q2);
      xml.data.push(this.support.perEval.q3);
      xml.data.push(this.support.perEval.q4);
      xml.data.push(this.support.perEval.q5);
      this.support.xml = xml;

      this.$store.state.pa[0].support = tools.copy(this.support);
    },
    pause: function () {
      this.save();
    },
    leave: function (page) {
      if (typeof this.page == "string") this.page = parseInt(this.page);

      if (page == 1) {
        this.feedback = this.getFeedback();
      } else if (page == 2) {
        this.repeat = true;
        let reList = [];
        if (this.haveNone(this.support.q1) || this.support.perEval.q1 == "否")
          reList.push(1);
        if (this.haveNone(this.support.q2) || this.support.perEval.q2 == "否")
          reList.push(2);
        if (this.haveNone(this.support.q3) || this.support.perEval.q3 == "否")
          reList.push(3);
        if (this.haveNone(this.support.q4) || this.support.perEval.q4 == "否")
          reList.push(4);
        if (this.haveNone(this.support.q5) || this.support.perEval.q5 == "否")
          reList.push(5);
        this.reList = reList;
        if (this.feedback == 3) this.repeat = false;
      } else if (page == 4) {
        this.feedback = this.getFeedback(true);
      }
    },
    valid: function (page) {
      console.log(this.support);
      console.log(this.countNone());
      if (page == 1) {
        for (let i = 1; i <= 5; i++) {
          if (
            this.support["q" + i].length == 0 ||
            (this.haveOther(this.support["q" + i]) &&
              !this.support["q" + i + "_other"])
          )
            return validErr.empty;
        }
      }
      if (page == 4) {
        if (
          !this.support.perEval.q1 ||
          !this.support.perEval.q2 ||
          !this.support.perEval.q3 ||
          !this.support.perEval.q4 ||
          !this.support.perEval.q5
        )
          return validErr.empty;
      }
    },
    next: function () {
      console.log("support next");
      if (this.valid(this.page)) {
        this.$createDialog({
          type: "alert",
          title: "",
          content: this.valid(this.page),
        }).show();
        return;
      }

      this.leave(this.page);

      let done = false;
      switch (this.page) {
        // 社會支持設置頁
        case 1:
          if (this.repeat) done = true;
          else {
            this.page = 2;
          }
          break;
        // 社會支持反饋頁
        case 2:
          if (this.repeat) this.page = 1;
          else done = true;
          break;
        // 社會支持總結頁
        case 3:
          this.page = 2;
          break;
        // 社會支持回顧頁
        case 4:
          this.page = 2;
          break;
      }
      if (done) {
        this.save();
        return this.page;
      }
    },
  },
  components: {
    wrapper: wrapper,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../assets/css/learn_common.css" />
<style scoped>
#list {
  margin: 0 auto;
}

div.cube-radio {
    font-size: 20px;
}
</style>