var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container vflex cscroll" }, [
    (_vm.user.pastate < 5 && _vm.type == "pa") ||
    (_vm.user.dietstate < 5 && _vm.type == "diet")
      ? _c("div", { staticClass: "barrier_no" }, [
          _c("p", [_vm._v("完成五星期嘅學習後，你將開始呢個部分嘅學習")]),
        ])
      : _c("div", [
          _vm.type == "pa"
            ? _c("div", { staticClass: "barrier_box" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v("運動障礙對應記錄 "),
                ]),
                _c("div", { staticClass: "barrier_context" }, [
                  _c("div", { staticClass: "barrier_line " }, [
                    _vm._v(" 1."),
                    _c("span", { staticClass: "bu txtrg" }, [_vm._v("如果:")]),
                    _c("div", { staticClass: "red u" }, [
                      _vm._v(" " + _vm._s(_vm.data[0].type) + " "),
                    ]),
                    _c("br"),
                    _c("span", { staticClass: "bu barrery_gap" }, [
                      _vm._v("咁:"),
                    ]),
                    _c("div", { staticClass: "blue u" }, [
                      _vm._v(" " + _vm._s(_vm.data[0].then) + " "),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.oneType,
                          expression: "!oneType",
                        },
                      ],
                      staticClass: "barrier_line",
                    },
                    [
                      _vm._v(" 2."),
                      _c("span", { staticClass: "bu txtrg" }, [
                        _vm._v("如果:"),
                      ]),
                      _c("div", { staticClass: "red u" }, [
                        _vm._v(" " + _vm._s(_vm.data[1].type) + " "),
                      ]),
                      _c("br"),
                      _c("span", { staticClass: "bu barrery_gap" }, [
                        _vm._v("咁:"),
                      ]),
                      _c("div", { staticClass: "blue u" }, [
                        _vm._v(" " + _vm._s(_vm.data[1].then) + " "),
                      ]),
                    ]
                  ),
                ]),
              ])
            : _c("div", { staticClass: "barrier_box" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v("飲食障礙對應記錄 "),
                ]),
                _c("div", { staticClass: "barrier_context" }, [
                  _c("div", { staticClass: "barrier_line" }, [
                    _vm._v(" 1."),
                    _c("span", { staticClass: "bu txtrg" }, [_vm._v("如果:")]),
                    _c("div", { staticClass: "red u" }, [
                      _vm._v(" " + _vm._s(_vm.data2[0].type) + " "),
                    ]),
                    _c("span", { staticClass: "bu barrery_gap" }, [
                      _vm._v("咁:"),
                    ]),
                    _c("div", { staticClass: "blue u" }, [
                      _vm._v(" " + _vm._s(_vm.data2[0].then) + " "),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.oneType2,
                          expression: "!oneType2",
                        },
                      ],
                      staticClass: "barrier_line",
                    },
                    [
                      _vm._v(" 2."),
                      _c("span", { staticClass: "bu txtrg" }, [
                        _vm._v("如果:"),
                      ]),
                      _c("div", { staticClass: "red u" }, [
                        _vm._v(" " + _vm._s(_vm.data2[1].type) + " "),
                      ]),
                      _c("span", { staticClass: "bu barrery_gap" }, [
                        _vm._v("咁:"),
                      ]),
                      _c("div", { staticClass: "blue u" }, [
                        _vm._v(" " + _vm._s(_vm.data2[1].then) + " "),
                      ]),
                    ]
                  ),
                ]),
              ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }