import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
//https://www.blinddb.com/userrec/2022-6-15-13-1-1.962.mp3
export default new Vuex.Store({
    // TODO:vuex 是臨時性的數據，當F5刷新時數據就沒了
    state: {
        // 系統當前在查看的內容類型 pa or diet
        type: 'pa',
        // 系統當前再看第幾周的內容
        week: 1,
        // 每週學習間隔（毫秒)
        interval: 1000, //1000 * 60 * 60 * 24 * 7
        review: false,
        configs: {
            dateLimit: {
                pa: "2022-01-01",
                diet: "2022-01-01"
            },
            note: {
                useNote: 0,
                note: "公告內容"
            }
        },
        // 系統錄音狀態，默認為false，true表示不使用錄音
        // 用户偏好保存在user.txt.txtDiary
        txtDiary: false,
        recHead: "DiaryRecURL:",
        debugbtn: false,

        // 當前登錄用户的用户信息
        user: {
            openid: 0,
            uname: '用户名佔位',
            sex: 0, //0表示男
            age: 3,
            tel: '13333333333',
            token: 'abcdefg',
            pastate: 0,
            dietstate: 0,
            txt: '{}'
        },
        //0 表示填寫未提交的數據
        pa: [
            {},
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
        ],
        //0 表示填寫未提交的數據
        diet: [
            {},
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
        ],
        checkin: {
            todayPa: false,
            todayDiet: false,
            // 打卡原始記錄
            raw: [],
            // 運動類型的打卡
            pa: [],
            // 飲食類型的打卡
            diet: [],
        }
    },
    mutations: {
        setWeek(state, payload) {
            state.week = payload.data;
            if (state.week < 1)
                state.week = 1;
            if (state.week > 10)
                state.week = 10;
        },
        setType(state, payload) {
            state.type = payload.data;
        },
        setUser(state, payload) {
            state.user = payload.data;
        },
        setReview(state, payload) {
            state.review = payload.data;
        },
        setTxtDiary(state, payload) {
            state.txtDiary = payload.data;
            state.user.txt.txtDiary = payload.data;
        },
    },
    actions: {
        login({ commit, state }, token) {
            return new Promise((resolve, reject) => {
                let query = {
                    token: token,
                };

                // axios.get("/api").then().catch();
                axios({
                        url: "/users/login",
                        method: "post",
                        data: query,
                        //baseURL: BASE_URL,
                    }).then(function(response) {
                        console.log("login-response");
                        console.log(response);
                        let openid = response.openid;
                        if (openid <= 0)
                            reject("err userid");

                        state.user = response.data.user;
                        state.user.sext = state.user.sex ? "女" : "男";
                        state.user.txt = JSON.parse(state.user.txt);
                        if (state.user.txt.txtDiary)
                            state.txtDiary = true;

                        for (let val of response.data.weeks) {
                            let obj = JSON.parse(val.txt);
                            obj.t = val.t;
                            obj.num = val.num;
                            if (val.kind == 1) {
                                state.pa[val.num] = obj;
                            } else if (val.kind == 2) {
                                state.diet[val.num] = obj;
                            }
                        }

                        // parse checkin
                        let date = new Date();
                        let y = date.getFullYear();
                        let m = date.getMonth() + 1;
                        let d = date.getDate();
                        for (let val of response.data.checkins) {
                            //state.checkin.raw.push(val);
                            let obj = val;
                            if (obj.kind == 1) {
                                state.checkin.pa.push(obj);
                                if (obj.y == y && obj.m == m && obj.d == d)
                                    state.checkin.todayPa = true;
                            } else if (obj.kind == 2) {
                                state.checkin.diet.push(obj);
                                if (obj.y == y && obj.m == m && obj.d == d)
                                    state.checkin.todayDiet = true;
                            }
                        }

                        for (let val of response.data.config) {
                            if (val.cname == "dateLimit")
                                state.configs.dateLimit = JSON.parse(val.txt);
                            else if (val.cname == "note")
                                state.configs.note = JSON.parse(val.txt);
                        }

                        commit("setWeek", { data: Math.max(state.user.pastate, state.user.dietstate) + 1 });

                        resolve(openid);
                    })
                    .catch(function(error) {
                        console.log("login-error");
                        console.log(error);
                        reject(error);
                    });
            });
        },

        upweek({ commit, state, dispatch }) {
            return new Promise((resolve, reject) => {
                //state[state.type][0].user = state.user;
                let obj = {
                    week: {
                        openid: state.user.openid,
                        num: state.user[state.type + "state"] + 1,
                        kind: (state.type == 'pa' ? 1 : 2),
                        txt: JSON.stringify(state[state.type][0]),
                    }
                }

                console.log("call-submitWeek");
                let checkinAwait = null;
                axios({
                    url: "/api/submitWeek",
                    method: "post",
                    data: obj,
                    //baseURL: BASE_URL,
                }).then(function(response) {
                    if(response.data.success)
                    {
                        if (obj.week.kind == 1) {
                            state.user.pastate += 1;
                            state.pa[0].t = new Date().getTime();
                            state.pa[0].num = obj.week.num;
                            state.pa[obj.week.num] = state.pa[0];
                            state.pa[0] = {};
                        } else if (obj.week.kind == 2) {
                            state.user.dietstate += 1;
                            state.diet[obj.week.num] = state.diet[0];
                            state.diet[0].t = new Date().getTime();
                            state.diet[0].num = obj.week.num;
                            state.diet[0] = {};
                        }
                        state.week += 1;
                        resolve("ok");
                    }
                    else
                    {
                        reject("error");
                    }

                }).catch(function(err) {
                    console.log("call-submitWeek-catch")
                    console.log(err);
                    reject(err);
                });

            });

        },

        checkin({ commit, state }, payload) {
            return new Promise((resolve, reject) => {
                if (!payload)
                    return;

                let checkin = {};
                checkin.openid = state.user.openid;
                let date = new Date();
                checkin.y = date.getFullYear();
                checkin.m = date.getMonth() + 1;
                checkin.d = date.getDate();
                checkin.w = date.getDay();
                checkin.kind = payload.kind;

                if (payload.rec) {
                    checkin.txt = state.recHead + payload.txt;
                } else {
                    checkin.txt = payload.txt;
                }
                
                console.log("call-checkin");
                axios({
                    url: "/api/checkin",
                    method: "post",
                    data: { checkin: checkin },
                    //baseURL: BASE_URL,
                }).then(function(response) {
                    if (checkin.kind == 1) {
                        state.checkin.pa.push(checkin);
                        state.checkin.todayPa = true;
                    }
                    if (checkin.kind == 2) {
                        state.checkin.diet.push(checkin);
                        state.checkin.todayDiet = true;
                    }
                    resolve("ok");
                }).catch(function(err) {
                    console.log(err);
                    reject(err);
                });
            });
        },

        uploadRec({ commit, state }, payload) {
            return new Promise((resolve, reject) => {
                if (!payload)
                    return;
                let blob = payload.blob;
                let length = payload.length;
                let kind = state.type == "diet" ? 2 : 1;
                let date = new Date();
                let y = date.getFullYear();
                let m = date.getMonth() + 1;
                let d = date.getDate();
                let fileName = `${y}-${m}-${d}-${state.user.openid}-${kind}-${length}.mp3`;



                let form = new FormData();
                //enctype = "multipart/form-data";
                form.append("recfile", blob, fileName);
                form.append("fileName", fileName);
                form.append("length", length);

                axios({
                    url: "/api/uploadRec",
                    method: "post",
                    data: form,
                    headers: { 'Content-Type': 'multipart/form-data' }
                    //baseURL: BASE_URL,
                }).then(function(response) {
                    console.log(response.data);
                    resolve(response.data);
                }).catch(function(err) {
                    console.log(err);
                    reject(err);
                });

            });
        },
    },
    modules: {}
})