var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _vm.$store.state.debugbtn
      ? _c("div", [
          _c("span", [_vm._v("頁面編號調試：")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.page,
                expression: "page",
              },
            ],
            domProps: { value: _vm.page },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.page = $event.target.value
              },
            },
          }),
        ])
      : _vm._e(),
    _vm.page == 1
      ? _c("div", { staticClass: "box" }, [
          _c("p", [
            _c("span", { staticClass: "bu" }, [_vm._v("寫健康飲食日記")]),
            _vm._v(
              "，可以幫你對自己嘅飲食行為進行總結和反思，促進健康飲食嘅堅持性。 "
            ),
          ]),
          _c("p", [
            _c("span", { staticClass: "b" }, [
              _vm._v("“健康飲食日記”嘅內容包括"),
            ]),
            _vm._v("：當日你喺"),
            _c("span", { staticClass: "bu" }, [_vm._v("咩時間")]),
            _vm._v("， "),
            _c("span", { staticClass: "bu" }, [_vm._v("咩地點")]),
            _vm._v("，"),
            _c("span", { staticClass: "bu" }, [_vm._v("同邊啲人")]),
            _vm._v("，食咗 "),
            _c("span", { staticClass: "bu" }, [_vm._v("咩食物")]),
            _vm._v("，"),
            _c("span", { staticClass: "bu" }, [_vm._v("心情如何")]),
            _vm._v("。 "),
          ]),
          _c("p", [_vm._v("下低你可以睇兩個健康飲食日記嘅例子。")]),
          _c("p", [
            _c("span", { staticClass: "red" }, [_vm._v("例子1")]),
            _vm._v(
              "：“這個星期五晚上，我和老伴在家裡吃了菠菜和西蘭花，還吃了雞肉和牛肉，晚飯過後和老伴一起在家吃了生果，達到了WHO推薦的飲食標準，心情很愉快” "
            ),
          ]),
          _c("p", [
            _c("span", { staticClass: "red" }, [_vm._v("例子2")]),
            _vm._v(
              "： “昨天早上，我吃了兩個雞蛋。中午的時候和家人一起吃了娃娃菜和菜心，還吃了豬肉。下午茶吃了香蕉和火龍果，晚上還和家人一起吃了芽菜和魚肉。今天的生果蔬菜和魚、肉、蛋的攝取量都達到了健康標準，很開心”。 "
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.txtDiary,
                  expression: "txtDiary",
                },
              ],
            },
            [
              _c("cube-textarea", {
                staticClass: "larget",
                attrs: { maxlength: _vm.max, placeholder: _vm.placeholder },
                model: {
                  value: _vm.diary.text,
                  callback: function ($$v) {
                    _vm.$set(_vm.diary, "text", $$v)
                  },
                  expression: "diary.text",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.txtDiary,
                  expression: "!txtDiary",
                },
              ],
            },
            [
              _c("p", [
                _c("span", { staticClass: "red" }, [
                  _vm._v("請點擊下低嘅“錄製”按鈕"),
                ]),
                _vm._v("，錄製你嘅電子語音健康飲食日記"),
              ]),
              _c("record", {
                ref: "recorder",
                attrs: {
                  "on-open-error": _vm.errHandle,
                  "on-stop-is-end": _vm.stopHandle,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "cube-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: true,
                      expression: "true",
                    },
                  ],
                  staticClass: "btnChange",
                  on: {
                    click: function ($event) {
                      return _vm.changeType()
                    },
                  },
                },
                [_vm._v("切換錄入方式")]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.page == 2
      ? _c("div", { staticClass: "box" }, [
          _c("p", { staticClass: "b" }, [
            _vm._v(
              " 你嘅日記將會儲存喺手機主頁“我的日記”一欄中, 你可以隨時查閲。 "
            ),
          ]),
          _c("p", [
            _vm._v(
              " 由而家開始，請你每日使用健康飲食日記，記錄你嘅飲食情況。如果"
            ),
            _c("span", { staticClass: "bu blue" }, [
              _vm._v("一星期內你可以寫低至少三個符合要求嘅健康飲食日記"),
            ]),
            _vm._v("，你將會獲得"),
            _c("span", { staticClass: "bu red" }, [_vm._v("10元現金獎勵")]),
            _vm._v("。 "),
          ]),
          _c("p", [_vm._v("加油，老友記！")]),
          _vm._v(" <"),
          _c("img", {
            staticClass: "fw8",
            attrs: {
              alt: "日記",
              src: require("../../assets/img/pa_diary.jpg"),
            },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }