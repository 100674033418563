var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _vm.debugbtn
      ? _c("div", [
          _c("span", [_vm._v("頁面編號調試：")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.page,
                expression: "page",
              },
            ],
            domProps: { value: _vm.page },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.page = $event.target.value
              },
            },
          }),
        ])
      : _vm._e(),
    _vm.page == 1
      ? _c("div", { staticClass: "box" }, [
          !_vm.repeat
            ? _c("div", [
                _c("div", { staticClass: "title" }, [_vm._v("社會支持")]),
                _c("p", [
                  _c("span", { staticClass: "bu blue" }, [_vm._v("社會支持")]),
                  _vm._v(
                    "對於我哋參與同堅持健康飲食具有重要嘅作用！跟住落黎我哋一齊睇下，你覺得邊啲人可以幫你更加積極去進行健康飲食。 "
                  ),
                ]),
              ])
            : _c("div", [
                _c("p", { staticClass: "red" }, [
                  _vm._v(
                    " 請你再次諗下邊啲人可以幫你進行健康飲食，並繼續完成啱先選擇“"
                  ),
                  _vm.num == 1
                    ? _c("span", [_vm._v("冇")])
                    : _c("span", [_vm._v("否")]),
                  _vm._v("”嘅條目。 "),
                ]),
              ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.repeat || _vm.reList.indexOf(1) != -1,
                  expression: "!repeat || (reList.indexOf(1)!=-1)",
                },
              ],
            },
            [
              _c("p", [
                _vm._v(" 1．你通常會"),
                _c("span", { staticClass: "bu blue" }, [_vm._v("同邊個一齊")]),
                _vm._v(
                  "進行健康飲食（如多食生果蔬菜同適量嘅肉、蛋及替代品） ？ （可多選） "
                ),
              ]),
              _c("cube-checkbox-group", {
                attrs: { options: _vm.allSupport },
                on: { input: _vm.checkNone },
                model: {
                  value: _vm.support.q1,
                  callback: function ($$v) {
                    _vm.$set(_vm.support, "q1", $$v)
                  },
                  expression: "support.q1",
                },
              }),
              _vm.haveOther(_vm.support.q1)
                ? _c(
                    "p",
                    [
                      _vm._v("   其他人喺指:  "),
                      _c("cube-input", {
                        staticClass: "other_input",
                        model: {
                          value: _vm.support.q1_other,
                          callback: function ($$v) {
                            _vm.$set(_vm.support, "q1_other", $$v)
                          },
                          expression: "support.q1_other",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("br"),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.repeat || _vm.reList.indexOf(2) != -1,
                  expression: "!repeat || (reList.indexOf(2)!=-1)",
                },
              ],
            },
            [
              _c("p", [
                _vm._v(" 2．邊個可以"),
                _c("span", { staticClass: "bu blue" }, [_vm._v("負責同確保")]),
                _vm._v(
                  "令你有規律咁食多啲生果蔬菜同適量嘅肉、蛋及替代品？（可多選） "
                ),
              ]),
              _c("cube-checkbox-group", {
                attrs: { options: _vm.allSupport },
                on: { input: _vm.checkNone },
                model: {
                  value: _vm.support.q2,
                  callback: function ($$v) {
                    _vm.$set(_vm.support, "q2", $$v)
                  },
                  expression: "support.q2",
                },
              }),
              _vm.haveOther(_vm.support.q2)
                ? _c(
                    "p",
                    [
                      _vm._v("   其他人喺指:  "),
                      _c("cube-input", {
                        staticClass: "other_input",
                        model: {
                          value: _vm.support.q2_other,
                          callback: function ($$v) {
                            _vm.$set(_vm.support, "q2_other", $$v)
                          },
                          expression: "support.q2_other",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("br"),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.repeat || _vm.reList.indexOf(3) != -1,
                  expression: "!repeat || (reList.indexOf(3)!=-1)",
                },
              ],
            },
            [
              _c("p", [
                _vm._v(" 3．如果你達到咗WHO推薦嘅健康飲食標準，邊個會"),
                _c("span", { staticClass: "bu blue" }, [_vm._v("誇獎同讚賞")]),
                _vm._v("你？（可多選） "),
              ]),
              _c("cube-checkbox-group", {
                attrs: { options: _vm.allSupport },
                on: { input: _vm.checkNone },
                model: {
                  value: _vm.support.q3,
                  callback: function ($$v) {
                    _vm.$set(_vm.support, "q3", $$v)
                  },
                  expression: "support.q3",
                },
              }),
              _vm.haveOther(_vm.support.q3)
                ? _c(
                    "p",
                    [
                      _vm._v("   其他人喺指:  "),
                      _c("cube-input", {
                        staticClass: "other_input",
                        model: {
                          value: _vm.support.q3_other,
                          callback: function ($$v) {
                            _vm.$set(_vm.support, "q3_other", $$v)
                          },
                          expression: "support.q3_other",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("br"),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.repeat || _vm.reList.indexOf(4) != -1,
                  expression: "!repeat || (reList.indexOf(4)!=-1)",
                },
              ],
            },
            [
              _c("p", [
                _vm._v(
                  " 4．邊個會幫你分擔一啲其他嘅事（如幫你買生果蔬菜和肉類），進而"
                ),
                _c("span", { staticClass: "bu blue" }, [_vm._v("幫")]),
                _vm._v("你達到WHO推薦嘅健康飲食標準？（可多選） "),
              ]),
              _c("cube-checkbox-group", {
                attrs: { options: _vm.allSupport },
                on: { input: _vm.checkNone },
                model: {
                  value: _vm.support.q4,
                  callback: function ($$v) {
                    _vm.$set(_vm.support, "q4", $$v)
                  },
                  expression: "support.q4",
                },
              }),
              _vm.haveOther(_vm.support.q4)
                ? _c(
                    "p",
                    [
                      _vm._v("   其他人喺指:  "),
                      _c("cube-input", {
                        staticClass: "other_input",
                        model: {
                          value: _vm.support.q4_other,
                          callback: function ($$v) {
                            _vm.$set(_vm.support, "q4_other", $$v)
                          },
                          expression: "support.q4_other",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("br"),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.repeat || _vm.reList.indexOf(5) != -1,
                  expression: "!repeat || (reList.indexOf(5)!=-1)",
                },
              ],
            },
            [
              _c("p", [
                _vm._v(" 5．喺進行健康飲食嘅時候，邊個會俾你一啲"),
                _c("span", { staticClass: "bu blue" }, [_vm._v("建議")]),
                _vm._v(
                  "（例如，食d咩生果蔬菜對你嘅健康最有好處）？（可多選） "
                ),
              ]),
              _c("cube-checkbox-group", {
                attrs: { options: _vm.allSupport },
                on: { input: _vm.checkNone },
                model: {
                  value: _vm.support.q5,
                  callback: function ($$v) {
                    _vm.$set(_vm.support, "q5", $$v)
                  },
                  expression: "support.q5",
                },
              }),
              _vm.haveOther(_vm.support.q5)
                ? _c(
                    "p",
                    [
                      _vm._v("   其他人喺指:  "),
                      _c("cube-input", {
                        staticClass: "other_input",
                        model: {
                          value: _vm.support.q5_other,
                          callback: function ($$v) {
                            _vm.$set(_vm.support, "q5_other", $$v)
                          },
                          expression: "support.q5_other",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("br"),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.page == 2
      ? _c("div", { staticClass: "box" }, [
          _vm.debugbtn
            ? _c("div", [
                _c("span", [_vm._v("頁面反饋調試：")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.feedback,
                      expression: "feedback",
                    },
                  ],
                  domProps: { value: _vm.feedback },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.feedback = $event.target.value
                    },
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback == 1 || _vm.feedback == 2
            ? _c("div", [
                _vm.debugbtn
                  ? _c("div", [
                      _c("span", [_vm._v("頁面故事調試：")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.story,
                            expression: "story",
                          },
                        ],
                        domProps: { value: _vm.story },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.story = $event.target.value
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.feedback == 1
                  ? _c("p", { staticClass: "blue" }, [
                      _vm._v(
                        " 你似乎唔太清楚邊啲人可以幫你進行健康飲食。唔緊要，其他人嘅例子可能會對你有所啟發。 "
                      ),
                    ])
                  : _vm._e(),
                _vm.feedback == 2
                  ? _c("p", { staticClass: "dark_green" }, [
                      _vm._v(
                        " 你已經諗到一啲可以幫你的人。但喺仲有一啲情況你唔清楚邊個可以幫到你。其他人嘅例子可能會對你有所啟發。 "
                      ),
                    ])
                  : _vm._e(),
                _vm.story == 1
                  ? _c("div", [
                      _c("audio", {
                        staticClass: "gaudio",
                        attrs: {
                          controls: "",
                          controlsList: "nodownload",
                          preload: "auto",
                          src: require("@/assets/guide/diet/Week_1_Social_support.mp3"),
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " 陳先生（68歲）：我和老婆以前喜歡在外面餐廳吃，而且餐餐只吃肉不吃菜。後來兒子建議我們要多吃生果蔬菜，説這能夠幫助補充維生素和提高身體免疫力。現在我和老婆經常去街市買餸，然後在家煮飯。"
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "通常我們會在去街市之前就計劃好想要買的食材，每次都會買新鮮蔬菜和魚、肉。做飯時，我們也是各有分工。儘管買菜做飯比較花時間，但我們在家裡會吃的比較健康，並且享受一起做飯的樂趣。 "
                        ),
                      ]),
                      _c("img", {
                        staticClass: "fw8",
                        attrs: {
                          alt: "支持反饋1",
                          src: require("../../assets/img/di_support_story1.png"),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.story == 2
                  ? _c("div", [
                      _c("audio", {
                        staticClass: "gaudio",
                        attrs: {
                          controls: "",
                          controlsList: "nodownload",
                          preload: "auto",
                          src: require("@/assets/guide/diet/Week_2_social_support.mp3"),
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " 劉先生（65歲）：我和老伴現在年紀都牙齒不好，已經嚼不了硬的生果和一些肉類了。但為了保證有足夠的營養，我們經常把豬肉和蔬菜剁碎，包餃子吃。"
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "子女們每次來看我們，都會買些適合老人吃的生果，例如香蕉、橙。我的老伴總是將生果切成小塊，方便我們每天都能吃兩、三個生果。家人的幫助和陪伴讓我更有信心堅持健康的飲食習慣，我很感激他們。 "
                        ),
                      ]),
                      _c("img", {
                        staticClass: "fw8",
                        attrs: {
                          alt: "支持反饋2",
                          src: require("../../assets/img/di_support_story2.jpg"),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.story == 3
                  ? _c("div", [
                      _c("audio", {
                        staticClass: "gaudio",
                        attrs: {
                          controls: "",
                          controlsList: "nodownload",
                          preload: "auto",
                          src: require("@/assets/guide/diet/Week_3_Social_support_65.mp3"),
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " 王先生（65歲）：2020年的時候，家庭醫生發現我患有高血壓和高膽固醇，醫生建議我注意飲食。我的老伴就開始監督我，並且在做飯的時選擇有益健康的食物。我的老伴不讓我吃加工和煎炸食品，只吃新鮮的食物，如魚類、雞肉、豬肉和大量的蔬菜，她每天給我準備兩次生果，包括提子、橙、蘋果和香蕉。"
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "雖然我60多歲，但是大家都讚我依然精神飽滿，活力充沛。現在我的各項指標和以前相比都有明顯改善。家人的幫助和鼓勵讓我一直有信心堅持進行健康飲食，我很感激他們。 "
                        ),
                      ]),
                      _c("img", {
                        staticClass: "fw8",
                        attrs: {
                          alt: "支持反饋3",
                          src: require("../../assets/img/di_support_story3.png"),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.story == 4
                  ? _c("div", [
                      _c("audio", {
                        staticClass: "gaudio",
                        attrs: {
                          controls: "",
                          controlsList: "nodownload",
                          preload: "auto",
                          src: require("@/assets/guide/diet/Week_3_Social_support_75.mp3"),
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " 胡先生（75歲）： 去年我去體檢，發現我的血壓血脂不正常，我也知道是因為自己的飲食習慣不好，但是自己只愛吃肉，蔬菜生果想起才吃一點。我老伴和我一起去體檢，她的各項指標都很正常，她建議我和她一起進行健康的飲食，多吃生果蔬菜，吃適量的肉，不要貪吃。"
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "我的老伴總是準備好生果拿到我面前，也開始換不同的烹調方式，例如蔬菜餅或者素食的餃子和餛飩，買菜的時候也不再像過去任由我吃肉了，讓我控制攝取量。上個月我去體檢，醫生説我的血壓和血脂恢復了正常，還問我是如何做到的！家人的支持和醫生的肯定讓我對堅持健康飲食充滿了信心。為了身體健康，我一定會堅持下去的。 "
                        ),
                      ]),
                      _c("img", {
                        staticClass: "fw8",
                        attrs: {
                          alt: "支持反饋4",
                          src: require("../../assets/img/di_support_story4.jpg"),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.story == 5
                  ? _c("div", [
                      _c("audio", {
                        staticClass: "gaudio",
                        attrs: {
                          controls: "",
                          controlsList: "nodownload",
                          preload: "auto",
                          src: require("@/assets/guide/diet/Week_5_Social_support.mp3"),
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " 祝先生（63歲）：我以前只知道老人家需要吃得健康，但是我並不清楚怎樣的飲食才是健康飲食習慣。有一次我的老伴參加了一個健康知識講座，瞭解到我們老人家每天要吃至少5份生果和蔬菜，蛋白質不能吃太多，每天吃5-6兩的魚、肉、蛋以及替代品就足夠了。"
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "在老伴的建議下，我們開始嚴格遵循這個健康飲食指南。每次去街市買餸前，老伴會計劃好買甚麼生果蔬菜和肉。她還會準備好生果拼盤做我們的下午茶。老伴的支持和陪伴令我對堅持健康飲食習慣忘滿了信心 "
                        ),
                      ]),
                      _c("img", {
                        staticClass: "fw8",
                        attrs: {
                          alt: "支持反饋5",
                          src: require("../../assets/img/di_support_story5.png"),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.story == 6
                  ? _c("div", [
                      _c("audio", {
                        staticClass: "gaudio",
                        attrs: {
                          controls: "",
                          controlsList: "nodownload",
                          preload: "auto",
                          src: require("@/assets/guide/diet/Week_6_Social_support.mp3"),
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " 黎先生（75歲）：因為我患有心臟病，醫生説想要保持心臟健康，日常的健康飲食十分重要。醫生建議我多吃瘦肉（如裏脊和臀尖肉）、禽類或魚，避免吃肥肉（如五花肉，腸，排骨和熱狗）。我老伴每次出街，總是買適合我吃的肉或魚。另外，醫生建議我避免油炸的食物。我老伴就用不同的方法去準備食物，例如烘烤，焙，蒸，煮，炒和燉。"
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " 醫生也建議我多食可溶性纖維，保證一天至少5份生果和蔬菜，有助於降低我的血膽固醇含量。可溶性纖維含量高的生果蔬菜有：胡蘿蔔，西蘭花，柑橘類生果，木瓜，草莓，和蘋果。每餐午飯和晚飯我老伴都會準備蔬菜，下午三、四點的時候，也會準備一些生果。她的支持和陪伴幫助我保持健康，我十分感激她。 "
                        ),
                      ]),
                      _c("img", {
                        staticClass: "fw8",
                        attrs: {
                          alt: "支持反饋6",
                          src: require("../../assets/img/di_support_story6.jpg"),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.story == 7
                  ? _c("div", [
                      _c("audio", {
                        staticClass: "gaudio",
                        attrs: {
                          controls: "",
                          controlsList: "nodownload",
                          preload: "auto",
                          src: require("@/assets/guide/diet/Week_7_Social_Support.mp3"),
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " 黃先生（70歲）：我有慢性胃炎，醫生説如果不注意日常的飲食會有胃癌的風險。我的家人們都十分照顧我的日常飲食，我兒子會為我列好一星期的菜單，並叮囑少吃下午茶，如果吃下午茶，建議我以蔬果沙拉為主。"
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "我老伴考慮到我應以清淡以及温和飲食為主，她會陪我一起不吃油炸辛辣的食物，並且給我做不同種類的蔬菜。冬天的時候，如果生果比較凍，她會將生果放在微波爐裡加熱。在家人的精心照顧下，我的日常飲食非常健康，已經有好久沒有復發胃炎了。非常感謝家人們在健康飲食上對我的支持。 "
                        ),
                      ]),
                      _c("img", {
                        staticClass: "fw8",
                        attrs: {
                          alt: "支持反饋7",
                          src: require("../../assets/img/di_support_story7.jpg"),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.story == 8
                  ? _c("div", [
                      _c("audio", {
                        staticClass: "gaudio",
                        attrs: {
                          controls: "",
                          controlsList: "nodownload",
                          preload: "auto",
                          src: require("@/assets/guide/diet/Week_8_Social_support.mp3"),
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " 馮先生（67歲）：我和我四個老友記都喜歡吃燒烤和油炸食物。大家每年的體檢報告都有三高現象。醫生建議我們多吃粗糧、雜糧、深綠色蔬菜、生果、豆製品、瘦肉、魚等食物，少吃油膩食品及少飲濃茶、咖啡等飲品。為了堅持健康飲食，我們一班老友記開始在WhatsApp羣組裡互相監督，互相鼓勵。每天我們都會在羣裡上傳自己吃的早餐、午餐和晚餐的圖片，也會告知大家自己當天吃了什麼生果。"
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "當有人吃了很多燒烤和油炸食物時，其他老友記會在羣裡提醒他注意。同時，大家還會分享自己在堅持健康飲食過程中的體會以及遇到的困難。當有老友記堅持不下去的時候，其他老友記會一起給他加油鼓勵！我們保持這樣的監督模式已經半年了，大家都感覺這個方法很有效，老友記的鼓勵和支持對我保持健康飲食非常重要！ "
                        ),
                      ]),
                      _c("img", {
                        staticClass: "fw8",
                        attrs: {
                          alt: "支持反饋8",
                          src: require("../../assets/img/di_support_story8.jpg"),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.story == 9
                  ? _c("div", [
                      _c("audio", {
                        staticClass: "gaudio",
                        attrs: {
                          controls: "",
                          controlsList: "nodownload",
                          preload: "auto",
                          src: require("@/assets/guide/diet/Week_9_Social_Support.mp3"),
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " 宋女士（68歲）：由於我患有糖尿病，科學飲食對我來説非常重要。過去我很喜歡吃甜食和糕點，每一餐都吃很多肉，不愛吃生果蔬菜。後來我老伴幫我整理了一份健康食譜，詳細列出了一星期每天的飲食內容和份量。督促我每天至少吃3份蔬菜和2份低糖分的生果（蘋果，橙）。還要每天吃兩掌心份量的肉類，主要以動物蛋白為主，包括廋肉，牛肉和魚肉。"
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "我女兒還在家裡的門上，冰箱上貼了一些小紙條提醒我進行健康飲食，例如“蛋糕+油炸食品：糖尿病人的飲食大忌”,“多吃蔬菜，多些健康！媽媽加油”。在家人的幫助和鼓勵下，我堅持了四個月的健康飲食。去醫院檢查血糖，醫生説我各項指標都有改善，我很開心。今後我還會繼續堅持健康飲食的！ "
                        ),
                      ]),
                      _c("img", {
                        staticClass: "fw8",
                        attrs: {
                          alt: "支持反饋9",
                          src: require("../../assets/img/di_support_story9.png"),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.story == 10
                  ? _c("div", [
                      _c("audio", {
                        staticClass: "gaudio",
                        attrs: {
                          controls: "",
                          controlsList: "nodownload",
                          preload: "auto",
                          src: require("@/assets/guide/diet/Week_10_Social_Support.mp3"),
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " 林女士（70歲）：兩年前我意外骨折，住院期間得知我有較嚴重的骨質疏鬆症。醫生告訴我，隨著身體的老化，骨密度會持續下降，未來骨折的風險更大。建議我要增強骨骼健康，除了服用鈣片，還需要在日常飲食中多吃對骨骼有幫助的食物。例如三文魚、沙丁魚、豆類食品和各類堅果。同時深色綠葉蔬菜（高麗菜和大頭菜）也能提供大量鈣質。"
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "我的家人們現在都會幫助我多吃對骨骼健康有益處的食物。老伴總是監督我每天多吃魚類、豆製品和深色蔬菜。子女們週末看我們，也都會買堅果讓我們閒時當小食。在家人的精心照顧下，我的骨密度一直沒有下降，也沒有骨折。我非常感激家人的支持。 "
                        ),
                      ]),
                      _c("img", {
                        staticClass: "fw8",
                        attrs: {
                          alt: "支持反饋10",
                          src: require("../../assets/img/di_support_story10.jpg"),
                        },
                      }),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.feedback == 3
            ? _c(
                "div",
                [
                  _c("p", [
                    _vm._v(
                      "你清楚地知道邊啲人可以支持你實現健康飲食嘅目標。非常好！繼續保持！"
                    ),
                  ]),
                  _c("p", { staticClass: "b" }, [
                    _vm._v(
                      " 希望你可以通過屋企人同朋友嘅幫助同埋支持，堅持進行健康飲食。 "
                    ),
                  ]),
                  _c("p"),
                  _c("cube-button", { on: { click: _vm.review } }, [
                    _vm._v("社會支持總結"),
                  ]),
                  _c("p"),
                  _c("p"),
                  _c("img", {
                    staticClass: "fw8",
                    attrs: {
                      alt: "支持反饋2",
                      src: require("../../assets/img/di_support_feedback3.jpg"),
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.page == 3
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("社會支持總結")]),
          _vm.revType == 1
            ? _c("div", [
                _c("p", [
                  _vm._v(" 1. 我可以同呢個人（呢啲人）"),
                  _c("span", { staticClass: "bu" }, [_vm._v("一齊")]),
                  _vm._v(
                    "進行健康飲食（如食多啲生果蔬菜同適量嘅肉、蛋及替代品）："
                  ),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.showcp(1))),
                  ]),
                ]),
                _c("p", [
                  _vm._v(" 2. 呢個人（呢啲人）可以"),
                  _c("span", { staticClass: "bu" }, [_vm._v("負責以確保")]),
                  _vm._v("有規律咁食多啲生果蔬菜同適量的肉、蛋及替代品："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.showcp(2))),
                  ]),
                ]),
                _c("p", [
                  _vm._v(
                    " 3. 當我盡力達到咗WHO推薦嘅健康飲食標準，呢個人（呢啲人）會"
                  ),
                  _c("span", { staticClass: "bu" }, [_vm._v("鼓勵同讚賞")]),
                  _vm._v("我："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.showcp(3))),
                  ]),
                ]),
                _c("p", [
                  _vm._v(
                    " 4. 呢個人（呢啲人）為咗令我達到WHO推薦嘅健康飲食標準會"
                  ),
                  _c("span", { staticClass: "bu" }, [_vm._v("幫我分擔")]),
                  _vm._v("一啲野："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.showcp(4))),
                  ]),
                ]),
                _c("p", [
                  _vm._v(" 5. 呢個人（呢啲人）可以為我進行健康飲食提出一啲"),
                  _c("span", { staticClass: "bu" }, [_vm._v("建議")]),
                  _vm._v("："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.showcp(5))),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.revType == 2
            ? _c("div", [
                _c("p", [
                  _vm._v(" 1．你通常會"),
                  _c("span", { staticClass: "bu" }, [_vm._v("同邊個一齊")]),
                  _vm._v("進行健康飲食："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.showcp(1))),
                  ]),
                ]),
                _c("p", [
                  _vm._v(" 2．邊個可以"),
                  _c("span", { staticClass: "bu" }, [_vm._v("負責同確保")]),
                  _vm._v("令你有規律咁食多啲生果蔬菜同適量的肉、蛋及替代品："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.showcp(2))),
                  ]),
                ]),
                _c("p", [
                  _vm._v(" 3．如果你盡力達到咗WHO推薦嘅健康飲食標準，邊個會"),
                  _c("span", { staticClass: "bu" }, [_vm._v("讚賞")]),
                  _vm._v("你："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.showcp(3))),
                  ]),
                ]),
                _c("p", [
                  _vm._v(
                    " 4．邊個會幫你分擔一啲其他嘅事（如幫你買生果蔬菜同肉類），進而"
                  ),
                  _c("span", { staticClass: "bu" }, [_vm._v("幫")]),
                  _vm._v("你達到WHO推薦嘅健康飲食標準："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.showcp(4))),
                  ]),
                ]),
                _c("p", [
                  _vm._v(" 5．喺進行健康飲食方面，邊個會俾你一啲"),
                  _c("span", { staticClass: "bu" }, [_vm._v("建議")]),
                  _vm._v("例如，食d咩生果蔬菜最有利於你嘅健康）："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.showcp(5))),
                  ]),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.page == 4
      ? _c(
          "div",
          { staticClass: "box" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("社會支持")]),
            _c("p", [
              _vm._v(
                " 上個禮拜你提出喺進行健康飲食嘅時候應該可以得到以下嘅社會支持，請你回憶你今個星期喺咪得到呢啲社會支持： "
              ),
            ]),
            _c("p", [
              _vm._v(" 1．我可以同呢個人（呢啲人）"),
              _c("span", { staticClass: "bu" }, [_vm._v("一齊")]),
              _vm._v(
                "進行健康飲食（如食多啲生果蔬菜同適量的肉、蛋及替代品）："
              ),
              _c("span", { staticClass: "red b" }, [
                _vm._v(_vm._s(_vm.showcp(1))),
              ]),
            ]),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.support.perEval.q1,
                  callback: function ($$v) {
                    _vm.$set(_vm.support.perEval, "q1", $$v)
                  },
                  expression: "support.perEval.q1",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: "是" } } },
                  [_vm._v(" 是")]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: "否" } } },
                  [_vm._v(" 否")]
                ),
              ],
              1
            ),
            _c("p", [
              _vm._v(" 2．呢個人（呢啲人）可以"),
              _c("span", { staticClass: "bu" }, [_vm._v("負責同確保")]),
              _vm._v("我有規律咁食多啲生果蔬菜同適量嘅肉、蛋及替代品："),
              _c("span", { staticClass: "red b" }, [
                _vm._v(_vm._s(_vm.showcp(2))),
              ]),
            ]),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.support.perEval.q2,
                  callback: function ($$v) {
                    _vm.$set(_vm.support.perEval, "q2", $$v)
                  },
                  expression: "support.perEval.q2",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: "是" } } },
                  [_vm._v(" 是")]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: "否" } } },
                  [_vm._v(" 否")]
                ),
              ],
              1
            ),
            _c("p", [
              _vm._v(
                " 3．當我盡力達到咗WHO推薦嘅健康飲食標準呢個人（呢啲人）會"
              ),
              _c("span", { staticClass: "bu" }, [_vm._v("讚賞")]),
              _vm._v("我："),
              _c("span", { staticClass: "red b" }, [
                _vm._v(_vm._s(_vm.showcp(3))),
              ]),
            ]),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.support.perEval.q3,
                  callback: function ($$v) {
                    _vm.$set(_vm.support.perEval, "q3", $$v)
                  },
                  expression: "support.perEval.q3",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: "是" } } },
                  [_vm._v(" 是")]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: "否" } } },
                  [_vm._v(" 否")]
                ),
              ],
              1
            ),
            _c("p", [
              _vm._v(" 4．呢個人（呢啲人）為咗令我達到WHO推薦嘅健康飲食標準會"),
              _c("span", { staticClass: "bu" }, [_vm._v("幫我")]),
              _vm._v("分擔一啲其他嘅事："),
              _c("span", { staticClass: "red b" }, [
                _vm._v(_vm._s(_vm.showcp(4))),
              ]),
            ]),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.support.perEval.q4,
                  callback: function ($$v) {
                    _vm.$set(_vm.support.perEval, "q4", $$v)
                  },
                  expression: "support.perEval.q4",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: "是" } } },
                  [_vm._v(" 是")]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: "否" } } },
                  [_vm._v(" 否")]
                ),
              ],
              1
            ),
            _c("p", [
              _vm._v(" 5．呢個人（呢啲人）可以為我進行健康飲食提出一啲"),
              _c("span", { staticClass: "bu" }, [_vm._v("建議")]),
              _vm._v("："),
              _c("span", { staticClass: "red b" }, [
                _vm._v(_vm._s(_vm.showcp(5))),
              ]),
            ]),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.support.perEval.q5,
                  callback: function ($$v) {
                    _vm.$set(_vm.support.perEval, "q5", $$v)
                  },
                  expression: "support.perEval.q5",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: "是" } } },
                  [_vm._v(" 是")]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: "否" } } },
                  [_vm._v(" 否")]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }