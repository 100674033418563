var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _vm.debugbtn
      ? _c("div", [
          _c("span", [_vm._v("頁面編號調試：")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.page,
                expression: "page",
              },
            ],
            domProps: { value: _vm.page },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.page = $event.target.value
              },
            },
          }),
        ])
      : _vm._e(),
    _vm.page == 1
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("健康飲食計劃")]),
          _c("p", [
            _vm._v(" 科學研究表明，訂下具體嘅"),
            _c("span", { staticClass: "bu" }, [_vm._v("“健康飲食計劃”")]),
            _vm._v("有助於我哋達到預期目標。 "),
            _c("span", { staticClass: "bu" }, [_vm._v("“健康飲食計劃”")]),
            _vm._v("嘅內容包括："),
            _c("span", { staticClass: "bu blue" }, [_vm._v("食咩食物")]),
            _vm._v("以及"),
            _c("span", { staticClass: "bu blue" }, [_vm._v("喺咩時間食")]),
            _vm._v("。 "),
          ]),
          _c("audio", {
            staticClass: "gaudio",
            attrs: {
              controls: "",
              controlsList: "nodownload",
              preload: "auto",
              src: require("@/assets/guide/diet/Week_1_Planning.mp3"),
            },
          }),
          _c("p", [
            _vm._v(
              " 何婆婆 (73歲)：我一直想建立健康飲食習慣。但以前我對自己每天吃什麼總是不太在意。一個月前，我參加了社區的長者健康飲食工作坊。學到了很多有關健康飲食的知識，同時學會制定飲食計劃。每天早上我都會認真思考當天會吃什麼生果蔬菜以及肉和魚，分別吃多少，並做一個詳細的計劃。這個計劃可以很好地幫助我在每天的飲食中維持均衡飲食，保持健康飲食習慣。 "
            ),
          ]),
          _c("img", {
            staticClass: "fw8",
            attrs: { src: require("../../assets/img/di_support_1.jpg") },
          }),
        ])
      : _vm._e(),
    _vm.page == 2
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("健康飲食計劃")]),
          _c("p", [
            _vm._v(" 我哋建議你運用以下"),
            _c("span", { staticClass: "bu" }, [_vm._v("兩大方法")]),
            _vm._v("黎制定健康飲食計劃。 "),
          ]),
          _c("p", [
            _vm._v("1. "),
            _c("span", { staticClass: "b" }, [_vm._v("適合性")]),
            _vm._v(" 呢啲食物喺咪適合你？"),
          ]),
          _c("p", [
            _vm._v("2. "),
            _c("span", { staticClass: "b" }, [_vm._v("有效性")]),
            _vm._v(" 食呢啲食物對你嘅健康有冇好處？"),
          ]),
        ])
      : _vm._e(),
    _vm.page == 3
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("健康飲食計劃")]),
          _c("p", [
            _vm._v("跟住落黎，請思考喺未來7日，你計劃"),
            _c("span", { staticClass: "bu blue" }, [_vm._v("喺咩時間")]),
            _vm._v("食生果蔬菜、肉、蛋同埋替代品。"),
          ]),
          _c(
            "div",
            { staticClass: "plan-container" },
            [
              _c("cube-tab-bar", {
                staticClass: "plan-left",
                attrs: { data: _vm.tabs },
                on: { change: _vm.changeWeek },
                model: {
                  value: _vm.weekSelect,
                  callback: function ($$v) {
                    _vm.weekSelect = $$v
                  },
                  expression: "weekSelect",
                },
              }),
              _c(
                "div",
                { staticClass: "plan-right" },
                [
                  _c("p", [
                    _vm._v("1.挑選邊種"),
                    _c("span", { staticClass: "bu" }, [_vm._v("食物類型")]),
                    _vm._v("？"),
                  ]),
                  _c("cube-select", {
                    attrs: { options: _vm.foodType },
                    on: { change: _vm.changeType },
                    model: {
                      value: _vm.typeSelect,
                      callback: function ($$v) {
                        _vm.typeSelect = $$v
                      },
                      expression: "typeSelect",
                    },
                  }),
                  _c("p", [
                    _vm._v("2.挑選邊種"),
                    _c("span", { staticClass: "bu" }, [_vm._v("食物")]),
                    _vm._v("？"),
                  ]),
                  _c("cube-checkbox-group", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.typeSelect == "生果",
                        expression: "typeSelect == '生果'",
                      },
                    ],
                    attrs: { options: _vm.fruit, shape: "square" },
                    model: {
                      value: _vm.fruitSelect,
                      callback: function ($$v) {
                        _vm.fruitSelect = $$v
                      },
                      expression: "fruitSelect",
                    },
                  }),
                  _c("cube-checkbox-group", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.typeSelect == "蔬菜",
                        expression: "typeSelect == '蔬菜'",
                      },
                    ],
                    attrs: { options: _vm.vg, shape: "square" },
                    model: {
                      value: _vm.vgSelect,
                      callback: function ($$v) {
                        _vm.vgSelect = $$v
                      },
                      expression: "vgSelect",
                    },
                  }),
                  _c("cube-checkbox-group", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.typeSelect == "肉類",
                        expression: "typeSelect == '肉類'",
                      },
                    ],
                    attrs: { options: _vm.meat, shape: "square" },
                    model: {
                      value: _vm.meatSelect,
                      callback: function ($$v) {
                        _vm.meatSelect = $$v
                      },
                      expression: "meatSelect",
                    },
                  }),
                  _c("cube-checkbox-group", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.typeSelect == "蛋類",
                        expression: "typeSelect == '蛋類'",
                      },
                    ],
                    attrs: { options: _vm.egg, shape: "square" },
                    model: {
                      value: _vm.eggSelect,
                      callback: function ($$v) {
                        _vm.eggSelect = $$v
                      },
                      expression: "eggSelect",
                    },
                  }),
                  _c("cube-checkbox-group", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.typeSelect == "替代品",
                        expression: "typeSelect == '替代品'",
                      },
                    ],
                    attrs: { options: _vm.bean, shape: "square" },
                    model: {
                      value: _vm.beanSelect,
                      callback: function ($$v) {
                        _vm.beanSelect = $$v
                      },
                      expression: "beanSelect",
                    },
                  }),
                  _c("cube-checkbox-group", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.typeSelect == "食物",
                        expression: "typeSelect == '食物'",
                      },
                    ],
                    attrs: { options: _vm.allFood, shape: "square" },
                    model: {
                      value: _vm.foodSelect,
                      callback: function ($$v) {
                        _vm.foodSelect = $$v
                      },
                      expression: "foodSelect",
                    },
                  }),
                  _c(
                    "div",
                    [
                      _c(
                        "cube-button",
                        {
                          staticClass: "btn_clear",
                          attrs: { primary: false },
                          on: { click: _vm.clear },
                        },
                        [_vm._v(" 清空")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "cube-button",
                        {
                          staticClass: "btn_clear",
                          attrs: { primary: true },
                          on: { click: _vm.applyAll },
                        },
                        [_vm._v(" 應用到本週")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("p", { staticClass: "b" }, [
            _vm._v(" 呢個計劃表將會儲存喺手機主頁"),
            _c("span", { staticClass: "red" }, [_vm._v("“健康飲食”")]),
            _vm._v("按鈕嘅“行動計劃”一欄之中，你可以隨時查閲。 "),
          ]),
        ])
      : _vm._e(),
    _vm.page == 4
      ? _c(
          "div",
          { staticClass: "box" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("健康飲食計劃")]),
            _c("p", [
              _vm._v(" 你上個禮拜已經學識咗制定“"),
              _c("span", { staticClass: "bu" }, [_vm._v("健康飲食計劃")]),
              _vm._v("”，包括："),
              _c("span", { staticClass: "bu blue" }, [_vm._v("咩時間")]),
              _vm._v("食生果蔬菜同埋"),
              _c("span", { staticClass: "bu blue" }, [_vm._v("咩時間")]),
              _vm._v("食肉、蛋同埋替代品。 "),
            ]),
            _c("p", [
              _vm._v(" 而家請回憶你上個禮拜制定嘅飲食計劃（"),
              _c(
                "span",
                {
                  staticClass: "bu",
                  on: {
                    click: function ($event) {
                      return _vm.reviewPlan()
                    },
                  },
                },
                [_vm._v("點擊查看")]
              ),
              _vm._v(
                "）同埋你完成呢個計劃嘅情況。請指出，喺最近7日實行呢個健康飲食計劃嘅實行。 "
              ),
            ]),
            _vm.plan.perEval.evl1 > 0
              ? _c("div", [
                  _vm.plan.perEval.evl1 == 1
                    ? _c("p", { staticClass: "red b" }, [
                        _vm._v("改變習慣唔易架，唔好氣餒，繼續加油！"),
                      ])
                    : _vm._e(),
                  _vm.plan.perEval.evl1 == 2
                    ? _c("p", { staticClass: "red b" }, [
                        _vm._v("仲有進步的空間，繼續加油!"),
                      ])
                    : _vm._e(),
                  _vm.plan.perEval.evl1 == 3
                    ? _c("p", { staticClass: "red b" }, [
                        _vm._v("做得非常好，請繼續保持！"),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.plan.perEval.evl1,
                  callback: function ($$v) {
                    _vm.$set(_vm.plan.perEval, "evl1", $$v)
                  },
                  expression: "plan.perEval.evl1",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "0", attrs: { option: { value: 1 } } },
                  [_c("p", [_vm._v("不好")])]
                ),
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: 2 } } },
                  [_c("p", [_vm._v("一般")])]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: 3 } } },
                  [_c("p", [_vm._v("好")])]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.page == 5
      ? _c(
          "div",
          { staticClass: "box" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("評估健康飲食計劃")]),
            _c("p", [
              _vm._v("而家請你運用以下"),
              _c("span", { staticClass: "bu" }, [_vm._v("兩大方法")]),
              _vm._v("黎評價一下你嘅飲食計劃。"),
            ]),
            _c("p", [
              _vm._v("1. "),
              _c("span", { staticClass: "b" }, [_vm._v("適合性")]),
              _vm._v(" 呢啲食物喺咪適合你？"),
            ]),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.plan.perEval.evl2,
                  callback: function ($$v) {
                    _vm.$set(_vm.plan.perEval, "evl2", $$v)
                  },
                  expression: "plan.perEval.evl2",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: "是" } } },
                  [_vm._v(" 是")]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: "否" } } },
                  [_vm._v(" 否")]
                ),
              ],
              1
            ),
            _c("p", [
              _vm._v("2. "),
              _c("span", { staticClass: "b" }, [_vm._v("有效性")]),
              _vm._v(" 食呢啲食物對你嘅健康有冇好處？"),
            ]),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.plan.perEval.evl3,
                  callback: function ($$v) {
                    _vm.$set(_vm.plan.perEval, "evl3", $$v)
                  },
                  expression: "plan.perEval.evl3",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: "是" } } },
                  [_vm._v(" 是")]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: "否" } } },
                  [_vm._v(" 否")]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.page == 6
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("健康飲食計劃表")]),
          _c("p", [_vm._v("呢度呈現嘅喺你過去7日所制定嘅健康飲食計劃。")]),
          _c(
            "ul",
            { staticClass: "list-wrapper vflex" },
            _vm._l(_vm.getPlanList(), function (item) {
              return _c(
                "li",
                { staticClass: "list-item", staticStyle: { display: "flex" } },
                [
                  _c("div", { staticClass: "list-left" }, [
                    _vm._v(" " + _vm._s(item.date) + " "),
                  ]),
                  _c("div", { staticClass: "list-right" }, [
                    _vm._v(" " + _vm._s(item.action) + " "),
                  ]),
                ]
              )
            }),
            0
          ),
          !_vm.reviewMode
            ? _c(
                "div",
                [
                  _c("p", [_vm._v("你可以選擇是否進行調整。")]),
                  _c(
                    "cube-radio-group",
                    {
                      model: {
                        value: _vm.modify,
                        callback: function ($$v) {
                          _vm.modify = $$v
                        },
                        expression: "modify",
                      },
                    },
                    [
                      _c(
                        "cube-radio",
                        { key: "1", attrs: { option: { value: 1 } } },
                        [_vm._v(" 修改")]
                      ),
                      _c(
                        "cube-radio",
                        { key: "0", attrs: { option: { value: 0 } } },
                        [_vm._v(" 唔修改")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }