<template>
    <wrapper>
        <div v-if="debugbtn">
            <span>頁面編號調試：</span>
            <input v-model="page" />
        </div>
        <div
            v-if="page == 1"
            class="box"
        >
            <div class="title">運動鍛煉計劃</div>
            <p>
                科學研究表明，制定具體嘅<span class="bu">“運動鍛煉計劃”</span>有助於我哋達到預期目標。
                <span class="bu">“運動鍛煉計劃”</span>嘅內容包括：
                <span class="bu blue">做咩運動</span>，
                <span class="bu blue">咩時間運動</span>，
                <span class="bu blue">同邊個一齊運動</span>同埋
                <span class="bu blue">喺邊度做運動</span>。
            </p>
            <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/pa/Week_1_Wang_78.mp3" ></audio>
            <p>
                王先生（78歲）：我一直想做更多運動，但以前我經常忘記。後來我慢慢發現，如果計劃好和朋友在某一天早上去公園做拉伸運動，我從來不會忘記，可見具體的計劃可以幫助我保持運動的決心，也可以幫助我堅持進行有規律的運動。
            </p>
            <img
                class="fw8"
                alt="運動i健康"
                src="../../assets/img/pa_plan1.jpg"
            />
        </div>
        <div
            v-if="page == 2"
            class="box"
        >
            <div class="title">運動鍛煉計劃</div>
            <p>
                我哋建議你運用以下<span class="bu">2大方法</span>黎制定運動鍛煉計劃。
            </p>

            <p>1. <span class="b red">適合性</span> 咁樣嘅運動適唔適合你做？</p>
            <p>2. <span class="b red">有效性</span> 咁樣嘅運動對你嘅健康有冇好處？</p>

            <p>想要堅持運動，選擇適合自己嘅運動項目非常重要。</p>
            <p class = "blue b">請喺下低選擇或者添加最近你想進行嘅運動項目。</p>

            <p>我最想進行嘅運動項目係</p>
            <p class="select_line">
                <cube-select
                    class="inline_select"
                    v-model="plan.data[0].type"
                    :options="typelist(0)"
                >
                </cube-select>
                <span v-if="plan.data[0].type == '其他'">請輸入<cube-input
                        class="other_input"
                        v-model="plan.data[0].type_other"
                    >
                    </cube-input></span>。
            </p>

            <div v-if="!plan.oneType">
                <p>我仲想進行嘅運動項目喺</p>
                <p class="select_line">
                    <cube-select
                        class="inline_select"
                        v-model="plan.data[1].type"
                        :options="typelist(1)"
                    >
                    </cube-select>
                    <span v-if="plan.data[1].type == '其他'">請輸入<cube-input
                            class="other_input"
                            v-model="plan.data[1].type_other"
                        >
                        </cube-input></span>。
                </p>
            </div>
            <cube-checkbox
                class="inline_checkbox font_large"
                v-model="plan.oneType"
            >暫時只想進行一項運動</cube-checkbox>

            <img
                class="fw8"
                src="../../assets/img/pa_plan1.jpg"
            />
        </div>

        <div
            v-if="page == 3"
            class="box"
        >
            <div class="title">運動鍛煉計劃</div>
            <p class="select_line">
                跟住落黎，請你添加喺咩時間，咩地點，同埋同邊個一齊參加<cube-select
                    class="inline_select"
                    v-model="typeSelect"
                    :options="typelist(2)"
                    @change="changeType"
                >
                </cube-select>。
            </p>
            <div class="plan-container">
                <cube-tab-bar
                    v-model="weekSelect"
                    :data="tabs"
                    class="plan-left"
                    @change="changeWeek"
                ></cube-tab-bar>
                <div class="plan-right">
                    <p>1.喺<span class="bu">邊個時間/時段</span>運動？</p>
                    <cube-select
                        v-model="whenSelect"
                        :options="allTime"
                    > </cube-select>
                    <p>2.喺<span class="bu">邊度</span>運動？</p>
                    <cube-select
                        v-model="whereSelect"
                        :options="allPlace"
                    > </cube-select>
                    <span
                        v-if="whereSelect == '其他'"
                        style="line-height: 50px;"
                    >請輸入<cube-input
                            class="other_input"
                            v-model="whereOther"
                        ></cube-input></span>
                    <p>3.<span class="bu">同邊個</span>一齊運動？</p>
                    <cube-select
                        v-model="whoSelect"
                        :options="allCP"
                    > </cube-select>
                    <span
                        v-if="whoSelect == '其他'"
                        style="line-height: 50px;"
                    >請輸入<cube-input
                            class="other_input"
                            v-model="whoOther"
                        ></cube-input></span>

                    <div>
                        <cube-button
                            class="btn_clear"
                            @click="clear"
                            :primary="true"
                        > 清空</cube-button>
                    </div>
                    <div v-show="true">
                        <cube-button
                            class="btn_clear"
                            @click="applyAll"
                            :primary="true"
                        > 應用到本週</cube-button>
                    </div>
                </div>
            </div>
            <p class="b">
                你嘅運動計劃表將會儲存喺手機主頁<span class="red">“運動鍛煉”</span>內嘅“行動計劃”一欄中，你可以隨時查閲。
            </p>
        </div>

        <div
            v-if="page == 4"
            class="box"
        >
            <div class="title">評估運動鍛煉計劃</div>
            <p>
                你上個禮拜已經學識咗制定“<span class="bu">運動鍛煉計劃</span>”，包括：
                <span class="bu blue">做咩運動</span>，
                <span class="bu blue">咩時間運動</span>，
                <span class="bu blue">同邊個一齊運動</span>同埋
                <span class="bu blue">喺邊度做運動</span>。今個星期，首先等我哋評估下你上個禮拜計劃嘅實行情況。
            </p>
            <p>
                而家請回憶你上個禮拜制定嘅運動計劃（<span
                    class="bu"
                    @click="reviewPlan()"
                >點擊查看</span>）同埋你完成呢個計劃嘅情況。請指出，喺最近7日內實行呢個運動計劃嘅情況。
            </p>
                        <div v-if="plan.perEval.evl1>0">
                <p
                    v-if="plan.perEval.evl1 ==1"
                    class="red b"
                >改變習慣唔易架，唔好氣餒，繼續加油！</p>
                <p
                    v-if="plan.perEval.evl1 ==2"
                    class="red b"
                >仲有進步嘅空間，繼續加油!</p>
                <p
                    v-if="plan.perEval.evl1 ==3"
                    class="red b"
                >做得非常好，請繼續保持！</p>
            </div>
            <cube-radio-group v-model="plan.perEval.evl1">
                <cube-radio
                    key="0"
                    :option="{ value: 1 }"
                >
                    <p>不好</p>
                </cube-radio>
                <cube-radio
                    key="1"
                    :option="{ value: 2 }"
                >
                    <p>一般</p>
                </cube-radio>
                <cube-radio
                    key="2"
                    :option="{ value: 3 }"
                >
                    <p>好</p>
                </cube-radio>
            </cube-radio-group>

        </div>

        <div
            v-if="page == 5"
            class="box"
        >
            <div class="title">評估運動鍛煉計劃</div>
            <p>而家請你運用下低<span class="bu">2大方法</span>黎評估你嘅運動鍛煉計劃。</p>
            <p>1. <span class="b">適合性</span> 咁樣嘅運動適合你做嗎？</p>
            <cube-radio-group v-model="plan.perEval.evl2">
                <cube-radio
                    key="1"
                    :option="{ value: '是' }"
                > 是</cube-radio>
                <cube-radio
                    key="2"
                    :option="{ value: '否' }"
                > 否</cube-radio>
            </cube-radio-group>
            <p>2. <span class="b">有效性</span> 咁樣嘅運動對你嘅健康有冇好處？</p>
            <cube-radio-group v-model="plan.perEval.evl3">
                <cube-radio
                    key="1"
                    :option="{ value: '是' }"
                > 是</cube-radio>
                <cube-radio
                    key="2"
                    :option="{ value: '否' }"
                > 否</cube-radio>
            </cube-radio-group>
        </div>

        <div
            v-if="page == 6"
            class="box"
        >
            <div class="title">運動鍛煉計劃表</div>
            <p>呢度呈現嘅係你當前嘅運動鍛煉計劃。</p>
            <ul class="list-wrapper vflex">
                <li
                    v-for="item in getPlanList()"
                    class="list-item"
                    style="display: flex"
                >
                    <div class="list-left">
                        {{ item.date }}
                    </div>
                    <div class="list-right">
                        <span :class="{ red: item.type == 1, blue: item.type == 2 }">
                            {{ item.action }}
                        </span>
                    </div>
                </li>
            </ul>
            <div v-if="!reviewMode && !atEnd">
                <p>你可以選擇是否要進行調整。</p>
                <cube-radio-group v-model="modify">
                    <cube-radio
                        key="1"
                        :option="{ value: 1 }"
                    > 修改</cube-radio>
                    <cube-radio
                        key="0"
                        :option="{ value: 0 }"
                    > 不修改</cube-radio>
                </cube-radio-group>
            </div>

        </div>
    </wrapper>
</template>

<script>
import wrapper from "../wrapper.vue";
import tools from "@/data/tools";
import {
    allType,
    allTime,
    allPlace,
    allCP,
    weekDays,
    validErr,
} from "@/data/data";

const tabWeek = (function () {
    let list = [];
    for (let i = 0; i < weekDays.length; i++) {
        list.push({ label: weekDays[i], value: i });
    }
    return list;
})();

export default {
    name: "pa_plan",
    data() {
        return {
            page: 0,
            typeSelect: "",
            weekSelect: 0,
            weekIndex: 0,
            whenSelect: "",
            whereSelect: "",
            whereOther: "",
            whoSelect: "",
            whoOther: "",
            tabs: tabWeek,
            allType: allType,
            allTime: allTime,
            allPlace: allPlace,
            allCP: allCP,
            reviewMode: false,
            modify: 0,
            atEnd: false,
            havePlan: false,
            plan: {
                oneType: false,
                perEval: {
                    evl1: 0,
                    evl2: "",
                    evl3: "",
                },
                data: [
                    {
                        type: "",
                        type_other: "",
                        detail: [
                            {
                                when: "",
                                where: "",
                                where_other: "",
                                who: "",
                                who_other: "",
                            },
                            {
                                when: "",
                                where: "",
                                where_other: "",
                                who: "",
                                who_other: "",
                            },
                            {
                                when: "",
                                where: "",
                                where_other: "",
                                who: "",
                                who_other: "",
                            },
                            {
                                when: "",
                                where: "",
                                where_other: "",
                                who: "",
                                who_other: "",
                            },
                            {
                                when: "",
                                where: "",
                                where_other: "",
                                who: "",
                                who_other: "",
                            },
                            {
                                when: "",
                                where: "",
                                where_other: "",
                                who: "",
                                who_other: "",
                            },
                            {
                                when: "",
                                where: "",
                                where_other: "",
                                who: "",
                                who_other: "",
                            },
                        ],
                    },
                    {
                        type: "",
                        type_other: "",
                        detail: [
                            {
                                when: "",
                                where: "",
                                where_other: "",
                                who: "",
                                who_other: "",
                            },
                            {
                                when: "",
                                where: "",
                                where_other: "",
                                who: "",
                                who_other: "",
                            },
                            {
                                when: "",
                                where: "",
                                where_other: "",
                                who: "",
                                who_other: "",
                            },
                            {
                                when: "",
                                where: "",
                                where_other: "",
                                who: "",
                                who_other: "",
                            },
                            {
                                when: "",
                                where: "",
                                where_other: "",
                                who: "",
                                who_other: "",
                            },
                            {
                                when: "",
                                where: "",
                                where_other: "",
                                who: "",
                                who_other: "",
                            },
                            {
                                when: "",
                                where: "",
                                where_other: "",
                                who: "",
                                who_other: "",
                            },
                        ],
                    },
                ],
            },
            perweek: {},
        };
    },
    computed: {
        type: function () {
            return this.$store.state.type;
        },
        num: function () {
            return this.$store.state.week;
        },
        user: function () {
            return this.$store.state.user;
        },
        pa: function () {
            return this.$store.state.pa;
        },
        debugbtn: function () {
            return this.$store.state.debugbtn;
        },
        
    },
    created() {
        if (!this.page) {
            if (this.num == 1) this.page = 1;
            else this.page = 4;
        }
        if (this.$store.state.pa[this.num] != null) {
            this.plan = tools.copy(this.$store.state.pa[this.num].plan);
        } else {
            //編輯，先查看是否有緩存
            if (this.$store.state.pa[0].plan) {
                this.plan = this.$store.state.pa[0].plan;
            }
        }
        // 無論查看還是編輯， 初始化上週內容
        if (this.$store.state.pa[this.num - 1] != null) {
            this.perweek = tools.copy(this.$store.state.pa[this.num - 1].plan);
        }

        if (!this.$store.state.review && this.num != 1) {
            this.plan = tools.copy(this.perweek);
            this.plan.perEval.evl1 = 0;
            this.plan.perEval.evl2 = "";
            this.plan.perEval.evl3 = "";
        }

        this.whenSelect = this.plan.data[0].detail[0].when;
        this.whereSelect = this.plan.data[0].detail[0].where;
        this.whereOther = this.plan.data[0].detail[0].where_other;
        this.whoSelect = this.plan.data[0].detail[0].who;
        this.whoOther = this.plan.data[0].detail[0].who_other;
    },
    methods: {
        clear: function () {
            this.whenSelect = "";
            this.whereSelect = "";
            this.whereOther = "";
            this.whoSelect = "";
            this.whoOther = "";
            let day = this.plan.data[this.typeOrder(this.typeSelect)].detail[this.weekIndex];
                            day.when = this.whenSelect;
                day.where = this.whereSelect;
                day.where_other = this.whereOther;
                day.who = this.whoSelect;
                day.who_other = this.whoOther;
        },
        applyAll: function () {
                        this.saveDay(
                this.weekIndex,
                this.weekIndex
            );
            let theType = this.typeOrder(this.typeSelect);
            let day = this.plan.data[theType].detail[this.weekIndex];
            for (let i = 0; i < this.plan.data[theType].detail.length; i++) {
                this.plan.data[theType].detail[i] = tools.copy(day);
            }
        },
        getPlanList: function (select) {
            let plan = this.plan;
            let list = [];
            for (let i = 0; i < plan.data[0].detail.length; i++) {
                let obj = {};
                if (plan.data[0].detail[i].when) 
                {
                    obj.date = weekDays[i];
                    obj.type = 1;
                    obj.action = plan.data[0].detail[i].when +
                            (plan.data[0].detail[i].who == "自己"?"":
                            "和" +
                            (plan.data[0].detail[i].who == "其他"
                                ? plan.data[0].detail[i].who_other
                                : plan.data[0].detail[i].who) +
                            "一起" )+ "到" +
                            (plan.data[0].detail[i].where == "其他"
                                ? plan.data[0].detail[i].where_other
                                : plan.data[0].detail[i].where) +
                            (plan.data[0].type == "其他"
                                ? plan.data[0].type_other
                                : plan.data[0].type);
                    if (!select || select == 1) list.push(obj);
                }
                obj = {};
                if (!plan.oneType && plan.data[1].detail[i].when) 
                {
                    obj.date = weekDays[i];
                    obj.type = 2;
                    obj.action = plan.data[1].detail[i].when +
                            (plan.data[1].detail[i].who == "自己"?"":
                            "和" +
                            (plan.data[1].detail[i].who == "其他"
                                ? plan.data[1].detail[i].who_other
                                : plan.data[1].detail[i].who) +
                            "一起" )+ "到" +
                            (plan.data[1].detail[i].where == "其他"
                                ? plan.data[1].detail[i].where_other
                                : plan.data[1].detail[i].where) +
                            (plan.data[1].type == "其他"
                                ? plan.data[1].type_other
                                : plan.data[1].type);
                    if (!select || select == 2) list.push(obj);
                }
            }

            console.log(list);
            return list;
        },
        reviewPlan: function () {
            console.log("click reviewPlan");
            this.reviewMode = true;
            this.plan = tools.copy(this.perweek);
            this.page = 6;
        },
        typelist: function (order) {
            let list = [];
            if (order == 2) {
                list.push(
                    this.plan.data[0].type == "其他"
                        ? this.plan.data[0].type_other
                        : this.plan.data[0].type
                );
                if (!this.plan.oneType) {
                    list.push(
                        this.plan.data[1].type == "其他"
                            ? this.plan.data[1].type_other
                            : this.plan.data[1].type
                    );
                }
                return list;
            }
            for (let item of allType) {
                if (order === 0 && item != this.plan.data[1].type) {
                    list.push(item);
                } else if (order === 1 && item != this.plan.data[0].type) {
                    list.push(item);
                } else if (item == "其他") list.push(item);
            }
            return list;
        },
        typeOrder: function (val) {
            if (
                val == this.plan.data[0].type ||
                val == this.plan.data[0].type_other
            ) {
                return 0;
            } else {
                return 1;
            }
        },
        saveDay: function (oldOrder, newOrder, otherType) {
            let valid =
                this.typeSelect &&
                this.whenSelect &&
                this.whereSelect &&
                this.whoSelect;
            if (this.whereSelect == "其他") valid = valid && this.whereOther;
            if (this.whoSelect == "其他") valid = valid && this.whoOther;

            if (!valid) {
                // const toast = this.$createToast({
                //     txt: '計劃未填寫完整',
                //     type: 'alert',
                //     time: 1000,
                // });
                // toast.show();
            } else {
                let theType = this.typeOrder(this.typeSelect);
                if(otherType)
                {
                    if(theType == 1)
                        theType = 0;
                    else
                        theType = 1;
                }
                let day =
                    this.plan.data[theType].detail[
                        oldOrder
                    ];
                day.when = this.whenSelect;
                day.where = this.whereSelect;
                day.where_other = this.whereOther;
                day.who = this.whoSelect;
                day.who_other = this.whoOther;
            }

            let day =
                this.plan.data[this.typeOrder(this.typeSelect)].detail[
                    newOrder
                ];
            this.whenSelect = day.when;
            this.whereSelect = day.where;
            this.whereOther = day.where_other;
            this.whoSelect = day.who;
            this.whoOther = day.who_other;
        },
        changeWeek: function (value) {
            this.saveDay(this.weekIndex, value);
            this.weekIndex = value;

            console.log(JSON.stringify(this.plan.data[0].detail));
        },
        changeType: function (value, index) {
            this.saveDay(this.weekIndex, this.weekIndex, true);
            let day =
                this.plan.data[this.typeOrder(value)].detail[this.weekIndex];
            this.whenSelect = day.when;
            this.whereSelect = day.where;
            this.whereOther = day.where_other;
            this.whoSelect = day.who;
            this.whoOther = day.who_other;
        },
        save: function () {
            if (this.$store.state.review) return;
            let xml = {};
            xml.key = [
                "運動計劃1",
                "運動計劃2",
                "上週實行情況",
                "適合性評價",
                "有效性評價",
            ];
            xml.data = [];
            let planText = [];
            for (let txt of this.getPlanList(1)) {
                planText.push(txt.date + txt.action);
            }
            xml.data.push(planText.join(";"));
            planText = [];
            for (let txt of this.getPlanList(2)) {
                planText.push(txt.date + txt.action);
            }
            xml.data.push(planText.join(";"));
            xml.data.push(
                ["未填寫", "不好", "一般", "好"][this.plan.perEval.evl1]
            );
            xml.data.push(this.plan.perEval.evl2);
            xml.data.push(this.plan.perEval.evl3);
            this.plan.xml = xml;

            this.$store.state.pa[0].plan = tools.copy(this.plan);
        },
        pause: function () {
            this.save();
        },
        leave: function (page) {
            if (typeof this.page == "string") this.page = parseInt(this.page);

            if (page == 2) {
                this.typeSelect = this.typelist(2)[0];
            } else if (page == 3) {
                this.saveDay(this.weekIndex, this.weekIndex);
            } else if (page == 4) {
                this.reviewMode = false;
            }
        },
        valid: function (page) {
            if (page == 2) {
                if (
                    (this.plan.data[0].type == "其他" &&
                        !this.plan.data[0].type_other) ||
                    this.plan.data[0].type == ""
                )
                    return validErr.empty;
                if (
                    !this.plan.oneType &&
                    ((this.plan.data[1].type == "其他" &&
                        !this.plan.data[1].type_other) ||
                        this.plan.data[1].type == "")
                )
                    return validErr.empty;
            } else if (page == 3) {
                this.saveDay(this.weekIndex, this.weekIndex);
                if (this.getPlanList(1).length == 0) return validErr.empty;
                if (!this.plan.oneType && this.getPlanList(2).length == 0) return validErr.empty;
            } else if (page == 4) {
                if (!this.plan.perEval.evl1) return validErr.empty;
            } else if (page == 5) {
                if (
                    this.plan.perEval.evl2.length != 1 ||
                    this.plan.perEval.evl3.length != 1
                )
                    return validErr.empty;
            }
        },

        next: function () {
            console.log("plan do next");
            if (this.valid(this.page)) {
                this.$createDialog({
                    type: "alert",
                    title: "",
                    content: this.valid(this.page),
                }).show();
                return;
            }

            this.leave(this.page);

            let done = false;
            switch (this.page) {
                // 引導頁
                case 1:
                    this.page = 2;
                    break;
                // 項目選擇頁
                case 2:
                    this.page = 3;
                    break;
                // 計劃制定頁
                case 3:
                    //done = true;
                    this.modify = false;
                    this.reviewMode = false;
                    this.atEnd = true;
                    this.page = 6;
                    break;
                // 評價上週計劃
                case 4:
                    this.page = 5;
                    break;
                // 再次評價上週計劃
                case 5:
                    this.page = 6;
                    break;
                // 計劃展示頁
                case 6:
                    if (this.modify) this.page = 2;
                    else if (this.reviewMode) this.page = 4;
                    else done = true;
            }
            if (done) {
                this.save();
                return this.page;
            }
        },
    },

    components: { wrapper },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../assets/css/learn_common.css" />
<style scoped>
#list {
    margin: 0 auto;
}

.plan-container {
    display: grid;
    grid-template-columns: minmax(80px, 25%) 1fr;
}

.plan-left {
    flex-wrap: wrap;
    margin: 5px;
    flex-direction: column;
    font-size: 24px;
   /* border: 1px dashed #5b5b5b; */
}
.plan-right {
    margin-left:2px;
    padding-left:5px;
border-left : 2px solid red;
}

/* 下面這個選擇器不生效 */
.cube-tab {
    width: 62.5px;
    flex-basis: unset;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
}

.vflex {
    display: flex;
    flex-direction: column;
}

.list-wrapper {
    padding: 20px 10px 0px 10px;
}

.list-item {
    min-height: 100px;
}

.list-left {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #5b5b5b;
}

.list-right {
    width: 100%;
    border: 1px dashed red;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    padding: 20px;
    line-height: 24px;
    word-wrap: break-word;
    word-break: break-all;
}

.rev_btn {
    display: inline;
    width: 60px;
    height: 20px;
}

.btn_clear {
    margin: 20px;
    width: 60%;
    font-size :20px;
}

.inline {
    display: inline;
}
</style>