var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _vm.debugbtn
      ? _c("div", [
          _c("span", [_vm._v("頁面編號調試：")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.page,
                expression: "page",
              },
            ],
            domProps: { value: _vm.page },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.page = $event.target.value
              },
            },
          }),
        ])
      : _vm._e(),
    _vm.page == 1
      ? _c("div", { staticClass: "box" }, [
          !_vm.repeat
            ? _c("div", [
                _c("div", { staticClass: "title" }, [_vm._v("社會支持")]),
                _c("p", [
                  _c("span", { staticClass: "bu blue" }, [_vm._v("社會支持")]),
                  _vm._v(
                    "對於我哋參與同堅持運動鍛煉起到重要嘅作用！跟住落黎我哋一齊睇下，你覺得邊啲人可以幫你更加積極去參與運動鍛煉。 "
                  ),
                ]),
              ])
            : _c("div", [
                _c("p", { staticClass: "red" }, [
                  _vm._v(
                    " 請你再次諗下邊啲人可以幫到你參與同堅持運動，並繼續完成啱先揀“否”嘅條目。 "
                  ),
                ]),
              ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.repeat || _vm.reList.indexOf(1) != -1,
                  expression: "!repeat || reList.indexOf(1) != -1",
                },
              ],
            },
            [
              _c("p", [
                _vm._v(" 1．你通常會"),
                _c("span", { staticClass: "bu blue" }, [_vm._v("同邊個一齊")]),
                _vm._v("去做運動？ （可多選） "),
              ]),
              _c("cube-checkbox-group", {
                attrs: { options: _vm.allSupport2 },
                on: { input: _vm.checkNone },
                model: {
                  value: _vm.support.q1,
                  callback: function ($$v) {
                    _vm.$set(_vm.support, "q1", $$v)
                  },
                  expression: "support.q1",
                },
              }),
              _vm.haveOther(_vm.support.q1)
                ? _c(
                    "p",
                    [
                      _vm._v("   其他人喺指:  "),
                      _c("cube-input", {
                        staticClass: "other_input",
                        model: {
                          value: _vm.support.q1_other,
                          callback: function ($$v) {
                            _vm.$set(_vm.support, "q1_other", $$v)
                          },
                          expression: "support.q1_other",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("br"),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.repeat || _vm.reList.indexOf(2) != -1,
                  expression: "!repeat || reList.indexOf(2) != -1",
                },
              ],
            },
            [
              _c("p", [
                _vm._v(" 2．邊個可以"),
                _c("span", { staticClass: "bu blue" }, [_vm._v("負責同確保")]),
                _vm._v("令你進行有規律嘅運動鍛煉？（可多選） "),
              ]),
              _c("cube-checkbox-group", {
                attrs: { options: _vm.allSupport2 },
                on: { input: _vm.checkNone },
                model: {
                  value: _vm.support.q2,
                  callback: function ($$v) {
                    _vm.$set(_vm.support, "q2", $$v)
                  },
                  expression: "support.q2",
                },
              }),
              _vm.haveOther(_vm.support.q2)
                ? _c(
                    "p",
                    [
                      _vm._v("   其他人喺指:  "),
                      _c("cube-input", {
                        staticClass: "other_input",
                        model: {
                          value: _vm.support.q2_other,
                          callback: function ($$v) {
                            _vm.$set(_vm.support, "q2_other", $$v)
                          },
                          expression: "support.q2_other",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("br"),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.repeat || _vm.reList.indexOf(3) != -1,
                  expression: "!repeat || reList.indexOf(3) != -1",
                },
              ],
            },
            [
              _c("p", [
                _vm._v(" 3．如果你盡力達到咗WHO推薦嘅運動鍛煉標準，邊個會"),
                _c("span", { staticClass: "bu blue" }, [_vm._v("讚賞")]),
                _vm._v("你？（可多選） "),
              ]),
              _c("cube-checkbox-group", {
                attrs: { options: _vm.allSupport2 },
                on: { input: _vm.checkNone },
                model: {
                  value: _vm.support.q3,
                  callback: function ($$v) {
                    _vm.$set(_vm.support, "q3", $$v)
                  },
                  expression: "support.q3",
                },
              }),
              _vm.haveOther(_vm.support.q3)
                ? _c(
                    "p",
                    [
                      _vm._v("   其他人喺指:  "),
                      _c("cube-input", {
                        staticClass: "other_input",
                        model: {
                          value: _vm.support.q3_other,
                          callback: function ($$v) {
                            _vm.$set(_vm.support, "q3_other", $$v)
                          },
                          expression: "support.q3_other",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("br"),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.repeat || _vm.reList.indexOf(4) != -1,
                  expression: "!repeat || reList.indexOf(4) != -1",
                },
              ],
            },
            [
              _c("p", [
                _vm._v(
                  " 4．邊個會替你分擔一啲其他嘅事（比如，做家務等），進而"
                ),
                _c("span", { staticClass: "bu blue" }, [_vm._v("幫助")]),
                _vm._v("你進行運動鍛煉？（可多選） "),
              ]),
              _c("cube-checkbox-group", {
                attrs: { options: _vm.allSupport },
                on: { input: _vm.checkNone },
                model: {
                  value: _vm.support.q4,
                  callback: function ($$v) {
                    _vm.$set(_vm.support, "q4", $$v)
                  },
                  expression: "support.q4",
                },
              }),
              _vm.haveOther(_vm.support.q4)
                ? _c(
                    "p",
                    [
                      _vm._v("   其他人喺指:  "),
                      _c("cube-input", {
                        staticClass: "other_input",
                        model: {
                          value: _vm.support.q4_other,
                          callback: function ($$v) {
                            _vm.$set(_vm.support, "q4_other", $$v)
                          },
                          expression: "support.q4_other",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("br"),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.repeat || _vm.reList.indexOf(5) != -1,
                  expression: "!repeat || reList.indexOf(5) != -1",
                },
              ],
            },
            [
              _c("p", [
                _vm._v(" 5．當你進行運動鍛煉嘅時候，邊個會俾你一啲"),
                _c("span", { staticClass: "bu blue" }, [_vm._v("建議")]),
                _vm._v("（如，做咩運動，點樣做運動等）？（可多選） "),
              ]),
              _c("cube-checkbox-group", {
                attrs: { options: _vm.allSupport },
                on: { input: _vm.checkNone },
                model: {
                  value: _vm.support.q5,
                  callback: function ($$v) {
                    _vm.$set(_vm.support, "q5", $$v)
                  },
                  expression: "support.q5",
                },
              }),
              _vm.haveOther(_vm.support.q5)
                ? _c(
                    "p",
                    [
                      _vm._v("   其他人喺指:  "),
                      _c("cube-input", {
                        staticClass: "other_input",
                        model: {
                          value: _vm.support.q5_other,
                          callback: function ($$v) {
                            _vm.$set(_vm.support, "q5_other", $$v)
                          },
                          expression: "support.q5_other",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("br"),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.page == 2
      ? _c("div", { staticClass: "box" }, [
          _vm.debugbtn
            ? _c("div", [
                _c("span", [_vm._v("頁面反饋調試：")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.feedback,
                      expression: "feedback",
                    },
                  ],
                  domProps: { value: _vm.feedback },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.feedback = $event.target.value
                    },
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback == 1 || _vm.feedback == 2
            ? _c("div", [
                _vm.debugbtn
                  ? _c("div", [
                      _c("span", [_vm._v("頁面故事調試：")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.story,
                            expression: "story",
                          },
                        ],
                        domProps: { value: _vm.story },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.story = $event.target.value
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.feedback == 1
                  ? _c("p", { staticClass: "blue" }, [
                      _vm._v(
                        " 你似乎唔太清楚邊啲人可以幫你參與同堅持運動。唔緊要，其他人嘅例子可能會對你有所啟發。 "
                      ),
                    ])
                  : _vm._e(),
                _vm.feedback == 2
                  ? _c("p", { staticClass: "dark_green" }, [
                      _vm._v(
                        " 你已經諗到咗一啲可以幫你嘅人。但是仲有一啲情況你唔清楚邊個可以幫到你。唔緊要，其他人嘅例子可能會對你有所啟發。 "
                      ),
                    ])
                  : _vm._e(),
                _vm.story == 1
                  ? _c("div", [
                      _c("audio", {
                        staticClass: "gaudio",
                        attrs: {
                          controls: "",
                          controlsList: "nodownload",
                          preload: "auto",
                          src: require("@/assets/guide/pa/Week_1_YANG_72.mp3"),
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " 楊先生（72歲）：我現在每星期都會參加三次老人中心舉辦的“長者健康行”活動。進行有規律的運動鍛煉，一方面提高心肺功能和身體體質，另一方面令我有機會認識新朋友。大家一起參加這個活動，我覺得好有趣。有時我們亦會相約一起做運動，互相鼓勵。 "
                        ),
                      ]),
                      _c("img", {
                        staticClass: "fw8",
                        attrs: {
                          alt: "支持反饋1",
                          src: require("../../assets/img/pa_spstory1.png"),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.story == 2
                  ? _c("div", [
                      _c("audio", {
                        staticClass: "gaudio",
                        attrs: {
                          controls: "",
                          controlsList: "nodownload",
                          preload: "auto",
                          src: require("@/assets/guide/pa/Week_2_Social_Support.mp3"),
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " 李女士（67歲）：我不太喜歡運動，而且我發現一個人運動很難堅持。所以我現在經常相約和我住一區的姐妹們一起運動。平時我們大家會一起去公園健步行，有時週末，大家會一起去遠足或者爬山。 "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " 慢慢有越來越多人開始加入我們。我很喜歡和大家一起運動，我覺得非常開心，精神也比以前更好了，大家的陪伴也讓我更容易堅持進行運動。 "
                        ),
                      ]),
                      _c("img", {
                        staticClass: "fw8",
                        attrs: {
                          alt: "支持反饋2",
                          src: require("../../assets/img/pa_spstory2.png"),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.story == 3
                  ? _c("div", [
                      _c("audio", {
                        staticClass: "gaudio",
                        attrs: {
                          controls: "",
                          controlsList: "nodownload",
                          preload: "auto",
                          src: require("@/assets/guide/pa/Week_3_WANG_65.mp3"),
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " 王先生（65歲）：我總是覺得沒有時間運動，而且覺得年齡大，運動很容易受傷。我兒子説他晚上回家經常看到一些年長的街坊在附近公園慢跑。為了鼓勵我進行鍛煉，他也經常陪我一起去公園跑步。 "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " 起初我只能慢跑十分鐘，還感覺很累，後來慢慢地可以跑更長時間。有了兒子的陪伴和鼓勵，我對自己堅持運動更有信心了。因為在一起運動，我們父子之間的關喺也變得越來越好。我現在非常開心做運動。 "
                        ),
                      ]),
                      _c("img", {
                        staticClass: "fw8",
                        attrs: {
                          alt: "支持反饋3",
                          src: require("../../assets/img/pa_spstory3.png"),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.story == 4
                  ? _c("div", [
                      _c("audio", {
                        staticClass: "gaudio",
                        attrs: {
                          controls: "",
                          controlsList: "nodownload",
                          preload: "auto",
                          src: require("@/assets/guide/pa/Week_4_Social_Support.mp3"),
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " 高女士（68歲）：我和老伴年紀大了都不愛運動，平時我們喜歡待在家裡看電視。有一次我在洗手間跌倒了，去了醫院醫生説我肌肉力量不足，建議我加強鍛煉。 "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " 後來我的老伴晚上總是拉著我去公園快步行，有時候也會在健身公園鍛煉。老伴為了讓我多運動，特意陪我步行去離家遠一點的街市買菜。現在我感覺提重物或者行樓梯都沒有以前那麼辛苦，走路也有力了。非常感謝老伴的支持和陪伴。 "
                        ),
                      ]),
                      _c("img", {
                        staticClass: "fw8",
                        attrs: {
                          alt: "支持反饋4",
                          src: require("../../assets/img/pa_spstory4.jpg"),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.story == 5
                  ? _c("div", [
                      _c("audio", {
                        staticClass: "gaudio",
                        attrs: {
                          controls: "",
                          controlsList: "nodownload",
                          preload: "auto",
                          src: require("@/assets/guide/pa/Week_5_Social_Support.mp3"),
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " 王先生（65歲）：我經常和老友記們一起去公園做運動。通常每週運動2-3次，已經堅持好多年了。運動已經成為我日常生活中的一部分，我和老友們還組建了一個運動羣，大家經常會在裡面分享一些運動養生的小貼士。 "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " 在堅持運動的過程中，也遇到了好多困難。比如遇到天氣不好的時候，我會偷懶，不想做運動，這個時候我的老友們就會互相提醒，互相督促。去不了公園，大家就會找個室內地方，做拉伸和瑜伽。在老友們的幫助下，現在我已經養成了堅持運動鍛煉的習慣，整個人也越來越健康，越來越年輕。 "
                        ),
                      ]),
                      _c("img", {
                        staticClass: "fw8",
                        attrs: {
                          alt: "支持反饋5",
                          src: require("../../assets/img/pa_spstory5.png"),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.story == 6
                  ? _c("div", [
                      _c("audio", {
                        staticClass: "gaudio",
                        attrs: {
                          controls: "",
                          controlsList: "nodownload",
                          preload: "auto",
                          src: require("@/assets/guide/pa/Week_6_Social_Support.mp3"),
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " 王女士（70歲）：我剛剛退休的時候，本來覺得自己有很多自由時間，但是後來由於需要照顧孫子孫女，每天還要煮飯做家務，這些事情就佔了我很多時間。 "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " 後來我跟老伴説很多街坊在老年社區參加了健身操和太極拳學習班，我也想去。他就和兒女一起支持我，幫我分擔很多家務，讓我有足夠的時間參與這些活動。和大家一起參加運動使我很開心，我很享受自己現在的老年生活。 "
                        ),
                      ]),
                      _c("img", {
                        staticClass: "fw8",
                        attrs: {
                          alt: "支持反饋6",
                          src: require("../../assets/img/pa_spstory6.jpg"),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.story == 7
                  ? _c("div", [
                      _c("audio", {
                        staticClass: "gaudio",
                        attrs: {
                          controls: "",
                          controlsList: "nodownload",
                          preload: "auto",
                          src: require("@/assets/guide/pa/Week_7_Social_Support.mp3"),
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " 歐先生（67歲）：我有遺傳性高血壓。醫生説，除了服用藥物，適當的體育鍛煉也能夠幫助降低血壓。我每天去公園健步行，還會用健身公園的運動器械做拉伸。每次運動回來，我的家人都説我氣色很好，誇獎我有毅力，能夠完成當天的鍛煉計劃。 "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " 有時候，家人還會買運動鞋和運動服給我獎勵我堅持運動。雖然我的家人工作忙，沒時間陪我一起運動，但是他們鼓勵和支持讓我更有信心堅持運動。我現在也十分滿意自己的健康狀況。 "
                        ),
                      ]),
                      _c("img", {
                        staticClass: "fw8",
                        attrs: {
                          alt: "支持反饋7",
                          src: require("../../assets/img/pa_spstory7.png"),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.story == 8
                  ? _c("div", [
                      _c("audio", {
                        staticClass: "gaudio",
                        attrs: {
                          controls: "",
                          controlsList: "nodownload",
                          preload: "auto",
                          src: require("@/assets/guide/pa/Week_8_Social_support.mp3"),
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " 張先生（70歲）：我之前體能比較差，只能做少量運動，如去公園散步。除此之外，沒做過其他運動。後來我在公園裡認識了張生，他是一個退休教師，也是一個練太極20多年的太極拳師傅。 "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " 張生告訴我，像我這種體質較差的人，練習太極拳對我最合適。我跟著張生在公園裡練習太極已經3年，明顯感覺精力充沛，心情也開朗了很多。我很感謝張生教我練太極，並幫助我堅持運動！我們也成為了生活中的朋友！ "
                        ),
                      ]),
                      _c("img", {
                        staticClass: "fw8",
                        attrs: {
                          alt: "支持反饋8",
                          src: require("../../assets/img/pa_spstory8.jpg"),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.story == 9
                  ? _c("div", [
                      _c("audio", {
                        staticClass: "gaudio",
                        attrs: {
                          controls: "",
                          controlsList: "nodownload",
                          preload: "auto",
                          src: require("@/assets/guide/pa/Week_9_Social_Support.mp3"),
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " 林先生（60歲）：我的腿以前受過傷，行樓梯或者走路太長時間都會感覺很辛苦。但是我知道運動對我保持健康是很重要的，可是不清楚像我這種情況，做什麼樣的運動更合適。後來我的孫子告訴我在家就可以做一些簡單的運動。他給我下載了很多長者健身運動視頻，比如説椅子操和毛巾操，每個視頻的時間都在十分鐘左右，很容易學會。 "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " 孫子總是鼓勵我，告訴我要想身體健康，一定要堅持運動。有時候孫子還會陪我一起做。每次我跟著視頻完成運動，就覺得好有成就感。 "
                        ),
                      ]),
                      _c("img", {
                        staticClass: "fw8",
                        attrs: {
                          alt: "支持反饋9",
                          src: require("../../assets/img/pa_spstory9.jpg"),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.story == 10
                  ? _c("div", [
                      _c("audio", {
                        staticClass: "gaudio",
                        attrs: {
                          controls: "",
                          controlsList: "nodownload",
                          preload: "auto",
                          src: require("@/assets/guide/pa/Week_10_Social_support.mp3"),
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " 鍾先生（75歲）：從前我總是覺得散步是最適合我的運動方式。後來我瞭解到長者也可以做強度略微大一點的運動，但是我不知道什麼運動適合我。 "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " 後來我的孩子們給我和我老伴報了一個長者羽毛球班。我們非常開心，因為有專業教練指導我們，而且給我們建議。在那裡我們也認識了很多球友。日常生活中，我們也經常一起打球，非常開心。 "
                        ),
                      ]),
                      _c("img", {
                        staticClass: "fw8",
                        attrs: {
                          alt: "支持反饋10",
                          src: require("../../assets/img/pa_spstory10.jpg"),
                        },
                      }),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.feedback == 3
            ? _c(
                "div",
                [
                  _c("p", [
                    _vm._v(
                      "你清楚地知道邊啲人可以支持你實現運動嘅目標。非常好！繼續保持！"
                    ),
                  ]),
                  _c("p", { staticClass: "b" }, [
                    _vm._v(
                      " 希望你可以充分藉助身邊屋企人同朋友嘅幫助與同支持，堅持進行運動鍛煉。 "
                    ),
                  ]),
                  _c("p"),
                  _c("cube-button", { on: { click: _vm.review } }, [
                    _vm._v("社會支持總結"),
                  ]),
                  _c("p"),
                  _c("p"),
                  _c("img", {
                    staticClass: "fw8",
                    attrs: {
                      alt: "支持反饋2",
                      src: require("../../assets/img/pa_spfb3.jpg"),
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.page == 3
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("社會支持總結")]),
          _vm.revType == 1
            ? _c("div", [
                _c("p", [
                  _vm._v(" 1. 我可以同呢個人（呢啲人）"),
                  _c("span", { staticClass: "bu" }, [_vm._v("一齊")]),
                  _vm._v("去做運動："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.showcp(1))),
                  ]),
                ]),
                _c("p", [
                  _vm._v(" 2. 呢個人（呢啲人）可以"),
                  _c("span", { staticClass: "bu" }, [_vm._v("負責以確保")]),
                  _vm._v("我進行有規律嘅運動鍛煉："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.showcp(2))),
                  ]),
                ]),
                _c("p", [
                  _vm._v(
                    " 3. 當我盡力達到咗WHO推薦嘅運動鍛煉標準，呢個人（呢啲人）會"
                  ),
                  _c("span", { staticClass: "bu" }, [_vm._v("鼓勵同讚賞")]),
                  _vm._v("我："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.showcp(3))),
                  ]),
                ]),
                _c("p", [
                  _vm._v(" 4. 呢個人（呢啲人）為咗令我參加運動鍛煉會"),
                  _c("span", { staticClass: "bu" }, [_vm._v("幫我分擔")]),
                  _vm._v("一啲野："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.showcp(4))),
                  ]),
                ]),
                _c("p", [
                  _vm._v(" 5. 呢個人（呢啲人）可以為我進行運動鍛煉提出一啲"),
                  _c("span", { staticClass: "bu" }, [_vm._v("建議")]),
                  _vm._v("："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.showcp(5))),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.revType == 2
            ? _c("div", [
                _c("p", [
                  _vm._v(" 1．你通常會"),
                  _c("span", { staticClass: "bu" }, [_vm._v("同邊個一齊")]),
                  _vm._v("去做運動："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.showcp(1))),
                  ]),
                ]),
                _c("p", [
                  _vm._v(" 2．邊個可以"),
                  _c("span", { staticClass: "bu" }, [_vm._v("負責同確保")]),
                  _vm._v("令你進行有規律嘅運動鍛煉："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.showcp(2))),
                  ]),
                ]),
                _c("p", [
                  _vm._v(" 3．如果你盡力達到咗WHO推薦嘅運動鍛煉標準，邊個會"),
                  _c("span", { staticClass: "bu" }, [_vm._v("讚賞")]),
                  _vm._v("你："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.showcp(3))),
                  ]),
                ]),
                _c("p", [
                  _vm._v(
                    " 4．邊個會幫你分擔一啲其他嘅事（比如，做家務等），進而"
                  ),
                  _c("span", { staticClass: "bu" }, [_vm._v("幫")]),
                  _vm._v("你進行運動鍛煉："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.showcp(4))),
                  ]),
                ]),
                _c("p", [
                  _vm._v(" 5．當你進行運動鍛煉嘅時候，邊個會俾你一啲"),
                  _c("span", { staticClass: "bu" }, [_vm._v("建議")]),
                  _vm._v("（比如，做d咩運動，點樣做運動等）："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.showcp(5))),
                  ]),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.page == 4
      ? _c(
          "div",
          { staticClass: "box" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("社會支持")]),
            _c("p", [
              _vm._v(
                " 上個禮拜你指出喺進行運動鍛煉嘅時候應該可以得到下低嘅社會支持，請你回憶你今個星期喺咪得到呢啲社會支持： "
              ),
            ]),
            _c("p", [
              _vm._v(" 1．你通常會"),
              _c("span", { staticClass: "bu" }, [_vm._v("同邊個一齊")]),
              _vm._v("去參加運動鍛煉："),
              _c("span", { staticClass: "red b" }, [
                _vm._v(_vm._s(_vm.showcp(1))),
              ]),
            ]),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.support.perEval.q1,
                  callback: function ($$v) {
                    _vm.$set(_vm.support.perEval, "q1", $$v)
                  },
                  expression: "support.perEval.q1",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: "是" } } },
                  [_vm._v(" 是")]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: "否" } } },
                  [_vm._v(" 否")]
                ),
              ],
              1
            ),
            _c("p", [
              _vm._v(" 2．邊個可以"),
              _c("span", { staticClass: "bu" }, [_vm._v("負責同確保")]),
              _vm._v("令你進行有規律嘅運動鍛煉："),
              _c("span", { staticClass: "red b" }, [
                _vm._v(_vm._s(_vm.showcp(2))),
              ]),
            ]),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.support.perEval.q2,
                  callback: function ($$v) {
                    _vm.$set(_vm.support.perEval, "q2", $$v)
                  },
                  expression: "support.perEval.q2",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: "是" } } },
                  [_vm._v(" 是")]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: "否" } } },
                  [_vm._v(" 否")]
                ),
              ],
              1
            ),
            _c("p", [
              _vm._v(" 3．如果你盡力達到咗WHO推薦嘅運動鍛煉標準，邊個會"),
              _c("span", { staticClass: "bu" }, [_vm._v("讚賞")]),
              _vm._v("你："),
              _c("span", { staticClass: "red b" }, [
                _vm._v(_vm._s(_vm.showcp(3))),
              ]),
            ]),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.support.perEval.q3,
                  callback: function ($$v) {
                    _vm.$set(_vm.support.perEval, "q3", $$v)
                  },
                  expression: "support.perEval.q3",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: "是" } } },
                  [_vm._v(" 是")]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: "否" } } },
                  [_vm._v(" 否")]
                ),
              ],
              1
            ),
            _c("p", [
              _vm._v(" 4．邊個會替你分擔一啲其他嘅事（如，做家務等），進而"),
              _c("span", { staticClass: "bu" }, [_vm._v("幫")]),
              _vm._v("你進行運動鍛煉："),
              _c("span", { staticClass: "red b" }, [
                _vm._v(_vm._s(_vm.showcp(4))),
              ]),
            ]),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.support.perEval.q4,
                  callback: function ($$v) {
                    _vm.$set(_vm.support.perEval, "q4", $$v)
                  },
                  expression: "support.perEval.q4",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: "是" } } },
                  [_vm._v(" 是")]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: "否" } } },
                  [_vm._v(" 否")]
                ),
              ],
              1
            ),
            _c("p", [
              _vm._v(" 5．當你進行運動鍛煉嘅時候，邊個會俾你一啲"),
              _c("span", { staticClass: "bu" }, [_vm._v("建議")]),
              _vm._v("（比如，做d咩運動，點樣做運動等）："),
              _c("span", { staticClass: "red b" }, [
                _vm._v(_vm._s(_vm.showcp(5))),
              ]),
            ]),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.support.perEval.q5,
                  callback: function ($$v) {
                    _vm.$set(_vm.support.perEval, "q5", $$v)
                  },
                  expression: "support.perEval.q5",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: "是" } } },
                  [_vm._v(" 是")]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: "否" } } },
                  [_vm._v(" 否")]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }