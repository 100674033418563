import { render, staticRenderFns } from "./pend.vue?vue&type=template&id=319d4169&scoped=true&"
import script from "./pend.vue?vue&type=script&lang=js&"
export * from "./pend.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/learn_common.css?vue&type=style&index=0&id=319d4169&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "319d4169",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/GoldenHealth/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('319d4169')) {
      api.createRecord('319d4169', component.options)
    } else {
      api.reload('319d4169', component.options)
    }
    module.hot.accept("./pend.vue?vue&type=template&id=319d4169&scoped=true&", function () {
      api.rerender('319d4169', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/pa/pend.vue"
export default component.exports