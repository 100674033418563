var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _vm.$store.state.debugbtn
      ? _c("div", [
          _c("span", [_vm._v("頁面編號調試：")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.page,
                expression: "page",
              },
            ],
            domProps: { value: _vm.page },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.page = $event.target.value
              },
            },
          }),
        ])
      : _vm._e(),
    _vm.page == 1
      ? _c("div", { staticClass: "box" }, [
          _c("p", [
            _c("span", { staticClass: "bu" }, [_vm._v("寫運動鍛煉日記")]),
            _vm._v(
              "，可以幫你對自己嘅鍛煉行為進行總結同反思，進而促進運動嘅堅持性。 "
            ),
          ]),
          _c("p", [
            _c("span", { staticClass: "b" }, [
              _vm._v("“運動鍛煉日記”嘅內容包括"),
            ]),
            _vm._v("：當日你喺"),
            _c("span", { staticClass: "bu" }, [_vm._v("咩時間")]),
            _vm._v("， "),
            _c("span", { staticClass: "bu" }, [_vm._v("咩地點")]),
            _vm._v("，"),
            _c("span", { staticClass: "bu" }, [_vm._v("同邊啲人")]),
            _vm._v("，進行咗"),
            _c("span", { staticClass: "bu" }, [_vm._v("咩強度嘅運動")]),
            _vm._v("， "),
            _c("span", { staticClass: "bu" }, [_vm._v("咩類型嘅運動")]),
            _vm._v("，"),
            _c("span", { staticClass: "bu" }, [_vm._v("心情如何")]),
            _vm._v("。 "),
          ]),
          _c("p", [_vm._v("跟住落黎你可以睇下兩個運動鍛煉日記嘅例子。")]),
          _c("p", [
            _c("span", { staticClass: "red" }, [_vm._v("例子1")]),
            _vm._v(
              "：“這個星期五，我和老伴晚飯後，在社區公園，散步了一個多小時，散完步有些輕微出汗和氣喘，心情很愉悦”。 "
            ),
          ]),
          _c("p", [
            _c("span", { staticClass: "red" }, [_vm._v("例子2")]),
            _vm._v(
              "： “這個星期六，早上我去街市買菜，步行往返20分鐘。晚上和鄰居夥伴一起在公園，跳了半個小時的舞，出了很多汗，很開心”。 "
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.txtDiary,
                  expression: "txtDiary",
                },
              ],
            },
            [
              _c("cube-textarea", {
                staticClass: "larget",
                attrs: { maxlength: _vm.max, placeholder: _vm.placeholder },
                model: {
                  value: _vm.diary.text,
                  callback: function ($$v) {
                    _vm.$set(_vm.diary, "text", $$v)
                  },
                  expression: "diary.text",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.txtDiary,
                  expression: "!txtDiary",
                },
              ],
            },
            [
              _c("p", [
                _c("span", { staticClass: "red" }, [
                  _vm._v("請禁下低嘅“錄製”按鈕"),
                ]),
                _vm._v("，錄製你嘅電子語音運動日記"),
              ]),
              _c("record", {
                ref: "recorder",
                attrs: {
                  "on-open-error": _vm.errHandle,
                  "on-stop-is-end": _vm.stopHandle,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "cube-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: true,
                      expression: "true",
                    },
                  ],
                  staticClass: "btnChange",
                  on: {
                    click: function ($event) {
                      return _vm.changeType()
                    },
                  },
                },
                [_vm._v("切換錄入方式")]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.page == 2
      ? _c("div", { staticClass: "box" }, [
          _c("p", { staticClass: "b" }, [
            _vm._v(
              " 你嘅日記將會儲存喺手機主頁“我的日記”一欄中, 你可以隨時查閲。 "
            ),
          ]),
          _c("p", [
            _vm._v(
              " 由而家開始，請你每日使用運動鍛煉日記，記錄你運動嘅情況。如果 "
            ),
            _c("span", { staticClass: "bu blue" }, [
              _vm._v("一星期內你可以記錄至少三個符合要求嘅運動日記"),
            ]),
            _vm._v("， 你將會獲得"),
            _c("span", { staticClass: "bu red" }, [_vm._v("10元現金獎勵")]),
            _vm._v("。 "),
          ]),
          _c("p", [_vm._v("加油，老友記！")]),
          _c("img", {
            staticClass: "fw8",
            attrs: {
              alt: "日記",
              src: require("../../assets/img/pa_diary.jpg"),
            },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }