var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", { staticClass: "root" }, [
    _vm.debugbtn
      ? _c("div", [
          _c("span", [_vm._v("頁面編號調試：")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.page,
                expression: "page",
              },
            ],
            domProps: { value: _vm.page },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.page = $event.target.value
              },
            },
          }),
        ])
      : _vm._e(),
    _vm.page == 1
      ? _c("div", { staticClass: "box" }, [
          _c("p", [
            _vm._v(" 世界衞生組織（WHO，2010）建議：為咗促進健康，長者應該 "),
            _c("span", { staticClass: "bu" }, [
              _vm._v(
                "每日進行中等強度或以上嘅運動至少30分鐘，每星期至少5次，加埋150分鐘，"
              ),
            ]),
            _vm._v(" 運動到覺得 "),
            _c("span", { staticClass: "bu" }, [_vm._v("會喘氣流汗")]),
            _vm._v("。 "),
          ]),
          _c("br"),
          _c("img", {
            staticClass: "fw8",
            attrs: {
              alt: "運動i健康",
              src: require("../../assets/img/welcome2.png"),
            },
          }),
        ])
      : _vm._e(),
    _vm.page == 2
      ? _c(
          "div",
          { staticClass: "box" },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v("過去一星期嘅運動鍛煉情況"),
            ]),
            _c("br"),
            _c("p", [
              _vm._v(
                " 我哋希望通過以下嘅問題了解你喺過去7日，有幾大程度經常做劇烈運動、中等強度運動同步行。請你回憶你所做過每次 "
              ),
              _c("span", { staticClass: "bu" }, [_vm._v("至少10分鐘")]),
              _vm._v(" 嘅運動。 "),
            ]),
            _c("br"),
            _c("p", [
              _vm._v(" 1."),
              _c("span", { staticClass: "b red" }, [_vm._v("劇烈運動")]),
              _vm._v(
                "（運動後你嘅呼吸會較平時快好多並且會大量出汗）。例如: 快速跑, 跳健康舞，游水，打羽毛球，快速踩單車等。 "
              ),
            ]),
            _c(
              "cube-checkbox",
              {
                staticClass: "inline_checkbox font_large",
                model: {
                  value: _vm.record.extreme_none,
                  callback: function ($$v) {
                    _vm.$set(_vm.record, "extreme_none", $$v)
                  },
                  expression: "record.extreme_none",
                },
              },
              [_vm._v(" 沒有 ")]
            ),
            !_vm.record.extreme_none
              ? _c(
                  "p",
                  { staticClass: "select_line" },
                  [
                    _vm._v(" 一星期"),
                    _c("cube-select", {
                      staticClass: "inline_select",
                      attrs: { options: _vm.weekdays },
                      model: {
                        value: _vm.record.extreme_day,
                        callback: function ($$v) {
                          _vm.$set(_vm.record, "extreme_day", $$v)
                        },
                        expression: "record.extreme_day",
                      },
                    }),
                    _vm._v("天 "),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.record.extreme_none && _vm.record.extreme_day > 0
              ? _c(
                  "p",
                  { staticClass: "select_line" },
                  [
                    _vm._v(" 一日"),
                    _c("cube-select", {
                      staticClass: "inline_select",
                      attrs: { options: _vm.dayhours },
                      model: {
                        value: _vm.record.extreme_hour,
                        callback: function ($$v) {
                          _vm.$set(_vm.record, "extreme_hour", $$v)
                        },
                        expression: "record.extreme_hour",
                      },
                    }),
                    _vm._v("小時"),
                    _c("cube-select", {
                      staticClass: "inline_select",
                      attrs: { options: _vm.hourminutes },
                      model: {
                        value: _vm.record.extreme_minute,
                        callback: function ($$v) {
                          _vm.$set(_vm.record, "extreme_minute", $$v)
                        },
                        expression: "record.extreme_minute",
                      },
                    }),
                    _vm._v("分鐘 "),
                  ],
                  1
                )
              : _vm._e(),
            _c("p", [
              _vm._v(" 2."),
              _c("span", { staticClass: "b red" }, [_vm._v("中等強度嘅運動")]),
              _vm._v(
                "（運動後你嘅呼吸會較平時快啲而且會輕微出汗）。例如：快步走，打太極拳等。 "
              ),
            ]),
            _c(
              "cube-checkbox",
              {
                staticClass: "inline_checkbox font_large",
                model: {
                  value: _vm.record.normal_none,
                  callback: function ($$v) {
                    _vm.$set(_vm.record, "normal_none", $$v)
                  },
                  expression: "record.normal_none",
                },
              },
              [_vm._v(" 沒有 ")]
            ),
            !_vm.record.normal_none
              ? _c(
                  "p",
                  { staticClass: "select_line" },
                  [
                    _vm._v(" 一星期"),
                    _c("cube-select", {
                      staticClass: "inline_select",
                      attrs: { options: _vm.weekdays },
                      model: {
                        value: _vm.record.normal_day,
                        callback: function ($$v) {
                          _vm.$set(_vm.record, "normal_day", $$v)
                        },
                        expression: "record.normal_day",
                      },
                    }),
                    _vm._v("日 "),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.record.normal_none && _vm.record.normal_day > 0
              ? _c(
                  "p",
                  { staticClass: "select_line" },
                  [
                    _vm._v(" 一日"),
                    _c("cube-select", {
                      staticClass: "inline_select",
                      attrs: { options: _vm.dayhours },
                      model: {
                        value: _vm.record.normal_hour,
                        callback: function ($$v) {
                          _vm.$set(_vm.record, "normal_hour", $$v)
                        },
                        expression: "record.normal_hour",
                      },
                    }),
                    _vm._v("小時"),
                    _c("cube-select", {
                      staticClass: "inline_select",
                      attrs: { options: _vm.hourminutes },
                      model: {
                        value: _vm.record.normal_minute,
                        callback: function ($$v) {
                          _vm.$set(_vm.record, "normal_minute", $$v)
                        },
                        expression: "record.normal_minute",
                      },
                    }),
                    _vm._v("分鐘 "),
                  ],
                  1
                )
              : _vm._e(),
            _c("p", [
              _vm._v(" 3. "),
              _c("span", { staticClass: "b red" }, [_vm._v("行路")]),
              _vm._v(
                " （你嘅呼吸較平時冇變化而且冇出汗）。例如行路往返屋企同街市，日常休閒嘅散步。 "
              ),
            ]),
            _c(
              "cube-checkbox",
              {
                staticClass: "inline_checkbox font_large",
                model: {
                  value: _vm.record.walk_none,
                  callback: function ($$v) {
                    _vm.$set(_vm.record, "walk_none", $$v)
                  },
                  expression: "record.walk_none",
                },
              },
              [_vm._v(" 沒有 ")]
            ),
            !_vm.record.walk_none
              ? _c(
                  "p",
                  { staticClass: "select_line" },
                  [
                    _vm._v(" 一星期"),
                    _c("cube-select", {
                      staticClass: "inline_select",
                      attrs: { options: _vm.weekdays },
                      model: {
                        value: _vm.record.walk_day,
                        callback: function ($$v) {
                          _vm.$set(_vm.record, "walk_day", $$v)
                        },
                        expression: "record.walk_day",
                      },
                    }),
                    _vm._v("日 "),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.record.walk_none && _vm.record.walk_day > 0
              ? _c(
                  "p",
                  { staticClass: "select_line" },
                  [
                    _vm._v(" 一天"),
                    _c("cube-select", {
                      staticClass: "inline_select",
                      attrs: { options: _vm.dayhours },
                      model: {
                        value: _vm.record.walk_hour,
                        callback: function ($$v) {
                          _vm.$set(_vm.record, "walk_hour", $$v)
                        },
                        expression: "record.walk_hour",
                      },
                    }),
                    _vm._v("小時"),
                    _c("cube-select", {
                      staticClass: "inline_select",
                      attrs: { options: _vm.hourminutes },
                      model: {
                        value: _vm.record.walk_minute,
                        callback: function ($$v) {
                          _vm.$set(_vm.record, "walk_minute", $$v)
                        },
                        expression: "record.walk_minute",
                      },
                    }),
                    _vm._v("分鐘 "),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.page == 3
      ? _c(
          "div",
          { staticClass: "box" },
          [
            _vm.debugbtn
              ? _c("div", [
                  _c("span", [_vm._v("頁面反饋調試：")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.feedback,
                        expression: "feedback",
                      },
                    ],
                    domProps: { value: _vm.feedback },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.feedback = $event.target.value
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _c("div", { ref: "chartt", staticClass: "title" }, [
              _vm._v("過去一星期嘅運動鍛煉情況"),
            ]),
            _c("echart", {
              ref: "chart",
              staticStyle: {
                "{\n          min-width": "300px",
                "min-height": "300px",
              },
              attrs: { option: _vm.chartOption },
            }),
            _c("p", [
              _vm._v(
                " WHO推薦嘅運動鍛煉標準係，每星期進行中等強度或以上嘅運動至少五日，每日至少30分鐘（或每星期加埋至少150分鐘）。 "
              ),
            ]),
            _c("br"),
          ],
          1
        )
      : _vm._e(),
    _vm.page == 5
      ? _c("div", { staticClass: "box" }, [
          _vm.debugbtn
            ? _c("div", [
                _c("span", [_vm._v("頁面反饋調試：")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.feedback,
                      expression: "feedback",
                    },
                  ],
                  domProps: { value: _vm.feedback },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.feedback = $event.target.value
                    },
                  },
                }),
              ])
            : _vm._e(),
          _c("div", { ref: "chartt", staticClass: "title" }, [
            _vm._v("過去一星期嘅運動鍛煉情況"),
          ]),
          _vm.feedback == 1
            ? _c("div", [
                _c("p", [
                  _vm._v(" 上個禮拜你進行"),
                  _c("span", { staticClass: "bu" }, [_vm._v("中等強度或以上")]),
                  _vm._v("嘅運動時間加埋係："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.totalminute()) + "分鐘"),
                  ]),
                  _vm._v("。 "),
                ]),
                _c("p", [
                  _vm._v("結果表明，你"),
                  _c("span", { staticClass: "blue b" }, [_vm._v("達到")]),
                  _vm._v("咗WHO推薦嘅運動標準。非常好！"),
                ]),
                _c("p", [
                  _vm._v("要有信心一定可以繼續保持落去，"),
                  _c("span", { staticClass: "bu" }, [_vm._v("加油！")]),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖1",
                    src: require("../../assets/img/pa_feedback1.jpg"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback == 2
            ? _c("div", [
                _c("p", [
                  _vm._v(" 上個禮拜你進行"),
                  _c("span", { staticClass: "bu" }, [_vm._v("中等強度或以上")]),
                  _vm._v("嘅運動時間加埋係："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.totalminute()) + "分鐘"),
                  ]),
                  _vm._v("。 "),
                ]),
                _c("p", [
                  _vm._v(" 結果表明，你仲"),
                  _c("span", { staticClass: "red b" }, [_vm._v("未達到")]),
                  _vm._v(
                    "WHO推薦嘅運動標準。咁樣嘅情況好普遍，因為對於大多數冇運動習慣嘅老人家黎講，啱啱開始參與運動總喺會"
                  ),
                  _c("span", { staticClass: "bu" }, [_vm._v("有啲難度")]),
                  _vm._v("。 "),
                ]),
                _c("p", [
                  _vm._v(
                    " 通過加入“食”全十美，行大“運”網站，你已經踏出咗重要嘅第一步，要相信自己一定會達到標準，"
                  ),
                  _c("span", { staticClass: "bu" }, [_vm._v("加油！")]),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖2",
                    src: require("../../assets/img/pa_feedback2.png"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback == 3
            ? _c("div", [
                _c("p", [
                  _vm._v(" 上個禮拜你進行"),
                  _c("span", { staticClass: "bu dark_green" }, [
                    _vm._v("中等強度或以上"),
                  ]),
                  _vm._v("嘅運動時間加埋係："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.totalminute()) + "分鐘。"),
                  ]),
                ]),
                _c("p", [
                  _vm._v(" 你嘅運動時間較兩星期前有所"),
                  _c("span", { staticClass: "bu" }, [_vm._v("進步")]),
                  _vm._v("！而且你嘅運動時間已經"),
                  _c("span", { staticClass: "blue b" }, [_vm._v("達到")]),
                  _vm._v(
                    "WHO推薦嘅運動標準，非常好！喺跟住落黎嘅活動中，你要相信自己可以繼續保持咁樣嘅運動狀態，繼續加油！ "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖3",
                    src: require("../../assets/img/pa_feedback3.png"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback == 4
            ? _c("div", [
                _c("p", [
                  _vm._v(" 上個禮拜你進行"),
                  _c("span", { staticClass: "bu dark_green" }, [
                    _vm._v("中等強度或以上"),
                  ]),
                  _vm._v("嘅運動時間加埋係："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.totalminute()) + "分鐘"),
                  ]),
                  _vm._v("。 "),
                ]),
                _c("p", [
                  _vm._v(" 你嘅運動時間較兩星期前有所"),
                  _c("span", { staticClass: "bu" }, [_vm._v("進步")]),
                  _vm._v("！不過，你仲"),
                  _c("span", { staticClass: "red b" }, [_vm._v("未達到")]),
                  _vm._v(
                    "WHO推薦的運動標準。唔緊要！進行有規律嘅運動一開頭都喺有難度嘅。你要相信自己，通過參加跟住落黎嘅單元學習活動，一定可以不斷進步，最終達到WHO推薦嘅運動標準，"
                  ),
                  _c("span", { staticClass: "bu" }, [_vm._v("加油！")]),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖4",
                    src: require("../../assets/img/pa_feedback4.png"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback == 5
            ? _c("div", [
                _c("p", [
                  _vm._v(" 上個禮拜你進行"),
                  _c("span", { staticClass: "bu dark_green" }, [
                    _vm._v("中等強度或以上"),
                  ]),
                  _vm._v("嘅運動時間加埋係："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.totalminute()) + "分鐘"),
                  ]),
                  _vm._v("。 "),
                ]),
                _c("p", [
                  _vm._v(" 你嘅運動時間較兩星期前"),
                  _c("span", { staticClass: "bu" }, [_vm._v("冇變化")]),
                  _vm._v("。不過，你嘅運動時間已經"),
                  _c("span", { staticClass: "blue b" }, [_vm._v("達到")]),
                  _vm._v("推薦嘅運動標準，非常好！ "),
                ]),
                _c("p", [
                  _vm._v(
                    " 喺跟住落黎嘅活動中，你要相信自己可以繼續保持咁樣嘅運動狀態，繼續"
                  ),
                  _c("span", { staticClass: "bu" }, [_vm._v("加油")]),
                  _vm._v("！ "),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖5",
                    src: require("../../assets/img/pa_feedback5.png"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback == 6
            ? _c("div", [
                _c("p", [
                  _vm._v(" 上個禮拜你進行"),
                  _c("span", { staticClass: "bu dark_green" }, [
                    _vm._v("中等強度或以上"),
                  ]),
                  _vm._v("嘅運動時間加埋係："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.totalminute()) + "分鐘"),
                  ]),
                  _vm._v("。 "),
                ]),
                _c("p", [
                  _vm._v(" 你嘅運動時間較兩星期前"),
                  _c("span", { staticClass: "bu" }, [_vm._v("冇變化")]),
                  _vm._v("。你仲"),
                  _c("span", { staticClass: "red b" }, [_vm._v("未達到")]),
                  _vm._v("WHO推薦嘅運動標準。 "),
                ]),
                _c("p", [
                  _vm._v(
                    " 唔緊要！進行有規律嘅運動一開頭都喺有難度嘅。你要相信自己，通過參加跟住落黎嘅單元學習活動，一定可以不斷進步，最終達到WHO推薦嘅運動標準，"
                  ),
                  _c("span", { staticClass: "bu" }, [_vm._v("加油")]),
                  _vm._v("！ "),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖6",
                    src: require("../../assets/img/pa_feedback6.png"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback == 7
            ? _c("div", [
                _c("p", [
                  _vm._v(" 上個禮拜你進行"),
                  _c("span", { staticClass: "bu dark_green" }, [
                    _vm._v("中等強度或以上"),
                  ]),
                  _vm._v("嘅運動時間加埋係："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.totalminute()) + "分鐘"),
                  ]),
                  _vm._v("。 "),
                ]),
                _c("p", [
                  _vm._v(" 你嘅運動時間較兩星期前有所"),
                  _c("span", { staticClass: "bu" }, [_vm._v("退步")]),
                  _vm._v("！不過，你已經"),
                  _c("span", { staticClass: "blue b" }, [_vm._v("達到")]),
                  _vm._v(
                    "WHO推薦嘅運動標準。繼續保持吧！喺跟住落黎嘅活動中，你要相信自己可以繼續保持咁樣嘅運動狀態，"
                  ),
                  _c("span", { staticClass: "bu" }, [_vm._v("繼續加油")]),
                  _vm._v("！ "),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖7",
                    src: require("../../assets/img/pa_feedback7.png"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback == 8
            ? _c("div", [
                _c("p", [
                  _vm._v(" 上個禮拜你進行"),
                  _c("span", { staticClass: "bu dark_green" }, [
                    _vm._v("中等強度或以上"),
                  ]),
                  _vm._v("嘅運動時間加埋係："),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.totalminute()) + "分鐘。"),
                  ]),
                ]),
                _c("p", [
                  _vm._v(" 你嘅運動時間較兩星期前有所"),
                  _c("span", { staticClass: "bu" }, [_vm._v("退步")]),
                  _vm._v("！你仲"),
                  _c("span", { staticClass: "red b" }, [_vm._v("未達到")]),
                  _vm._v("WHO推薦嘅運動標準。 "),
                ]),
                _c("p", [
                  _vm._v(
                    " 唔緊要！進行有規律嘅運動一開頭都喺有難度嘅。你要相信自己，通過參加跟住落黎嘅單元學習活動，一定可以不斷進步，最終達到WHO推薦嘅運動標準，"
                  ),
                  _c("span", { staticClass: "bu" }, [_vm._v("加油")]),
                  _vm._v("！ "),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖8",
                    src: require("../../assets/img/pa_feedback8.png"),
                  },
                }),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.page == 4
      ? _c("div", { staticClass: "box" }, [
          _vm.debugbtn
            ? _c("div", [
                _c("span", [_vm._v("事例分享調試：")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.encourage,
                      expression: "encourage",
                    },
                  ],
                  domProps: { value: _vm.encourage },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.encourage = $event.target.value
                    },
                  },
                }),
              ])
            : _vm._e(),
          _c("div", { staticClass: "title" }, [_vm._v("成功事例分享")]),
          _vm.encourage == 1
            ? _c("div", [
                _c("audio", {
                  staticClass: "gaudio",
                  attrs: {
                    controls: "",
                    controlsList: "nodownload",
                    preload: "auto",
                    src: require("@/assets/guide/pa/Week_2_ASE.mp3"),
                  },
                }),
                _c("p", [
                  _vm._v(
                    " 跟住落黎同大家分享郭生嘅故事。郭生已經70歲，現在依然堅持每天跑步20-25公里，他説在60歲的時候馬拉松還跑進了3小時，有些年輕人也比不過他。郭生説他以前有糖尿病併發症，剛出院的時候，走路很吃力也吃不下東西，當時的心情很低落。那時候他57歲還沒退休，他希望自己要活過退休。醫生建議他出院後可以慢慢進行一些體育運動，這對他的體能會有好處。於是郭生在老伴的陪同下，開始健步行，在操場上慢行一圈都相當困難。於是他就搭著老伴的肩，慢慢行。後來一直積極運動，看到血糖一點點降下來了。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "後來郭生要求自己每天都去跑步，剛開始的時候，身體虛，出很多汗，鞋子每天都能倒出水。郭生説，萬事起頭難，但是他對自己一直有信心。就這樣堅持跑步兩年。後來到醫院檢查，發現血糖的各項指標都已恢復正常。郭生很高興。他説他會繼續堅持跑步，爭取用3-4年的時間把糖尿病病情完全控制下來。常年的運動使郭生的生活充滿了活力。 "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "圖表佔位",
                    src: require("../../assets/img/pa_encourage1.jpg"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.encourage == 2
            ? _c("div", [
                _c("audio", {
                  staticClass: "gaudio",
                  attrs: {
                    controls: "",
                    controlsList: "nodownload",
                    preload: "auto",
                    src: require("@/assets/guide/pa/Week_3_ASE.mp3"),
                  },
                }),
                _c("p", [
                  _vm._v(
                    " 跟住落黎同大家分享一位劉女士嘅故事。劉女士已經72歲，堅持鍛煉多年。她每天在公園和同伴們一起做鍛煉，身體特別好，還表示自己能“打得過”年輕人。劉女士説她以前上班工作很忙，還要照顧孫子，去醫院體檢發現了三高。那時醫生建議她可以多做運動。為了健康的身體，劉女士65歲退休後，下定決心進行鍛煉。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "劉女士喜歡去社區健身公園，利用各種健身器材做一些拉伸運動，增強上下肢的力量以及提高平衡性。雖然一開始，劉女士不知道怎麼用這些器材，但是通過看健身器材上標註的使用説明，慢慢就知道如何正確使用了。而且每次鍛煉後，劉女士感覺身體都很放鬆，整個人充滿了活力。通過堅持運動，劉女士已經沒有三高現象了。她説有信心會一直堅持運動下去。 "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "圖表佔位",
                    src: require("../../assets/img/pa_encourage2.jpg"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.encourage == 3
            ? _c("div", [
                _c("audio", {
                  staticClass: "gaudio",
                  attrs: {
                    controls: "",
                    controlsList: "nodownload",
                    preload: "auto",
                    src: require("@/assets/guide/pa/Week_4_ASE.mp3"),
                  },
                }),
                _c("p", [
                  _vm._v(
                    " 跟住落黎同大家分享張伯嘅故事。張伯已經81歲，60歲就開始在健身室鍛煉，如今還能舉重50公斤。張伯曾經是模特兒，在老年模特兒隊十多年。大家都説張伯看起來不到五十歲。他每天運動1.5-2個小時，一星期練五次。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "教練説張伯練習舉重七八年了，訓練的強度和年輕人差不多，非常好。張伯説長者的財富就是身體健康，要健康就一定要進行有規律的長期鍛煉。張伯表示只要身體允許，他會一直堅持健身。 "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "圖表佔位",
                    src: require("../../assets/img/pa_encourage3.jpg"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.encourage == 4
            ? _c("div", [
                _c("audio", {
                  staticClass: "gaudio",
                  attrs: {
                    controls: "",
                    controlsList: "nodownload",
                    preload: "auto",
                    src: require("@/assets/guide/pa/Week_5_MSE.mp3"),
                  },
                }),
                _c("p", [
                  _vm._v(
                    " 跟住落黎同大家分享67歲健身達人鄭先生嘅事例。在退休前，鄭生一直忙於工作，很少做運動。他體態偏胖，總是感覺累。去醫院檢查身體，沒發現有什麼問題。2014年，在朋友的影響下，鄭生開始健身，一練就是7年。每天下午鄭生會在健身房進行2個小時的鍛煉。雖然已年過六旬，但他健身的動作十分標準，絲毫不輸身邊的年輕人。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "鄭生説考慮到自己的年齡，現在平均一週會去健身室3次，不過做的運動量會維持在2個小時左右，無論寒冬還是酷暑都一直堅持著。開始健身後，鄭生感覺自己又變得精力充沛了，肚腩也消失了，身材變得更健美。鄭生表示，自己有信心一定會繼續堅持鍛煉下去！ "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "圖表佔位",
                    src: require("../../assets/img/pa_encourage4.jpg"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.encourage == 5
            ? _c("div", [
                _c("audio", {
                  staticClass: "gaudio",
                  attrs: {
                    controls: "",
                    controlsList: "nodownload",
                    preload: "auto",
                    src: require("@/assets/guide/pa/Week_6_MSE.mp3"),
                  },
                }),
                _c("p", [
                  _vm._v(
                    " 跟住落黎同大家分享85歲陳先生嘅事例。陳生80多歲還能連續做40個俯卧撐，“深蹲”每次可完成40次以上，仰卧起坐一次能完成40多次，晨操完成“萬米長跑”。陳生原本是中學老師，退休後被查出腸胃有問題，長期腹痛但去了醫院也沒法徹底治癒。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "陳生便想試試通過運動減輕病痛，後來漸漸喜歡上運動。陳生堅持運動二十多年，期間很少生病，之前的病症也得到很大的緩解。問起陳生身體好的秘訣，陳生説沒什麼訣竅，就是堅持運動，中途中斷了也沒關喺，要相信自己有能力重新恢復運動鍛煉。只要長期堅持運動，身體一定會越來越好。 "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "圖表佔位",
                    src: require("../../assets/img/pa_encourage5.jpg"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.encourage == 6
            ? _c("div", [
                _c("audio", {
                  staticClass: "gaudio",
                  attrs: {
                    controls: "",
                    controlsList: "nodownload",
                    preload: "auto",
                    src: require("@/assets/guide/pa/Week_7_MSE.mp3"),
                  },
                }),
                _c("p", [
                  _vm._v(
                    " 跟住落黎同大家分享梁先生（62歲）嘅事例。梁先生在退休之前，工作一直很忙碌，幾乎不做運動。他還患有慢性肩周炎、三高，肚子也發福。家庭醫生建議梁生在生活中要多鍛煉身體。退休後，梁生決心開始做運動。他去過健身房健身，登過山，在公園裡跑過步，但是運動效果都不明顯。後來，梁生的兒子買了一隻狗給他，寵物狗很可愛，給他和老伴增添了不少樂趣。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "每天遛狗的時候，用繩子牽著狗和狗一起跑，運動量都比較大。此外，有繩子的牽引，對緩解肩周炎效果也非常明顯。梁生經常遛狗。基本上每天3次，每次1小時左右。並且這樣堅持了好幾年。梁生現在肩周炎好了，也沒有三高，肚腩也細了很多。梁生表示，通過遛狗鍛煉身體，對他來説是一個非常合適的運動方式。他有信心一定會繼續堅持下去。 "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "圖表佔位",
                    src: require("../../assets/img/pa_encourage6.jpg"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.encourage == 7
            ? _c("div", [
                _c("audio", {
                  staticClass: "gaudio",
                  attrs: {
                    controls: "",
                    controlsList: "nodownload",
                    preload: "auto",
                    src: require("@/assets/guide/pa/Week_8_RSE.mp3"),
                  },
                }),
                _c("p", [
                  _vm._v(
                    " 跟住落黎同大家分享69歲尚先生堅持鍛煉嘅事例。尚先生十年如一日，每天堅持早晨鍛煉身體，拉伸半小時，慢跑半小時，很多年都沒進過醫院。尚先生説社區裡像他這樣堅持鍛煉的沒幾個。 "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "尚先生還説，幾年前有一次開始慢跑的時候，因為沒有做好充分的熱身運動，結果不小心扭傷腳，在牀上躺了好幾個月。由於暫停了好久沒有運動，體力下降，後來人也不太想運動了。但是想到了運動的好處，尚先生還是恢復了晨操。為了預防運動扭傷，他每次運動前，拉伸都做得很充足。尚先生如今身體依然硬朗。他的長壽秘訣就是，堅持運動！只要有信心，堅持下去，身體就會越來越好，疾病也會越來越少！。 "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "圖表佔位",
                    src: require("../../assets/img/pa_encourage7.png"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.encourage == 8
            ? _c("div", [
                _c("audio", {
                  staticClass: "gaudio",
                  attrs: {
                    controls: "",
                    controlsList: "nodownload",
                    preload: "auto",
                    src: require("@/assets/guide/pa/Week_9_RSE.mp3"),
                  },
                }),
                _c("p", [
                  _vm._v(
                    " 跟住落黎同大家分享一位70歲劉女士嘅事例。劉女士在65歲時因為腦梗塞住院很長時間。出院的時候，醫生告訴她，病可以靠藥治，但身體的恢復還是要靠平常的運動鍛煉。劉女士經常去區內公園散步，有時候也會用公園裡的健身器械做拉伸。但是時間久了，就覺得一個人做運動很枯燥，沒有什麼樂趣。曾經有一段時間中途退出了運動。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " 後來劉女士的女兒幫她報名參加了社區老年中心舉辦的鍛煉工作坊。劉女士和其他老友記在運動教練的指導下，學會了椅子操和毛巾操。劉女士感覺大家一起做運動，好開心！鍛煉工作坊結束後，劉女士還經常約一兩個街坊一起去公園運動，有時候天氣不好，就會在家裡做椅子操和毛巾操。劉女士説自從她堅持做運動，身體輕鬆很多，體力充沛，快步行一個鐘也不覺得氣喘。好多人都説看不出她曾經腦梗塞。劉女士説，堅持運動是她的健康法寶。中途退出了也沒關喺，她有信心會重新運動並一直堅持下去的。 "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "圖表佔位",
                    src: require("../../assets/img/pa_encourage8.jpg"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.encourage == 9
            ? _c("div", [
                _c("p", [
                  _c("audio", {
                    staticClass: "gaudio",
                    attrs: {
                      controls: "",
                      controlsList: "nodownload",
                      preload: "auto",
                      src: require("@/assets/guide/pa/Week_10_RSE.mp3"),
                    },
                  }),
                  _vm._v(
                    " 跟住落黎同大家分享83歲高齡陳先生嘅事例。陳先生十幾年來一直堅持鍛煉，每天都是第一個到公園晨操的，陳先生經常在公園展示他驚人的臂力（雙手撐地腿腳懸空），每天圍觀人數非常多。大家説老先生雖然83歲高齡，身體還是非常靈活，比很多年輕人都厲害，看到他這樣的狀態都十分佩服，也想向他學習。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " 很多人擔心陳老先生這樣的年紀進行鍛煉容易受傷，陳先生直言：“剛開始那幾年會感覺手臂或者腿有點不適，中途因為拉傷，自己年紀大了又恢復的很慢，導致我好長時間不能運動。但是我知道再大的困難也要克服。身體康復後，我每次運動前都會做好熱身活動，之後從來沒有中斷過，現在已經堅持了十多年。”"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " 陳先生如今身體依然硬朗。他的長壽秘訣就是，堅持運動！只要有信心，堅持下去，身體就會越來越好，疾病也會越來越少！ "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "圖表佔位",
                    src: require("../../assets/img/pa_encourage9.png"),
                  },
                }),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }