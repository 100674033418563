<template>
  <div class="container vflex cscroll">
    <div class="plan_no"
      v-if="
        (user.pastate == 0 && type == 'pa') ||
        (user.dietstate == 0 && type == 'diet')
      "
    >
      <p>請點擊“進入課程”，完成第一星期嘅學習</p>
    </div>
    <div v-else>
      <div class="uplan_box" v-if="type == 'pa'">
        <div class="title">運動計劃</div>
        <ul class="list-wrapper vflex">
          <li
            v-for="item in getPlanList()"
            class="list-item"
            style="display: flex"
          >
            <div class="list-left">
              {{ item.date }}
            </div>
            <div class="list-right">
              <span :class="{ red: item.type == 1, blue: item.type == 2 }">
                {{ item.action }}
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div class="uplan_box" v-else>
        <div class="title">健康飲食計劃</div>
        <ul class="list-wrapper vflex">
          <li
            v-for="item in getPlanList2()"
            class="list-item"
            style="display: flex"
          >
            <div class="list-left">
              {{ item.date }}
            </div>
            <div class="list-right">
              <span :class="{ red: item.type == 1, blue: item.type == 2 }">
                {{ item.action }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
//import Some from "@/components/some.vue";
import { weekDays } from "@/data/data";

export default {
  name: "userplan",
  data: function () {
    return {
      plan: {
        data: [
          {
            type: "健步走",
            type_other: "",
            detail: [
              {
                when: "",
                where: "",
                where_other: "",
                who: "",
                who_other: "",
              },
              {
                when: "",
                where: "",
                where_other: "",
                who: "",
                who_other: "",
              },
              {
                when: "",
                where: "",
                where_other: "",
                who: "",
                who_other: "",
              },
              {
                when: "",
                where: "",
                where_other: "",
                who: "",
                who_other: "",
              },
              {
                when: "",
                where: "",
                where_other: "",
                who: "",
                who_other: "",
              },
              {
                when: "",
                where: "",
                where_other: "",
                who: "",
                who_other: "",
              },
              {
                when: "",
                where: "",
                where_other: "",
                who: "",
                who_other: "",
              },
            ],
          },
          {
            type: "慢跑",
            type_other: "",
            detail: [
              {
                when: "",
                where: "",
                where_other: "",
                who: "",
                who_other: "",
              },
              {
                when: "",
                where: "",
                where_other: "",
                who: "",
                who_other: "",
              },
              {
                when: "",
                where: "",
                where_other: "",
                who: "",
                who_other: "",
              },
              {
                when: "",
                where: "",
                where_other: "",
                who: "",
                who_other: "",
              },
              {
                when: "",
                where: "",
                where_other: "",
                who: "",
                who_other: "",
              },
              {
                when: "",
                where: "",
                where_other: "",
                who: "",
                who_other: "",
              },
              {
                when: "",
                where: "",
                where_other: "",
                who: "",
                who_other: "",
              },
            ],
          },
        ],
        data2: [
          {
            detail: [
                      {when: "",food: [],fruit: [],vg: [],meat: [],egg: [],bean: [],},
                      {when: "",food: [],fruit: [],vg: [],meat: [],egg: [],bean: [],},
                      {when: "",food: [],fruit: [],vg: [],meat: [],egg: [],bean: [],},
                      {when: "",food: [],fruit: [],vg: [],meat: [],egg: [],bean: [],},
                      {when: "",food: [],fruit: [],vg: [],meat: [],egg: [],bean: [],},
                      {when: "",food: [],fruit: [],vg: [],meat: [],egg: [],bean: [],},
                      {when: "",food: [],fruit: [],vg: [],meat: [],egg: [],bean: [],}
            ],
          },
        ],
      },
    };
  },
  computed: {
    type: function () {
      return this.$store.state.type;
    },
    num: function () {
      return this.$store.state.week;
    },
    user: function () {
      return this.$store.state.user;
    },
    pa: function () {
      return this.$store.state.pa;
    },
    diet: function () {
      return this.$store.state.diet;
    },
  },
  methods: {
    getPlanList: function (select) {
            let plan = this.plan;
            let list = [];
            for (let i = 0; i < plan.data[0].detail.length; i++) {
                let obj = {};
                if (plan.data[0].detail[i].when) 
                {
                    obj.date = weekDays[i];
                    obj.type = 1;
                    obj.action = plan.data[0].detail[i].when +
                            (plan.data[0].detail[i].who == "自己"?"":
                            "和" +
                            (plan.data[0].detail[i].who == "其他"
                                ? plan.data[0].detail[i].who_other
                                : plan.data[0].detail[i].who) +
                            "一起" )+ "到" +
                            (plan.data[0].detail[i].where == "其他"
                                ? plan.data[0].detail[i].where_other
                                : plan.data[0].detail[i].where) +
                            (plan.data[0].type == "其他"
                                ? plan.data[0].type_other
                                : plan.data[0].type);
                    if (!select || select == 1) list.push(obj);
                }
                obj = {};
                if (!plan.oneType && plan.data[1].detail[i].when) 
                {
                    obj.date = weekDays[i];
                    obj.type = 2;
                    obj.action = plan.data[1].detail[i].when +
                            (plan.data[1].detail[i].who == "自己"?"":
                            "和" +
                            (plan.data[1].detail[i].who == "其他"
                                ? plan.data[1].detail[i].who_other
                                : plan.data[1].detail[i].who) +
                            "一起" )+ "到" +
                            (plan.data[1].detail[i].where == "其他"
                                ? plan.data[1].detail[i].where_other
                                : plan.data[1].detail[i].where) +
                            (plan.data[1].type == "其他"
                                ? plan.data[1].type_other
                                : plan.data[1].type);
                    if (!select || select == 2) list.push(obj);
                }
            }

            console.log(list);
            return list;
        },
    getPlanList2: function () {
            let plan = this.plan;
            let list = [];
            for (let i = 0; i < plan.data2[0].detail.length; i++) {
                let obj;
                let day = plan.data2[0].detail[i];
                let count = day.fruit.length + day.vg.length + day.egg.length + day.meat.length + day.bean.length;
                let firstType = true;
                if (count>0) {
                    obj = {
                        date: weekDays[i],
                        action:"我計劃食",
                    };
                    if(day.fruit.length > 0)
                    {
                        if(!firstType)
                            obj.action = obj.action + "、";
                        obj.action = obj.action + "生果（" + day.fruit.join("和")+"）";
                        firstType = false;
                    }
                    if(day.vg.length > 0)
                    {
                        if(!firstType)
                            obj.action = obj.action + "、";
                        obj.action = obj.action + "蔬菜（" + day.vg.join("和")+"）";
                        firstType = false;
                    }
                    if(day.meat.length > 0)
                    {
                        if(!firstType)
                            obj.action = obj.action + "、";
                        obj.action = obj.action + "肉（" + day.meat.join("和")+"）";
                        firstType = false;
                    }
                    if(day.egg.length > 0)
                    {
                        if(!firstType)
                            obj.action = obj.action + "、";
                        obj.action = obj.action + "蛋（" + day.egg.join("和")+"）";
                        firstType = false;
                    }
                    if(day.bean.length > 0)
                    {
                        if(!firstType)
                            obj.action = obj.action + "、";
                        obj.action = obj.action + "替代品（" + day.bean.join("和")+"）";
                        firstType = false;
                    }
                    list.push(obj);
                }
                
            }
            return list;
        },
  },
  components: {
    //Some,
  },
  created() {
    let index = 1;
    let index2 = 1;
    if (this.user.pastate > 0) {
      if (this.user.pastate > 4) index = 4;
      else index = this.user.pastate;
      this.plan.data = this.pa[index].plan.data;
    }
    if (this.user.dietstate > 0) {
      if (this.user.dietstate > 4) index2 = 4;
      else index2 = this.user.dietstate;
      this.plan.data2 = this.diet[index2].plan.data;
    }
  },
  beforeDestroy() {},
};
</script>

<style scoped src="@/assets/css/common.css" />
<style scoped>
.custom {
  color: lightblue;
}

.list-wrapper {
  padding: 20px 10px 0px 10px;
}

.list-item {
  min-height: 100px;
}

.list-left {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #5b5b5b;
}

.list-right {
  width: 100%;
  border: 1px dashed red;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 20px;
  line-height:24px;
      word-wrap: break-word;
    word-break: break-all;
}

.cscroll {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.uplan_box {
  padding: 20px 10px 0px 10px;
}

.plan_no{
  padding:30px;
}

.plan_no p{
  font-size:16px;
  font-weight:bold;
}
</style>

