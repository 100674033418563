<template>
    <wrapper>
        <div v-if="debugbtn">
            <span>頁面編號調試：</span>
            <input v-model="page" />
        </div>
        <div
            v-if="page == 1"
            class="box"
        >
            <div v-if="!repeat">
                <div class="title">社會支持</div>
                <p>
                    <span class="bu blue">社會支持</span>對於我哋參與同堅持健康飲食具有重要嘅作用！跟住落黎我哋一齊睇下，你覺得邊啲人可以幫你更加積極去進行健康飲食。
                </p>
            </div>
            <div v-else>
                <p class="red">
                    請你再次諗下邊啲人可以幫你進行健康飲食，並繼續完成啱先選擇“<span v-if="num==1">冇</span> <span v-else>否</span>”嘅條目。
                </p>
            </div>

            <div v-show="!repeat || (reList.indexOf(1)!=-1)">
            
            <p>
                1．你通常會<span class="bu blue">同邊個一齊</span>進行健康飲食（如多食生果蔬菜同適量嘅肉、蛋及替代品） ？ （可多選）
            </p>
            <cube-checkbox-group
                v-model="support.q1"
                :options="allSupport"
                @input="checkNone"
            />
            <p v-if="haveOther(support.q1)">
                &nbsp;&nbsp;其他人喺指:&nbsp;
                <cube-input
                    class="other_input"
                    v-model="support.q1_other"
                ></cube-input>
            </p>
            <br />
            </div>

<div v-show="!repeat || (reList.indexOf(2)!=-1)">
            <p>
                2．邊個可以<span class="bu blue">負責同確保</span>令你有規律咁食多啲生果蔬菜同適量嘅肉、蛋及替代品？（可多選）
            </p>
            <cube-checkbox-group
                v-model="support.q2"
                :options="allSupport"
                @input="checkNone"
            />
            <p v-if="haveOther(support.q2)">
                &nbsp;&nbsp;其他人喺指:&nbsp;
                <cube-input
                    class="other_input"
                    v-model="support.q2_other"
                ></cube-input>
            </p>
            <br />
</div>

<div v-show="!repeat || (reList.indexOf(3)!=-1)">
            <p>
                3．如果你達到咗WHO推薦嘅健康飲食標準，邊個會<span class="bu blue">誇獎同讚賞</span>你？（可多選）
            </p>
            <cube-checkbox-group
                v-model="support.q3"
                :options="allSupport"
                @input="checkNone"
            />
            <p v-if="haveOther(support.q3)">
                &nbsp;&nbsp;其他人喺指:&nbsp;
                <cube-input
                    class="other_input"
                    v-model="support.q3_other"
                ></cube-input>
            </p>
            <br />
</div>

<div v-show="!repeat || (reList.indexOf(4)!=-1)">
            <p>
                4．邊個會幫你分擔一啲其他嘅事（如幫你買生果蔬菜和肉類），進而<span class="bu blue">幫</span>你達到WHO推薦嘅健康飲食標準？（可多選）
            </p>
            <cube-checkbox-group
                v-model="support.q4"
                :options="allSupport"
                @input="checkNone"
            />
            <p v-if="haveOther(support.q4)">
                &nbsp;&nbsp;其他人喺指:&nbsp;
                <cube-input
                    class="other_input"
                    v-model="support.q4_other"
                ></cube-input>
            </p>
            <br />
</div>

<div v-show="!repeat || (reList.indexOf(5)!=-1)">

            <p>
                5．喺進行健康飲食嘅時候，邊個會俾你一啲<span class="bu blue">建議</span>（例如，食d咩生果蔬菜對你嘅健康最有好處）？（可多選）
            </p>
            <cube-checkbox-group
                v-model="support.q5"
                :options="allSupport"
                @input="checkNone"
            />
            <p v-if="haveOther(support.q5)">
                &nbsp;&nbsp;其他人喺指:&nbsp;
                <cube-input
                    class="other_input"
                    v-model="support.q5_other"
                ></cube-input>
            </p>
            <br />
</div>
        </div>
        <div
            v-if="page == 2"
            class="box"
        >
            <div v-if="debugbtn">
                <span>頁面反饋調試：</span>
                <input v-model="feedback" />
            </div>

            <div v-if="feedback == 1 || feedback == 2">
                <div v-if="debugbtn">
                    <span>頁面故事調試：</span>
                    <input v-model="story" />
                </div>
                <p v-if="feedback == 1"  class="blue">
                    你似乎唔太清楚邊啲人可以幫你進行健康飲食。唔緊要，其他人嘅例子可能會對你有所啟發。
                </p>
                <p v-if="feedback == 2"  class="dark_green">
                    你已經諗到一啲可以幫你的人。但喺仲有一啲情況你唔清楚邊個可以幫到你。其他人嘅例子可能會對你有所啟發。
                </p>
                <div v-if="story == 1">
                <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/diet/Week_1_Social_support.mp3" ></audio>
                    <p>
                        陳先生（68歲）：我和老婆以前喜歡在外面餐廳吃，而且餐餐只吃肉不吃菜。後來兒子建議我們要多吃生果蔬菜，説這能夠幫助補充維生素和提高身體免疫力。現在我和老婆經常去街市買餸，然後在家煮飯。</p><p>通常我們會在去街市之前就計劃好想要買的食材，每次都會買新鮮蔬菜和魚、肉。做飯時，我們也是各有分工。儘管買菜做飯比較花時間，但我們在家裡會吃的比較健康，並且享受一起做飯的樂趣。
                    </p>
                    <img
                     class="fw8" 
                        alt="支持反饋1"
                        src="../../assets/img/di_support_story1.png"
                    />
                </div>

                <div v-if="story == 2">
                <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/diet/Week_2_social_support.mp3" ></audio>
                    <p>
                        劉先生（65歲）：我和老伴現在年紀都牙齒不好，已經嚼不了硬的生果和一些肉類了。但為了保證有足夠的營養，我們經常把豬肉和蔬菜剁碎，包餃子吃。</p><p>子女們每次來看我們，都會買些適合老人吃的生果，例如香蕉、橙。我的老伴總是將生果切成小塊，方便我們每天都能吃兩、三個生果。家人的幫助和陪伴讓我更有信心堅持健康的飲食習慣，我很感激他們。
                    </p>
                    <img
                     class="fw8" 
                        alt="支持反饋2"
                        src="../../assets/img/di_support_story2.jpg"
                    />
                </div>

                <div v-if="story == 3">
                <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/diet/Week_3_Social_support_65.mp3" ></audio>
                    <p>
                        王先生（65歲）：2020年的時候，家庭醫生發現我患有高血壓和高膽固醇，醫生建議我注意飲食。我的老伴就開始監督我，並且在做飯的時選擇有益健康的食物。我的老伴不讓我吃加工和煎炸食品，只吃新鮮的食物，如魚類、雞肉、豬肉和大量的蔬菜，她每天給我準備兩次生果，包括提子、橙、蘋果和香蕉。</p><p>雖然我60多歲，但是大家都讚我依然精神飽滿，活力充沛。現在我的各項指標和以前相比都有明顯改善。家人的幫助和鼓勵讓我一直有信心堅持進行健康飲食，我很感激他們。
                    </p>
                    <img
                     class="fw8" 
                        alt="支持反饋3"
                        src="../../assets/img/di_support_story3.png"
                    />
                </div>

                <div v-if="story == 4">
                <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/diet/Week_3_Social_support_75.mp3" ></audio>
                    <p>
                        胡先生（75歲）：
                        去年我去體檢，發現我的血壓血脂不正常，我也知道是因為自己的飲食習慣不好，但是自己只愛吃肉，蔬菜生果想起才吃一點。我老伴和我一起去體檢，她的各項指標都很正常，她建議我和她一起進行健康的飲食，多吃生果蔬菜，吃適量的肉，不要貪吃。</p><p>我的老伴總是準備好生果拿到我面前，也開始換不同的烹調方式，例如蔬菜餅或者素食的餃子和餛飩，買菜的時候也不再像過去任由我吃肉了，讓我控制攝取量。上個月我去體檢，醫生説我的血壓和血脂恢復了正常，還問我是如何做到的！家人的支持和醫生的肯定讓我對堅持健康飲食充滿了信心。為了身體健康，我一定會堅持下去的。

                    </p>
                    <img
                     class="fw8" 
                        alt="支持反饋4"
                        src="../../assets/img/di_support_story4.jpg"
                    />
                </div>
                <div v-if="story == 5">
                <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/diet/Week_5_Social_support.mp3" ></audio>
                    <p>
                        祝先生（63歲）：我以前只知道老人家需要吃得健康，但是我並不清楚怎樣的飲食才是健康飲食習慣。有一次我的老伴參加了一個健康知識講座，瞭解到我們老人家每天要吃至少5份生果和蔬菜，蛋白質不能吃太多，每天吃5-6兩的魚、肉、蛋以及替代品就足夠了。</p><p>在老伴的建議下，我們開始嚴格遵循這個健康飲食指南。每次去街市買餸前，老伴會計劃好買甚麼生果蔬菜和肉。她還會準備好生果拼盤做我們的下午茶。老伴的支持和陪伴令我對堅持健康飲食習慣忘滿了信心
                    </p>
                    <img
                     class="fw8" 
                        alt="支持反饋5"
                        src="../../assets/img/di_support_story5.png"
                    />
                </div>
                <div v-if="story == 6">
                <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/diet/Week_6_Social_support.mp3" ></audio>
                    <p>
                        黎先生（75歲）：因為我患有心臟病，醫生説想要保持心臟健康，日常的健康飲食十分重要。醫生建議我多吃瘦肉（如裏脊和臀尖肉）、禽類或魚，避免吃肥肉（如五花肉，腸，排骨和熱狗）。我老伴每次出街，總是買適合我吃的肉或魚。另外，醫生建議我避免油炸的食物。我老伴就用不同的方法去準備食物，例如烘烤，焙，蒸，煮，炒和燉。</p><p>
                        醫生也建議我多食可溶性纖維，保證一天至少5份生果和蔬菜，有助於降低我的血膽固醇含量。可溶性纖維含量高的生果蔬菜有：胡蘿蔔，西蘭花，柑橘類生果，木瓜，草莓，和蘋果。每餐午飯和晚飯我老伴都會準備蔬菜，下午三、四點的時候，也會準備一些生果。她的支持和陪伴幫助我保持健康，我十分感激她。

                    </p>
                    <img
                     class="fw8" 
                        alt="支持反饋6"
                        src="../../assets/img/di_support_story6.jpg"
                    />
                </div>
                <div v-if="story == 7">
                <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/diet/Week_7_Social_Support.mp3" ></audio>
                    <p>
                        黃先生（70歲）：我有慢性胃炎，醫生説如果不注意日常的飲食會有胃癌的風險。我的家人們都十分照顧我的日常飲食，我兒子會為我列好一星期的菜單，並叮囑少吃下午茶，如果吃下午茶，建議我以蔬果沙拉為主。</p><p>我老伴考慮到我應以清淡以及温和飲食為主，她會陪我一起不吃油炸辛辣的食物，並且給我做不同種類的蔬菜。冬天的時候，如果生果比較凍，她會將生果放在微波爐裡加熱。在家人的精心照顧下，我的日常飲食非常健康，已經有好久沒有復發胃炎了。非常感謝家人們在健康飲食上對我的支持。
                    </p>
                    <img
                     class="fw8" 
                        alt="支持反饋7"
                        src="../../assets/img/di_support_story7.jpg"
                    />
                </div>
                <div v-if="story == 8">
                <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/diet/Week_8_Social_support.mp3" ></audio>
                    <p>
                        馮先生（67歲）：我和我四個老友記都喜歡吃燒烤和油炸食物。大家每年的體檢報告都有三高現象。醫生建議我們多吃粗糧、雜糧、深綠色蔬菜、生果、豆製品、瘦肉、魚等食物，少吃油膩食品及少飲濃茶、咖啡等飲品。為了堅持健康飲食，我們一班老友記開始在WhatsApp羣組裡互相監督，互相鼓勵。每天我們都會在羣裡上傳自己吃的早餐、午餐和晚餐的圖片，也會告知大家自己當天吃了什麼生果。</p><p>當有人吃了很多燒烤和油炸食物時，其他老友記會在羣裡提醒他注意。同時，大家還會分享自己在堅持健康飲食過程中的體會以及遇到的困難。當有老友記堅持不下去的時候，其他老友記會一起給他加油鼓勵！我們保持這樣的監督模式已經半年了，大家都感覺這個方法很有效，老友記的鼓勵和支持對我保持健康飲食非常重要！
                    </p>
                    <img
                     class="fw8" 
                        alt="支持反饋8"
                        src="../../assets/img/di_support_story8.jpg"
                    />
                </div>
                <div v-if="story == 9">
                 <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/diet/Week_9_Social_Support.mp3" ></audio>
                    <p>
                        宋女士（68歲）：由於我患有糖尿病，科學飲食對我來説非常重要。過去我很喜歡吃甜食和糕點，每一餐都吃很多肉，不愛吃生果蔬菜。後來我老伴幫我整理了一份健康食譜，詳細列出了一星期每天的飲食內容和份量。督促我每天至少吃3份蔬菜和2份低糖分的生果（蘋果，橙）。還要每天吃兩掌心份量的肉類，主要以動物蛋白為主，包括廋肉，牛肉和魚肉。</p><p>我女兒還在家裡的門上，冰箱上貼了一些小紙條提醒我進行健康飲食，例如“蛋糕+油炸食品：糖尿病人的飲食大忌”,“多吃蔬菜，多些健康！媽媽加油”。在家人的幫助和鼓勵下，我堅持了四個月的健康飲食。去醫院檢查血糖，醫生説我各項指標都有改善，我很開心。今後我還會繼續堅持健康飲食的！
                    </p>
                    <img
                     class="fw8" 
                        alt="支持反饋9"
                        src="../../assets/img/di_support_story9.png"
                    />
                </div>
                <div v-if="story == 10">
                <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/diet/Week_10_Social_Support.mp3" ></audio>
                    <p>
                        林女士（70歲）：兩年前我意外骨折，住院期間得知我有較嚴重的骨質疏鬆症。醫生告訴我，隨著身體的老化，骨密度會持續下降，未來骨折的風險更大。建議我要增強骨骼健康，除了服用鈣片，還需要在日常飲食中多吃對骨骼有幫助的食物。例如三文魚、沙丁魚、豆類食品和各類堅果。同時深色綠葉蔬菜（高麗菜和大頭菜）也能提供大量鈣質。</p><p>我的家人們現在都會幫助我多吃對骨骼健康有益處的食物。老伴總是監督我每天多吃魚類、豆製品和深色蔬菜。子女們週末看我們，也都會買堅果讓我們閒時當小食。在家人的精心照顧下，我的骨密度一直沒有下降，也沒有骨折。我非常感激家人的支持。
                    </p>
                    <img
                     class="fw8" 
                        alt="支持反饋10"
                        src="../../assets/img/di_support_story10.jpg"
                    />
                </div>
            </div>
              <div v-if="feedback == 3">
                <p>你清楚地知道邊啲人可以支持你實現健康飲食嘅目標。非常好！繼續保持！</p>
                <p class="b">
                    希望你可以通過屋企人同朋友嘅幫助同埋支持，堅持進行健康飲食。
                </p>
<p></p>
                <cube-button @click="review">社會支持總結</cube-button>
                <p></p>
                <p></p>
                <img
                 class="fw8" 
                    alt="支持反饋2"
                    src="../../assets/img/di_support_feedback3.jpg"
                />
            </div>
        </div>
        <div
            v-if="page == 3"
            class="box"
        >
            <div class="title">社會支持總結</div>

            <div v-if="revType == 1">
                <p>
                    1. 我可以同呢個人（呢啲人）<span class="bu">一齊</span>進行健康飲食（如食多啲生果蔬菜同適量嘅肉、蛋及替代品）：<span class="red b">{{ showcp(1) }}</span>
                </p>
                <p>
                    2. 呢個人（呢啲人）可以<span class="bu">負責以確保</span>有規律咁食多啲生果蔬菜同適量的肉、蛋及替代品：<span class="red b">{{ showcp(2) }}</span>
                </p>
                <p>
                    3. 當我盡力達到咗WHO推薦嘅健康飲食標準，呢個人（呢啲人）會<span class="bu">鼓勵同讚賞</span>我：<span class="red b">{{ showcp(3) }}</span>
                </p>
                <p>
                    4. 呢個人（呢啲人）為咗令我達到WHO推薦嘅健康飲食標準會<span class="bu">幫我分擔</span>一啲野：<span class="red b">{{ showcp(4) }}</span>
                </p>
                <p>
                    5. 呢個人（呢啲人）可以為我進行健康飲食提出一啲<span class="bu">建議</span>：<span class="red b">{{ showcp(5) }}</span>
                </p>
            </div>
            <div v-if="revType == 2">
                <p>
                    1．你通常會<span class="bu">同邊個一齊</span>進行健康飲食：<span class="red b">{{
            showcp(1)
          }}</span>
                </p>
                <p>
                    2．邊個可以<span class="bu">負責同確保</span>令你有規律咁食多啲生果蔬菜同適量的肉、蛋及替代品：<span class="red b">{{
            showcp(2)
          }}</span>
                </p>
                <p>
                    3．如果你盡力達到咗WHO推薦嘅健康飲食標準，邊個會<span class="bu">讚賞</span>你：<span class="red b">{{ showcp(3) }}</span>
                </p>
                <p>
                    4．邊個會幫你分擔一啲其他嘅事（如幫你買生果蔬菜同肉類），進而<span class="bu">幫</span>你達到WHO推薦嘅健康飲食標準：<span class="red b">{{ showcp(4) }}</span>
                </p>
                <p>
                    5．喺進行健康飲食方面，邊個會俾你一啲<span class="bu">建議</span>例如，食d咩生果蔬菜最有利於你嘅健康）：<span class="red b">{{ showcp(5) }}</span>
                </p>
            </div>
        </div>

        <div
            v-if="page == 4"
            class="box"
        >
            <div class="title">社會支持</div>
            <p>
                上個禮拜你提出喺進行健康飲食嘅時候應該可以得到以下嘅社會支持，請你回憶你今個星期喺咪得到呢啲社會支持：
            </p>
            <p>
                1．我可以同呢個人（呢啲人）<span class="bu">一齊</span>進行健康飲食（如食多啲生果蔬菜同適量的肉、蛋及替代品）：<span class="red b">{{
          showcp(1)
        }}</span>
            </p>
            <cube-radio-group v-model="support.perEval.q1">
                <cube-radio
                    key="1"
                    :option="{ value: '是' }"
                > 是</cube-radio>
                <cube-radio
                    key="2"
                    :option="{ value: '否' }"
                > 否</cube-radio>
            </cube-radio-group>
            <p>
                2．呢個人（呢啲人）可以<span class="bu">負責同確保</span>我有規律咁食多啲生果蔬菜同適量嘅肉、蛋及替代品：<span class="red b">{{
          showcp(2)
        }}</span>
            </p>
            <cube-radio-group v-model="support.perEval.q2">
                <cube-radio
                    key="1"
                    :option="{ value: '是' }"
                > 是</cube-radio>
                <cube-radio
                    key="2"
                    :option="{ value: '否' }"
                > 否</cube-radio>
            </cube-radio-group>
            <p>
                3．當我盡力達到咗WHO推薦嘅健康飲食標準呢個人（呢啲人）會<span class="bu">讚賞</span>我：<span class="red b">{{ showcp(3) }}</span>
            </p>
            <cube-radio-group v-model="support.perEval.q3">
                <cube-radio
                    key="1"
                    :option="{ value: '是' }"
                > 是</cube-radio>
                <cube-radio
                    key="2"
                    :option="{ value: '否' }"
                > 否</cube-radio>
            </cube-radio-group>
            <p>
                4．呢個人（呢啲人）為咗令我達到WHO推薦嘅健康飲食標準會<span class="bu">幫我</span>分擔一啲其他嘅事：<span class="red b">{{ showcp(4) }}</span>
            </p>
            <cube-radio-group v-model="support.perEval.q4">
                <cube-radio
                    key="1"
                    :option="{ value: '是' }"
                > 是</cube-radio>
                <cube-radio
                    key="2"
                    :option="{ value: '否' }"
                > 否</cube-radio>
            </cube-radio-group>
            <p>
                5．呢個人（呢啲人）可以為我進行健康飲食提出一啲<span class="bu">建議</span>：<span class="red b">{{ showcp(5) }}</span>
            </p>
            <cube-radio-group v-model="support.perEval.q5">
                <cube-radio
                    key="1"
                    :option="{ value: '是' }"
                > 是</cube-radio>
                <cube-radio
                    key="2"
                    :option="{ value: '否' }"
                > 否</cube-radio>
            </cube-radio-group>
        </div>
    </wrapper>
</template>



<script>
import wrapper from "@/components/wrapper";
import { allSupport, allSupport2, validErr } from "@/data/data";
import tools from "@/data/tools";

export default {
    name: "pa_support",
    data() {
        return {
            page: 0,
            feedback: 1,
            story: 1,
            revType: 1,
            allSupport: allSupport,
            allSupport2: allSupport2,
            repeat: false,
            reList:[],
            support: {
                perEval: {
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0,
                    q5: 0,
                },
                q1: [],
                q1_other: "",
                q2: [],
                q2_other: "",
                q3: [],
                q3_other: "",
                q4: [],
                q4_other: "",
                q5: [],
                q5_other: "",
            },

            perweek: {},
        };
    },
    computed: {
        type: function () {
            return this.$store.state.type;
        },
        num: function () {
            return this.$store.state.week;
        },
        user: function () {
            return this.$store.state.user;
        },
        diet: function () {
            return this.$store.state.diet;
        },
        debugbtn: function () {
            return this.$store.state.debugbtn;
        },

    },
    created() {
        if (!this.page) {
            if (this.num == 1) this.page = 1;
            else if (this.num >= 2 && this.num <= 10) this.page = 4;
        }

        if (this.diet[this.num] != null) {
            this.support = tools.copy(this.diet[this.num].support);
        } else {
            //編輯，先查看是否有緩存
            if (this.diet[0].support) {
                this.support = this.diet[0].support;
            }
        }
        // 無論查看還是編輯， 初始化上週內容
        if (this.diet[this.num - 1] != null) {
            this.perweek = tools.copy(this.diet[this.num - 1].support);
        }
        if (!this.$store.state.review && this.num != 1) {
            this.support = tools.copy(this.perweek);
            this.support.perEval.q1 = 0;
            this.support.perEval.q2 = 0;
            this.support.perEval.q3 = 0;
            this.support.perEval.q4 = 0;
            this.support.perEval.q5 = 0;
        }
    },
    methods: {
        checkNone: function (val) {
            if (this.haveNone(val)) {
                while (val.length > 1) val.shift();
            }
        },
        haveOther: function (arr) {
            for (let item of arr) {
                if (item == "其他人") return true;
            }
            return false;
        },
        haveNone: function (arr) {
            for (let item of arr) {
                if (item == "沒有") return true;
            }
            return false;
        },
        showcp: function (no) {
            let cps = "";
            let arr = this.support["q" + no];
            if (arr.length == 0 || this.haveNone(arr)) return "沒有";
            for (let val of arr) {
                if (cps) cps = cps + "和";
                if (val != "其他人") {
                    cps = cps + val;
                } else {
                    cps = cps + this.support["q" + no + "_other"];
                }
            }
            return cps;
        },
        countNo: function () {
            let num = 0;
            if (this.support.perEval.q1 == "否") num += 1;
            if (this.support.perEval.q2 == "否") num += 1;
            if (this.support.perEval.q3 == "否") num += 1;
            if (this.support.perEval.q4 == "否") num += 1;
            if (this.support.perEval.q5 == "否") num += 1;
            return num;
        },
        countNone: function () {
            let num = 0;
            if (this.support.q1.length == 0 || this.haveNone(this.support.q1))
                num += 1;
            if (this.support.q2.length == 0 || this.haveNone(this.support.q2))
                num += 1;
            if (this.support.q3.length == 0 || this.haveNone(this.support.q3))
                num += 1;
            if (this.support.q4.length == 0 || this.haveNone(this.support.q4))
                num += 1;
            if (this.support.q5.length == 0 || this.haveNone(this.support.q5))
                num += 1;
            return num;
        },
        getFeedback: function (byper) {
            this.story = this.num;
            if (byper) {
                let no = this.countNo();
                if (no == 0) return 3;
                else if (no >= 1 && no <= 3) return 2;
                else if (no > 3) return 1;
            } else {
                let none = this.countNone();
                if (none == 0) return 3;
                else if (none >= 1 && none <= 3) return 2;
                else if (none > 3) return 1;
            }
        },
        review: function () {
            this.page = 3;
        },
        save: function () {
                        if(this.$store.state.review)
                return;
            let xml = {};
            xml.key = [
                "社會支持1",
                "社會支持2",
                "社會支持3",
                "社會支持4",
                "社會支持5",
                "上週獲得支持1",
                "上週獲得支持2",
                "上週獲得支持3",
                "上週獲得支持4",
                "上週獲得支持5",
            ];
            xml.data = [];
            xml.data.push(this.showcp(1));
            xml.data.push(this.showcp(2));
            xml.data.push(this.showcp(3));
            xml.data.push(this.showcp(4));
            xml.data.push(this.showcp(5));
            xml.data.push(this.support.perEval.q1);
            xml.data.push(this.support.perEval.q2);
            xml.data.push(this.support.perEval.q3);
            xml.data.push(this.support.perEval.q4);
            xml.data.push(this.support.perEval.q5);
            this.support.xml = xml;

            this.diet[0].support = tools.copy(this.support);
        },
        pause: function () {
            this.save();
        },
        leave: function (page) {
            if (typeof this.page == "string") this.page = parseInt(this.page);

            if (page == 1) {
                this.feedback = this.getFeedback();
            } else if (page == 2) {
                this.repeat = true;
                let reList = [];
                if(this.haveNone(this.support.q1) || this.support.perEval.q1=='否')
                    reList.push(1);
                if(this.haveNone(this.support.q2) || this.support.perEval.q2=='否')
                    reList.push(2);
                if(this.haveNone(this.support.q3) || this.support.perEval.q3=='否')
                    reList.push(3);
                if(this.haveNone(this.support.q4) || this.support.perEval.q4=='否')
                    reList.push(4);
                if(this.haveNone(this.support.q5) || this.support.perEval.q5=='否')
                    reList.push(5);
                this.reList = reList;
                if (this.feedback == 3) this.repeat = false;
            } else if (page == 4) {
                this.feedback = this.getFeedback(true);
            }
        },
        valid: function (page) {
            console.log(this.support);
            console.log(this.countNone());
            if (page == 1) {
                for (let i = 1; i <= 5; i++) {
                    if (
                        this.support["q" + i].length == 0 ||
                        (this.haveOther(this.support["q" + i]) &&
                            !this.support["q" + i + "_other"])
                    )
                        return validErr.empty;
                }
            }
            if (page == 4) {
                if (
                    !this.support.perEval.q1 ||
                    !this.support.perEval.q2 ||
                    !this.support.perEval.q3 ||
                    !this.support.perEval.q4 ||
                    !this.support.perEval.q5
                )
                    return validErr.empty;
            }
        },
        next: function () {
            console.log("support next");
            if (this.valid(this.page)) {
                this.$createDialog({
                    type: "alert",
                    title: "",
                    content: this.valid(this.page),
                }).show();
                return;
            }

            this.leave(this.page);

            let done = false;
            switch (this.page) {
                // 社會支持設置頁
                case 1:
                    if (this.repeat) done = true;
                    else {
                        this.page = 2;
                    }
                    break;
                // 社會支持反饋頁
                case 2:
                    if(this.num == 1 &&this.countNone()==0 ||
                    this.num!=1 && this.countNo()==0)
                        done = true;
                    else if (this.repeat) this.page = 1;
                    else done = true;
                    break;
                // 社會支持總結頁
                case 3:
                    this.page = 2;
                    break;
                // 社會支持回顧頁
                case 4:
                    this.page = 2;
                    break;
            }
            if (done) {
                this.save();
                return this.page;
            }
        },
    },
    components: {
        wrapper: wrapper,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../assets/css/learn_common.css" />
<style scoped>
#list {
    margin: 0 auto;
}

div.cube-radio {
    font-size: 20px;
}
</style>