import Vue from 'vue'
import Cube from 'cube-ui'
import { Locale } from 'cube-ui'
import App from './App.vue'
import store from './store'
import router from './router'
import Meta from "vue-meta";
import './assets/css/global.css'
import zh_TW from './data/zh-TW.js'

// 使用 Cube
Vue.use(Locale)
Vue.use(Cube)


Locale.use('zh-CN', zh_TW);
Locale.use('default', zh_TW);


// 使用 vue-meta
Vue.use(Meta);

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')