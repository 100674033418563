var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _vm.debugbtn
      ? _c("div", [
          _c("span", [_vm._v("頁面編號調試：")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.page,
                expression: "page",
              },
            ],
            domProps: { value: _vm.page },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.page = $event.target.value
              },
            },
          }),
        ])
      : _vm._e(),
    _vm.page == 1
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("運動鍛煉的障礙")]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.complete,
                  expression: "!complete",
                },
              ],
            },
            [
              _c("p", [
                _vm._v(
                  " 通常，我哋喺進行運動鍛煉時會面臨好多困難，令到我哋唔可以堅持落去，而家等我哋黎學下點樣克服運動鍛煉嘅時候可能遇到嘅障礙。 "
                ),
              ]),
              _c("p", [
                _vm._v(
                  " 好多情況下，你會覺得完成足夠嘅運動（姐係每星期最少5日，每日最少30分鐘或者每星期加埋最少150分鐘）好困難。而家請你認真思考，然後回答以下問題。 "
                ),
              ]),
              _c("p", [
                _vm._v(" 1. 就算我有"),
                _c("span", { staticClass: "blue" }, [_vm._v("好多野要做")]),
                _vm._v("，我都會計劃好去做運動。 "),
              ]),
              _c(
                "p",
                [
                  _vm._v("不符合"),
                  _c("cube-rate", {
                    model: {
                      value: _vm.barrier.rate1,
                      callback: function ($$v) {
                        _vm.$set(_vm.barrier, "rate1", $$v)
                      },
                      expression: "barrier.rate1",
                    },
                  }),
                  _vm._v("符合我"),
                ],
                1
              ),
              _c("br"),
              _c("p", [
                _vm._v(" 2. 就算"),
                _c("span", { staticClass: "blue" }, [_vm._v("天氣唔好")]),
                _vm._v("，我都會計劃好去做運動。 "),
              ]),
              _c(
                "p",
                [
                  _vm._v("不符合"),
                  _c("cube-rate", {
                    model: {
                      value: _vm.barrier.rate2,
                      callback: function ($$v) {
                        _vm.$set(_vm.barrier, "rate2", $$v)
                      },
                      expression: "barrier.rate2",
                    },
                  }),
                  _vm._v("符合我"),
                ],
                1
              ),
              _c("br"),
              _c("p", [
                _vm._v(" 3. 就算我"),
                _c("span", { staticClass: "blue" }, [
                  _vm._v("覺得運動好晒時間"),
                ]),
                _vm._v("，我都會計劃好去做運動。 "),
              ]),
              _c(
                "p",
                [
                  _vm._v("不符合"),
                  _c("cube-rate", {
                    model: {
                      value: _vm.barrier.rate3,
                      callback: function ($$v) {
                        _vm.$set(_vm.barrier, "rate3", $$v)
                      },
                      expression: "barrier.rate3",
                    },
                  }),
                  _vm._v("符合我"),
                ],
                1
              ),
              _c("br"),
              _c("p", [
                _vm._v(" 4. 就算我"),
                _c("span", { staticClass: "blue" }, [_vm._v("覺得好攰")]),
                _vm._v("，我都會計劃好去做運動。 "),
              ]),
              _c(
                "p",
                [
                  _vm._v("不符合"),
                  _c("cube-rate", {
                    model: {
                      value: _vm.barrier.rate4,
                      callback: function ($$v) {
                        _vm.$set(_vm.barrier, "rate4", $$v)
                      },
                      expression: "barrier.rate4",
                    },
                  }),
                  _vm._v("符合我"),
                ],
                1
              ),
              _c("br"),
              _c("p", [
                _vm._v(" 5. 就算我"),
                _c("span", { staticClass: "blue" }, [_vm._v("冇運動夥伴")]),
                _vm._v("，我都會計劃好去做運動。 "),
              ]),
              _c(
                "p",
                [
                  _vm._v("不符合"),
                  _c("cube-rate", {
                    model: {
                      value: _vm.barrier.rate5,
                      callback: function ($$v) {
                        _vm.$set(_vm.barrier, "rate5", $$v)
                      },
                      expression: "barrier.rate5",
                    },
                  }),
                  _vm._v("符合我"),
                ],
                1
              ),
              _c("br"),
              _c("p", [
                _vm._v(" 6. 就算"),
                _c("span", { staticClass: "blue" }, [_vm._v("缺乏運動場地")]),
                _vm._v("，我都會計劃好去做運動。 "),
              ]),
              _c(
                "p",
                [
                  _vm._v("不符合"),
                  _c("cube-rate", {
                    model: {
                      value: _vm.barrier.rate6,
                      callback: function ($$v) {
                        _vm.$set(_vm.barrier, "rate6", $$v)
                      },
                      expression: "barrier.rate6",
                    },
                  }),
                  _vm._v("符合我"),
                ],
                1
              ),
              _c("br"),
            ]
          ),
          _vm.complete
            ? _c("div", [
                _vm.barrier.rate1 > 3 ||
                _vm.barrier.rate2 > 3 ||
                _vm.barrier.rate3 > 3 ||
                _vm.barrier.rate4 > 3 ||
                _vm.barrier.rate5 > 3 ||
                _vm.barrier.rate6 > 3
                  ? _c("p", [
                      _vm._v("你啱先已經指出，喺呢啲情況下，你"),
                      _c("span", { staticClass: "blue" }, [_vm._v("仲係可以")]),
                      _vm._v("做足夠的運動 ："),
                    ])
                  : _vm._e(),
                _vm.barrier.rate1 > 3
                  ? _c("p", { staticClass: "limegreen" }, [
                      _vm._v("有好多野要做"),
                    ])
                  : _vm._e(),
                _vm.barrier.rate2 > 3
                  ? _c("p", { staticClass: "limegreen" }, [_vm._v("天氣唔好")])
                  : _vm._e(),
                _vm.barrier.rate3 > 3
                  ? _c("p", { staticClass: "limegreen" }, [
                      _vm._v("覺得運動好晒時間"),
                    ])
                  : _vm._e(),
                _vm.barrier.rate4 > 3
                  ? _c("p", { staticClass: "limegreen" }, [_vm._v("覺得好攰")])
                  : _vm._e(),
                _vm.barrier.rate5 > 3
                  ? _c("p", { staticClass: "limegreen" }, [
                      _vm._v("冇運動夥伴"),
                    ])
                  : _vm._e(),
                _vm.barrier.rate6 > 3
                  ? _c("p", { staticClass: "limegreen" }, [
                      _vm._v("缺乏運動場地"),
                    ])
                  : _vm._e(),
                _c("br"),
                _vm.barrier.rate1 < 4 ||
                _vm.barrier.rate2 < 4 ||
                _vm.barrier.rate3 < 4 ||
                _vm.barrier.rate4 < 4 ||
                _vm.barrier.rate5 < 4 ||
                _vm.barrier.rate6 < 4
                  ? _c("p", [
                      _vm._v("但係喺呢啲情況下，你會覺得做足夠運動量有困難："),
                    ])
                  : _vm._e(),
                _vm.barrier.rate1 < 4
                  ? _c("p", { staticClass: "red" }, [_vm._v("有好多野要做")])
                  : _vm._e(),
                _vm.barrier.rate2 < 4
                  ? _c("p", { staticClass: "red" }, [_vm._v("天氣唔好")])
                  : _vm._e(),
                _vm.barrier.rate3 < 4
                  ? _c("p", { staticClass: "red" }, [
                      _vm._v("覺得活動好晒時間"),
                    ])
                  : _vm._e(),
                _vm.barrier.rate4 < 4
                  ? _c("p", { staticClass: "red" }, [_vm._v("覺得好攰")])
                  : _vm._e(),
                _vm.barrier.rate5 < 4
                  ? _c("p", { staticClass: "red" }, [_vm._v("冇運動夥伴")])
                  : _vm._e(),
                _vm.barrier.rate6 < 4
                  ? _c("p", { staticClass: "red" }, [_vm._v("缺乏運動場地")])
                  : _vm._e(),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.page == 2
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("運動障礙對應計劃")]),
          _c("p", { staticClass: "blue" }, [
            _vm._v(
              " 唔好咁灰。針對困難情況諗出對應計劃會對你有幫助。其他人嘅例子可能會對你有所啟發。 "
            ),
          ]),
          _c("audio", {
            staticClass: "gaudio",
            attrs: {
              controls: "",
              controlsList: "nodownload",
              preload: "auto",
              src: require("@/assets/guide/pa/Week_5_Barriers.mp3"),
            },
          }),
          _c("p", [
            _vm._v(
              " 黃先生（75歲）：我感覺自己年紀大了，不適合進行運動，也擔心自己運動的時候會受傷。在一次聚會中，我和以前的同事取得了聯繫。因為大家的身體狀況差不多，子女們比較忙，沒有時間陪伴我們。"
            ),
          ]),
          _c("p", [
            _vm._v(
              "現在大家早上就會相約一起去公園鍛鍊，星期六日大家也會一起去行山。和他們在一起我很開心，現在我也更願意堅持去進行運動鍛鍊。在這個過程中我感覺自己的身體越來越好，越來越健康。 "
            ),
          ]),
          _c("img", {
            staticClass: "fw8",
            attrs: {
              alt: "pa_barrier_1.jpg",
              src: require("../../assets/img/pa_barrier_1.jpg"),
            },
          }),
        ])
      : _vm._e(),
    _vm.page == 3
      ? _c(
          "div",
          { staticClass: "box" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("運動障礙對應計劃")]),
            _c("p", [
              _vm._v(" 跟住落黎請你認真思考下"),
              _c("span", { staticClass: "red b" }, [
                _vm._v("阻礙你參加同堅持運動嘅原因"),
              ]),
              _vm._v(
                "。如果你有做運動嘅諗法，但始終冇去做。諗一諗有啲咩原因阻礙你去運動呢？ "
              ),
            ]),
            _c("p", [
              _vm._v(" 請用幾分鐘嘅時間諗下，揀出最多"),
              _c("span", { staticClass: "bu" }, [_vm._v("兩種")]),
              _vm._v("障礙和困難。 "),
            ]),
            _c("p", [_vm._v("我最難應對嘅障礙喺")]),
            _c(
              "p",
              { staticClass: "select_line" },
              [
                _c("cube-select", {
                  staticClass: "inline_select",
                  attrs: { options: _vm.barrierList(0) },
                  model: {
                    value: _vm.barrier.data[0].type,
                    callback: function ($$v) {
                      _vm.$set(_vm.barrier.data[0], "type", $$v)
                    },
                    expression: "barrier.data[0].type",
                  },
                }),
                _vm.barrier.data[0].type == _vm.otherTxt
                  ? _c(
                      "span",
                      [
                        _vm._v("請輸入"),
                        _c("cube-input", {
                          staticClass: "other_input",
                          model: {
                            value: _vm.barrier.data[0].type_other,
                            callback: function ($$v) {
                              _vm.$set(_vm.barrier.data[0], "type_other", $$v)
                            },
                            expression: "barrier.data[0].type_other",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v("。 "),
              ],
              1
            ),
            !_vm.barrier.oneType
              ? _c("div", [
                  _c("p", [_vm._v("另一個阻礙我運動的障礙喺")]),
                  _c(
                    "p",
                    { staticClass: "select_line" },
                    [
                      _c("cube-select", {
                        staticClass: "inline_select",
                        attrs: { options: _vm.barrierList(1) },
                        model: {
                          value: _vm.barrier.data[1].type,
                          callback: function ($$v) {
                            _vm.$set(_vm.barrier.data[1], "type", $$v)
                          },
                          expression: "barrier.data[1].type",
                        },
                      }),
                      _vm.barrier.data[1].type == _vm.otherTxt
                        ? _c(
                            "span",
                            [
                              _vm._v("請輸入"),
                              _c("cube-input", {
                                staticClass: "other_input",
                                model: {
                                  value: _vm.barrier.data[1].type_other,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.barrier.data[1],
                                      "type_other",
                                      $$v
                                    )
                                  },
                                  expression: "barrier.data[1].type_other",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v("。 "),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c(
              "cube-checkbox",
              {
                staticClass: "inline_checkbox font_large",
                model: {
                  value: _vm.barrier.oneType,
                  callback: function ($$v) {
                    _vm.$set(_vm.barrier, "oneType", $$v)
                  },
                  expression: "barrier.oneType",
                },
              },
              [_vm._v("暫時只諗到一個障礙")]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.page == 4
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("運動障礙對應計劃")]),
          _c("p", [
            _vm._v(
              " 同運動計劃相似，一個準確嘅計劃會對你有幫助。記住 3大方法（適合性，有效性，易實現性）。 "
            ),
          ]),
          _c("p", [
            _vm._v(" 訂下有效的對應計劃最方便嘅辦法就係"),
            _c("span", { staticClass: "bu" }, [_vm._v("造句法")]),
            _vm._v("，用"),
            _c("span", [_vm._v("“如果…….咁…….”")]),
            _vm._v("造句。"),
          ]),
          _c("p", [
            _vm._v(
              "對應計劃越貼近你嘅實際情況，越有效果。跟住落黎，請你寫低你嘅對應計劃"
            ),
          ]),
          _c(
            "p",
            { staticClass: "select_line" },
            [
              _vm._v(" 1． 如果" + _vm._s(_vm.type1) + "，咁"),
              _c("cube-input", {
                staticClass: "other_input",
                model: {
                  value: _vm.barrier.data[0].then,
                  callback: function ($$v) {
                    _vm.$set(_vm.barrier.data[0], "then", $$v)
                  },
                  expression: "barrier.data[0].then",
                },
              }),
            ],
            1
          ),
          !_vm.barrier.oneType
            ? _c(
                "p",
                { staticClass: "select_line" },
                [
                  _vm._v(" 2． 如果" + _vm._s(_vm.type2) + "，咁"),
                  _c("cube-input", {
                    staticClass: "other_input",
                    model: {
                      value: _vm.barrier.data[1].then,
                      callback: function ($$v) {
                        _vm.$set(_vm.barrier.data[1], "then", $$v)
                      },
                      expression: "barrier.data[1].then",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("p", { staticClass: "b blue" }, [
            _vm._v("如果你唔知點寫，請參考我哋俾嘅例子。"),
          ]),
          _c("ul", [
            _c("li", [
              _vm.showeg(0)
                ? _c("p", { staticClass: "borange" }, [
                    _vm._v(
                      " 如果我有好多野要做，咁我會盡量合理安排各樣野，預留時間每日進行至少十分鐘嘅運動。 "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("li", [
              _vm.showeg(1)
                ? _c("p", { staticClass: "borange" }, [
                    _vm._v("如果天氣唔好，咁我就留喺屋企做運動。"),
                  ])
                : _vm._e(),
            ]),
            _c("li", [
              _vm.showeg(2)
                ? _c("p", { staticClass: "borange" }, [
                    _vm._v(
                      " 如果做運動太晒時間， 咁我就將運動融入日常生活。比如提前一個巴士站或 港鐵站落車，行路返屋企。 "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("li", [
              _vm.showeg(3)
                ? _c("p", { staticClass: "borange" }, [
                    _vm._v(" 如果覺得好攰，咁我就好好休息，第二日再做運動。 "),
                  ])
                : _vm._e(),
            ]),
            _c("li", [
              _vm.showeg(4)
                ? _c("p", { staticClass: "borange" }, [
                    _vm._v(
                      " 如果冇運動夥伴，咁我就嘗試堅持一個人運動或者揀可以一個人進行嘅運動，比如喺公園慢跑。 "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("li", [
              _vm.showeg(5)
                ? _c("p", { staticClass: "borange" }, [
                    _vm._v(
                      " 如果缺乏場地，咁我就嘗試進行唔需要特殊場地嘅運動，比如散步同慢跑。 "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("li", [
              _vm.showeg(6)
                ? _c("p", { staticClass: "borange" }, [
                    _vm._v(
                      " 如果缺少運動設施，咁我就選擇方便易行嘅運動，比如散步和慢跑 "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("p", { staticClass: "b" }, [
            _vm._v(" 你的運動障礙對應計劃表將會儲存喺手機主頁“"),
            _c("span", { staticClass: "b red" }, [_vm._v("運動鍛煉")]),
            _vm._v("”內嘅“對應計劃”一欄中，你可以隨時查閲 "),
          ]),
        ])
      : _vm._e(),
    _vm.page == 5
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("運動障礙對應計劃總結")]),
          _c("p", { staticClass: "b" }, [
            _vm._v("下低係你嘅“運動障礙對應計劃”"),
          ]),
          _c("p", [
            _vm._v("1． "),
            _c("span", { staticClass: "blue" }, [_vm._v("如果")]),
            _vm._v(_vm._s(_vm.type1) + "，"),
            _c("span", { staticClass: "blue" }, [_vm._v("咁")]),
            _vm._v(_vm._s(_vm.barrier.data[0].then)),
          ]),
          !_vm.barrier.oneType
            ? _c("p", [
                _vm._v(" 2． "),
                _c("span", { staticClass: "blue" }, [_vm._v("如果")]),
                _vm._v(_vm._s(_vm.type2) + "，"),
                _c("span", { staticClass: "blue" }, [_vm._v("咁")]),
                _vm._v(_vm._s(_vm.barrier.data[1].then) + " "),
              ])
            : _vm._e(),
          !_vm.reviewMode && !_vm.atEnd
            ? _c(
                "div",
                [
                  _c("p", [_vm._v("你可以選擇喺咪要進行調整。")]),
                  _c(
                    "cube-radio-group",
                    {
                      model: {
                        value: _vm.modify,
                        callback: function ($$v) {
                          _vm.modify = $$v
                        },
                        expression: "modify",
                      },
                    },
                    [
                      _c(
                        "cube-radio",
                        { key: "1", attrs: { option: { value: 1 } } },
                        [_vm._v(" 調整")]
                      ),
                      _c(
                        "cube-radio",
                        { key: "0", attrs: { option: { value: 0 } } },
                        [_vm._v(" 唔調整")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.page == 6
      ? _c(
          "div",
          { staticClass: "box" },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v("評估運動障礙對應計劃"),
            ]),
            _c("p", [
              _vm._v(
                " 上個禮拜你已經制定咗運動障礙對應計劃，進而幫助你克服困難，堅持去做運動。跟住落黎，請你評估一下對應計劃嘅實行情況。如果呢啲對應策略對你幫助唔大，咁就進行調整。 "
              ),
            ]),
            _c("p", [
              _vm._v(" 而家請回憶你上個禮拜制定嘅運動障礙對應計劃（"),
              _c(
                "span",
                {
                  staticClass: "bu",
                  on: {
                    click: function ($event) {
                      return _vm.reviewPlan(6)
                    },
                  },
                },
                [_vm._v("點擊查看")]
              ),
              _vm._v(
                "）同埋你完成呢個計劃嘅情況。請指出，喺最近7日內實行呢個運動障礙對應計劃嘅情況。 "
              ),
            ]),
            _vm.barrier.perEval.evl1 > 0
              ? _c("div", [
                  _vm.barrier.perEval.evl1 == 1
                    ? _c("p", { staticClass: "red b" }, [
                        _vm._v(" 改變習慣唔容易架，唔好氣餒，繼續加油！ "),
                      ])
                    : _vm._e(),
                  _vm.barrier.perEval.evl1 == 2
                    ? _c("p", { staticClass: "red b" }, [
                        _vm._v(" 仲有進步的空間，繼續加油! "),
                      ])
                    : _vm._e(),
                  _vm.barrier.perEval.evl1 == 3
                    ? _c("p", { staticClass: "red b" }, [
                        _vm._v(" 做得非常好，請繼續保持！ "),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.barrier.perEval.evl1,
                  callback: function ($$v) {
                    _vm.$set(_vm.barrier.perEval, "evl1", $$v)
                  },
                  expression: "barrier.perEval.evl1",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "0", attrs: { option: { value: 1 } } },
                  [_c("p", [_vm._v("不好")])]
                ),
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: 2 } } },
                  [_c("p", [_vm._v("一般")])]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: 3 } } },
                  [_c("p", [_vm._v("好")])]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.page == 7
      ? _c(
          "div",
          { staticClass: "box" },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v("評估運動障礙對應計劃"),
            ]),
            _c("p", [
              _vm._v(" 而家請你運用以下2大方法黎評估你嘅運動障礙對應計劃。"),
            ]),
            _c("p", [_vm._v(" 1. 適合性 咁樣嘅對應計劃適合你嗎？ ")]),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.barrier.perEval.evl2,
                  callback: function ($$v) {
                    _vm.$set(_vm.barrier.perEval, "evl2", $$v)
                  },
                  expression: "barrier.perEval.evl2",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: "是" } } },
                  [_vm._v(" 是")]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: "否" } } },
                  [_vm._v(" 否")]
                ),
              ],
              1
            ),
            _c("p", [_vm._v("2. 有效性 咁樣嘅對應計劃對你有冇好處？")]),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.barrier.perEval.evl3,
                  callback: function ($$v) {
                    _vm.$set(_vm.barrier.perEval, "evl3", $$v)
                  },
                  expression: "barrier.perEval.evl3",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: "是" } } },
                  [_vm._v(" 是")]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: "否" } } },
                  [_vm._v(" 否")]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }