var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _vm.$store.state.debugbtn
      ? _c("div", [
          _c("span", [_vm._v("頁面編號調試：")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.page,
                expression: "page",
              },
            ],
            domProps: { value: _vm.page },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.page = $event.target.value
              },
            },
          }),
        ])
      : _vm._e(),
    _vm.page == 1
      ? _c("div", { staticClass: "box" }, [
          _c("p", [
            _vm._v("你好 [" + _vm._s(_vm.$store.state.user.uname) + "],"),
          ]),
          _c("br"),
          _c("p", [
            _vm._v(
              "歡迎你第" +
                _vm._s(_vm.the_num) +
                "次到訪“食”全十美，行大“運”嘅“健康飲食部份”。"
            ),
          ]),
          _c("br"),
          _c("p", [_vm._v("今日嘅活動將佔用你15分鐘左右嘅時間，內容包括：")]),
          _vm.num == 1
            ? _c("div", { attrs: { id: "list" } }, [
                _c("p", { staticClass: "list" }, [
                  _vm._v("1. 建立個人化飲食檔案；"),
                ]),
                _c("p", { staticClass: "list" }, [
                  _vm._v("2. 制定健康飲食計劃；"),
                ]),
                _c("p", { staticClass: "list" }, [
                  _vm._v("3. 思考邊啲人可以幫你積極參與健康飲食。"),
                ]),
              ])
            : _vm._e(),
          _vm.num >= 2 && _vm.num <= 4
            ? _c("div", { attrs: { id: "list" } }, [
                _c("p", { staticClass: "list" }, [
                  _vm._v("1. 建立個人化飲食檔案；"),
                ]),
                _c("p", { staticClass: "list" }, [
                  _vm._v("2. 評估同調整健康飲食計劃；"),
                ]),
                _c("p", { staticClass: "list" }, [
                  _vm._v("3. 思考邊啲人可以幫你積極參與健康飲食。"),
                ]),
              ])
            : _vm._e(),
          _vm.num == 5
            ? _c("div", { attrs: { id: "list" } }, [
                _c("p", { staticClass: "list" }, [
                  _vm._v("1. 建立個人化飲食檔案；"),
                ]),
                _c("p", { staticClass: "list" }, [
                  _vm._v("2. 搵出健康飲食障礙並制定對應計劃；"),
                ]),
                _c("p", { staticClass: "list" }, [
                  _vm._v("3. 學習使用電子飲食日記，請允許錄音權限。"),
                ]),
              ])
            : _vm._e(),
          _vm.num >= 6 && _vm.num <= 10
            ? _c("div", { attrs: { id: "list" } }, [
                _c("p", { staticClass: "list" }, [
                  _vm._v("1. 建立個人化飲食檔案；"),
                ]),
                _c("p", { staticClass: "list" }, [
                  _vm._v("2. 評估同調整健康飲食障礙對應計劃；"),
                ]),
                _c("p", { staticClass: "list" }, [
                  _vm._v("3. 繼續使用電子飲食日記，請允許錄音權限。"),
                ]),
              ])
            : _vm._e(),
          _c("div", [
            _c("p", { staticClass: "b red" }, [
              _vm._v("請點擊右上角嘅繼續以進入學習。"),
            ]),
          ]),
          _vm.num >= 5 && _vm.num <= 10
            ? _c(
                "div",
                { attrs: { id: "list" } },
                [
                  _c("record", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false",
                      },
                    ],
                    ref: "recorder",
                    attrs: {
                      "on-open-error": _vm.errHandle,
                      "on-stop-is-end": _vm.errHandle,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.page == 2
      ? _c("div", { staticClass: "box" }, [
          _vm.lock
            ? _c("div", [
                _c("p", [
                  _vm._v(
                    "學習內容宜家處於鎖定狀態，請留意管理員通知您嘅開始學習時間"
                  ),
                ]),
              ])
            : _c("div", [
                _vm.$store.state.user.dietstate < 10
                  ? _c("p", { staticClass: "title" }, [
                      _vm._v("需要間隔一週才可以學習下個單元，請返回主頁。"),
                    ])
                  : _c("p", { staticClass: "title" }, [
                      _vm._v("您已完成全部健康飲食學習內容，請返回主頁。"),
                    ]),
                false
                  ? _c("ul", [
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$store.state.user.dietstate >= 1,
                              expression: "$store.state.user.dietstate>=1",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.clickWeek(1)
                            },
                          },
                        },
                        [_c("span", { staticClass: "b" }, [_vm._v("第一週")])]
                      ),
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$store.state.user.dietstate >= 2,
                              expression: "$store.state.user.dietstate>=2",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.clickWeek(2)
                            },
                          },
                        },
                        [_c("span", { staticClass: "b" }, [_vm._v("第二週")])]
                      ),
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$store.state.user.dietstate >= 3,
                              expression: "$store.state.user.dietstate>=3",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.clickWeek(3)
                            },
                          },
                        },
                        [_c("span", { staticClass: "b" }, [_vm._v("第三週")])]
                      ),
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$store.state.user.dietstate >= 4,
                              expression: "$store.state.user.dietstate>=4",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.clickWeek(4)
                            },
                          },
                        },
                        [_c("span", { staticClass: "b" }, [_vm._v("第四週")])]
                      ),
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$store.state.user.dietstate >= 5,
                              expression: "$store.state.user.dietstate>=5",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.clickWeek(5)
                            },
                          },
                        },
                        [_c("span", { staticClass: "b" }, [_vm._v("第五週")])]
                      ),
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$store.state.user.dietstate >= 6,
                              expression: "$store.state.user.dietstate>=6",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.clickWeek(6)
                            },
                          },
                        },
                        [_c("span", { staticClass: "b" }, [_vm._v("第六週")])]
                      ),
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$store.state.user.dietstate >= 7,
                              expression: "$store.state.user.dietstate>=7",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.clickWeek(7)
                            },
                          },
                        },
                        [_c("span", { staticClass: "b" }, [_vm._v("第七週")])]
                      ),
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$store.state.user.dietstate >= 8,
                              expression: "$store.state.user.dietstate>=8",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.clickWeek(8)
                            },
                          },
                        },
                        [_c("span", { staticClass: "b" }, [_vm._v("第八週")])]
                      ),
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$store.state.user.dietstate >= 9,
                              expression: "$store.state.user.dietstate>=9",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.clickWeek(9)
                            },
                          },
                        },
                        [_c("span", { staticClass: "b" }, [_vm._v("第九週")])]
                      ),
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$store.state.user.dietstate >= 10,
                              expression: "$store.state.user.dietstate>=10",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.clickWeek(10)
                            },
                          },
                        },
                        [_c("span", { staticClass: "b" }, [_vm._v("第十週")])]
                      ),
                    ])
                  : _vm._e(),
              ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }