var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container center" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.user.openid <= 0 || !_vm.user.openid,
            expression: "user.openid <= 0 || !user.openid",
          },
        ],
        staticClass: "welpage center fw",
      },
      [
        _vm._m(0),
        _c("div", { staticClass: "content center" }, [
          _c("span", { staticClass: "text" }, [
            _vm._v(
              "請使用為您分配嘅專屬鏈接進入網站，如您還沒有您嘅專屬鏈接， 請聯繫管理員。"
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.debugbtn,
                  expression: "debugbtn",
                },
              ],
            },
            [
              _c("cube-input", {
                staticClass: "codeinput",
                attrs: { id: "userinput" },
                model: {
                  value: _vm.userinputid,
                  callback: function ($$v) {
                    _vm.userinputid = $$v
                  },
                  expression: "userinputid",
                },
              }),
              _c(
                "cube-button",
                {
                  staticClass: "enterbtn",
                  attrs: { id: "loginbtn" },
                  on: { click: _vm.login },
                },
                [_vm._v("進入")]
              ),
            ],
            1
          ),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.user.openid > 0,
            expression: "user.openid > 0",
          },
        ],
        staticClass: "selectpage center",
      },
      [
        _vm._m(1),
        _c("div", { staticClass: "content center" }, [
          _c(
            "div",
            { staticClass: "btnbox" },
            [
              _c(
                "cube-button",
                { staticClass: "typebtn", on: { click: _vm.goPa } },
                [_vm._v("運動鍛煉")]
              ),
              _c(
                "cube-button",
                { staticClass: "typebtn", on: { click: _vm.goDiet } },
                [_vm._v("健康飲食")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.user.pastate >= 5 || _vm.user.dietstate >= 5,
                  expression: "user.pastate >= 5 || user.dietstate >= 5",
                },
              ],
              staticClass: "btnbox",
            },
            [
              _c(
                "cube-button",
                {
                  staticClass: "typebtn",
                  attrs: { disabled: _vm.todayPa },
                  on: { click: _vm.goPaDiary },
                },
                [
                  _vm._v(
                    _vm._s(!_vm.todayPa ? "電子運動日記" : "今日已完成運動日記")
                  ),
                ]
              ),
              _c(
                "cube-button",
                {
                  staticClass: "typebtn",
                  attrs: { disabled: _vm.todayDiet },
                  on: { click: _vm.goDietDiary },
                },
                [
                  _vm._v(
                    _vm._s(
                      !_vm.todayDiet ? "電子飲食日記" : "今日已完成飲食日記"
                    )
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false",
                },
              ],
              staticClass: "gobtndiv",
            },
            [
              _c(
                "cube-button",
                { staticClass: "gobtn", on: { click: _vm.enter } },
                [_vm._v("進入")]
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        attrs: {
          alt: "“食”全十美，行大“運”",
          src: require("../assets/img/panel_top.jpg"),
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        attrs: {
          alt: "運動i健康",
          src: require("../assets/img/panel_top.jpg"),
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }