<template>
  <div class="container flexcolumn">
    <div class="top btngroup">
      <cube-button class="topbtn" @click="back"> 返回主頁</cube-button>
      <cube-button class="topbtn" @click="next" :disabled="gap"> 繼續</cube-button>
    </div>

    <div class="middlebox">
      <component :is="selectName" ref="active"></component>
    </div>
  </div>
</template>



<script>
import pa_welcome from "@/components/pa/pwelcome";
import pa_record from "@/components/pa/precord";
import pa_plan from "@/components/pa/pplan";
import pa_barrier from "@/components/pa/pbarrier";
import pa_support from "@/components/pa/psupport";
import pa_diary from "@/components/pa/pdiary";
import pa_end from "@/components/pa/pend";

import diet_welcome from "@/components/diet/dwelcome";
import diet_record from "@/components/diet/drecord";
import diet_plan from "@/components/diet/dplan";
import diet_barrier from "@/components/diet/dbarrier";
import diet_support from "@/components/diet/dsupport";
import diet_diary from "@/components/diet/ddiary";
import diet_end from "@/components/diet/dend";

import { CHINESE_NUM } from "@/data/data";
import PA_CONFIG from "@/data/pa";
import DIET_CONFIG from "@/data/diet";
import tools from "@/data/tools";

export default {
  name: "learn",
  data() {
    return {
      progress: 0,
      gap:false
    };
  },
  computed: {
    type: function () {
      return this.$store.state.type;
    },
    num: function () {
      return this.$store.state.week;
    },
    user: function () {
      return this.$store.state.user;
    },
    config: function () {
      return {
        pa: PA_CONFIG,
        diet: DIET_CONFIG,
      }[this.type];
    },
    selectName: function () {
      return this.type + "_" + this.config[this.num].order[this.progress];
    },
    the_num: function () {
      return tools.the_num(this.num);
    },
  },

  created() {},
  mounted() {
    if (
      !this.config[this.num].order[this.progress] ||
      this.config[this.num].order[this.progress] == "back"
    ) {
      this.progress = 0;
      this.$router.push({ path: "/user" });
    }
  },
  methods: {
    // 子組件通信接口next
    // 當收到非空返回值時，就切換為下一個組件
    // 當子組件之間需要傳遞石距時， 使用store。
    next: function () {
      let _this = this;
      this.gap = true;
      setTimeout(()=>_this.gap = false, 1000 * 5);
      // console.log(
      //   "parent call next " +
      //     " progress:" +
      //     this.progress +
      //     " selectName:" +
      //     this.selectName
      // );
      let ret = this.$refs.active.next();
      //console.log("child ret is:" + ret);
      if (ret) {
        this.progress += 1;
        if (this.config[this.num].order[this.progress] == "back") {
          this.progress = 0;
          this.$router.push({ path: "/home" });
        }
      }
      //
      //setTimeout(()=>{_this.$refs.active.$el.scrollTo(0,0);console.log("return top")},1000);
      //console.log(this.$store.state);
    },
    playaudio: function () {
      if (this.$refs.active.audio) {
        let address = this.$refs.active.audio();
        address = "base" + address;
        // play audio
      } else {
        this.$createToast({
          txt: "當前學習頁面沒有幫助音頻，請聯繫管理員尋求幫助",
        }).show();
      }
    },
    back: function () {
      this.$createDialog({
        type: "confirm",
        content: "確定要返回到主頁嗎, 會丟失當前的數據",
        confirmBtn: {
          text: "確定",
          active: true,
          disabled: false,
          href: "javascript:;",
        },
        cancelBtn: {
          text: "取消",
          active: false,
          disabled: false,
          href: "javascript:;",
        },
        onConfirm: () => {
          // 通知子控件將要返回， 緩存數據
          if (this.$refs.active.back) {
            this.$refs.active.back();
          }
          if (this.$refs.active.pause) {
            this.$refs.active.pause();
          }

          this.$router.push({ path: "/home" });
        },
        onCancel: () => {
          let _;
        },
      }).show();
    },
  },
  components: {
    pa_welcome,
    pa_record,
    pa_plan,
    pa_barrier,
    pa_support,
    pa_diary,
    pa_end,

    diet_welcome,
    diet_record,
    diet_plan,
    diet_barrier,
    diet_support,
    diet_diary,
    diet_end,
  },
  metaInfo: {
    title: "“食”全十美，行大“運”",
    meta: [
      {
        name: "viewport",
        content:
          "width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, viewport-fit=cover",
      },
      {
        name: "apple-mobile-web-app-capable",
        content: "yes",
      },
      {
        name: "mobile-web-app-capable",
        content: "yes",
      },
    ],
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="@/assets/css/common.css" />
<style scoped>
.top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.middlebox {
  height: 0;
  flex: 1;
}

.bottom {
  height: 10%;
}

.topbox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 35%;
}

.topimgbox {
  width: 100%;
  height: 65%;
}

.topimgbox img {
  width: 100%;
  height: 100%;
}

.gobtn {
  width: 80%;
  height: 55%;
  font-size: 22px;
}

p {
  font-size: 18px;
  color: black;
  text-align: left;
  margin-top: 5px;
}

.btngroup {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
}

.topbtn {
  width: 100%;
  color: white;
  font-size: 24px;
  font-weight: bold;
}
</style>