export default [{
        num: 0,
        order: ['back']
    },
    {
        num: 1,
        // // 第一單元應該沒有 barrier 和 diary 為了調試方便暫留
        order: ['welcome', 'record', 'plan', 'support', 'end', 'back']
    },
    {
        num: 2,
        order: ['welcome', 'record', 'plan', 'support', 'end', 'back']
    },
    {
        num: 3,
        order: ['welcome', 'record', 'plan', 'support', 'end', 'back']
    },
    {
        num: 4,
        order: ['welcome', 'record', 'plan', 'support', 'end', 'back']
    },
    {
        num: 5,
        order: ['welcome', 'record', 'barrier', 'support', 'diary', 'end', 'back']
    },
    {
        num: 6,
        order: ['welcome', 'record', 'barrier', 'support', 'diary', 'end', 'back']
    },
    {
        num: 7,
        order: ['welcome', 'record', 'barrier', 'support', 'diary', 'end', 'back']
    },
    {
        num: 8,
        order: ['welcome', 'record', 'barrier', 'support', 'diary', 'end', 'back']
    },
    {
        num: 9,
        order: ['welcome', 'record', 'barrier', 'support', 'diary', 'end', 'back']
    },
    {
        num: 10,
        order: ['welcome', 'record', 'barrier', 'support', 'diary', 'end', 'back']
    }
];