<template>
    <wrapper>
        <div v-if="debugbtn">
            <span>頁面編號調試：</span>
            <input v-model="page" />
        </div>
        <div
            v-if="page==1"
            class="box"
        >
            <div class="bu title">結 尾</div>
            <br />
            <br />
            <p v-if="num == 10">“食”全十美，行大“運”嘅 “健康飲食”嘅內容到此結束。</p>
            <p v-else>“食”全十美，行大“運”嘅第{{ the_num }}單元“健康飲食嘅內容到此結束。<span class="bu red">請點擊繼續以提交你的數據</span>。</p>
            <br />
            <p>喺呢度祝你一切順利成功！</p>
            <br />
            <p>你的“食”全十美，行大“運”團隊</p>
        </div>
        <div
            v-if="page==2"
            class="box"
        >
            <div class="bu">結 尾</div>
            <br />
            <br />
            “食”全十美，行大“運”嘅 “健康飲食”嘅內容到此結束。

            <br />
            <p>喺呢度祝你一切順利成功！</p>
            <br />
            <p>你的“食”全十美，行大“運”團隊</p>
        </div>
        <img
            class="fw8"
            src="../../assets/img/end.png"
        />
    </wrapper>
</template>


<script>
import tools from "@/data/tools";
import wrapper from "@/components/wrapper";

export default {
    name: "pa_end",
    data() {
        return {
            page: 1,
        };
    },
    computed: {
        type: function () {
            return this.$store.state.type;
        },
        num: function () {
            return this.$store.state.week;
        },
        user: function () {
            return this.$store.state.user;
        },
        pa: function () {
            return this.$store.state.pa;
        },
        debugbtn: function () {
            return this.$store.state.debugbtn;
        },

        the_num: function () {
            return tools.the_num(this.num);
        },
    },
    created() {
        if (!this.page) {
            this.page = 1;
        }
    },
    methods: {
        pause: function () {
            // 提交數據
            const _this = this;
            const state = this.$store.state;
            if (!this.$store.state.review) {
                if (state.diet[0].diary) {
                    console.log("do check in");
                    this.$store
                        .dispatch("checkin", {
                            rec: state.diet[0].diary.rec,
                            kind: state.diet[0].diary.kind,
                            txt: state.diet[0].diary.text,
                        })
                        .then((val) => {
                            _this.$store
                                .dispatch("upweek")
                                .then((val) => {
                                    _this
                                        .$createDialog({
                                            type: "alert",
                                            content: "提交成功",
                                        })
                                        .show();
                                    setTimeout(()=>_this.$router.push({ path: "/home" }), 1000);
                                        
                                })
                                .catch((err) => {
                                    console.log("pend-fail");
                                    console.log(err);
                                    //notice 提交失敗
                                    _this
                                        .$createDialog({
                                            type: "alert",
                                            content: "提交失敗,请稍后重试",
                                        })
                                        .show();
                                });
                        })
                        .catch((err) => {
                            console.log("dend-checkin-fail");
                            console.log(err);
                            //notice 提交失敗
                            _this
                                .$createDialog({
                                    type: "alert",
                                    content: "提交失敗,请稍后重试",
                                })
                                .show();
                        });
                } else {
                    // 提交數據
                    this.$store
                        .dispatch("upweek")
                        .then((val) => {
                            _this
                                .$createDialog({
                                    type: "alert",
                                    content: "提交成功",
                                })
                                .show();
                        })
                        .catch((err) => {
                            console.log("dend-fail");
                            console.log(err);
                            //notice 提交失敗
                            _this
                                .$createDialog({
                                    type: "alert",
                                    content: "提交失敗,请稍后重试",
                                })
                                .show();
                        });
                }
            }
        },
        next: function () {
            console.log("end do next");
            if (this.page == 1) {
                this.pause();
                return 0;
            }
        },
    },

    components: {
        wrapper: wrapper,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../assets/css/learn_common.css" />
<style scoped>
</style>