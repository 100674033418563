export const CHINESE_NUM = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
export const allType = [
    "健步走",
    "慢跑",
    "打太極拳",
    "練氣功",
    "打乒乓球",
    "打羽毛球",
    "游泳",
    "跳廣場舞",
    "用健身器材鍛煉",
    "其他",
];
export const allTime = [
    "早上：8：00 之前",
    "上午：8：00 - 12：00",
    "中午：12：00 – 14：00",
    "下午：14：00 - 18：00",
    "晚上：18：00 之後",
];
export const allPlace = ["家裡", "公園", "社區周邊", "田徑場", "運動館", "游泳館", "其他"];
export const allCP = ["自己", "伴侶", "子女", "朋友", "同事", "鄰居", "其他"];
export const allSupport = [
    "親密伴侶",
    "朋友",
    "家庭成員（如：兄弟姊妹或子女）",
    "其他人",
    "沒有"
];
export const allSupport2 = [
    "親密伴侶",
    "朋友",
    "家庭成員（如：兄弟姊妹或子女）",
    // "我自己",
    "其他人",
    "沒有"
];
export const weekDays = ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期日"];

export const allBarrier = ["有好多野要做", "天氣唔好", "太晒時間", "覺得好攰", "冇運動夥伴", "缺乏場地", "缺少運動設施", "其他障礙"];

export const allEatTime = [
    "上午：12：00前",
    "下午：12：00-18：00",
    "晚上：18：00後"
];

export const allFood = [
    "生果",
    "蔬菜",
    "肉類",
    "蛋（雞蛋、鹹蛋、皮蛋）",
    "替代品（豆腐、堅果類）",
    "生果",
    "蔬菜",
    "肉類",
    "蛋（雞蛋、鹹蛋、皮蛋）",
    "替代品（豆腐、堅果類）",
];

export const allVgBarrier = ["好忙",
    "牙唔好，咬野有困難",
    "出去食飯或者度假",
    "蔬菜不岩胃口",
    "生果蔬菜好貴",
    "其他障礙"
];

export const allMeatBarrier = ["好忙",
    "牙唔好，咬野有困難",
    "出去食飯或者度假",
    "不鐘意食某d魚或肉類",
    "我鐘意食嘅某種魚或肉類比較貴",
    "其他障礙"
];

export const validErr = {
    "empty": "請填寫完整後再繼續",
};

export const foodType = [
    "生果",
    "蔬菜",
    "肉類",
    "蛋類",
    "替代品"
];

export const fruit = ["蘋果",
"香蕉",
"橙",
"梨",
"火龍果",
"芒果",
"葡萄、紅提或青提",
"獼猴桃",
"車釐子或櫻桃",
"瓜類 （西瓜，哈密瓜等）",
"其他生果"];

export const vg = ["葉菜類：芥菜、菠菜、油麥菜、生菜、小白菜、空心菜",
"豆芽類：豆芽菜、豆苗",
"瓜果類：黃瓜、冬瓜、苦瓜、南瓜",
"茄果類：番茄、辣椒、茄子",
"根菜類：蘿蔔、萵苣、筍",
"花菜類：花椰菜、西蘭花",
"豆莢類：四季豆、秋葵",
"食用菌類：香菇、木耳、金針菇",
"水生菜類：菱角、蓮藕",
"海洋蔬菜：紫菜、海帶",
"其他蔬菜"];

export const meat = ["牛肉",
"羊肉",
"豬肉",
"雞肉",
"鴨肉",
"乳鴿",
"魚及海產類",
"其他肉類"];

export const egg = ["雞蛋",
"鴨蛋",
"鵪鶉蛋",
"其他蛋類"];

export const bean = ["豆製品",
"堅果果仁",
"其他替代品"];