<template>
  <wrapper>
    <div v-if="$store.state.debugbtn">
      <span>頁面編號調試：</span>
      <input v-model="page" />
    </div>
    <div v-if="page == 1" class="box">
      <p>
        <span class="bu">寫健康飲食日記</span
        >，可以幫你對自己嘅飲食行為進行總結和反思，促進健康飲食嘅堅持性。
      </p>

      <p>
        <span class="b">“健康飲食日記”嘅內容包括</span>：當日你喺<span
          class="bu"
          >咩時間</span
        >， <span class="bu">咩地點</span>，<span class="bu">同邊啲人</span
        >，食咗 <span class="bu">咩食物</span>，<span class="bu"
          >心情如何</span
        >。
      </p>
      <p>下低你可以睇兩個健康飲食日記嘅例子。</p>

      <p>
        <span class="red">例子1</span>：“這個星期五晚上，我和老伴在家裡吃了菠菜和西蘭花，還吃了雞肉和牛肉，晚飯過後和老伴一起在家吃了生果，達到了WHO推薦的飲食標準，心情很愉快”
      </p>
      <p>
        <span class="red">例子2</span>：
        “昨天早上，我吃了兩個雞蛋。中午的時候和家人一起吃了娃娃菜和菜心，還吃了豬肉。下午茶吃了香蕉和火龍果，晚上還和家人一起吃了芽菜和魚肉。今天的生果蔬菜和魚、肉、蛋的攝取量都達到了健康標準，很開心”。
      </p>

      <div v-show="txtDiary">
        <cube-textarea
          class="larget"
          v-model="diary.text"
          :maxlength="max"
          :placeholder="placeholder"
        >
        </cube-textarea>
      </div>
      <div v-show="!txtDiary">
        <p><span class="red">請點擊下低嘅“錄製”按鈕</span>，錄製你嘅電子語音健康飲食日記</p>
        <record
          :on-open-error="errHandle"
          :on-stop-is-end="stopHandle"
          ref="recorder"
        ></record>
      </div>
            <div>
      <cube-button v-show="true" class="btnChange" @click="changeType()">切換錄入方式</cube-button>
      </div>
    </div>
    <div v-if="page == 2" class="box">
      <p class="b">
        你嘅日記將會儲存喺手機主頁“我的日記”一欄中, 你可以隨時查閲。
      </p>
      <p>
        由而家開始，請你每日使用健康飲食日記，記錄你嘅飲食情況。如果<span class="bu blue"
          >一星期內你可以寫低至少三個符合要求嘅健康飲食日記</span
        >，你將會獲得<span class="bu red">10元現金獎勵</span>。
      </p>
      <p>加油，老友記！</p>
      <<img class="fw8" alt="日記" src="../../assets/img/pa_diary.jpg" />
    </div>
  </wrapper>
</template>


<script>
import tools from "@/data/tools";
import wrapper from "@/components/wrapper";
import record from "@/components/record";
import { validErr } from "@/data/data";

export default {
  name: "pa_diary",
  data() {
    return {
      page: 1,
      max: 1000,
      placeholder: "請在這裡輸入日記內容",
      diary: {
        kind: 2,
        rec: false,
        text: "",
      },
      perweek: {},
    };
  },
  computed: {
    type: function () {
      return this.$store.state.type;
    },
    num: function () {
      return this.$store.state.week;
    },
    user: function () {
      return this.$store.state.user;
    },
    diet: function () {
      return this.$store.state.diet;
    },
    debugbtn: function () {
      return this.$store.state.debugbtn;
    },

    the_num: function () {
      return tools.the_num(this.num);
    },
    txtDiary: function () {
      return this.$store.state.txtDiary;
    },
  },
  created() {
    if (!this.page) {
      if (this.num == 5) this.page = 1;
      else this.page = 1;
    }

    if (this.$store.state.diet[this.num] != null) {
      this.diary = tools.copy(this.$store.state.diet[this.num].diary);
    } else {
      //編輯，先查看是否有緩存
      if (this.$store.state.diet[0].diary) {
        this.diary = this.$store.state.diet[0].diary;
      }
    }
  },
  methods: {
    errHandle: function (err, byUser) {
      console.log("in errHandle");
      this.changeType(true);
    },
    stopHandle: function (success, data) {
      console.log("in stopHandle");
      if (!success) {
        this.$createDialog({
          type: "alert",
          title: "",
          content: "錄音保存失敗",
        }).show();
        this.changeType(true);
      } else {
        if (
          !this.txtDiary &&
          this.$refs.recorder.blob != null &&
          this.$refs.recorder.length > 0
        ) {
          this.$store
            .dispatch("uploadRec", {
              blob: this.$refs.recorder.blob,
              length: this.$refs.recorder.length,
            })
            .then((obj) => {
              this.diary.text = obj.url;
            })
            .catch((err) => {
              this.diary.text = "";
              this.$refs.recorder.drop();
              this.$createToast({
                txt: "錄音保存失敗",
              }).show();
            });
        }
      }
    },
    changeType: function (useTxt) {
            if(useTxt == null)
        {
            useTxt= !this.txtDiary;
        }

      this.$store.commit("setTxtDiary", { data: useTxt });
      if (!useTxt) {
        this.$refs.recorder.init();
      }
      else
      {
      this.diary.text = "";
      }
      this.diary.rec = !this.txtDiary;
    },

    save: function () {
      if (this.$store.state.review) return;
      let xml = {};
      xml.key = ["日記內容"];
      xml.data = [];
      if (this.txtDiary) xml.data.push(this.diary.text);
      else xml.data.push("diary is audio");
      this.diary.xml = xml;

      this.$store.state.diet[0].diary = tools.copy(this.diary);
    },
    pause: function () {
      this.save();
    },
    leave: function (page) {
      if (typeof this.page == "string") this.page = parseInt(this.page);

      if (page == 1) {
          if(!this.txtDiary)
          {
              this.diary.rec = true;
              this.diary.text = this.diary.text;
          }
      }
    },
    valid: function (page) {
      if (page == 1) {
        if (this.diary.text.length < 1) {
          return validErr.empty;
        }
      } else if (page == 2) {
      }
    },
    next: function () {
      console.log("diary do next");
      this.leave(this.page);

      if (this.valid(this.page)) {
        this.$createDialog({
          type: "alert",
          title: "",
          content: this.valid(this.page),
        }).show();
        return;
      }

      let done = false;
      switch (this.page) {
        // 日記
        case 1:
          this.page = 2;
          //done = true;
          break;
        case 2:
          done = true;
          break;
      }

      if (done) {
        this.save();
        return this.page;
      }
    },
  },

  components: {
    wrapper: wrapper,
    record: record,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../assets/css/learn_common.css" />
<style scoped>
#list {
  margin: 0 auto;
}
.larget {
  font-size: 20px;
  height: 200px;
}
.btnChange{
      margin: 20px auto;
    width: 150px;
}
</style>