<template>
  <div class="container user-container">
    <div class="logo">
      <img
        v-if="type == 'pa'"
        alt="運動頂圖"
        src="../assets/img/learn_pa_top.png"
      />
      <img v-else alt="飲食頂圖" src="../assets/img/learn_diet_top.png" />

      <div class="btngroup">
        <cube-button class="topbtn" @click="goLearn"> 進入課程</cube-button>
        <cube-button class="topbtn">
          <router-link to="/home">返回首頁</router-link>
        </cube-button>
      </div>
    </div>

    <div class="container midpage">
      <Record v-if="select == '行為記錄'"></Record>
      <Plan v-if="select == '行為計劃'"></Plan>
      <Barrier v-if="select == '對應計劃'"></Barrier>
      <Diary v-if="select == '我的日記'"></Diary>
    </div>

    <div class="home-user">
      <cube-tab-bar
        v-model="select"
        :data="tabs"
        @change="changeHandler"
      ></cube-tab-bar>
    </div>
  </div>
</template>



<script>
//登錄後學習前的檔案頁面
//包含記錄，計劃，支持總結，日記，4個tab
//需要獲取用户的所有信息
import Record from "@/components/urecord.vue";
import Plan from "@/components/uplan.vue";
import Barrier from "@/components/ubarrier.vue";
import Diary from "@/components/udiary.vue";

export default {
  name: "user",
  data: function () {
    return {
      select: "行為記錄",
      tabs: [
        {
          label: "行為記錄",
          icon: "cubeic-person",
        },
        {
          label: "行為計劃",
          icon: "cubeic-aim",
        },
        {
          label: "對應計劃",
          icon: "cubeic-danger",
        },
        {
          label: "我的日記",
          icon: "cubeic-edit",
        },
      ],
    };
  },
  computed: {
    type: function () {
      return this.$store.state.type;
    },
    num: function () {
      return this.$store.state.week;
    },
    user: function () {
      return this.$store.state.user;
    },
    note: function () {
      return this.$store.state.configs.note;
    },
  },
  created() {},
  methods: {
    changeHandler: function (label) {
      // if you clicked different tab, this methods can be emitted
      console.log("changeHandler");
      console.log(this.select);
    },
    goLearn: function () {
      console.log("go learen " + this.type + "   " + this.num);
      console.log(this.user);
      this.$store.commit("setWeek", {
        data:
          this.type == "pa" ? this.user.pastate + 1 : this.user.dietstate + 1,
      });
      this.$store.commit("setReview", {
        data: false,
      });
      this.$router.push({ path: "/learn" });
    },
  },
  components: {
    Record,
    Plan,
    Diary,
    Barrier,
  },
  created() {},
  beforeDestroy() {},
  metaInfo: {
    title: "“食”全十美，行大“運”",
    meta: [
      {
        name: "viewport",
        content:
          "width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, viewport-fit=cover",
      },
      {
        name: "apple-mobile-web-app-capable",
        content: "yes",
      },
      {
        name: "mobile-web-app-capable",
        content: "yes",
      },
    ],
  },
};
</script>

<style scoped src="@/assets/css/common.css" />

<style>
.home-user .cube-tab div {
  font-size: 16px !important;
  margin-top: 5px !important;
}

.home-user {
  flex-shrink: 0;
}

.home-user .cube-tab i {
  font-size: 26px !important;
}
</style>

<style scoped>
.user-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.logo {
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.btngroup {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.midpage {
  flex: auto;
  overflow-y: auto;
}

.topbtn {
  width: 100%;
  color: white;
}

.topbtn a {
  color: white;
}
</style>

