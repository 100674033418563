<template>
  <div id="main"></div>
</template>

<script>
// 引入 echarts 核心，核心提供了 echarts 使用必須要的接口。
import * as echarts from "echarts/core";
// 引入柱狀圖圖表，圖表後綴都為 Chart
import { PieChart, BarChart } from "echarts/charts";
// 引入提示框，標題，直角座標系，數據集，內置數據轉換器組件，組件後綴都為 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  DataZoomComponent,
} from "echarts/components";
// 標籤自動佈局，全局過渡動畫等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必須的一步
import { CanvasRenderer } from "echarts/renderers";
import { MarkLineComponent } from 'echarts/components';
// 註冊必須的組件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  BarChart,
  PieChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  DataZoomComponent,
  MarkLineComponent,
]);

// 接下來的使用就跟之前一樣，初始化圖表，設置配置項

export default {
  data() {
    return {
      echarts: echarts,
      mychart: null,
    };
  },
  props: {
    option: Object,
    callback: Function,
  },
  mounted() {
    this.draw();
  },
  beforeDestroy() {
    if (this.mychart && !this.mychart.isDisposed()) {
      this.mychart.dispose();
      this.mychart = null;
    }
  },
  methods: {
    draw: function () {
      //console.log("chart draw with->");
      //console.log(this.option);

      this.myChart = this.echarts.init(document.getElementById("main"));
      this.myChart.setOption(this.option);
      if (this.callback) callback(this.myChart);
    },
    flush: function (option) {
      //console.log("chart flush");
      this.myChart.setOption(option, true);
    },
  },
};
</script>

<style>
</style>
