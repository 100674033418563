var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _vm.debugbtn
      ? _c("div", [
          _c("span", [_vm._v("頁面編號調試：")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.page,
                expression: "page",
              },
            ],
            domProps: { value: _vm.page },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.page = $event.target.value
              },
            },
          }),
        ])
      : _vm._e(),
    _vm.page == 1
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("運動鍛煉計劃")]),
          _c("p", [
            _vm._v(" 科學研究表明，制定具體嘅"),
            _c("span", { staticClass: "bu" }, [_vm._v("“運動鍛煉計劃”")]),
            _vm._v("有助於我哋達到預期目標。 "),
            _c("span", { staticClass: "bu" }, [_vm._v("“運動鍛煉計劃”")]),
            _vm._v("嘅內容包括： "),
            _c("span", { staticClass: "bu blue" }, [_vm._v("做咩運動")]),
            _vm._v("， "),
            _c("span", { staticClass: "bu blue" }, [_vm._v("咩時間運動")]),
            _vm._v("， "),
            _c("span", { staticClass: "bu blue" }, [_vm._v("同邊個一齊運動")]),
            _vm._v("同埋 "),
            _c("span", { staticClass: "bu blue" }, [_vm._v("喺邊度做運動")]),
            _vm._v("。 "),
          ]),
          _c("audio", {
            staticClass: "gaudio",
            attrs: {
              controls: "",
              controlsList: "nodownload",
              preload: "auto",
              src: require("@/assets/guide/pa/Week_1_Wang_78.mp3"),
            },
          }),
          _c("p", [
            _vm._v(
              " 王先生（78歲）：我一直想做更多運動，但以前我經常忘記。後來我慢慢發現，如果計劃好和朋友在某一天早上去公園做拉伸運動，我從來不會忘記，可見具體的計劃可以幫助我保持運動的決心，也可以幫助我堅持進行有規律的運動。 "
            ),
          ]),
          _c("img", {
            staticClass: "fw8",
            attrs: {
              alt: "運動i健康",
              src: require("../../assets/img/pa_plan1.jpg"),
            },
          }),
        ])
      : _vm._e(),
    _vm.page == 2
      ? _c(
          "div",
          { staticClass: "box" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("運動鍛煉計劃")]),
            _c("p", [
              _vm._v(" 我哋建議你運用以下"),
              _c("span", { staticClass: "bu" }, [_vm._v("2大方法")]),
              _vm._v("黎制定運動鍛煉計劃。 "),
            ]),
            _c("p", [
              _vm._v("1. "),
              _c("span", { staticClass: "b red" }, [_vm._v("適合性")]),
              _vm._v(" 咁樣嘅運動適唔適合你做？"),
            ]),
            _c("p", [
              _vm._v("2. "),
              _c("span", { staticClass: "b red" }, [_vm._v("有效性")]),
              _vm._v(" 咁樣嘅運動對你嘅健康有冇好處？"),
            ]),
            _c("p", [_vm._v("想要堅持運動，選擇適合自己嘅運動項目非常重要。")]),
            _c("p", { staticClass: "blue b" }, [
              _vm._v("請喺下低選擇或者添加最近你想進行嘅運動項目。"),
            ]),
            _c("p", [_vm._v("我最想進行嘅運動項目係")]),
            _c(
              "p",
              { staticClass: "select_line" },
              [
                _c("cube-select", {
                  staticClass: "inline_select",
                  attrs: { options: _vm.typelist(0) },
                  model: {
                    value: _vm.plan.data[0].type,
                    callback: function ($$v) {
                      _vm.$set(_vm.plan.data[0], "type", $$v)
                    },
                    expression: "plan.data[0].type",
                  },
                }),
                _vm.plan.data[0].type == "其他"
                  ? _c(
                      "span",
                      [
                        _vm._v("請輸入"),
                        _c("cube-input", {
                          staticClass: "other_input",
                          model: {
                            value: _vm.plan.data[0].type_other,
                            callback: function ($$v) {
                              _vm.$set(_vm.plan.data[0], "type_other", $$v)
                            },
                            expression: "plan.data[0].type_other",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v("。 "),
              ],
              1
            ),
            !_vm.plan.oneType
              ? _c("div", [
                  _c("p", [_vm._v("我仲想進行嘅運動項目喺")]),
                  _c(
                    "p",
                    { staticClass: "select_line" },
                    [
                      _c("cube-select", {
                        staticClass: "inline_select",
                        attrs: { options: _vm.typelist(1) },
                        model: {
                          value: _vm.plan.data[1].type,
                          callback: function ($$v) {
                            _vm.$set(_vm.plan.data[1], "type", $$v)
                          },
                          expression: "plan.data[1].type",
                        },
                      }),
                      _vm.plan.data[1].type == "其他"
                        ? _c(
                            "span",
                            [
                              _vm._v("請輸入"),
                              _c("cube-input", {
                                staticClass: "other_input",
                                model: {
                                  value: _vm.plan.data[1].type_other,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.plan.data[1],
                                      "type_other",
                                      $$v
                                    )
                                  },
                                  expression: "plan.data[1].type_other",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v("。 "),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c(
              "cube-checkbox",
              {
                staticClass: "inline_checkbox font_large",
                model: {
                  value: _vm.plan.oneType,
                  callback: function ($$v) {
                    _vm.$set(_vm.plan, "oneType", $$v)
                  },
                  expression: "plan.oneType",
                },
              },
              [_vm._v("暫時只想進行一項運動")]
            ),
            _c("img", {
              staticClass: "fw8",
              attrs: { src: require("../../assets/img/pa_plan1.jpg") },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.page == 3
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("運動鍛煉計劃")]),
          _c(
            "p",
            { staticClass: "select_line" },
            [
              _vm._v(" 跟住落黎，請你添加喺咩時間，咩地點，同埋同邊個一齊參加"),
              _c("cube-select", {
                staticClass: "inline_select",
                attrs: { options: _vm.typelist(2) },
                on: { change: _vm.changeType },
                model: {
                  value: _vm.typeSelect,
                  callback: function ($$v) {
                    _vm.typeSelect = $$v
                  },
                  expression: "typeSelect",
                },
              }),
              _vm._v("。 "),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "plan-container" },
            [
              _c("cube-tab-bar", {
                staticClass: "plan-left",
                attrs: { data: _vm.tabs },
                on: { change: _vm.changeWeek },
                model: {
                  value: _vm.weekSelect,
                  callback: function ($$v) {
                    _vm.weekSelect = $$v
                  },
                  expression: "weekSelect",
                },
              }),
              _c(
                "div",
                { staticClass: "plan-right" },
                [
                  _c("p", [
                    _vm._v("1.喺"),
                    _c("span", { staticClass: "bu" }, [
                      _vm._v("邊個時間/時段"),
                    ]),
                    _vm._v("運動？"),
                  ]),
                  _c("cube-select", {
                    attrs: { options: _vm.allTime },
                    model: {
                      value: _vm.whenSelect,
                      callback: function ($$v) {
                        _vm.whenSelect = $$v
                      },
                      expression: "whenSelect",
                    },
                  }),
                  _c("p", [
                    _vm._v("2.喺"),
                    _c("span", { staticClass: "bu" }, [_vm._v("邊度")]),
                    _vm._v("運動？"),
                  ]),
                  _c("cube-select", {
                    attrs: { options: _vm.allPlace },
                    model: {
                      value: _vm.whereSelect,
                      callback: function ($$v) {
                        _vm.whereSelect = $$v
                      },
                      expression: "whereSelect",
                    },
                  }),
                  _vm.whereSelect == "其他"
                    ? _c(
                        "span",
                        { staticStyle: { "line-height": "50px" } },
                        [
                          _vm._v("請輸入"),
                          _c("cube-input", {
                            staticClass: "other_input",
                            model: {
                              value: _vm.whereOther,
                              callback: function ($$v) {
                                _vm.whereOther = $$v
                              },
                              expression: "whereOther",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("p", [
                    _vm._v("3."),
                    _c("span", { staticClass: "bu" }, [_vm._v("同邊個")]),
                    _vm._v("一齊運動？"),
                  ]),
                  _c("cube-select", {
                    attrs: { options: _vm.allCP },
                    model: {
                      value: _vm.whoSelect,
                      callback: function ($$v) {
                        _vm.whoSelect = $$v
                      },
                      expression: "whoSelect",
                    },
                  }),
                  _vm.whoSelect == "其他"
                    ? _c(
                        "span",
                        { staticStyle: { "line-height": "50px" } },
                        [
                          _vm._v("請輸入"),
                          _c("cube-input", {
                            staticClass: "other_input",
                            model: {
                              value: _vm.whoOther,
                              callback: function ($$v) {
                                _vm.whoOther = $$v
                              },
                              expression: "whoOther",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _c(
                        "cube-button",
                        {
                          staticClass: "btn_clear",
                          attrs: { primary: true },
                          on: { click: _vm.clear },
                        },
                        [_vm._v(" 清空")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: true,
                          expression: "true",
                        },
                      ],
                    },
                    [
                      _c(
                        "cube-button",
                        {
                          staticClass: "btn_clear",
                          attrs: { primary: true },
                          on: { click: _vm.applyAll },
                        },
                        [_vm._v(" 應用到本週")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("p", { staticClass: "b" }, [
            _vm._v(" 你嘅運動計劃表將會儲存喺手機主頁"),
            _c("span", { staticClass: "red" }, [_vm._v("“運動鍛煉”")]),
            _vm._v("內嘅“行動計劃”一欄中，你可以隨時查閲。 "),
          ]),
        ])
      : _vm._e(),
    _vm.page == 4
      ? _c(
          "div",
          { staticClass: "box" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("評估運動鍛煉計劃")]),
            _c("p", [
              _vm._v(" 你上個禮拜已經學識咗制定“"),
              _c("span", { staticClass: "bu" }, [_vm._v("運動鍛煉計劃")]),
              _vm._v("”，包括： "),
              _c("span", { staticClass: "bu blue" }, [_vm._v("做咩運動")]),
              _vm._v("， "),
              _c("span", { staticClass: "bu blue" }, [_vm._v("咩時間運動")]),
              _vm._v("， "),
              _c("span", { staticClass: "bu blue" }, [
                _vm._v("同邊個一齊運動"),
              ]),
              _vm._v("同埋 "),
              _c("span", { staticClass: "bu blue" }, [_vm._v("喺邊度做運動")]),
              _vm._v("。今個星期，首先等我哋評估下你上個禮拜計劃嘅實行情況。 "),
            ]),
            _c("p", [
              _vm._v(" 而家請回憶你上個禮拜制定嘅運動計劃（"),
              _c(
                "span",
                {
                  staticClass: "bu",
                  on: {
                    click: function ($event) {
                      return _vm.reviewPlan()
                    },
                  },
                },
                [_vm._v("點擊查看")]
              ),
              _vm._v(
                "）同埋你完成呢個計劃嘅情況。請指出，喺最近7日內實行呢個運動計劃嘅情況。 "
              ),
            ]),
            _vm.plan.perEval.evl1 > 0
              ? _c("div", [
                  _vm.plan.perEval.evl1 == 1
                    ? _c("p", { staticClass: "red b" }, [
                        _vm._v("改變習慣唔易架，唔好氣餒，繼續加油！"),
                      ])
                    : _vm._e(),
                  _vm.plan.perEval.evl1 == 2
                    ? _c("p", { staticClass: "red b" }, [
                        _vm._v("仲有進步嘅空間，繼續加油!"),
                      ])
                    : _vm._e(),
                  _vm.plan.perEval.evl1 == 3
                    ? _c("p", { staticClass: "red b" }, [
                        _vm._v("做得非常好，請繼續保持！"),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.plan.perEval.evl1,
                  callback: function ($$v) {
                    _vm.$set(_vm.plan.perEval, "evl1", $$v)
                  },
                  expression: "plan.perEval.evl1",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "0", attrs: { option: { value: 1 } } },
                  [_c("p", [_vm._v("不好")])]
                ),
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: 2 } } },
                  [_c("p", [_vm._v("一般")])]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: 3 } } },
                  [_c("p", [_vm._v("好")])]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.page == 5
      ? _c(
          "div",
          { staticClass: "box" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("評估運動鍛煉計劃")]),
            _c("p", [
              _vm._v("而家請你運用下低"),
              _c("span", { staticClass: "bu" }, [_vm._v("2大方法")]),
              _vm._v("黎評估你嘅運動鍛煉計劃。"),
            ]),
            _c("p", [
              _vm._v("1. "),
              _c("span", { staticClass: "b" }, [_vm._v("適合性")]),
              _vm._v(" 咁樣嘅運動適合你做嗎？"),
            ]),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.plan.perEval.evl2,
                  callback: function ($$v) {
                    _vm.$set(_vm.plan.perEval, "evl2", $$v)
                  },
                  expression: "plan.perEval.evl2",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: "是" } } },
                  [_vm._v(" 是")]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: "否" } } },
                  [_vm._v(" 否")]
                ),
              ],
              1
            ),
            _c("p", [
              _vm._v("2. "),
              _c("span", { staticClass: "b" }, [_vm._v("有效性")]),
              _vm._v(" 咁樣嘅運動對你嘅健康有冇好處？"),
            ]),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.plan.perEval.evl3,
                  callback: function ($$v) {
                    _vm.$set(_vm.plan.perEval, "evl3", $$v)
                  },
                  expression: "plan.perEval.evl3",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: "是" } } },
                  [_vm._v(" 是")]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: "否" } } },
                  [_vm._v(" 否")]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.page == 6
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("運動鍛煉計劃表")]),
          _c("p", [_vm._v("呢度呈現嘅係你當前嘅運動鍛煉計劃。")]),
          _c(
            "ul",
            { staticClass: "list-wrapper vflex" },
            _vm._l(_vm.getPlanList(), function (item) {
              return _c(
                "li",
                { staticClass: "list-item", staticStyle: { display: "flex" } },
                [
                  _c("div", { staticClass: "list-left" }, [
                    _vm._v(" " + _vm._s(item.date) + " "),
                  ]),
                  _c("div", { staticClass: "list-right" }, [
                    _c(
                      "span",
                      { class: { red: item.type == 1, blue: item.type == 2 } },
                      [_vm._v(" " + _vm._s(item.action) + " ")]
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
          !_vm.reviewMode && !_vm.atEnd
            ? _c(
                "div",
                [
                  _c("p", [_vm._v("你可以選擇是否要進行調整。")]),
                  _c(
                    "cube-radio-group",
                    {
                      model: {
                        value: _vm.modify,
                        callback: function ($$v) {
                          _vm.modify = $$v
                        },
                        expression: "modify",
                      },
                    },
                    [
                      _c(
                        "cube-radio",
                        { key: "1", attrs: { option: { value: 1 } } },
                        [_vm._v(" 修改")]
                      ),
                      _c(
                        "cube-radio",
                        { key: "0", attrs: { option: { value: 0 } } },
                        [_vm._v(" 不修改")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }