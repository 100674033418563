var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container vflex" }, [
    (_vm.user.pastate == 0 && _vm.type == "pa") ||
    (_vm.user.dietstate == 0 && _vm.type == "diet")
      ? _c("div", { staticClass: "rec_no" }, [
          _c("p", [_vm._v("請點擊“進入課程”，完成第一星期嘅學習")]),
        ])
      : _c("div", [
          _c(
            "div",
            { staticClass: "chart_box" },
            [
              _c("echart", {
                ref: "chart",
                staticClass: "chart_img",
                attrs: { option: _vm.chartOption },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "record_box" }, [
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.num >= 5,
                    expression: "num >= 5",
                  },
                ],
              },
              [_vm._v(" 可以喺上圖中左右拖動查看唔同嘅星期嘅記錄情況。 ")]
            ),
            _vm.type == "pa"
              ? _c("div", [
                  _c("p", [
                    _vm._v(
                      " WHO推薦嘅運動鍛煉標準係，每星期進行中等強度或以上嘅運動至少五日，每日至少30分鐘（或每星期加埋至少150分鐘）。 "
                    ),
                  ]),
                ])
              : _c("div", [_vm._m(0)]),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " 世界衞生組織（WHO）飲食營養與慢性病預防機構建議：為咗促進健康，長者應該 "
      ),
      _c("span", { staticClass: "bu red" }, [
        _vm._v(
          "平均每日食至少5份生果蔬菜，同埋5-6兩肉、蛋及替代品（例如：豆腐, 堅果）"
        ),
      ]),
      _vm._v("。 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }