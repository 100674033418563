<template>
  <div class="cube-page" :class="type">
    <div class="wrapper" ref="window">
      <main class="content">
        <slot>{{ content }}</slot>
      </main>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
  },
  mounted() {
      
  },
  methods: {
    back() {
      this.$router.back();
    },
  },
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.cube-page {
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e1ffe1;

  >.wrapper {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;

    // -webkit-overflow-scrolling: touch
    .desc {
      padding: 10px;
      margin: 10px 0;
      line-height: 20px;
      font-size: 14px;
    }

    .content {
      margin: 10px;
    }
  }
}
</style>
