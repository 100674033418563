var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _vm.debugbtn
      ? _c("div", [
          _c("span", [_vm._v("頁面編號調試：")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.page,
                expression: "page",
              },
            ],
            domProps: { value: _vm.page },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.page = $event.target.value
              },
            },
          }),
        ])
      : _vm._e(),
    _vm.page == 1
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("健康飲食的障礙")]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.vg_complete,
                  expression: "!vg_complete",
                },
              ],
            },
            [
              _c("p", [
                _vm._v(
                  " 通常我哋喺進行健康飲食嘅時候會面臨好多困難，令到我哋唔可以堅持落去，而家等我哋黎學下點樣克服健康飲食可能會遇到嘅障礙。 "
                ),
              ]),
              _c("p", [
                _vm._v(" 喺好多情況下，你會覺得食到"),
                _c("span", { staticClass: "b" }, [
                  _vm._v("足夠份量嘅生果蔬菜"),
                ]),
                _vm._v("（即喺每日至少五份生果蔬菜）"),
                _c("span", { staticClass: "b" }, [_vm._v("好困難")]),
                _vm._v(
                  "。針對呢啲問題，你會點樣應對？請認真思考，然後回答以下問題。 "
                ),
              ]),
              _c("p", [_vm._v(" 1. 就算我好忙，我都會盡量食多啲生果蔬菜。 ")]),
              _c(
                "p",
                [
                  _vm._v("不符合"),
                  _c("cube-rate", {
                    model: {
                      value: _vm.barrier.vg_rate1,
                      callback: function ($$v) {
                        _vm.$set(_vm.barrier, "vg_rate1", $$v)
                      },
                      expression: "barrier.vg_rate1",
                    },
                  }),
                  _vm._v("符合我"),
                ],
                1
              ),
              _c("br"),
              _c("p", [
                _vm._v(
                  " 2. 就算牙唔好，咬野有困難，我都會盡量食多啲生果蔬菜。 "
                ),
              ]),
              _c(
                "p",
                [
                  _vm._v("不符合"),
                  _c("cube-rate", {
                    model: {
                      value: _vm.barrier.vg_rate2,
                      callback: function ($$v) {
                        _vm.$set(_vm.barrier, "vg_rate2", $$v)
                      },
                      expression: "barrier.vg_rate2",
                    },
                  }),
                  _vm._v("符合我"),
                ],
                1
              ),
              _c("br"),
              _c("p", [
                _vm._v(" 3. 就算出去食飯或者度假，我都會盡量食多啲生果蔬菜。 "),
              ]),
              _c(
                "p",
                [
                  _vm._v("不符合"),
                  _c("cube-rate", {
                    model: {
                      value: _vm.barrier.vg_rate3,
                      callback: function ($$v) {
                        _vm.$set(_vm.barrier, "vg_rate3", $$v)
                      },
                      expression: "barrier.vg_rate3",
                    },
                  }),
                  _vm._v("符合我"),
                ],
                1
              ),
              _c("br"),
              _c("p", [_vm._v(" 4. 就算菜唔岩口味，我都會盡量食多啲。 ")]),
              _c(
                "p",
                [
                  _vm._v("不符合"),
                  _c("cube-rate", {
                    model: {
                      value: _vm.barrier.vg_rate4,
                      callback: function ($$v) {
                        _vm.$set(_vm.barrier, "vg_rate4", $$v)
                      },
                      expression: "barrier.vg_rate4",
                    },
                  }),
                  _vm._v("符合我"),
                ],
                1
              ),
              _c("br"),
              _c("p", [_vm._v(" 5.就算生果蔬菜好貴，我都會盡量食多啲。 ")]),
              _c(
                "p",
                [
                  _vm._v("不符合"),
                  _c("cube-rate", {
                    model: {
                      value: _vm.barrier.vg_rate5,
                      callback: function ($$v) {
                        _vm.$set(_vm.barrier, "vg_rate5", $$v)
                      },
                      expression: "barrier.vg_rate5",
                    },
                  }),
                  _vm._v("符合我"),
                ],
                1
              ),
              _c("br"),
            ]
          ),
          _vm.vg_complete
            ? _c("div", [
                _c("p", [
                  _vm._v(
                    "你啱先已經指出，就算喺呢啲情況下，你都可以食足夠嘅生果蔬菜 ："
                  ),
                ]),
                _vm.barrier.vg_rate1 > 3
                  ? _c("p", { staticClass: "limegreen" }, [_vm._v("好忙")])
                  : _vm._e(),
                _vm.barrier.vg_rate2 > 3
                  ? _c("p", { staticClass: "limegreen" }, [
                      _vm._v("牙唔好，咬野有困難"),
                    ])
                  : _vm._e(),
                _vm.barrier.vg_rate3 > 3
                  ? _c("p", { staticClass: "limegreen" }, [
                      _vm._v("出去食飯或者度假"),
                    ])
                  : _vm._e(),
                _vm.barrier.vg_rate4 > 3
                  ? _c("p", { staticClass: "limegreen" }, [
                      _vm._v("菜唔岩口味"),
                    ])
                  : _vm._e(),
                _vm.barrier.vg_rate5 > 3
                  ? _c("p", { staticClass: "limegreen" }, [
                      _vm._v("生果蔬菜比較貴"),
                    ])
                  : _vm._e(),
                _c("br"),
                _c("p", [
                  _vm._v("但係喺呢啲情況下，你覺得食足夠嘅生果蔬菜會有困難 ："),
                ]),
                _vm.barrier.vg_rate1 < 4
                  ? _c("p", { staticClass: "red" }, [_vm._v("好忙")])
                  : _vm._e(),
                _vm.barrier.vg_rate2 < 4
                  ? _c("p", { staticClass: "red" }, [
                      _vm._v("牙唔好，咬野有困難"),
                    ])
                  : _vm._e(),
                _vm.barrier.vg_rate3 < 4
                  ? _c("p", { staticClass: "red" }, [
                      _vm._v("出去食飯或者度假"),
                    ])
                  : _vm._e(),
                _vm.barrier.vg_rate4 < 4
                  ? _c("p", { staticClass: "red" }, [_vm._v("菜唔岩口味")])
                  : _vm._e(),
                _vm.barrier.vg_rate5 < 4
                  ? _c("p", { staticClass: "red" }, [_vm._v("生果蔬菜比較貴")])
                  : _vm._e(),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.page == 2
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v("進食足夠份量生果蔬菜嘅障礙"),
          ]),
          _c("p", { staticClass: "blue" }, [
            _vm._v(
              " 唔好咁灰。面對困難，諗個應對計劃就可以幫到你。其他人嘅例子可能都會對你有啟發。 "
            ),
          ]),
          _c("audio", {
            staticClass: "gaudio",
            attrs: {
              controls: "",
              controlsList: "nodownload",
              preload: "auto",
              src: require("@/assets/guide/diet/Week_5_Barriers_vg.mp3"),
            },
          }),
          _c("p", [
            _vm._v(
              " 胡先生（60歲）：我以前很少進食蔬菜，只是偶爾在午飯時吃一點蔬菜。吃生果也大多只吃蛋糕上的那一點生果，平常也想不起來什麼時候吃生果。雖然我知道多吃生果蔬菜，對身體健康有益處。但是我總是半途而廢，很難堅持下來。後來我身邊的一個朋友分享了他堅持吃生果蔬菜的秘訣，就是制定對應計劃。例如，思考堅持吃生果蔬菜可能會遇到的困難以及制定克服這些困難的計劃。"
            ),
          ]),
          _c("p", [
            _vm._v(
              "例如，對家裡做的蔬菜吃膩了，於是在網絡上看看別人做蔬菜的影片，學習不同的做法。通過制定對應計劃，我已經慢慢開始有規律咁吃生果蔬菜了。例如每天吃一個香蕉和蘋果。在午餐和晚餐的時候，也都會吃蔬菜。我還會繼續制定飲食計劃，堅持多吃生果蔬菜，最終養成健康飲食的好習慣。 "
            ),
          ]),
          _c("img", {
            staticClass: "fw8",
            attrs: { src: require("../../assets/img/di_barrier_story1.jpg") },
          }),
        ])
      : _vm._e(),
    _vm.page == 3
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v("進食足夠份量生果蔬菜嘅障礙"),
          ]),
          _c("p", [
            _vm._v(" 如果你有食多啲生果蔬菜嘅諗法，但一直都冇去做。"),
            _c("span", { staticClass: "b" }, [
              _vm._v("諗下有啲咩原因阻礙你食生果蔬菜呢"),
            ]),
            _vm._v("？ "),
          ]),
          _c("p", [_vm._v("請用幾分鐘時間諗下，揀出或者寫低嘅障礙同困難。")]),
          _c("p", [_vm._v("我最難應對嘅障礙喺")]),
          _c(
            "p",
            { staticClass: "select_line" },
            [
              _c("cube-select", {
                staticClass: "inline_select",
                attrs: { options: _vm.barrierList(0) },
                model: {
                  value: _vm.barrier.data[0].type,
                  callback: function ($$v) {
                    _vm.$set(_vm.barrier.data[0], "type", $$v)
                  },
                  expression: "barrier.data[0].type",
                },
              }),
              _vm.barrier.data[0].type == _vm.otherTxt
                ? _c(
                    "span",
                    [
                      _vm._v("請輸入"),
                      _c("cube-input", {
                        staticClass: "other_input",
                        model: {
                          value: _vm.barrier.data[0].type_other,
                          callback: function ($$v) {
                            _vm.$set(_vm.barrier.data[0], "type_other", $$v)
                          },
                          expression: "barrier.data[0].type_other",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v("。 "),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.page == 4
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("健康飲食障礙對應計劃")]),
          _c("p", [
            _vm._v(" 訂下有效的對應計劃最方便嘅辦法就係"),
            _c("span", { staticClass: "bu" }, [_vm._v("造句法")]),
            _vm._v("，用"),
            _c("span", [_vm._v("“如果…….咁…….”")]),
            _vm._v("造句。"),
          ]),
          _c("p", [
            _vm._v(
              "對應計劃越貼近你嘅實際情況，越有效果。跟住落黎，請你寫低你嘅對應計劃"
            ),
          ]),
          _c(
            "p",
            { staticClass: "select_line" },
            [
              _vm._v(" 1． 如果" + _vm._s(_vm.type1) + "，咁"),
              _c("cube-input", {
                staticClass: "other_input",
                model: {
                  value: _vm.barrier.data[0].then,
                  callback: function ($$v) {
                    _vm.$set(_vm.barrier.data[0], "then", $$v)
                  },
                  expression: "barrier.data[0].then",
                },
              }),
            ],
            1
          ),
          _c("p", { staticClass: "b blue" }, [
            _vm._v("如果你唔知點寫，請參考我哋俾嘅例子。"),
          ]),
          _c("ul", [
            _c("li", [
              _vm.showeg_vg(0)
                ? _c("p", { staticClass: "borange" }, [
                    _vm._v(
                      " 如果我好忙，咁我會提前喺屋企或者公司放d生果，方便每日食。 "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("li", [
              _vm.showeg_vg(1)
                ? _c("p", { staticClass: "borange" }, [
                    _vm._v(
                      " 如果牙唔好，咬野有困難，咁我會盡量揀d軟熟嘅生果，比如香蕉、奇異果、提子。蔬菜我都會揀軟身同煮腍嘅，比如冬瓜，蘿蔔同菠菜。 "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("li", [
              _vm.showeg_vg(2)
                ? _c("p", { staticClass: "borange" }, [
                    _vm._v(
                      " 如果出去食飯或者度假， 咁我就會刻意的要求自己喺出面食飯嘅時候，都要食足夠嘅蔬菜同生果。 "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("li", [
              _vm.showeg_vg(3)
                ? _c("p", { staticClass: "borange" }, [
                    _vm._v(
                      " 如果蔬菜唔岩口味，咁我都會試下其他嘅菜，或者試下唔同嘅煮法，比如做蔬菜餅。 "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("li", [
              _vm.showeg_vg(4)
                ? _c("p", { staticClass: "borange" }, [
                    _vm._v(
                      " 如果生果蔬菜比較貴，咁我會揀街市就黎閂門嘅時候去買，咁樣就比較平。 "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("p", { staticClass: "b" }, [
            _vm._v(" 你嘅障礙對應計劃將會儲存喺電話主頁“"),
            _c("span", { staticClass: "b red" }, [_vm._v("飲食健康")]),
            _vm._v("” 嘅“對應計劃”一欄中，你可以隨時查閲 "),
          ]),
        ])
      : _vm._e(),
    _vm.page == 5
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("肉、蛋及替代品")]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.meat_complete,
                  expression: "!meat_complete",
                },
              ],
            },
            [
              _c("p", [
                _vm._v(" 喺好多情況下，你會覺得"),
                _c("span", { staticClass: "b" }, [
                  _vm._v("食適量嘅肉、蛋同替代品"),
                ]),
                _vm._v("（每日食5-6兩）"),
                _c("span", { staticClass: "b" }, [_vm._v("好困難")]),
                _vm._v(
                  "。針對呢啲情況，你會點樣應對？請認真思考，然後回答以下問題。 "
                ),
              ]),
              _c("p", [
                _vm._v(" 1. 就算我好忙，我每日都會食適量嘅肉、蛋同替代品。 "),
              ]),
              _c(
                "p",
                [
                  _vm._v("不符合"),
                  _c("cube-rate", {
                    model: {
                      value: _vm.barrier.meat_rate1,
                      callback: function ($$v) {
                        _vm.$set(_vm.barrier, "meat_rate1", $$v)
                      },
                      expression: "barrier.meat_rate1",
                    },
                  }),
                  _vm._v("符合我"),
                ],
                1
              ),
              _c("br"),
              _c("p", [
                _vm._v(
                  " 2. 就算牙唔好，咬野有困難，我每日都會食適量嘅肉、蛋同替代品。 "
                ),
              ]),
              _c(
                "p",
                [
                  _vm._v("不符合"),
                  _c("cube-rate", {
                    model: {
                      value: _vm.barrier.meat_rate2,
                      callback: function ($$v) {
                        _vm.$set(_vm.barrier, "meat_rate2", $$v)
                      },
                      expression: "barrier.meat_rate2",
                    },
                  }),
                  _vm._v("符合我"),
                ],
                1
              ),
              _c("br"),
              _c("p", [
                _vm._v(
                  " 3. 就算出去食飯或者度假，我都會控制自己食適量嘅肉、蛋同替代品。 "
                ),
              ]),
              _c(
                "p",
                [
                  _vm._v("不符合"),
                  _c("cube-rate", {
                    model: {
                      value: _vm.barrier.meat_rate3,
                      callback: function ($$v) {
                        _vm.$set(_vm.barrier, "meat_rate3", $$v)
                      },
                      expression: "barrier.meat_rate3",
                    },
                  }),
                  _vm._v("符合我"),
                ],
                1
              ),
              _c("br"),
              _c("p", [
                _vm._v(
                  " 4. 就算我唔鐘意食某d肉類或魚類，我都會揀食豆製品或者蛋類補充植物性蛋白質。 "
                ),
              ]),
              _c(
                "p",
                [
                  _vm._v("不符合"),
                  _c("cube-rate", {
                    model: {
                      value: _vm.barrier.meat_rate4,
                      callback: function ($$v) {
                        _vm.$set(_vm.barrier, "meat_rate4", $$v)
                      },
                      expression: "barrier.meat_rate4",
                    },
                  }),
                  _vm._v("符合我"),
                ],
                1
              ),
              _c("br"),
              _c("p", [
                _vm._v(
                  " 5. 就算有啲魚或肉類比較貴，我都會盡量保證每日都食適量嘅肉、蛋同替代品。 "
                ),
              ]),
              _c(
                "p",
                [
                  _vm._v("不符合"),
                  _c("cube-rate", {
                    model: {
                      value: _vm.barrier.meat_rate5,
                      callback: function ($$v) {
                        _vm.$set(_vm.barrier, "meat_rate5", $$v)
                      },
                      expression: "barrier.meat_rate5",
                    },
                  }),
                  _vm._v("符合我"),
                ],
                1
              ),
              _c("br"),
            ]
          ),
          _vm.meat_complete
            ? _c("div", [
                _c("p", [
                  _vm._v(
                    "你啱先已經指出，喺呢啲情況下，你仍然可以食適量嘅肉、蛋同替代品 ："
                  ),
                ]),
                _vm.barrier.meat_rate1 > 3
                  ? _c("p", { staticClass: "limegreen" }, [_vm._v("好忙")])
                  : _vm._e(),
                _vm.barrier.meat_rate2 > 3
                  ? _c("p", { staticClass: "limegreen" }, [
                      _vm._v("牙唔好，咬野有困難"),
                    ])
                  : _vm._e(),
                _vm.barrier.meat_rate3 > 3
                  ? _c("p", { staticClass: "limegreen" }, [
                      _vm._v("出去食飯或者度假"),
                    ])
                  : _vm._e(),
                _vm.barrier.meat_rate4 > 3
                  ? _c("p", { staticClass: "limegreen" }, [
                      _vm._v("唔鐘意食某d肉類或魚類"),
                    ])
                  : _vm._e(),
                _vm.barrier.meat_rate5 > 3
                  ? _c("p", { staticClass: "limegreen" }, [
                      _vm._v("有啲魚或肉類比較貴"),
                    ])
                  : _vm._e(),
                _c("br"),
                _c("p", [
                  _vm._v(
                    "但係喺呢啲情況下，你食適量嘅肉、蛋同替代品喺有困難 ："
                  ),
                ]),
                _vm.barrier.meat_rate1 < 4
                  ? _c("p", { staticClass: "red" }, [_vm._v("好忙")])
                  : _vm._e(),
                _vm.barrier.meat_rate2 < 4
                  ? _c("p", { staticClass: "red" }, [
                      _vm._v("牙唔好，咬野有困難"),
                    ])
                  : _vm._e(),
                _vm.barrier.meat_rate3 < 4
                  ? _c("p", { staticClass: "red" }, [
                      _vm._v("出去食飯或者度假"),
                    ])
                  : _vm._e(),
                _vm.barrier.meat_rate4 < 4
                  ? _c("p", { staticClass: "red" }, [
                      _vm._v("肉或者魚類唔岩胃口"),
                    ])
                  : _vm._e(),
                _vm.barrier.meat_rate5 < 4
                  ? _c("p", { staticClass: "red" }, [
                      _vm._v("肉或者魚類比較貴"),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.page == 6
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v("障礙------肉、蛋同替代品"),
          ]),
          _c("p", { staticClass: "blue" }, [
            _vm._v(
              " 唔好咁灰。針對困難情況，諗出對應計劃會對你有幫助。人地嘅例子都可能會對你有所啟發。 "
            ),
          ]),
          _c("audio", {
            staticClass: "gaudio",
            attrs: {
              controls: "",
              controlsList: "nodownload",
              preload: "auto",
              src: require("@/assets/guide/diet/Week_5_Barriers_meat.mp3"),
            },
          }),
          _c("p", [
            _vm._v(
              " 劉先生（66歲）：我以前每天早上都要吃兩隻雞蛋還有午餐肉，每天下午茶時間都會吃一種肉排（豬排、雞排或牛排），午餐和晚餐沒有肉我都吃不下飯。後來去醫院做體檢，發現自己的膽固醇偏高，血壓也不正常，我就開始意識到自己要控制每天的肉類攝取量。後來我和老伴説，去街市買餸時買少一些肉。但是想要改變以前不良的飲食習慣太難了。"
            ),
          ]),
          _c("p", [
            _vm._v(
              "為了控制好每天的肉類攝取量，我開始嘗試逐步減量法。例如，早餐只吃一隻雞蛋，下午茶盡量不吃肉類，改為吃生果和點心，午餐和晚餐吃的肉也比之前少一半。通過循序漸進的方法，我慢慢覺得自己可以做到堅持吃適量的肉類，並逐漸養成了健康的飲食習慣。 "
            ),
          ]),
          _c("img", {
            staticClass: "fw8",
            attrs: { src: require("../../assets/img/di_barrier_story1.jpg") },
          }),
        ])
      : _vm._e(),
    _vm.page == 7
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("障礙——肉、蛋同替代品")]),
          _c("p", [
            _vm._v(" 如果你有食適量嘅肉、蛋同替代品的想法，但一直都冇去做。"),
            _c("span", { staticClass: "b" }, [
              _vm._v("諗下有啲咩原因阻礙你咁樣做呢"),
            ]),
            _vm._v("？ "),
          ]),
          _c("p", [
            _vm._v("請用幾分鐘嘅時間思考下，揀出或者寫低你遇到嘅障礙同困難。"),
          ]),
          _c("p", [_vm._v("我最難應對嘅障礙喺")]),
          _c(
            "p",
            { staticClass: "select_line" },
            [
              _c("cube-select", {
                staticClass: "inline_select",
                attrs: { options: _vm.barrierList(1) },
                model: {
                  value: _vm.barrier.data[1].type,
                  callback: function ($$v) {
                    _vm.$set(_vm.barrier.data[1], "type", $$v)
                  },
                  expression: "barrier.data[1].type",
                },
              }),
              _vm.barrier.data[1].type == _vm.otherTxt
                ? _c(
                    "span",
                    [
                      _vm._v("請輸入"),
                      _c("cube-input", {
                        staticClass: "other_input",
                        model: {
                          value: _vm.barrier.data[1].type_other,
                          callback: function ($$v) {
                            _vm.$set(_vm.barrier.data[1], "type_other", $$v)
                          },
                          expression: "barrier.data[1].type_other",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v("。 "),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.page == 8
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("健康飲食障礙對應計劃")]),
          _c("p", [
            _vm._v(" 訂下有效的對應計劃最方便嘅辦法就係"),
            _c("span", { staticClass: "bu" }, [_vm._v("造句法")]),
            _vm._v("，用"),
            _c("span", [_vm._v("“如果…….咁…….”")]),
            _vm._v("造句。"),
          ]),
          _c("p", [
            _vm._v(
              "對應計劃越貼近你嘅實際情況，越有效果。跟住落黎，請你寫低你嘅對應計劃"
            ),
          ]),
          _c(
            "p",
            { staticClass: "select_line" },
            [
              _vm._v(" 1． 如果" + _vm._s(_vm.type2) + "，咁"),
              _c("cube-input", {
                staticClass: "other_input",
                model: {
                  value: _vm.barrier.data[1].then,
                  callback: function ($$v) {
                    _vm.$set(_vm.barrier.data[1], "then", $$v)
                  },
                  expression: "barrier.data[1].then",
                },
              }),
            ],
            1
          ),
          _c("p", { staticClass: "b" }, [
            _vm._v("如果你唔知點寫，請參考我哋俾嘅例子。"),
          ]),
          _c("ul", [
            _c("li", [
              _vm.showeg_meat(0)
                ? _c("p", { staticClass: "borange" }, [
                    _vm._v(
                      " 如果我好忙，咁我會提前喺屋企或者公司放d堅果，方便每日食。 "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("li", [
              _vm.showeg_meat(1)
                ? _c("p", { staticClass: "borange" }, [
                    _vm._v(
                      " 如果牙唔好，咬野有困難，咁我會盡量揀d軟質的食物，比如炒蛋、軟嫩肉類、三文魚沙拉、 絞肉丸、豆腐等。 "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("li", [
              _vm.showeg_meat(2)
                ? _c("p", { staticClass: "borange" }, [
                    _vm._v(
                      " 如果出去食飯或者度假， 咁我就會刻意的要求自己喺出面食飯嘅時候，食適量嘅魚肉，唔可以太多。 "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("li", [
              _vm.showeg_meat(3)
                ? _c("p", { staticClass: "borange" }, [
                    _vm._v(
                      " 如果我唔鐘意食某d肉類或魚類，咁我會揀食豆製品、蛋類補充植物性蛋白質 。 "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("li", [
              _vm.showeg_meat(4)
                ? _c("p", { staticClass: "borange" }, [
                    _vm._v(
                      " 如果我鐘意食嘅某種魚或肉類比較貴，咁我會揀街市就黎閂門嘅時候去買，咁樣就會平啲。 "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("p", { staticClass: "b" }, [
            _vm._v(" 你嘅障礙對應計劃將會儲存喺電話主頁“"),
            _c("span", { staticClass: "b red" }, [_vm._v("飲食健康")]),
            _vm._v("” 嘅“對應計劃”一欄中，你可以隨時查閲 "),
          ]),
        ])
      : _vm._e(),
    _vm.page == 9
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v("健康飲食障礙對應計劃總結"),
          ]),
          _c("p", { staticClass: "b" }, [
            _vm._v(
              "當你喺健康飲食過程中遇到困難，請用下低嘅對應計劃幫助自己克服困難！"
            ),
          ]),
          _c("p", [
            _c("span", { staticClass: "blue" }, [_vm._v("如果")]),
            _vm._v(_vm._s(_vm.type1) + "，"),
            _c("span", { staticClass: "blue" }, [_vm._v("咁")]),
            _vm._v(_vm._s(_vm.barrier.data[0].then)),
          ]),
          _c("p", [
            _c("span", { staticClass: "blue" }, [_vm._v("如果")]),
            _vm._v(_vm._s(_vm.type2) + "，"),
            _c("span", { staticClass: "blue" }, [_vm._v("咁")]),
            _vm._v(_vm._s(_vm.barrier.data[1].then)),
          ]),
          _vm.num > 5 && false
            ? _c("div", [
                _c("br"),
                _c("p", { staticClass: "b" }, [
                  _vm._v("下低喺用兩大方法對你嘅健康飲食障礙對應計劃嘅評估"),
                ]),
                _c("p", [
                  _vm._v(" 1. "),
                  _c("span", { staticClass: "b" }, [_vm._v("適合性")]),
                  _vm._v(
                    " 呢啲食物喺咪適合你？(" +
                      _vm._s(_vm.barrier.perEval.evl2) +
                      ") "
                  ),
                ]),
                _c("p", [
                  _vm._v(" 2. "),
                  _c("span", { staticClass: "b" }, [_vm._v("有效性")]),
                  _vm._v(
                    " 食呢啲食物對你嘅健康有冇好處？(" +
                      _vm._s(_vm.barrier.perEval.evl3) +
                      ") "
                  ),
                ]),
              ])
            : _vm._e(),
          !_vm.reviewMode
            ? _c(
                "div",
                [
                  _c("p", [_vm._v("你可以揀喺咪要進行調整。")]),
                  _c(
                    "cube-radio-group",
                    {
                      model: {
                        value: _vm.modify,
                        callback: function ($$v) {
                          _vm.modify = $$v
                        },
                        expression: "modify",
                      },
                    },
                    [
                      _c(
                        "cube-radio",
                        { key: "1", attrs: { option: { value: 1 } } },
                        [_vm._v(" 調整")]
                      ),
                      _c(
                        "cube-radio",
                        { key: "0", attrs: { option: { value: 0 } } },
                        [_vm._v(" 唔調整")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.page == 10
      ? _c(
          "div",
          { staticClass: "box" },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v("評估健康飲食障礙對應計劃"),
            ]),
            _c("p", [
              _vm._v(
                " 上個禮拜你已經制定咗健康飲食障礙對應計劃，幫助你克服困難，堅持健康飲食。跟住落黎，請你評估一下對應計劃嘅實行情況。如果呢啲對應計劃對你幫助唔大，咁就進行調整。 "
              ),
            ]),
            _c("p", [
              _vm._v(" 而家請回憶你上個禮拜制定嘅健康飲食障礙對應計劃（"),
              _c(
                "span",
                {
                  staticClass: "bu",
                  on: {
                    click: function ($event) {
                      return _vm.reviewPlan(10)
                    },
                  },
                },
                [_vm._v("點擊查看")]
              ),
              _vm._v(
                "）同埋你完成呢個計劃嘅情況。請指出，喺最近7日實行呢個健康飲食障礙對應計劃嘅情況。 "
              ),
            ]),
            _vm.barrier.perEval.evl1 > 0
              ? _c("div", [
                  _vm.barrier.perEval.evl1 == 1
                    ? _c("p", { staticClass: "red b" }, [
                        _vm._v(" 改變習慣唔易架，唔好氣餒，繼續加油！ "),
                      ])
                    : _vm._e(),
                  _vm.barrier.perEval.evl1 == 2
                    ? _c("p", { staticClass: "red b" }, [
                        _vm._v(" 仲有進步的空間，繼續加油! "),
                      ])
                    : _vm._e(),
                  _vm.barrier.perEval.evl1 == 3
                    ? _c("p", { staticClass: "red b" }, [
                        _vm._v(" 做的非常好，請繼續保持！ "),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.barrier.perEval.evl1,
                  callback: function ($$v) {
                    _vm.$set(_vm.barrier.perEval, "evl1", $$v)
                  },
                  expression: "barrier.perEval.evl1",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "0", attrs: { option: { value: 1 } } },
                  [_c("p", [_vm._v("不好")])]
                ),
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: 2 } } },
                  [_c("p", [_vm._v("一般")])]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: 3 } } },
                  [_c("p", [_vm._v("好")])]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.page == 11
      ? _c(
          "div",
          { staticClass: "box" },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v("評估健康飲食障礙對應計劃"),
            ]),
            _c("p", [
              _vm._v("而家請你運用兩大方法黎評估你嘅健康飲食障礙對應計劃。"),
            ]),
            _c("p", [_vm._v("1. 適合性 呢啲食物係咪適合你？")]),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.barrier.perEval.evl2,
                  callback: function ($$v) {
                    _vm.$set(_vm.barrier.perEval, "evl2", $$v)
                  },
                  expression: "barrier.perEval.evl2",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: "是" } } },
                  [_vm._v(" 是")]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: "否" } } },
                  [_vm._v(" 否")]
                ),
              ],
              1
            ),
            _c("p", [_vm._v("2. 有效性 食呢啲食物對你嘅健康有冇好處？")]),
            _c(
              "cube-radio-group",
              {
                model: {
                  value: _vm.barrier.perEval.evl3,
                  callback: function ($$v) {
                    _vm.$set(_vm.barrier.perEval, "evl3", $$v)
                  },
                  expression: "barrier.perEval.evl3",
                },
              },
              [
                _c(
                  "cube-radio",
                  { key: "1", attrs: { option: { value: "是" } } },
                  [_vm._v(" 是")]
                ),
                _c(
                  "cube-radio",
                  { key: "2", attrs: { option: { value: "否" } } },
                  [_vm._v(" 否")]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }