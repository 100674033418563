var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", [
    _vm.debugbtn
      ? _c("div", [
          _c("span", [_vm._v("頁面編號調試：")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.page,
                expression: "page",
              },
            ],
            domProps: { value: _vm.page },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.page = $event.target.value
              },
            },
          }),
        ])
      : _vm._e(),
    _vm.page == 1
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "bu title" }, [_vm._v("結 尾")]),
          _c("br"),
          _c("br"),
          _vm.num == 10
            ? _c("p", [
                _vm._v("“食”全十美，行大“運”嘅 “健康飲食”嘅內容到此結束。"),
              ])
            : _c("p", [
                _vm._v(
                  "“食”全十美，行大“運”嘅第" +
                    _vm._s(_vm.the_num) +
                    "單元“健康飲食嘅內容到此結束。"
                ),
                _c("span", { staticClass: "bu red" }, [
                  _vm._v("請點擊繼續以提交你的數據"),
                ]),
                _vm._v("。"),
              ]),
          _c("br"),
          _c("p", [_vm._v("喺呢度祝你一切順利成功！")]),
          _c("br"),
          _c("p", [_vm._v("你的“食”全十美，行大“運”團隊")]),
        ])
      : _vm._e(),
    _vm.page == 2
      ? _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "bu" }, [_vm._v("結 尾")]),
          _c("br"),
          _c("br"),
          _vm._v(" “食”全十美，行大“運”嘅 “健康飲食”嘅內容到此結束。 "),
          _c("br"),
          _c("p", [_vm._v("喺呢度祝你一切順利成功！")]),
          _c("br"),
          _c("p", [_vm._v("你的“食”全十美，行大“運”團隊")]),
        ])
      : _vm._e(),
    _c("img", {
      staticClass: "fw8",
      attrs: { src: require("../../assets/img/end.png") },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }