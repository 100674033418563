var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container vflex cscroll" }, [
    (_vm.user.pastate == 0 && _vm.type == "pa") ||
    (_vm.user.dietstate == 0 && _vm.type == "diet")
      ? _c("div", { staticClass: "plan_no" }, [
          _c("p", [_vm._v("請點擊“進入課程”，完成第一星期嘅學習")]),
        ])
      : _c("div", [
          _vm.type == "pa"
            ? _c("div", { staticClass: "uplan_box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("運動計劃")]),
                _c(
                  "ul",
                  { staticClass: "list-wrapper vflex" },
                  _vm._l(_vm.getPlanList(), function (item) {
                    return _c(
                      "li",
                      {
                        staticClass: "list-item",
                        staticStyle: { display: "flex" },
                      },
                      [
                        _c("div", { staticClass: "list-left" }, [
                          _vm._v(" " + _vm._s(item.date) + " "),
                        ]),
                        _c("div", { staticClass: "list-right" }, [
                          _c(
                            "span",
                            {
                              class: {
                                red: item.type == 1,
                                blue: item.type == 2,
                              },
                            },
                            [_vm._v(" " + _vm._s(item.action) + " ")]
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _c("div", { staticClass: "uplan_box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("健康飲食計劃")]),
                _c(
                  "ul",
                  { staticClass: "list-wrapper vflex" },
                  _vm._l(_vm.getPlanList2(), function (item) {
                    return _c(
                      "li",
                      {
                        staticClass: "list-item",
                        staticStyle: { display: "flex" },
                      },
                      [
                        _c("div", { staticClass: "list-left" }, [
                          _vm._v(" " + _vm._s(item.date) + " "),
                        ]),
                        _c("div", { staticClass: "list-right" }, [
                          _c(
                            "span",
                            {
                              class: {
                                red: item.type == 1,
                                blue: item.type == 2,
                              },
                            },
                            [_vm._v(" " + _vm._s(item.action) + " ")]
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }