<template>
  <div class="container flexcolumn">
    <div class="top btngroup">
      <cube-button class="topbtn" @click="back"> 返回主頁</cube-button>
      <cube-button class="topbtn" @click="next"> 提交打卡</cube-button>
    </div>
    <div class="middlebox">
      <wrapper>
        <div>
          <p v-show="type == 'pa'">
            <span class="b">“運動鍛煉日記”嘅內容包括</span>：當日你喺<span
              class="bu"
              >咩時間</span
            >， <span class="bu">咩地點</span>，<span class="bu"
              >同邊啲人</span
            >，進行咗<span class="bu">咩強度</span>，
            <span class="bu">咩類型嘅運動</span>，<span class="bu"
              >心情如何</span
            >。
          </p>

          <p v-show="type == 'diet'">
            <span class="b">“健康飲食日記”嘅內容包括</span>：當日你喺<span
              class="bu"
              >咩時間</span
            >， <span class="bu">咩地點</span>，<span class="bu"
              >同邊啲人</span
            >，食咗 <span class="bu">咩食物</span>，<span class="bu"
              >心情如何</span
            >。
          </p>
        </div>
        <div>
          <div v-show="txtDiary">
            <cube-textarea
              class="larget"
              v-model="diaryText"
              :maxlength="max"
              :placeholder="placeholder"
            >
            </cube-textarea>
            <div v-show="true">
              <cube-button class="btnChange" @click="changeType(false)"
                >切換錄入方式</cube-button
              >
            </div>
          </div>

          <div v-show="!txtDiary">
            <p>請點擊下低嘅“錄製”按鈕，錄製你嘅電子日記</p>
            <record
              :on-open-error="errHandle"
              :on-stop-is-end="stopHandle"
              ref="recorder"
            ></record>
            <cube-button v-show="true" class="btnChange" @click="changeType(true)"
              >切換錄入方式</cube-button
            >
          </div>
        </div>
      </wrapper>
    </div>
  </div>
</template>



<script>
import wrapper from "@/components/wrapper";
import record from "@/components/record";

export default {
  name: "userdiary",
  data: function () {
    return {
      diaryText: "",
      max: 1000,
      placeholder: "請在這裡輸入日記內容",

      recUrl: "",
      queryType: true,
    };
  },
  computed: {
    type: function () {
      return this.$store.state.type;
    },
    num: function () {
      return this.$store.state.week;
    },
    user: function () {
      return this.$store.state.user;
    },
    pa: function () {
      return this.$store.state.pa;
    },
    diet: function () {
      return this.$store.state.diet;
    },
    kind: function () {
      return this.type == "diet" ? 2 : 1;
    },
    txtDiary: function () {
      return this.$store.state.txtDiary;
    },
  },
  methods: {
    errHandle: function (err, byUser) {
      console.log("in errHandle");
      console.log(err);
      console.log(byUser);
      // 當用户主動取消時，彈窗提示問是否切換為文字版
      if (byUser) {
        this.queryChangeType();
      } else {
        this.changeType(true);
        this.queryType = false;
      }
    },
    stopHandle: function (success, data) {
      console.log("in stopHandle");
      console.log(success);
      console.log(data);

      if (success) {
        
      } else {
        queryChangeType(
          "音頻錄製時遇到錯誤(" + data + "),請考慮使用文本進行打卡"
        );
        this
          .$createDialog({
            type: "alert",
            title: "",
            content: "錄音保存失敗",
          }).show();
        return;
      }
    },
    queryChangeType: function (msg) {
      if (!msg) {
        msg = "未能獲取到您設備的錄音權限，您打算使用文本來記錄打卡日記嗎";
      }
      this.$createDialog({
        type: "confirm",
        content: msg,
        confirmBtn: {
          text: "使用文本",
          active: true,
          disabled: false,
          href: "javascript:;",
        },
        cancelBtn: {
          text: "使用錄音",
          active: false,
          disabled: false,
          href: "javascript:;",
        },
        onConfirm: () => {
          this.changeType(true);
        },
        onCancel: () => {
          this.changeType(false);
          this.$refs.recorder.init();
        },
      }).show();
    },
    changeType: function (useTxt) {
      this.$store.commit("setTxtDiary", { data: useTxt });
      if(!useTxt)
      {
          this.$refs.recorder.init();
      }
      else
      {
      this.diaryText = "";
      }
    },
    back: function () {
      this.$createDialog({
        type: "confirm",
        content: "確定要返回到主頁嗎",
        confirmBtn: {
          text: "確定",
          active: true,
          disabled: false,
          href: "javascript:;",
        },
        cancelBtn: {
          text: "取消",
          active: false,
          disabled: false,
          href: "javascript:;",
        },
        onConfirm: () => {
          this.$router.push({ path: "/home" });
        },
        onCancel: () => {
          let _;
        },
      }).show();
    },
    checkin: function () {
      this.$store
        .dispatch("checkin", {
          kind: this.kind,
          rec: !this.txtDiary,
          txt: !this.txtDiary ? this.recUrl : this.diaryText,
        })
        .then((msg) => {
          this.$createToast({
            txt: "打卡完成",
          }).show();
          this.$router.push({ path: "/home" });
        })
        .catch((err) => {
          this.$createToast({
            txt: "提交失敗，請稍後再試",
          }).show();
        });
    },
    next: function () {
      if (
        (this.diaryText.length < 1 && this.txtDiary) ||
        (this.$refs.recorder.blob==null && !this.txtDiary)
      ) {
        this.$createDialog({
          type: "alert",
          title: "",
          content: "請完成打卡內容後再提交",
        }).show();
      } else
        this.$createDialog({
          type: "confirm",
          content: "確定要提交打卡嗎",
          confirmBtn: {
            text: "提交",
            active: true,
            disabled: false,
            href: "javascript:;",
          },
          cancelBtn: {
            text: "取消",
            active: false,
            disabled: false,
            href: "javascript:;",
          },
          onConfirm: () => {
            if (!this.txtDiary) {
              this.$store
                .dispatch("uploadRec", {
                  blob: this.$refs.recorder.blob,
                  length: this.$refs.recorder.length,
                })
                .then((obj) => {
                  this.recUrl = obj.url;
                  this.checkin();
                })
                .catch((err) => {
                  this.recUrl = "";
                  this.$createToast({
                    txt: "提交失敗，請稍後再試",
                  }).show();
                });
            } else {
              this.checkin();
            }
          },
          onCancel: () => {
            let _;
          },
        }).show();
    },
  },
  components: {
    wrapper: wrapper,
    record: record,
  },
  created() {
    //console.log("Diary create");
  },
  beforeDestroy() {},

  metaInfo: {
    title: "“食”全十美，行大“運”",
    meta: [
      {
        name: "viewport",
        content:
          "width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, viewport-fit=cover",
      },
      {
        name: "apple-mobile-web-app-capable",
        content: "yes",
      },
      {
        name: "mobile-web-app-capable",
        content: "yes",
      },
    ],
  },
};
</script>
<style scoped src="@/assets/css/common.css" />

<style scoped>
.btngroup {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.middlebox {
  height: 0;
  flex: 1;
}

p {
  font-size: 28px;
  line-height: 36px;
}

.larget {
  font-size: 24px;
  height: 200px;
}

.btnChange{
      margin: 20px auto;
    width: 150px;
}
</style>