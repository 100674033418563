var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container user-container" }, [
    _c("div", { staticClass: "logo" }, [
      _vm.type == "pa"
        ? _c("img", {
            attrs: {
              alt: "運動頂圖",
              src: require("../assets/img/learn_pa_top.png"),
            },
          })
        : _c("img", {
            attrs: {
              alt: "飲食頂圖",
              src: require("../assets/img/learn_diet_top.png"),
            },
          }),
      _c(
        "div",
        { staticClass: "btngroup" },
        [
          _c(
            "cube-button",
            { staticClass: "topbtn", on: { click: _vm.goLearn } },
            [_vm._v(" 進入課程")]
          ),
          _c(
            "cube-button",
            { staticClass: "topbtn" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v("返回首頁"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "container midpage" },
      [
        _vm.select == "行為記錄" ? _c("Record") : _vm._e(),
        _vm.select == "行為計劃" ? _c("Plan") : _vm._e(),
        _vm.select == "對應計劃" ? _c("Barrier") : _vm._e(),
        _vm.select == "我的日記" ? _c("Diary") : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "home-user" },
      [
        _c("cube-tab-bar", {
          attrs: { data: _vm.tabs },
          on: { change: _vm.changeHandler },
          model: {
            value: _vm.select,
            callback: function ($$v) {
              _vm.select = $$v
            },
            expression: "select",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }