<template>
  <div id="main">
    <div class="btnLine">
      <button
        v-show="state == 'ready'"
        @click="start"
        class="recButton bttn-unite bttn-md bttn-danger bttn-block"
      >
        <div class="btnText">
          <img class="recImg" alt="" src="../assets/img/rec.png" />錄製
        </div>
      </button>

      <button
        v-show="state == 'rec'"
        @click="pause"
        class="recButton bttn-unite bttn-md bttn-warning bttn-block"
      >
        <div class="btnText">
          <img class="recImg" alt="" src="../assets/img/pause.png" />暫停
        </div>
      </button>

      <button
        v-show="state == 'pause'"
        @click="pause"
        class="recButton bttn-unite bttn-md bttn-success bttn-block"
      >
        <div class="btnText">
          <img class="recImg" alt="" src="../assets/img/rec.png" />繼續
        </div>
      </button>

      <button
        v-show="state == 'rec' || state == 'pause'"
        @click="stop"
        class="recButton bttn-unite bttn-md bttn-primary bttn-block"
      >
        <div class="btnText">
          <img class="recImg" alt="" src="../assets/img/stop.png" />完成
        </div>
      </button>

      <button
        v-show="state == 'stop'"
        @click="play"
        class="recButton bttn-unite bttn-md bttn-success bttn-block"
        id="btnplay"
      >
        <div class="btnText">
          <img class="recImg" alt="" src="../assets/img/play.png" />播放
        </div>
      </button>

      <button
        v-show="state == 'stop'"
        @click="drop"
        class="recButton bttn-unite bttn-md bttn-danger bttn-block"
      >
        <div class="btnText">
          <img class="recImg" alt="" src="../assets/img/rec.png" />重錄
        </div>
      </button>
    </div>

    <div class="btnLine" v-if="false">
      <button
        @click="upload"
        class="recButton bttn-unite bttn-md bttn-primary bttn-block"
        ref="btntest"
      >
        <div class="btnText">
          <img class="recImg" alt="" src="../assets/img/play.png" />上傳
        </div>
      </button>
    </div>

    <div v-if="false" class="bu" ref="rmvtest">這是一個加粗粗的文本</div>

    <audio id="recAudio"></audio>
  </div>
</template>

<script>
import Recorder from "recorder-core/recorder.mp3.min";
//關閉控件自帶的流量統計功能
Recorder.TrafficImgUrl = "";
let reclog = function (str) {
  console.log(str);
};

function hasClass(elem, cls) {
  cls = cls || "";
  if (cls.replace(/\s/g, "").length === 0) return false; //當cls沒有參數時，返回false
  return new RegExp(" " + cls + " ").test(" " + elem.className + " ");
}

function addClass(elem, cls) {
  if (!hasClass(elem, cls)) {
    elem.className = elem.className === "" ? cls : elem.className + " " + cls;
  }
}

function removeClass(elem, cls) {
  if (hasClass(elem, cls)) {
    var newClass = " " + elem.className.replace(/[\t\r\n]/g, "") + " ";
    while (newClass.indexOf(" " + cls + " ") >= 0) {
      newClass = newClass.replace(" " + cls + " ", " ");
    }
    elem.className = newClass.replace(/^\s+|\s+$/g, "");
  }
}

export default {
  data() {
    return {
      // 當前在錄音的實例
      rec: null,
      // 錄製好的音頻文件
      blob: null,
      // 當前錄製時長：秒
      length: 0,
      // "none", "ready", "rec", "pause", "stop"
      state: "none",
      // 是否正在檢測錄音長度
      checkingLength: true,
      // 錄音的最大長度：秒
      maxLength:  10*60,
    };
  },
  computed: {
    type: function () {
      return this.$store.state.type;
    },
    user: function () {
      return this.$store.state.user;
    },
    txtDiary: function () {
      return this.$store.state.txtDiary;
    },
  },
  props: {
    onOpenError: {
      type: Function,
      required: false,
      default: function () {},
    },
    onStopIsEnd: {
      type: Function,
      required: false,
      default: function () {},
    },
  },
  beforeDestroy() {
    if (this.rec) {
      this.rec.close();
      this.rec = null;
      this.blob = null;
      reclog("已關閉");
    } else {
      reclog("未打開錄音", 1);
    }
    this.state = "none";
    Recorder.Destroy();
  },
  mounted() {
    if (!Recorder.Support()) {
      reclog("un surrpot in created");
      //TODO 瀏覽器不支持錄音，切換文字版
      if (this.onOpenError) {
        this.onOpenError("un surrpot", false);
      }
      return;
    }
    this.init();
  },
  methods: {
    onProcess: function (
      buffers,
      powerLevel,
      bufferDuration,
      bufferSampleRate,
      newBufferIdx,
      asyncEnd
    ) {
      //接收到錄音數據時的回調函數：fn(buffers,powerLevel,bufferDuration,bufferSampleRate,newBufferIdx,asyncEnd)
      //返回值：onProcess如果返回true代表開啓異步模式，在某些大量運算的場合異步是必須的，
      //必須在異步處理完成時調用asyncEnd(不能真異步時需用setTimeout包裹)；
      //返回其他值或者不返回為同步模式（需避免在回調內執行耗時邏輯）；
      //如果開啓異步模式，在onProcess執行後新增的buffer會全部替換成空數組，
      //因此本回調開頭應立即將newBufferIdx到本次回調結尾位置的buffer全部保存到另外一個數組內，
      //處理完成後寫回buffers中本次回調的結尾位置。
      //buffers=[[Int16,...],...]：緩衝的PCM數據塊(16位小端LE)，
      //為從開始錄音到現在的所有pcm片段，每次回調可能增加0-n個不定量的pcm片段。
      //注意：buffers數據的採樣率為bufferSampleRate，它和set.sampleRate不一定相同，
      //可能為瀏覽器提供的原始採樣率rec.srcSampleRate，也可能為已轉換好的採樣率set.sampleRate；
      //如需瀏覽器原始採樣率的數據，請使用rec.buffers原始數據，而不是本回調的參數；
      //如需明確和set.sampleRate完全相同採樣率的數據，請在onProcess中自行連續調用採樣率轉換函數Recorder.SampleData()，
      //配合mock方法可實現實時轉碼和壓縮語音傳輸；修改或替換buffers內的數據將會改變最終生成的音頻內容（注意不能改變第一維數組長度），
      //比如簡單有限的實現實時靜音、降噪、混音等處理，詳細參考下面的rec.buffers
      //powerLevel：當前緩衝的音量級別0-100。
      //bufferDuration：已緩衝時長。
      //bufferSampleRate：buffers緩存數據的採樣率（當type支持邊錄邊轉碼(Worker)時，此採樣率和設置的採樣率相同，否則不一定相同）。
      //newBufferIdx:本次回調新增的buffer起始索引。
      //asyncEnd：fn() 如果onProcess是異步的(返回值為true時)，處理完成時需要調用此回調，
      //如果不是異步的請忽略此參數，此方法回調時必須是真異步（不能真異步時需用setTimeout包裹）。
      //如果需要繪製波形之類功能，需要實現此方法即可，使用以計算好的powerLevel可以實現音量大小的直觀展示，使用buffers可以達到更高級效果
      //如果需要實時上傳（發送）之類的，可以配合Recorder.SampleData方法，
      //將buffers中的新數據連續的轉換成pcm，或使用mock方法將新數據連續的轉碼成其他格式，
      //可以參考文檔裏面的：Demo片段列表 -> 實時轉碼並上傳-通用版；
      //基於本功能可以做到：實時轉發數據、實時保存數據、實時語音識別（ASR）等
      this.length = bufferDuration / 1000;
      if (this.checkingLength && this.length > this.maxLength - 1) {
        this.checkingLength = false;
        let _this = this;
        setTimeout(() => {
          _this.stop();
          _this
          .$createDialog({
            type: "alert",
            title: "",
            content: "已達到10分鐘的錄製上限， 錄音自動停止",
          })
          .show();
        }, 100);

      }
      //console.log(`錄音長度：${this.length/1000}, buffer長度${bufferDuration/1000}`);
    },

    init: function () {

//         set={
//     type:"mp3" //输出类型：mp3,wav等，使用一个类型前需要先引入对应的编码引擎
//     ,bitRate:16 //比特率，必须是数字 wav(位):16、8，MP3(单位kbps)：8kbps时文件大小1k/s，16kbps 2k/s，录音文件很小
//     ,sampleRate:16000 //采样率，必须是数字，wav格式（8位）文件大小=sampleRate*时间；mp3此项对低比特率文件大小有影响，高比特率几乎无影响。
//                 //wav任意值，mp3取值范围：48000, 44100, 32000, 24000, 22050, 16000, 12000, 11025, 8000
//     //*******高级设置******
//         //,disableEnvInFix:false 内部参数，禁用设备卡顿时音频输入丢失补偿功能，如果不清楚作用请勿随意使用

      this.rec = Recorder({
        type: "mp3",
        sampleRate: 16000,
        bitRate: 16,
        onProcess: this.onProcess,
        noiseSuppression: true,
        //echoCancellation: true,
        autoGainControl:true,
      });

      //在這之前可以考慮一個延時彈窗Dilog，提醒用户授予權限
      let _this = this;
      this.rec.open(
        function () {
          // 關閉Dilog
          _this.state = "ready";
        },
        function (msg, isUserNotAllow) {
          // 關閉Dilog
          if (_this.onOpenError) {
            _this.onOpenError(msg, isUserNotAllow);
          }
        }
      );
    },

    start: function () {
      //打開了錄音後才能進行start、stop調用
      if (this.rec && Recorder.IsOpen()) {
        this.blob = null;
        this.rec.start();
        this.state = "rec";

        this.checkingLength = true;
        reclog("已開始錄音...");
      } else {
        reclog("未打開錄音", 1);
      }
    },

    pause: function () {
      if (this.rec && Recorder.IsOpen()) {
        if (this.state == "rec") {
          this.rec.pause();
          this.state = "pause";
        } else if (this.state == "pause") {
          this.rec.resume();
          this.state = "rec";
        }
      } else {
        reclog("未打開錄音", 1);
      }
    },
    // 這個函數只會在已經完成一次錄音之後調用
    // 所以假定設備是滿足錄音支持的
    drop: function () {
      if (this.rec) {
        this.rec.close();
      }
      this.rec = null;
      this.blob = null;
      this.length = 0;

      this.state = "none";
      this.init();
    },

    play: function () {
      if (!this.blob) {
        reclog("請先錄音，然後停止後再播放", 1);
        return;
      }

      let audio = document.getElementById("recAudio");
      //audio.controls = true;
      audio.src = (window.URL || webkitURL).createObjectURL(this.blob);
      audio.play();

      let btn = document.getElementById("btnplay");
      btn.blur();
      console.log(btn);
    },

    stop: function () {
      if (!(this.rec && Recorder.IsOpen())) {
        reclog("未打開錄音", 1);
        return;
      }

      // TODO這個函數會異步處理錄音數據，需要用Loading遮罩防止用户提前離開頁面
      let _this = this;
      this.rec.stop(
        function (blob, duration) {
          _this.state = "stop";
          _this.blob = blob;
          _this.length = duration / 1000;

          if (_this.onStopIsEnd) {
            _this.onStopIsEnd(true, _this.blob);
          }
          reclog(
            "已錄製mp3：" +
              duration +
              "ms " +
              blob.size +
              "字節，可以點擊播放、上傳了",
            2
          );
        },
        function (msg) {
          if (_this.onStopIsEnd) {
            _this.onStopIsEnd(false, msg);
          }
          _this.drop();
          reclog("錄音失敗:" + msg, 1);
        }
      );
    },

    // 上傳
    upload: function () {
      //removeClass(this.$refs.btntest, "focus");
      //removeClass(this.$refs.btntest, "hover");
      //removeClass(this.$refs.btntest, "after");
      //removeClass(this.$refs.rmvtest, "bu");
      // document.getElementById("btntest").style.visibility="hidden";
      //     let _this = this;
      //   setTimeout(()=>{
      // //     let btn = document.getElementById("btntest");
      // //     btn.blur();
      // //     btn.scrollIntoView();
      // //     btn.setAttribute('class', btn.getAttribute('class'))
      // //     //console.log(btn);
      // //     //$("#btntest").removeClass("bttn-unite").addClass("bttn-unite");
      // //     //console.log($("#btntest"));
      // //     let audio = document.getElementById("recAudio");
      // //     audio.focus();
      // //     audio.click();
      // //     audio.scrollIntoView();
      //     console.log("click audio");
      //     document.getElementById("btntest").style.visibility="visible";
      //   }, 1000);

      //var blob = this.blob;
      if (!this.blob) {
        reclog("請先錄音，然後停止後再上傳", 1);
        return;
      }

      let _this = this;
      this.$store
        .dispatch("uploadRec", {
          blob: this.blob,
          length: this.length,
        })
        .then((obj) => {
          _this.url = obj.url;
        })
        .catch((err) => {
          _this.url = "";
        });
    },
  },
};
</script>

<style scoped src="@/assets/css/bttn.css" />

<style scoped>
.btnLine {
  display: flex;
  margin: 1px auto;
  justify-content: center;
  align-items: center;
}

.recButton {
  margin: 5px 10px;
}

.recImg {
  display: inline;
  margin: auto 5px;
  height: 24px;
  width: 24px;
}

.btnText {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-weight: bold;
}
</style>
