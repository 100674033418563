<template>
    <wrapper>
        <div v-if="debugbtn">
            <span>頁面編號調試：</span>
            <input v-model="page" />
        </div>
        <div
            v-if="page == 1"
            class="box"
        >
            <div class="title">健康飲食計劃</div>
            <p>
                科學研究表明，訂下具體嘅<span class="bu">“健康飲食計劃”</span>有助於我哋達到預期目標。
                <span class="bu">“健康飲食計劃”</span>嘅內容包括：<span class="bu blue">食咩食物</span>以及<span class="bu blue">喺咩時間食</span>。
            </p>
            <audio
                class="gaudio"
                controls
                controlsList="nodownload"
                preload="auto"
                src="@/assets/guide/diet/Week_1_Planning.mp3"
            ></audio>
            <p>
                何婆婆 (73歲)：我一直想建立健康飲食習慣。但以前我對自己每天吃什麼總是不太在意。一個月前，我參加了社區的長者健康飲食工作坊。學到了很多有關健康飲食的知識，同時學會制定飲食計劃。每天早上我都會認真思考當天會吃什麼生果蔬菜以及肉和魚，分別吃多少，並做一個詳細的計劃。這個計劃可以很好地幫助我在每天的飲食中維持均衡飲食，保持健康飲食習慣。
            </p>
            <img
                class="fw8"
                src="../../assets/img/di_support_1.jpg"
            />
        </div>
        <div
            v-if="page == 2"
            class="box"
        >
            <div class="title">健康飲食計劃</div>
            <p>
                我哋建議你運用以下<span class="bu">兩大方法</span>黎制定健康飲食計劃。
            </p>

            <p>1. <span class="b">適合性</span> 呢啲食物喺咪適合你？</p>
            <p>2. <span class="b">有效性</span> 食呢啲食物對你嘅健康有冇好處？</p>
        </div>

        <div
            v-if="page == 3"
            class="box"
        >
            <div class="title">健康飲食計劃</div>
            <p>跟住落黎，請思考喺未來7日，你計劃<span class="bu blue">喺咩時間</span>食生果蔬菜、肉、蛋同埋替代品。</p>
            <div class="plan-container">
                <cube-tab-bar
                    v-model="weekSelect"
                    :data="tabs"
                    class="plan-left"
                    @change="changeWeek"
                ></cube-tab-bar>
                <div class="plan-right">
                    <p>1.挑選邊種<span class="bu">食物類型</span>？</p>
                    <cube-select
                        v-model="typeSelect"
                        :options="foodType"
                        @change="changeType"
                    > </cube-select>
                    <p>2.挑選邊種<span class="bu">食物</span>？</p>
                    <cube-checkbox-group
                        v-show="typeSelect == '生果'"
                        v-model="fruitSelect"
                        :options="fruit"
                        shape="square"
                    />
                    <cube-checkbox-group
                        v-show="typeSelect == '蔬菜'"
                        v-model="vgSelect"
                        :options="vg"
                        shape="square"
                    />
                    <cube-checkbox-group
                        v-show="typeSelect == '肉類'"
                        v-model="meatSelect"
                        :options="meat"
                        shape="square"
                    />
                    <cube-checkbox-group
                        v-show="typeSelect == '蛋類'"
                        v-model="eggSelect"
                        :options="egg"
                        shape="square"
                    />
                    <cube-checkbox-group
                        v-show="typeSelect == '替代品'"
                        v-model="beanSelect"
                        :options="bean"
                        shape="square"
                    />
                    <cube-checkbox-group
                        v-show="typeSelect == '食物'"
                        v-model="foodSelect"
                        :options="allFood"
                        shape="square"
                    />
                    <div>
                        <cube-button
                            class="btn_clear"
                            @click="clear"
                            :primary="false"
                        > 清空</cube-button>
                    </div>
                    <div>
                        <cube-button
                            class="btn_clear"
                            @click="applyAll"
                            :primary="true"
                        > 應用到本週</cube-button>
                    </div>
                </div>
            </div>
            <p class="b">
                呢個計劃表將會儲存喺手機主頁<span class="red">“健康飲食”</span>按鈕嘅“行動計劃”一欄之中，你可以隨時查閲。
            </p>

        </div>

        <div
            v-if="page == 4"
            class="box"
        >
            <div class="title">健康飲食計劃</div>
            <p>
                你上個禮拜已經學識咗制定“<span class="bu">健康飲食計劃</span>”，包括：<span class="bu blue">咩時間</span>食生果蔬菜同埋<span class="bu blue">咩時間</span>食肉、蛋同埋替代品。
            </p>
            <p>
                而家請回憶你上個禮拜制定嘅飲食計劃（<span
                    class="bu"
                    @click="reviewPlan()"
                >點擊查看</span>）同埋你完成呢個計劃嘅情況。請指出，喺最近7日實行呢個健康飲食計劃嘅實行。
            </p>
            <div v-if="plan.perEval.evl1>0">
                <p
                    v-if="plan.perEval.evl1 ==1"
                    class="red b"
                >改變習慣唔易架，唔好氣餒，繼續加油！</p>
                <p
                    v-if="plan.perEval.evl1 ==2"
                    class="red b"
                >仲有進步的空間，繼續加油!</p>
                <p
                    v-if="plan.perEval.evl1 ==3"
                    class="red b"
                >做得非常好，請繼續保持！</p>
            </div>
            <cube-radio-group v-model="plan.perEval.evl1">
                <cube-radio
                    key="0"
                    :option="{ value: 1 }"
                >
                    <p>不好</p>
                </cube-radio>
                <cube-radio
                    key="1"
                    :option="{ value: 2 }"
                >
                    <p>一般</p>
                </cube-radio>
                <cube-radio
                    key="2"
                    :option="{ value: 3 }"
                >
                    <p>好</p>
                </cube-radio>
            </cube-radio-group>

        </div>

        <div
            v-if="page == 5"
            class="box"
        >
            <div class="title">評估健康飲食計劃</div>
            <p>而家請你運用以下<span class="bu">兩大方法</span>黎評價一下你嘅飲食計劃。</p>
            <p>1. <span class="b">適合性</span> 呢啲食物喺咪適合你？</p>
            <cube-radio-group v-model="plan.perEval.evl2">
                <cube-radio
                    key="1"
                    :option="{ value: '是' }"
                > 是</cube-radio>
                <cube-radio
                    key="2"
                    :option="{ value: '否' }"
                > 否</cube-radio>
            </cube-radio-group>
            <p>2. <span class="b">有效性</span> 食呢啲食物對你嘅健康有冇好處？</p>
            <cube-radio-group v-model="plan.perEval.evl3">
                <cube-radio
                    key="1"
                    :option="{ value: '是' }"
                > 是</cube-radio>
                <cube-radio
                    key="2"
                    :option="{ value: '否' }"
                > 否</cube-radio>
            </cube-radio-group>
        </div>

        <div
            v-if="page == 6"
            class="box"
        >
            <div class="title">健康飲食計劃表</div>
            <p>呢度呈現嘅喺你過去7日所制定嘅健康飲食計劃。</p>
            <ul class="list-wrapper vflex">
                <li
                    v-for="item in getPlanList()"
                    class="list-item"
                    style="display: flex"
                >
                    <div class="list-left">
                        {{ item.date }}
                    </div>
                    <div class="list-right">
                        {{ item.action }}
                    </div>
                </li>
            </ul>
            <div v-if="!reviewMode">
                <p>你可以選擇是否進行調整。</p>
                <cube-radio-group v-model="modify">
                    <cube-radio
                        key="1"
                        :option="{ value: 1 }"
                    > 修改</cube-radio>
                    <cube-radio
                        key="0"
                        :option="{ value: 0 }"
                    > 唔修改</cube-radio>
                </cube-radio-group>
            </div>

        </div>
    </wrapper>
</template>



<script>
import wrapper from "../wrapper.vue";
import tools from "@/data/tools";
import {
    weekDays,
    allEatTime,
    allFood,
    validErr,
    foodType,
    fruit,
    vg,
    meat,
    egg,
    bean,
} from "@/data/data";

const tabWeek = (function () {
    let list = [];
    for (let i = 0; i < weekDays.length; i++) {
        list.push({ label: weekDays[i], value: i });
    }
    return list;
})();

export default {
    name: "pa_plan",
    data() {
        return {
            page: 0,
            weekSelect: 0,
            weekIndex: 0,
            whenSelect: "上午：12：00前",
            typeSelect: "生果",
            typeIndex: 0,
            foodSelect: [],
            fruitSelect: [],
            vgSelect: [],
            meatSelect: [],
            eggSelect: [],
            beanSelect: [],
            tabs: tabWeek,
            reviewMode: false,
            modify: 0,
            allEatTime: allEatTime,
            allFood: allFood,
            foodType: foodType,
            fruit: fruit,
            vg: vg,
            meat: meat,
            egg: egg,
            bean: bean,
            plan: {
                perEval: {
                    evl1: 0,
                    evl2: "",
                    evl3: "",
                },
                data: [
                    {
                        detail: [
                            {
                                when: "",
                                food: [],
                                fruit: [],
                                vg: [],
                                meat: [],
                                egg: [],
                                bean: [],
                            },
                            {
                                when: "",
                                food: [],
                                fruit: [],
                                vg: [],
                                meat: [],
                                egg: [],
                                bean: [],
                            },
                            {
                                when: "",
                                food: [],
                                fruit: [],
                                vg: [],
                                meat: [],
                                egg: [],
                                bean: [],
                            },
                            {
                                when: "",
                                food: [],
                                fruit: [],
                                vg: [],
                                meat: [],
                                egg: [],
                                bean: [],
                            },
                            {
                                when: "",
                                food: [],
                                fruit: [],
                                vg: [],
                                meat: [],
                                egg: [],
                                bean: [],
                            },
                            {
                                when: "",
                                food: [],
                                fruit: [],
                                vg: [],
                                meat: [],
                                egg: [],
                                bean: [],
                            },
                            {
                                when: "",
                                food: [],
                                fruit: [],
                                vg: [],
                                meat: [],
                                egg: [],
                                bean: [],
                            },
                        ],
                    },
                ],
            },
            perweek: {},
        };
    },
    computed: {
        type: function () {
            return this.$store.state.type;
        },
        num: function () {
            return this.$store.state.week;
        },
        user: function () {
            return this.$store.state.user;
        },
        diet: function () {
            return this.$store.state.diet;
        },
        debugbtn: function () {
            return this.$store.state.debugbtn;
        },
    },
    created() {
        if (!this.page) {
            if (this.num == 1) this.page = 1;
            else this.page = 4;
        }
        if (this.diet[this.num] != null) {
            this.plan = tools.copy(this.diet[this.num].plan);
        } else {
            //編輯，先查看是否有緩存
            if (this.diet[0].plan) {
                this.plan = this.diet[0].plan;
            }
        }
        // 無論查看還是編輯， 初始化上週內容
        if (this.diet[this.num - 1] != null) {
            this.perweek = tools.copy(this.diet[this.num - 1].plan);
        }

        if (!this.$store.state.review && this.num != 1) {
            this.plan = tools.copy(this.perweek);
            this.plan.perEval.evl1 = 0;
            this.plan.perEval.evl2 = "";
            this.plan.perEval.evl3 = "";
        }
        this.typeSelect= "生果";
        this.fruitSelect=this.plan.data[0].detail[0].fruit;
    },
    methods: {
        clear: function () {
        let day = this.plan.data[0].detail[this.weekIndex];
            if(this.typeSelect == "生果")
            {
                this.fruitSelect = [];
            }
            if(this.typeSelect == "蔬菜")
            {
                this.vgSelect = [];
            }
            if(this.typeSelect == "肉類")
            {
                this.meatSelect= [];
            }
            if(this.typeSelect == "蛋類")
            {
                this.eggSelect = [];
            }
            if(this.typeSelect == "替代品")
            {
                this.beanSelect = [];
            }
                day.fruit = this.fruitSelect;
                day.vg = this.vgSelect;
                day.meat = this.meatSelect;
                day.egg = this.eggSelect;
                day.bean = this.beanSelect;
        },
        applyAll: function () {
                        this.saveDay(
                this.weekIndex,
                this.weekIndex
            );
            let day = this.plan.data[0].detail[this.weekIndex];
            for (let i = 0; i < this.plan.data[0].detail.length; i++) {
                this.plan.data[0].detail[i] = tools.copy(day);
            }
        },
        getPlanList: function () {
            let plan = this.plan;
            let list = [];
            for (let i = 0; i < plan.data[0].detail.length; i++) {
                let obj;
                let day = plan.data[0].detail[i];
                let count = day.fruit.length + day.vg.length + day.egg.length + day.meat.length + day.bean.length;
                let firstType = true;
                if (count>0) {
                    obj = {
                        date: weekDays[i],
                        action:"我計劃食",
                    };
                    if(day.fruit.length > 0)
                    {
                        if(!firstType)
                            obj.action = obj.action + "、";
                        obj.action = obj.action + "生果（" + day.fruit.join("和")+"）";
                        firstType = false;
                    }
                    if(day.vg.length > 0)
                    {
                        if(!firstType)
                            obj.action = obj.action + "、";
                        obj.action = obj.action + "蔬菜（" + day.vg.join("和")+"）";
                        firstType = false;
                    }
                    if(day.meat.length > 0)
                    {
                        if(!firstType)
                            obj.action = obj.action + "、";
                        obj.action = obj.action + "肉（" + day.meat.join("和")+"）";
                        firstType = false;
                    }
                    if(day.egg.length > 0)
                    {
                        if(!firstType)
                            obj.action = obj.action + "、";
                        obj.action = obj.action + "蛋（" + day.egg.join("和")+"）";
                        firstType = false;
                    }
                    if(day.bean.length > 0)
                    {
                        if(!firstType)
                            obj.action = obj.action + "、";
                        obj.action = obj.action + "替代品（" + day.bean.join("和")+"）";
                        firstType = false;
                    }
                    list.push(obj);
                }
                
            }
            return list;
        },
        reviewPlan: function () {
            console.log("click reviewPlan");
            this.reviewMode = true;
            this.plan = tools.copy(this.perweek);
            this.page = 6;
        },
        saveDay: function (oldOrder, newOrder) {
            let valid = this.whenSelect && this.foodSelect.length > 0;
            if (false) {
                // const toast = this.$createToast({
                //     txt: '計劃未填寫完整',
                //     type: 'alert',
                //     time: 1000,
                // });
                // toast.show();
            } else {
                let day = this.plan.data[0].detail[oldOrder];
                //day.when = this.whenSelect;
                //day.food = this.foodSelect;
                day.fruit = this.fruitSelect;
                day.vg = this.vgSelect;
                day.meat = this.meatSelect;
                day.egg = this.eggSelect;
                day.bean = this.beanSelect;
            }
            let day = this.plan.data[0].detail[newOrder];
            //this.whenSelect = day.when;
            //this.foodSelect = day.food;
            this.fruitSelect = day.fruit;
            this.vgSelect = day.vg;
            this.meatSelect = day.meat;
            this.eggSelect = day.egg;
            this.beanSelect = day.bean;
        },
        changeWeek: function (value) {
            console.log("changeWeek:" + this.weekIndex + "  " + value);
            this.saveDay(
                this.weekIndex,
                value
            );
            this.weekIndex = value;

            console.log(this.plan.data[0].detail);
            console.log(this.getPlanList());
        },
        changeType: function (value) {
            console.log("typeSelect:" + this.typeSelect);
            this.saveDay(
                this.weekIndex,
                this.weekIndex
            );
        },
        save: function () {
            if (this.$store.state.review) return;
            let xml = {};
            xml.key = ["飲食計劃", "上週實行情況", "適合性評價", "有效性評價"];
            xml.data = [];
            let planText = [];
            for (let txt of this.getPlanList()) {
                planText.push(txt.date + txt.action);
            }
            xml.data.push(planText.join(";"));
            xml.data.push(
                ["未填寫", "不好", "一般", "好"][this.plan.perEval.evl1]
            );
            xml.data.push(this.plan.perEval.evl2);
            xml.data.push(this.plan.perEval.evl3);
            this.plan.xml = xml;

            this.diet[0].plan = tools.copy(this.plan);
        },
        pause: function () {
            this.save();
        },
        leave: function (page) {
            if (typeof this.page == "string") this.page = parseInt(this.page);

            if (page == 2) {
            } else if (page == 3) {
                this.saveDay(
                    this.weekIndex,
                    this.weekIndex
                );
            } else if (page == 4) {
                this.reviewMode = false;
            }
        },
        valid: function (page) {
            if (page == 2) {
            } else if (page == 3) {
                this.saveDay(
                    this.weekIndex,
                    this.weekIndex
                );
                if (this.getPlanList().length == 0) return validErr.empty;
            } else if (page == 4) {
                if (!this.plan.perEval.evl1) return validErr.empty;
            } else if (page == 5) {
                if (
                    this.plan.perEval.evl2.length != 1 ||
                    this.plan.perEval.evl3.length != 1
                )
                    return validErr.empty;
            }
        },

        next: function () {
            console.log("plan do next");
            if (this.valid(this.page)) {
                this.$createDialog({
                    type: "alert",
                    title: "",
                    content: this.valid(this.page),
                }).show();
                return;
            }

            this.leave(this.page);

            let done = false;
            switch (this.page) {
                // 引導頁
                case 1:
                    this.page = 2;
                    break;
                // 項目選擇頁
                case 2:
                    this.page = 3;
                    break;
                // 計劃制定頁
                case 3:
                    done = true;
                    break;
                // 評價上週計劃
                case 4:
                    this.page = 5;
                    break;
                // 再次評價上週計劃
                case 5:
                    this.page = 6;
                    break;
                // 計劃展示頁
                case 6:
                    if (this.modify) this.page = 2;
                    else if (this.reviewMode) this.page = 4;
                    else done = true;
            }
            if (done) {
                this.save();
                return this.page;
            }
        },
    },

    components: { wrapper },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../assets/css/learn_common.css" />
<style scoped>
#list {
    margin: 0 auto;
}

.plan-container {
    display: grid;
    grid-template-columns: minmax(80px, 25%) 1fr;
}

.plan-left {
    flex-wrap: wrap;
    margin: 5px;
    flex-direction: column;
    font-size: 24px;
}
.plan-right {
    margin-left: 2px;
    padding-left: 5px;
    border-left: 2px solid red;
}

.cube-tab {
    width: 62.5px;
    flex-basis: unset;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
}
.vflex {
    display: flex;
    flex-direction: column;
}

.list-wrapper {
    padding: 20px 10px 0px 10px;
}

.list-item {
    min-height: 100px;
}

.list-left {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #5b5b5b;
}

.list-right {
    width: 100%;
    border: 1px dashed red;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    padding: 20px;
    line-height: 24px;
    word-wrap: break-word;
    word-break: break-all;
}

.btn_clear {
    margin: 20px;
    width: 60%;
    font-size :20px;
}

.rev_btn {
    display: inline;
    width: 60px;
    height: 20px;
}

.inline {
    display: inline;
}
</style>