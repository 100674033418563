var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("wrapper", { staticClass: "root" }, [
    _vm.debugbtn
      ? _c("div", [
          _c("span", [_vm._v("頁面編號調試：")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.page,
                expression: "page",
              },
            ],
            domProps: { value: _vm.page },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.page = $event.target.value
              },
            },
          }),
        ])
      : _vm._e(),
    _vm.page == 1
      ? _c("div", { staticClass: "box" }, [
          _c("p", [
            _vm._v(
              " 世界衞生組織（WHO）飲食營養與慢性病預防機構建議：為咗促進健康，長者應該 "
            ),
            _c("span", { staticClass: "bu" }, [
              _vm._v(
                "平均每日至少食5份生果蔬菜，同埋5-6兩肉、蛋及替代品（例如：豆腐, 堅果）"
              ),
            ]),
            _vm._v("。 "),
          ]),
          _c("br"),
          _c("img", {
            staticClass: "fw10",
            attrs: { src: require("../../assets/img/di_record_food.png") },
          }),
        ])
      : _vm._e(),
    _vm.page == 2
      ? _c(
          "div",
          { staticClass: "box" },
          [
            _c(
              "div",
              { staticClass: "title", staticStyle: { "font-size": "24px" } },
              [_vm._v("過去一星期嘅生果蔬菜攝入情況")]
            ),
            _c("p", [
              _vm._v(" 下面請你回憶一下喺啱啱過去嘅7日，你食生果蔬菜（"),
              _c("span", { staticClass: "red b" }, [
                _vm._v("薯仔唔屬於蔬菜類"),
              ]),
              _vm._v("）嘅情況。 "),
            ]),
            _c(
              "cube-button",
              {
                staticClass: "topbtn",
                attrs: { primary: true },
                on: { click: _vm.learnVG },
              },
              [_vm._v(" 學習如何衡量蔬菜")]
            ),
            _c(
              "p",
              { staticClass: "select_line" },
              [
                _vm._v(" 過去7日，我記錄了 "),
                _c("cube-select", {
                  staticClass: "inline_select",
                  attrs: { options: _vm.weekDay },
                  model: {
                    value: _vm.record.vg_week_day,
                    callback: function ($$v) {
                      _vm.$set(_vm.record, "vg_week_day", $$v)
                    },
                    expression: "record.vg_week_day",
                  },
                }),
                _vm._v(" 日的飲食,"),
                _c("span", { staticClass: "b" }, [_vm._v("總共食過")]),
                _vm._v("："),
                _c("br"),
                _vm._v(" "),
                _c("br"),
                _c("cube-input", {
                  ref: "inputv",
                  staticClass: "other_input",
                  attrs: { type: "number" },
                  on: { input: _vm.inputLimit, change: _vm.inputLimit },
                  model: {
                    value: _vm.record.vg_day,
                    callback: function ($$v) {
                      _vm.$set(_vm.record, "vg_day", $$v)
                    },
                    expression: "record.vg_day",
                  },
                }),
                _vm._v(" 份生果蔬菜。 "),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.cmpVg && _vm.num == 1,
                    expression: "cmpVg && num == 1",
                  },
                ],
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.sumVg >= 5 || _vm.debugbtn,
                        expression: "sumVg>=5 || debugbtn",
                      },
                    ],
                  },
                  [
                    _c("p", [
                      _vm._v("上個禮拜你每日平均生果蔬菜攝取量為"),
                      _c("span", { staticClass: "red b" }, [
                        _vm._v(_vm._s(_vm.sumVg) + "份"),
                      ]),
                      _vm._v("。"),
                    ]),
                    _c("p", [
                      _vm._v("結果表明，你已經"),
                      _c("span", { staticClass: "blue b" }, [_vm._v("達到")]),
                      _vm._v("咗WHO推薦的生果蔬菜攝入標準。非常好！"),
                    ]),
                    _c("p", [_vm._v("要有信心一定可以長期保持呢個標準。")]),
                    _c("img", {
                      staticClass: "fw8",
                      attrs: {
                        src: require("../../assets/img/di_record_feedback1.jpg"),
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.sumVg < 5 || _vm.debugbtn,
                        expression: "sumVg<5 || debugbtn",
                      },
                    ],
                  },
                  [
                    _c("p", [
                      _vm._v("上個禮拜你每日平均生果蔬菜攝取量為"),
                      _c("span", { staticClass: "red b" }, [
                        _vm._v(_vm._s(_vm.sumVg) + "份"),
                      ]),
                      _vm._v("。"),
                    ]),
                    _c("p", [
                      _vm._v("結果表明，你仲"),
                      _c("span", { staticClass: "red b" }, [_vm._v("未達到")]),
                      _vm._v(
                        "WHO推薦的生果蔬菜攝入標準。但呢個情況十分常見，對於大多數長者來講，一開始會比較難。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "通過加入“食”全十美，行大“運”網站，你已經踏出咗重要嘅第一步，要相信自己一定會達到標準，加油！"
                      ),
                    ]),
                    _c("img", {
                      staticClass: "fw8",
                      attrs: {
                        src: require("../../assets/img/di_record_feedback2.jpg"),
                      },
                    }),
                  ]
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.page == 3
      ? _c("div", { staticClass: "box" }, [
          _vm.debugbtn
            ? _c("div", [
                _c("span", [_vm._v("頁面反饋調試：")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.feedback_vg,
                      expression: "feedback_vg",
                    },
                  ],
                  domProps: { value: _vm.feedback_vg },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.feedback_vg = $event.target.value
                    },
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback_vg == 1
            ? _c("div", [
                _c("p", [
                  _vm._v("上個禮拜你每日平均生果蔬菜攝取量為"),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.sumVg) + "份"),
                  ]),
                  _vm._v("。"),
                ]),
                _c("p", [
                  _vm._v("結果表明，你已經"),
                  _c("span", { staticClass: "blue b" }, [_vm._v("達到")]),
                  _vm._v("咗WHO推薦的生果蔬菜攝入標準。非常好！"),
                ]),
                _c("p", [_vm._v("要有信心一定可以長期保持呢個標準。")]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖1",
                    src: require("../../assets/img/di_record_feedback1.jpg"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback_vg == 2
            ? _c("div", [
                _c("p", [
                  _vm._v("上個禮拜你每日平均生果蔬菜攝取量為"),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.sumVg) + "份"),
                  ]),
                  _vm._v("。"),
                ]),
                _c("p", [
                  _vm._v("結果表明，你仲"),
                  _c("span", { staticClass: "red b" }, [_vm._v("未達到")]),
                  _vm._v(
                    "WHO推薦的生果蔬菜攝入標準。但呢個情況十分常見，對於大多數長者來講，一開始會比較難。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "通過加入金色養生堂網站，你已經踏出咗重要嘅第一步，要相信自己一定會達到標準，加油！"
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖2",
                    src: require("../../assets/img/di_record_feedback2.jpg"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback_vg == 3
            ? _c("div", [
                _c("p", [
                  _vm._v(" 上個禮拜你每日平均生果蔬菜攝取量為"),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.sumVg) + "份"),
                  ]),
                  _vm._v("。 "),
                ]),
                _c("p", [
                  _vm._v(" 你嘅生果蔬菜攝取量較兩星期前有所"),
                  _c("span", { staticClass: "bu" }, [_vm._v("進步")]),
                  _vm._v("！而且你的生果蔬菜攝取量已經"),
                  _c("span", { staticClass: "blue b" }, [_vm._v("達到")]),
                  _vm._v(
                    "WHO推薦嘅生果蔬菜攝取量標準，非常好！喺跟住落黎嘅活動中，你要相信自己可以繼續保持咁樣嘅健康飲食，繼續加油！ "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖3",
                    src: require("../../assets/img/pa_feedback3.png"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback_vg == 4
            ? _c("div", [
                _c("p", [
                  _vm._v(" 上個禮拜你每日平均生果蔬菜攝取量為"),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.sumVg) + "份"),
                  ]),
                  _vm._v("。 "),
                ]),
                _c("p", [
                  _vm._v(" 你的生果蔬菜攝取量較兩星期前有所"),
                  _c("span", { staticClass: "bu" }, [_vm._v("進步")]),
                  _vm._v("！不過，你仲"),
                  _c("span", { staticClass: "red b" }, [_vm._v("未達到")]),
                  _vm._v(
                    "WHO推薦嘅生果蔬菜攝取量標準。唔緊要！形成健康的飲食習慣一開始都喺有難度架。你要相信自己，通過參加之後嘅單元學習活動，一定可以不斷進步，最終必定能達到健康飲食標準，加油！ "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖4",
                    src: require("../../assets/img/pa_feedback4.png"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback_vg == 5
            ? _c("div", [
                _c("p", [
                  _vm._v(" 上個禮拜你每日平均生果蔬菜攝取量為"),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.sumVg) + "份"),
                  ]),
                  _vm._v("。 "),
                ]),
                _c("p", [
                  _vm._v(" 你嘅生果蔬菜攝取量較兩星期前"),
                  _c("span", { staticClass: "bu" }, [_vm._v("冇變化")]),
                  _vm._v("。不過，你嘅生果蔬菜攝取量已經"),
                  _c("span", { staticClass: "blue b" }, [_vm._v("達到")]),
                  _vm._v("咗WHO推薦嘅標準。非常好！ "),
                ]),
                _c("p", [
                  _vm._v(
                    " 喺跟住落黎嘅活動中，你要相信自己可以繼續保持咁樣嘅健康飲食，繼續加油！ "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖5",
                    src: require("../../assets/img/pa_feedback5.png"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback_vg == 6
            ? _c("div", [
                _c("p", [
                  _vm._v(" 上個禮拜你每日平均生果蔬菜攝取量為"),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.sumVg) + "份"),
                  ]),
                  _vm._v("。 "),
                ]),
                _c("p", [
                  _vm._v(" 你嘅生果蔬菜攝取量較兩星期前"),
                  _c("span", { staticClass: "bu" }, [_vm._v("冇變化")]),
                  _vm._v("。你嘅生果蔬菜攝取量仲"),
                  _c("span", { staticClass: "red b" }, [_vm._v("未達到")]),
                  _vm._v("WHO推薦嘅標準。 "),
                ]),
                _c("p", [
                  _vm._v(
                    " 不過唔緊要！形成健康嘅飲食習慣一開頭都喺有難度嘅。你要相信自己，通過參加跟住落黎嘅單元學習活動，一定可以不斷進步，最終必定能達到健康飲食標準，加油！ "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖6",
                    src: require("../../assets/img/pa_feedback6.png"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback_vg == 7
            ? _c("div", [
                _c("p", [
                  _vm._v(" 上個禮拜你每日平均生果蔬菜攝取量為"),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.sumVg) + "份"),
                  ]),
                  _vm._v("。 "),
                ]),
                _c("p", [
                  _vm._v(" 你的生果蔬菜攝取量較兩星期前有所"),
                  _c("span", { staticClass: "bu" }, [_vm._v("退步")]),
                  _vm._v("！不過，你已經"),
                  _c("span", { staticClass: "blue b" }, [_vm._v("達到")]),
                  _vm._v(
                    "WHO推薦的生果蔬菜攝取量標準。在接下來的活動中，你要相信自己可以繼續保持這樣的健康飲食，繼續加油！ "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖7",
                    src: require("../../assets/img/pa_feedback7.png"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback_vg == 8
            ? _c("div", [
                _c("p", [
                  _vm._v(" 上個禮拜你每日平均生果蔬菜攝取量為"),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.sumVg) + "份"),
                  ]),
                  _vm._v("。 "),
                ]),
                _c("p", [
                  _vm._v(" 你嘅生果蔬菜攝取量較兩星期前有所"),
                  _c("span", { staticClass: "bu" }, [_vm._v("退步")]),
                  _vm._v("！你仲"),
                  _c("span", { staticClass: "red b" }, [_vm._v("未達到")]),
                  _vm._v("WHO推薦的生果蔬菜攝取量標準。 "),
                ]),
                _c("p", [
                  _vm._v(
                    " 不過唔緊要！形成健康嘅飲食習慣一開頭都喺有難度嘅。你要相信自己，通過參加跟住落黎嘅單元學習活動，一定可以不斷進步，最終必定能達到健康飲食標準，加油！ "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖8",
                    src: require("../../assets/img/pa_feedback8.png"),
                  },
                }),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.page == 4
      ? _c(
          "div",
          { staticClass: "box" },
          [
            _c(
              "div",
              { staticClass: "title", staticStyle: { "font-size": "20px" } },
              [_vm._v("過去一星期嘅肉、蛋及替代品攝入情況")]
            ),
            _c(
              "p",
              { staticClass: "b", staticStyle: { "font-size": "20px" } },
              [
                _vm._v(
                  " 下面請你回憶一下喺啱啱過去嘅7日，你食肉、蛋及替代品嘅情況？ "
                ),
              ]
            ),
            _c(
              "cube-button",
              { attrs: { primary: true }, on: { click: _vm.learnMeat } },
              [_vm._v(" 學習點樣衡量肉、蛋及替代品")]
            ),
            _c(
              "p",
              { staticClass: "select_line" },
              [
                _vm._v(" 過去7日，我記錄了 "),
                _c("cube-select", {
                  staticClass: "inline_select",
                  attrs: { options: _vm.weekDay },
                  model: {
                    value: _vm.record.meat_week_day,
                    callback: function ($$v) {
                      _vm.$set(_vm.record, "meat_week_day", $$v)
                    },
                    expression: "record.meat_week_day",
                  },
                }),
                _vm._v(" 日的飲食,"),
                _c("span", { staticClass: "b" }, [_vm._v("總共食過")]),
                _vm._v("："),
                _c("br"),
                _vm._v(" "),
                _c("br"),
                _c("cube-input", {
                  ref: "inputm",
                  staticClass: "other_input",
                  attrs: { type: "number" },
                  on: { change: _vm.inputLimit, input: _vm.inputLimit },
                  model: {
                    value: _vm.record.meat_day,
                    callback: function ($$v) {
                      _vm.$set(_vm.record, "meat_day", $$v)
                    },
                    expression: "record.meat_day",
                  },
                }),
                _vm._v("兩肉、蛋及替代品。 "),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.cmpMeat && _vm.num == 1,
                    expression: "cmpMeat && num == 1",
                  },
                ],
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          (_vm.sumMeat >= 5 && _vm.sumMeat <= 6) ||
                          _vm.debugbtn,
                        expression: "(sumMeat>=5 && sumMeat<=6) || debugbtn",
                      },
                    ],
                  },
                  [
                    _c("p", [
                      _vm._v("過去嘅7日，你嘅肉、蛋及替代品攝取量為"),
                      _c("span", { staticClass: "red b" }, [
                        _vm._v(_vm._s(_vm.sumMeat) + "兩"),
                      ]),
                      _vm._v("。"),
                    ]),
                    _c("p", [
                      _vm._v("結果表明，你已經"),
                      _c("span", { staticClass: "blue b" }, [_vm._v("達到")]),
                      _vm._v("咗WHO推薦嘅標準。非常好！"),
                    ]),
                    _c("p", [_vm._v("要有信心一定可以長期保持呢個標準。")]),
                    _c("img", {
                      staticClass: "fw8",
                      attrs: {
                        src: require("../../assets/img/di_record_feedback1.jpg"),
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.sumMeat < 5 || _vm.sumMeat > 6 || _vm.debugbtn,
                        expression: "(sumMeat<5 || sumMeat>6) || debugbtn",
                      },
                    ],
                  },
                  [
                    _c("p", [
                      _vm._v("過去嘅7日，你的肉、蛋及替代品攝取量為"),
                      _c("span", { staticClass: "red b" }, [
                        _vm._v(_vm._s(_vm.sumMeat) + "兩"),
                      ]),
                      _vm._v("。"),
                    ]),
                    _c("p", [
                      _vm._v("結果表明，你仲"),
                      _c("span", { staticClass: "red b" }, [_vm._v("未達到")]),
                      _vm._v(
                        "咗WHO推薦嘅標準。但呢個情況十分常見，對於大多數長者黎講，一開頭會比較難。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "通過加入“食”全十美，行大“運”網站，你已經踏出咗重要嘅第一步，要相信自己一定會達到標準，加油！"
                      ),
                    ]),
                    _c("img", {
                      staticClass: "fw8",
                      attrs: {
                        src: require("../../assets/img/di_record_feedback2.jpg"),
                      },
                    }),
                  ]
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.page == 5
      ? _c("div", { staticClass: "box" }, [
          _vm.debugbtn
            ? _c("div", [
                _c("span", [_vm._v("頁面反饋調試：")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.feedback_meat,
                      expression: "feedback_meat",
                    },
                  ],
                  domProps: { value: _vm.feedback_meat },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.feedback_meat = $event.target.value
                    },
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback_vg < 3
            ? _c("div", [_vm._v(" 反饋編號從3開始 ")])
            : _vm._e(),
          _vm.feedback_meat == 3
            ? _c("div", [
                _c("p", [
                  _vm._v(" 上個禮拜你每日平均嘅肉、蛋及替代品攝取量為"),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.sumMeat) + "兩"),
                  ]),
                  _vm._v("。 "),
                ]),
                _c("p", [
                  _vm._v(" 你嘅肉、蛋及替代品攝取量較兩星期前有所"),
                  _c("span", { staticClass: "bu" }, [_vm._v("增加")]),
                  _vm._v("！而且你喺呢類食物嘅攝取量"),
                  _c("span", { staticClass: "blue b" }, [_vm._v("達到")]),
                  _vm._v("咗WHO推薦嘅標準，非常好！ "),
                ]),
                _c("p", [
                  _vm._v(
                    " 喺之後嘅活動中，你要相信自己可以繼續保持咁樣嘅健康飲食，繼續加油！ "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖3",
                    src: require("../../assets/img/pa_feedback3.png"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback_meat == 4
            ? _c("div", [
                _c("p", [
                  _vm._v(" 上個禮拜你每日平均嘅肉、蛋及替代品攝取量為"),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.sumMeat) + "兩"),
                  ]),
                  _vm._v("。 "),
                ]),
                _c("p", [
                  _vm._v(" 你嘅肉、蛋及替代品攝取量較兩星期前有所"),
                  _c("span", { staticClass: "bu" }, [_vm._v("增加")]),
                  _vm._v("！你喺呢類食物嘅攝取量仲"),
                  _c("span", { staticClass: "red b" }, [_vm._v("未達到")]),
                  _vm._v("WHO推薦的標準。 "),
                ]),
                _c("p", [
                  _vm._v(
                    " 不過唔緊要！形成健康嘅飲食習慣一開頭都喺有難度嘅。你要相信自己，通過參加跟住落黎嘅單元學習活動，一定可以不斷進步，最終必定能達到健康飲食標準，加油！ "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖4",
                    src: require("../../assets/img/pa_feedback4.png"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback_meat == 5
            ? _c("div", [
                _c("p", [
                  _vm._v(" 上個禮拜你每日平均嘅肉、蛋及替代品攝取量為"),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.sumMeat) + "兩"),
                  ]),
                  _vm._v("。 "),
                ]),
                _c("p", [
                  _vm._v(" 你嘅肉、蛋及替代品攝取量較兩星期前"),
                  _c("span", { staticClass: "bu" }, [_vm._v("冇變化")]),
                  _vm._v("。你喺呢類食物嘅攝取量已經"),
                  _c("span", { staticClass: "blue b" }, [_vm._v("達到")]),
                  _vm._v("咗WHO推薦嘅標準，非常好。 "),
                ]),
                _c("p", [
                  _vm._v(
                    " 喺之後嘅活動中，你要相信自己可以繼續保持咁樣嘅健康飲食，繼續加油！ "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖5",
                    src: require("../../assets/img/pa_feedback5.png"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback_meat == 6
            ? _c("div", [
                _c("p", [
                  _vm._v(" 上個禮拜你每日平均嘅肉、蛋及替代品攝取量為"),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.sumMeat) + "兩"),
                  ]),
                  _vm._v("。 "),
                ]),
                _c("p", [
                  _vm._v(" 你嘅肉、蛋及替代品攝取量較兩星期前"),
                  _c("span", { staticClass: "bu" }, [_vm._v("冇變化")]),
                  _vm._v("。你喺呢類食物嘅攝取量仲"),
                  _c("span", { staticClass: "red b" }, [_vm._v("未達到")]),
                  _vm._v(
                    "WHO推薦的標準，不過唔緊要！形成健康嘅飲食習慣一開頭都喺有難度嘅。 "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " 你要相信自己，通過參加跟住落黎嘅單元學習活動，一定可以不斷進步，最終必定能達到健康飲食標準，加油！ "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖6",
                    src: require("../../assets/img/pa_feedback6.png"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback_meat == 7
            ? _c("div", [
                _c("p", [
                  _vm._v(" 上個禮拜你每日平均嘅肉、蛋及替代品攝取量為"),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.sumMeat) + "兩"),
                  ]),
                  _vm._v("。 "),
                ]),
                _c("p", [
                  _vm._v(" 你嘅肉、蛋及替代品攝取量較兩星期前有所"),
                  _c("span", { staticClass: "bu" }, [_vm._v("減少")]),
                  _vm._v("！不過，你喺呢類食物嘅攝取量已經"),
                  _c("span", { staticClass: "blue b" }, [_vm._v("達到")]),
                  _vm._v("咗WHO推薦嘅標準，繼續保持吧！ "),
                ]),
                _c("p", [
                  _vm._v(
                    " 喺之後嘅活動中，你要相信自己可以繼續保持咁樣嘅健康飲食，繼續加油！ "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖7",
                    src: require("../../assets/img/pa_feedback7.png"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.feedback_meat == 8
            ? _c("div", [
                _c("p", [
                  _vm._v(" 上個禮拜你每日平均嘅肉、蛋及替代品攝取量為"),
                  _c("span", { staticClass: "red b" }, [
                    _vm._v(_vm._s(_vm.sumMeat) + "兩"),
                  ]),
                  _vm._v("。 "),
                ]),
                _c("p", [
                  _vm._v(" 你嘅肉、蛋及替代品攝取量較兩星期前有所"),
                  _c("span", { staticClass: "bu" }, [_vm._v("減少")]),
                  _vm._v("！你在此類食物的攝取量仲"),
                  _c("span", { staticClass: "red b" }, [_vm._v("未達到")]),
                  _vm._v("WHO推薦的標準。 "),
                ]),
                _c("p", [
                  _vm._v(
                    " 不過唔緊要！形成健康嘅飲食習慣一開頭都喺有難度嘅。你要相信自己，通過參加跟住落黎嘅單元學習活動，一定可以不斷進步，最終必定能達到健康飲食標準，加油！ "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "反饋圖8",
                    src: require("../../assets/img/pa_feedback8.png"),
                  },
                }),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.page == 6
      ? _c("div", { staticClass: "box" }, [
          _vm.debugbtn
            ? _c("div", [
                _c("span", [_vm._v("事例分享調試：")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.encourage,
                      expression: "encourage",
                    },
                  ],
                  domProps: { value: _vm.encourage },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.encourage = $event.target.value
                    },
                  },
                }),
              ])
            : _vm._e(),
          _c("div", { staticClass: "title" }, [_vm._v("成功事例分享")]),
          _vm.encourage == 1
            ? _c("div", [
                _c("audio", {
                  staticClass: "gaudio",
                  attrs: {
                    controls: "",
                    controlsList: "nodownload",
                    preload: "auto",
                    src: require("@/assets/guide/diet/Week_2_ASE.mp3"),
                  },
                }),
                _c("p", [
                  _vm._v(
                    " 下面為大家分享一位陳伯通過進行健康飲食成功對抗高血壓的故事。陳伯有很多年的高血壓疾病史。有時候發起病，嚴重到需要住院治療。醫生建議他，除了按時服藥，在生活當中健康飲食也很重要。例如，多吃菠菜、芹菜、番茄、蘿蔔、火龍果、香蕉這些有利於心血管健康的生果蔬菜，同時吃適量的雞肉、魚類及蝦、牛肉以及瘦豬肉幫助控制血壓。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "陳伯説一開始其實很難，不能完全兼顧到各個飲食方面的要求，但是他對自己有信心，並且通過一點點的進步，最終完全做到醫生推薦的飲食標準。通過一年多的健康飲食配合藥物治療，陳伯的血壓也控制得很好，沒有再住過醫院。 2021年年初去醫院檢查，發現血壓各項指標也都正常。陳伯説，為了保持生活質素，他會一直堅持健康飲食的。 "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "圖表佔位",
                    src: require("../../assets/img/di_record_story2.jpg"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.encourage == 2
            ? _c("div", [
                _c("audio", {
                  staticClass: "gaudio",
                  attrs: {
                    controls: "",
                    controlsList: "nodownload",
                    preload: "auto",
                    src: require("@/assets/guide/diet/Week_3_ASE.mp3"),
                  },
                }),
                _c("p", [
                  _vm._v(
                    " 下面為大家分享黃女士的事例。黃女士現年70歲，精力充沛，皮膚紅潤光潔，身材苗條，看上去比同齡人年輕很多。這主要是她多年以來的健康飲食養生。黃女士每天都要吃一條香蕉和橙，午餐和晚餐都必須吃一種綠色蔬菜（如青椒、通菜，芥蘭）。黃女士每年的血液體檢報告都沒有三高。除了多吃生果蔬菜，黃女士也很注重飲食中蛋白質的攝入。她每天都會吃一個水煮雞蛋，並吃適量的魚和肉（豬肉，雞肉）。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "黃女士特別喜歡吃深海魚。因為有很好的抗氧化功能，有助於延緩衰老，也能提高長者的免疫力。黃女士説一開始自己也不是特別愛吃生果蔬菜，每天堅持吃五份的生果蔬菜是很難，但是自己很有信心，並且逐步做到了。黃女士表示，她會一直堅持這種健康飲食，享受健康快樂的人生。 "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "圖表佔位",
                    src: require("../../assets/img/di_record_story3.jpg"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.encourage == 3
            ? _c("div", [
                _c("audio", {
                  staticClass: "gaudio",
                  attrs: {
                    controls: "",
                    controlsList: "nodownload",
                    preload: "auto",
                    src: require("@/assets/guide/diet/Week_4_ASE.mp3"),
                  },
                }),
                _c("p", [
                  _vm._v(
                    " 下面為大家分享趙女士（65歲）和家人的事例。趙女士退休之後經常參加社區的健康飲食講座活動，她十分了解健康飲食的重要性。趙女士表示她通常早上會吃生果和蔬菜，中午還會加一些肉類補充蛋白質，晚上會吃綠色蔬菜或者素餃子。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " 趙女士説她老伴在一年前查出有三高，醫生建議飲食要清淡一些。一開始很不習慣新的飲食方式，經常忍不住想多吃肉不想吃蔬菜，趙女士就不斷鼓勵他，慢慢調整。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "趙女士和老伴堅持健康飲食快一年，他的血壓和血脂指標都有好轉。趙女士説自從飲食改變後，感覺整個人都非常輕鬆。不像以前吃很多肉，很油膩的感覺，非常好。 "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "圖表佔位",
                    src: require("../../assets/img/di_record_story4.jpg"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.encourage == 4
            ? _c("div", [
                _c("audio", {
                  staticClass: "gaudio",
                  attrs: {
                    controls: "",
                    controlsList: "nodownload",
                    preload: "auto",
                    src: require("@/assets/guide/diet/Week_5_MSE.mp3"),
                  },
                }),
                _c("p", [
                  _vm._v(
                    " 下面為大家分享王先生（68歲）的事例。王先生退休之前，在一家公司做管理人員，經常和同事一起吃外賣，或者去餐廳聚會。由於常在外面吃大油大葷的食物，王先生超重，還有慢性胃潰瘍，需要經常服用胃藥。退休之後，他有更多的時間在家吃飯，於是聽從醫生的建議，開始嘗試調整自己的飲食習慣。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "他盡量蒸、煮蔬菜，吃適量的魚、肉和蛋，保持飲食清淡。除了每天的正餐，還會吃1-2份生果。 堅持了三個月之後，王先生明顯感覺到身體輕鬆了很多，體重也回復正常。去醫院複查時，醫生説劉先生的胃潰瘍病灶面明顯變小，病情有顯著好轉。看到健康飲食帶來的改變，王先生更加有信心堅持下去。 "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "圖表佔位",
                    src: require("../../assets/img/di_record_story5.jpg"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.encourage == 5
            ? _c("div", [
                _c("audio", {
                  staticClass: "gaudio",
                  attrs: {
                    controls: "",
                    controlsList: "nodownload",
                    preload: "auto",
                    src: require("@/assets/guide/diet/Week_6_MSE.mp3"),
                  },
                }),
                _c("p", [
                  _vm._v(
                    " 下面為大家分享陳女士（70歲）的事例。陳女士在三年前被診斷出患有2型糖尿病，醫生給陳女士制定了飲食治療方案。醫生建議她每天吃兩掌心份量的肉，包括牛、豬、雞、鴨、魚等各種肉類，每天至少吃兩隻手能抓住的蔬菜量，特別是綠葉蔬菜，包括椰菜、芹菜、油菜、菠菜等。生果不宜過量食用。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "陳女士一直謹遵醫囑，嚴格執行醫生給予的飲食治療方案，堅持了3個月後，去醫院複查，醫生表示她的血糖水平控制得很好，並建議她一直實行這個飲食方案。如今陳女士堅持健康飲食已經兩年多了，深刻感受到健康飲食的好處。陳女士表示自己有信心會繼續堅持下去。 "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "圖表佔位",
                    src: require("../../assets/img/di_record_story6.jpg"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.encourage == 6
            ? _c("div", [
                _c("audio", {
                  staticClass: "gaudio",
                  attrs: {
                    controls: "",
                    controlsList: "nodownload",
                    preload: "auto",
                    src: require("@/assets/guide/diet/Week_7_MSE.mp3"),
                  },
                }),
                _c("p", [
                  _vm._v(
                    " 下面為大家分享楊先生（65歲）的事例。楊先生一年前感覺胃不太舒服，去醫院做了胃鏡檢查，結果發現患有慢性胃炎。醫生建議應以清淡飲食為主，減少食用刺激性食物，包括過油、過辣、和過燙的食物。同時忌食高纖蔬果（竹筍、芹菜、鳳梨），過甜食物（香蕉、榴槤、龍眼、紅豆湯），高渣堅硬食物（腰果、核桃）以及刺激性飲品（濃茶、咖啡、酒類、巧克力、可樂）。而烹調方式也要少油少醬，減少胃部的負擔。同時飲食還要少食多餐，細嚼慢嚥。楊先生聽從了醫生的建議，改善了飲食習慣。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " 當問到很多人覺得保持健康的飲食習慣十分困難時，楊先生表示最重要的是要樹立自己改變飲食習慣的信心，為了健康，相信自己一定能夠堅持下去。楊先生説長期保持健康飲食，感覺胃功能增強了，慢性胃炎的情況也得到好轉。自己會一直堅持健康飲食的。 "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "圖表佔位",
                    src: require("../../assets/img/di_record_story7.jpg"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.encourage == 7
            ? _c("div", [
                _c("audio", {
                  staticClass: "gaudio",
                  attrs: {
                    controls: "",
                    controlsList: "nodownload",
                    preload: "auto",
                    src: require("@/assets/guide/diet/Week_8_RSE.mp3"),
                  },
                }),
                _c("p", [
                  _vm._v(
                    " 下面為大家分享周女士（67歲）的事例。周女士在退休前，經常感覺胸悶、氣促並大量出汗，後來去醫院確診為冠心病。除了藥物治療外，醫生建議她要保持均衡飲食，多吃低脂高纖食物，每天吃五份蔬果，保證每星期吃兩份魚。同時減少進食含大量脂肪的食物如豬腩肉、肥牛肉、雞腳，以免增加血膽固醇積聚在血管壁，使血管硬化和受到阻塞。此外，醫生説肥胖會增加心肺負荷，周女士還需要控制體重。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " 周女士一直按照醫生的建議進行健康飲食。她提到在堅持的過程中，自己也遇到過困難。例如，有時心情不好就不想吃生果蔬菜或者清淡的飲食。曾經有一次健康飲食中斷了大半個月。後來想到自己有冠心病，如果再不堅持健康飲食，可能會加重病情。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "因此周女士努力調整心態，並不斷告訴自己一定能夠重新開始做到健康飲食。經過自我調節和管理，周女士重新開始了健康飲食，並且一直堅持到現在。經過半年多的藥物治療和健康飲食，周女士的冠心病症狀已明顯減輕，體重一直保持在正常範圍內。周女士説自己會一直堅持健康飲食的。 "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "圖表佔位",
                    src: require("../../assets/img/di_record_story8.jpg"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.encourage == 8
            ? _c("div", [
                _c("audio", {
                  staticClass: "gaudio",
                  attrs: {
                    controls: "",
                    controlsList: "nodownload",
                    preload: "auto",
                    src: require("@/assets/guide/diet/Week_9_RSE.mp3"),
                  },
                }),
                _c("p", [
                  _vm._v(
                    " 下面為大家分享歐先生（70歲）的事例。歐先生患痛風已經十多年，經過藥物治療，病情時好時壞。醫生説痛風不能根治。想防止痛風的復發，除了適當的藥物治療，飲食習慣也很重要。例如，應避免吃動物內臟，避免吃魚卵、貝殼類的海產，而且不能吃過量的肉類、家禽類和魚類以及過量的冬菇、椰菜花、菠菜等蔬菜。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "醫生建議歐先生多吃蔬菜蛋白：包括豆類、豆腐，以及不加鹽的堅果和種籽，食用小量的肉和海鮮，每天吃5份蔬菜生果。歐先生表示自己一直都很喜歡吃動物內臟（豬肝，雞肝）和貝殼類海產（帶子，青口），平常也不太愛吃蔬菜生果。想要改變自己多年的飲食習慣並不容易，有時候出去聚會還是忍不住吃海鮮，無法堅持健康飲食。後來痛風反復發作，非常影響生活質素。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "他就下定決心為了自己的健康，一定要重新開始健康飲食，管住自己的嘴。後來堅持了三個月的健康飲食，痛風發作次數明顯減少。歐先生逐漸樹立了信心。現在他已經能夠堅持每天至少吃5份蔬菜生果，吃適量的肉類，同時吃很多豆腐製品。他還把自己的成功事例分享給了其他的病友，鼓勵他們一起進行健康飲食。 "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "圖表佔位",
                    src: require("../../assets/img/di_record_story9.jpg"),
                  },
                }),
              ])
            : _vm._e(),
          _vm.encourage == 9
            ? _c("div", [
                _c("audio", {
                  staticClass: "gaudio",
                  attrs: {
                    controls: "",
                    controlsList: "nodownload",
                    preload: "auto",
                    src: require("@/assets/guide/diet/Week_10_RSE.mp3"),
                  },
                }),
                _c("p", [
                  _vm._v(
                    " 下面為大家分享何先生（70歲）的事例。由於何先生喜歡吃紅肉（例如：豬肉、牛肉）和動物內臟，退休的時候體檢有高血脂。除了用降脂藥控制血脂，醫生還建議何先生注意健康飲食。應多食深綠色蔬菜和新鮮生果，而且要多吃白肉（例如魚類和海產品）和堅果（例如核桃、南瓜子）。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "還可以多吃一些有降脂降壓作用的食物，如洋葱、木耳、大蒜、芹菜、白蘿蔔等，避免吃肥肉、動物內臟、奶油、和油膩的湯，何先生表示剛開始遵照醫生叮囑，堅持了一個月的健康飲食。之後去醫院複查，發現血脂水平並沒有顯著下降，有些心灰意冷，就放任自己大吃大喝。之後高血脂的病症又開始嚴重起來，頻繁出現心慌，胸悶，氣短的情況。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "醫生提醒何先生除了藥物治療，長期的健康飲食習慣才是高血脂治療後恢復的關鍵。為了健康，何先生還是告訴自己要有信心，於是又重新開始嘗試堅持健康飲食。堅持了4個月後再去複查，醫生告訴何先生他的血脂水平已經下降到正常水平，這讓何先生更加有信心堅持健康飲食了！他還把自己的成功事例分享給了其他老友記，鼓勵他們一起進行健康飲食。 "
                  ),
                ]),
                _c("img", {
                  staticClass: "fw8",
                  attrs: {
                    alt: "圖表佔位",
                    src: require("../../assets/img/di_record_story10.png"),
                  },
                }),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.page == 7
      ? _c("div", { staticClass: "box" }, [
          _c("p", { staticClass: "green bu" }, [_vm._v("“一份蔬菜” 約等於：")]),
          _c("p", [_vm._v("1碗未經烹煮嘅蔬菜（例如：生菜、紫椰菜）")]),
          _c("img", {
            attrs: { src: require("../../assets/img/di_record_food1.jpg") },
          }),
          _c("p", [_vm._v("半碗煮熟嘅蔬菜、芽菜、瓜類、豆類或菇菌。")]),
          _c("img", {
            attrs: { src: require("../../assets/img/di_record_food2.png") },
          }),
          _c("p", { staticClass: "green bu" }, [_vm._v("“一份生果” 約等於：")]),
          _c("p", [_vm._v("2個小型生果（如：奇異果，杏子）")]),
          _c("img", {
            attrs: { src: require("../../assets/img/di_record_food3.jpg") },
          }),
          _c("p", [_vm._v("1個中型生果（如：蘋果，芒果）, 如網球大小")]),
          _c("img", {
            attrs: { src: require("../../assets/img/di_record_food4.png") },
          }),
          _c("p", [_vm._v("半個大型生果（如：香蕉，西柚，火龍果）")]),
          _c("img", {
            attrs: { src: require("../../assets/img/di_record_food5.png") },
          }),
          _c("p", [_vm._v("半碗生果粒（如西瓜、車厘子、提子）")]),
          _c("img", {
            attrs: { src: require("../../assets/img/di_record_food6.png") },
          }),
          _c("p", [_vm._v("一湯匙冇添加鹽同糖嘅果乾")]),
          _c("img", {
            attrs: { src: require("../../assets/img/di_record_food7.jpg") },
          }),
          _c("p", [_vm._v("四分三杯冇加糖嘅新鮮蔬果汁")]),
          _c("img", {
            attrs: { src: require("../../assets/img/di_record_food8.jpg") },
          }),
          _c("p", { staticClass: "bu" }, [
            _vm._v("註：1杯=240毫升；1碗=250-300毫升；一湯匙=15毫升"),
          ]),
        ])
      : _vm._e(),
    _vm.page == 8
      ? _c("div", { staticClass: "box" }, [
          _c("p", { staticClass: "red bu" }, [_vm._v("“一兩肉”約等於：")]),
          _c("p", [_vm._v("1個乒乓球/麻雀牌大小嘅肉類")]),
          _c("img", {
            attrs: { src: require("../../assets/img/di_record_food9.png") },
          }),
          _c("p", [_vm._v("1隻雞蛋")]),
          _c("img", {
            attrs: { src: require("../../assets/img/di_record_food10.png") },
          }),
          _c("p", [_vm._v("1磚布包豆腐")]),
          _c("img", {
            attrs: { src: require("../../assets/img/di_record_food11.jpg") },
          }),
          _c("p", [_vm._v("6至8湯匙煮熟嘅乾豆類")]),
          _c("img", {
            attrs: { src: require("../../assets/img/di_record_food12.jpg") },
          }),
          _c("p", [_vm._v("40克 (一手掌) 乾焗原味嘅果仁")]),
          _c("img", {
            attrs: { src: require("../../assets/img/di_record_food13.png") },
          }),
          _c("p", { staticClass: "bu" }, [
            _vm._v("註：一湯匙=15毫升；一兩約等於40克(生肉)"),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }