var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container flexcolumn" }, [
    _c(
      "div",
      { staticClass: "top btngroup" },
      [
        _c("cube-button", { staticClass: "topbtn", on: { click: _vm.back } }, [
          _vm._v(" 返回主頁"),
        ]),
        _c(
          "cube-button",
          {
            staticClass: "topbtn",
            attrs: { disabled: _vm.gap },
            on: { click: _vm.next },
          },
          [_vm._v(" 繼續")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "middlebox" },
      [_c(_vm.selectName, { ref: "active", tag: "component" })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }