<template>
  <wrapper class="root">
    <div v-if="debugbtn">
      <span>頁面編號調試：</span>
      <input v-model="page" />
    </div>
    <div v-if="page == 1" class="box">
      <p>
        世界衞生組織（WHO，2010）建議：為咗促進健康，長者應該
        <span class="bu"
          >每日進行中等強度或以上嘅運動至少30分鐘，每星期至少5次，加埋150分鐘，</span
        >
        運動到覺得
        <span class="bu">會喘氣流汗</span>。
      </p>
      <br />
      <img class="fw8" alt="運動i健康" src="../../assets/img/welcome2.png" />
    </div>
    <div v-if="page == 2" class="box">
      <div class="title">過去一星期嘅運動鍛煉情況</div>
      <br />
      <p>
        我哋希望通過以下嘅問題了解你喺過去7日，有幾大程度經常做劇烈運動、中等強度運動同步行。請你回憶你所做過每次
        <span class="bu">至少10分鐘</span>
        嘅運動。
      </p>
      <br />

      <p>
        1.<span class="b red">劇烈運動</span
        >（運動後你嘅呼吸會較平時快好多並且會大量出汗）。例如: 快速跑,
        跳健康舞，游水，打羽毛球，快速踩單車等。
      </p>

      <cube-checkbox
        class="inline_checkbox font_large"
        v-model="record.extreme_none"
      >
        沒有
      </cube-checkbox>

      <p class="select_line" v-if="!record.extreme_none">
        一星期<cube-select
          class="inline_select"
          v-model="record.extreme_day"
          :options="weekdays"
        ></cube-select
        >天
      </p>

      <p
        class="select_line"
        v-if="!record.extreme_none && record.extreme_day > 0"
      >
        一日<cube-select
          class="inline_select"
          v-model="record.extreme_hour"
          :options="dayhours"
        ></cube-select
        >小時<cube-select
          class="inline_select"
          v-model="record.extreme_minute"
          :options="hourminutes"
        ></cube-select
        >分鐘
      </p>

      <p>
        2.<span class="b red">中等強度嘅運動</span
        >（運動後你嘅呼吸會較平時快啲而且會輕微出汗）。例如：快步走，打太極拳等。
      </p>

      <cube-checkbox
        class="inline_checkbox font_large"
        v-model="record.normal_none"
      >
        沒有
      </cube-checkbox>
      <p class="select_line" v-if="!record.normal_none">
        一星期<cube-select
          class="inline_select"
          v-model="record.normal_day"
          :options="weekdays"
        ></cube-select
        >日
      </p>
      <p
        class="select_line"
        v-if="!record.normal_none && record.normal_day > 0"
      >
        一日<cube-select
          class="inline_select"
          v-model="record.normal_hour"
          :options="dayhours"
        ></cube-select
        >小時<cube-select
          class="inline_select"
          v-model="record.normal_minute"
          :options="hourminutes"
        ></cube-select
        >分鐘
      </p>

      <p>
        3. <span class="b red">行路</span>
        （你嘅呼吸較平時冇變化而且冇出汗）。例如行路往返屋企同街市，日常休閒嘅散步。
      </p>

      <cube-checkbox
        class="inline_checkbox font_large"
        v-model="record.walk_none"
      >
        沒有
      </cube-checkbox>
      <p class="select_line" v-if="!record.walk_none">
        一星期<cube-select
          class="inline_select"
          v-model="record.walk_day"
          :options="weekdays"
        ></cube-select
        >日
      </p>
      <p class="select_line" v-if="!record.walk_none && record.walk_day > 0">
        一天<cube-select
          class="inline_select"
          v-model="record.walk_hour"
          :options="dayhours"
        ></cube-select
        >小時<cube-select
          class="inline_select"
          v-model="record.walk_minute"
          :options="hourminutes"
        ></cube-select
        >分鐘
      </p>

      <!-- <div>{{totalminute}}</div> -->
    </div>
    <div v-if="page == 3" class="box">
      <div v-if="debugbtn">
        <span>頁面反饋調試：</span>
        <input v-model="feedback" />
      </div>
      <div class="title" ref="chartt">過去一星期嘅運動鍛煉情況</div>
      <echart
        ref="chart"
        style="
           {
            min-width: 300px;
            min-height: 300px;
          }
        "
        :option="chartOption"
      ></echart>
      <p>
        WHO推薦嘅運動鍛煉標準係，每星期進行中等強度或以上嘅運動至少五日，每日至少30分鐘（或每星期加埋至少150分鐘）。
      </p>
      <br />

    </div>
    <div v-if="page == 5" class="box">
          <div v-if="debugbtn">
        <span>頁面反饋調試：</span>
        <input v-model="feedback" />
      </div>
      <div class="title" ref="chartt">過去一星期嘅運動鍛煉情況</div>
          <div v-if="feedback == 1">
        <p>
          上個禮拜你進行<span class="bu">中等強度或以上</span>嘅運動時間加埋係：<span class="red b">{{
            totalminute()
          }}分鐘</span>。
        </p>
        <p>結果表明，你<span class="blue b">達到</span>咗WHO推薦嘅運動標準。非常好！</p>
        <p>要有信心一定可以繼續保持落去，<span class="bu">加油！</span></p>
        <img
          alt="反饋圖1"
          class="fw8"
          src="../../assets/img/pa_feedback1.jpg"
        />
      </div>

      <div v-if="feedback == 2">
        <p>
          上個禮拜你進行<span class="bu">中等強度或以上</span>嘅運動時間加埋係：<span class="red b">{{
            totalminute()
          }}分鐘</span>。
        </p>
        <p>
          結果表明，你仲<span class="red b">未達到</span>WHO推薦嘅運動標準。咁樣嘅情況好普遍，因為對於大多數冇運動習慣嘅老人家黎講，啱啱開始參與運動總喺會<span class="bu">有啲難度</span>。
        </p>
        <p>
          通過加入“食”全十美，行大“運”網站，你已經踏出咗重要嘅第一步，要相信自己一定會達到標準，<span class="bu">加油！</span>
        </p>
        <img
          alt="反饋圖2"
          class="fw8"
          src="../../assets/img/pa_feedback2.png"
        />
      </div>

      <div v-if="feedback == 3">
        <p>
          上個禮拜你進行<span class="bu dark_green">中等強度或以上</span
          >嘅運動時間加埋係：<span class="red b">{{ totalminute() }}分鐘。</span>
        </p>
        <p>
          你嘅運動時間較兩星期前有所<span class="bu">進步</span
          >！而且你嘅運動時間已經<span class="blue b">達到</span>WHO推薦嘅運動標準，非常好！喺跟住落黎嘅活動中，你要相信自己可以繼續保持咁樣嘅運動狀態，繼續加油！
        </p>
        <img
          alt="反饋圖3"
          class="fw8"
          src="../../assets/img/pa_feedback3.png"
        />
      </div>
      <div v-if="feedback == 4">
        <p>
          上個禮拜你進行<span class="bu dark_green">中等強度或以上</span
          >嘅運動時間加埋係：<span class="red b">{{ totalminute() }}分鐘</span>。
        </p>
        <p>
          你嘅運動時間較兩星期前有所<span class="bu">進步</span
          >！不過，你仲<span class="red b">未達到</span>WHO推薦的運動標準。唔緊要！進行有規律嘅運動一開頭都喺有難度嘅。你要相信自己，通過參加跟住落黎嘅單元學習活動，一定可以不斷進步，最終達到WHO推薦嘅運動標準，<span class="bu">加油！</span>
        </p>
        <img
          alt="反饋圖4"
          class="fw8"
          src="../../assets/img/pa_feedback4.png"
        />
      </div>

      <div v-if="feedback == 5">
        <p>
          上個禮拜你進行<span class="bu dark_green">中等強度或以上</span
          >嘅運動時間加埋係：<span class="red b">{{ totalminute() }}分鐘</span>。
        </p>
        <p>
          你嘅運動時間較兩星期前<span class="bu">冇變化</span
          >。不過，你嘅運動時間已經<span class="blue b">達到</span>推薦嘅運動標準，非常好！
        </p>
        <p>
          喺跟住落黎嘅活動中，你要相信自己可以繼續保持咁樣嘅運動狀態，繼續<span class="bu">加油</span>！
        </p>
        <img
          alt="反饋圖5"
          class="fw8"
          src="../../assets/img/pa_feedback5.png"
        />
      </div>

      <div v-if="feedback == 6">
        <p>
          上個禮拜你進行<span class="bu dark_green">中等強度或以上</span
          >嘅運動時間加埋係：<span class="red b">{{ totalminute() }}分鐘</span>。
        </p>
        <p>
          你嘅運動時間較兩星期前<span class="bu">冇變化</span
          >。你仲<span class="red b">未達到</span>WHO推薦嘅運動標準。
        </p>
        <p>
          唔緊要！進行有規律嘅運動一開頭都喺有難度嘅。你要相信自己，通過參加跟住落黎嘅單元學習活動，一定可以不斷進步，最終達到WHO推薦嘅運動標準，<span class="bu">加油</span>！
        </p>
        <img
          alt="反饋圖6"
          class="fw8"
          src="../../assets/img/pa_feedback6.png"
        />
      </div>

      <div v-if="feedback == 7">
        <p>
          上個禮拜你進行<span class="bu dark_green">中等強度或以上</span
          >嘅運動時間加埋係：<span class="red b">{{ totalminute() }}分鐘</span>。
        </p>
        <p>
          你嘅運動時間較兩星期前有所<span class="bu">退步</span
          >！不過，你已經<span class="blue b">達到</span>WHO推薦嘅運動標準。繼續保持吧！喺跟住落黎嘅活動中，你要相信自己可以繼續保持咁樣嘅運動狀態，<span class="bu">繼續加油</span>！
        </p>

        <img
          alt="反饋圖7"
          class="fw8"
          src="../../assets/img/pa_feedback7.png"
        />
      </div>

      <div v-if="feedback == 8">
        <p>
          上個禮拜你進行<span class="bu dark_green">中等強度或以上</span
          >嘅運動時間加埋係：<span class="red b">{{ totalminute() }}分鐘。</span>
        </p>
        <p>
          你嘅運動時間較兩星期前有所<span class="bu">退步</span
          >！你仲<span class="red b">未達到</span>WHO推薦嘅運動標準。
        </p>
        <p>
          唔緊要！進行有規律嘅運動一開頭都喺有難度嘅。你要相信自己，通過參加跟住落黎嘅單元學習活動，一定可以不斷進步，最終達到WHO推薦嘅運動標準，<span class="bu">加油</span>！
        </p>
        <img
          alt="反饋圖8"
          class="fw8"
          src="../../assets/img/pa_feedback8.png"
        />
      </div>
    </div>
    <div v-if="page == 4" class="box">
      <div v-if="debugbtn">
        <span>事例分享調試：</span>
        <input v-model="encourage" />
      </div>
      <div class="title">成功事例分享</div>
      <div v-if="encourage == 1">
             <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/pa/Week_2_ASE.mp3" ></audio>

        <p>
          跟住落黎同大家分享郭生嘅故事。郭生已經70歲，現在依然堅持每天跑步20-25公里，他説在60歲的時候馬拉松還跑進了3小時，有些年輕人也比不過他。郭生説他以前有糖尿病併發症，剛出院的時候，走路很吃力也吃不下東西，當時的心情很低落。那時候他57歲還沒退休，他希望自己要活過退休。醫生建議他出院後可以慢慢進行一些體育運動，這對他的體能會有好處。於是郭生在老伴的陪同下，開始健步行，在操場上慢行一圈都相當困難。於是他就搭著老伴的肩，慢慢行。後來一直積極運動，看到血糖一點點降下來了。</p><p>後來郭生要求自己每天都去跑步，剛開始的時候，身體虛，出很多汗，鞋子每天都能倒出水。郭生説，萬事起頭難，但是他對自己一直有信心。就這樣堅持跑步兩年。後來到醫院檢查，發現血糖的各項指標都已恢復正常。郭生很高興。他説他會繼續堅持跑步，爭取用3-4年的時間把糖尿病病情完全控制下來。常年的運動使郭生的生活充滿了活力。
        </p>
        <img
          alt="圖表佔位"
          class="fw8"
          src="../../assets/img/pa_encourage1.jpg"
        />
      </div>
      <div v-if="encourage == 2">
      <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/pa/Week_3_ASE.mp3" ></audio>
        <p>
          跟住落黎同大家分享一位劉女士嘅故事。劉女士已經72歲，堅持鍛煉多年。她每天在公園和同伴們一起做鍛煉，身體特別好，還表示自己能“打得過”年輕人。劉女士説她以前上班工作很忙，還要照顧孫子，去醫院體檢發現了三高。那時醫生建議她可以多做運動。為了健康的身體，劉女士65歲退休後，下定決心進行鍛煉。</p><p>劉女士喜歡去社區健身公園，利用各種健身器材做一些拉伸運動，增強上下肢的力量以及提高平衡性。雖然一開始，劉女士不知道怎麼用這些器材，但是通過看健身器材上標註的使用説明，慢慢就知道如何正確使用了。而且每次鍛煉後，劉女士感覺身體都很放鬆，整個人充滿了活力。通過堅持運動，劉女士已經沒有三高現象了。她説有信心會一直堅持運動下去。
        </p>
        <img
          alt="圖表佔位"
          class="fw8"
          src="../../assets/img/pa_encourage2.jpg"
        />
      </div>
      <div v-if="encourage == 3">
       <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/pa/Week_4_ASE.mp3" ></audio>
       <p>
          跟住落黎同大家分享張伯嘅故事。張伯已經81歲，60歲就開始在健身室鍛煉，如今還能舉重50公斤。張伯曾經是模特兒，在老年模特兒隊十多年。大家都説張伯看起來不到五十歲。他每天運動1.5-2個小時，一星期練五次。</p><p>教練説張伯練習舉重七八年了，訓練的強度和年輕人差不多，非常好。張伯説長者的財富就是身體健康，要健康就一定要進行有規律的長期鍛煉。張伯表示只要身體允許，他會一直堅持健身。
        </p>
        <img
          alt="圖表佔位"
          class="fw8"
          src="../../assets/img/pa_encourage3.jpg"
        />
      </div>
      <div v-if="encourage == 4">
          <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/pa/Week_5_MSE.mp3" ></audio>
    <p>
          跟住落黎同大家分享67歲健身達人鄭先生嘅事例。在退休前，鄭生一直忙於工作，很少做運動。他體態偏胖，總是感覺累。去醫院檢查身體，沒發現有什麼問題。2014年，在朋友的影響下，鄭生開始健身，一練就是7年。每天下午鄭生會在健身房進行2個小時的鍛煉。雖然已年過六旬，但他健身的動作十分標準，絲毫不輸身邊的年輕人。</p><p>鄭生説考慮到自己的年齡，現在平均一週會去健身室3次，不過做的運動量會維持在2個小時左右，無論寒冬還是酷暑都一直堅持著。開始健身後，鄭生感覺自己又變得精力充沛了，肚腩也消失了，身材變得更健美。鄭生表示，自己有信心一定會繼續堅持鍛煉下去！
        </p>
        <img
          alt="圖表佔位"
          class="fw8"
          src="../../assets/img/pa_encourage4.jpg"
        />
      </div>

      <div v-if="encourage == 5">
          <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/pa/Week_6_MSE.mp3" ></audio>
    <p>
          跟住落黎同大家分享85歲陳先生嘅事例。陳生80多歲還能連續做40個俯卧撐，“深蹲”每次可完成40次以上，仰卧起坐一次能完成40多次，晨操完成“萬米長跑”。陳生原本是中學老師，退休後被查出腸胃有問題，長期腹痛但去了醫院也沒法徹底治癒。</p><p>陳生便想試試通過運動減輕病痛，後來漸漸喜歡上運動。陳生堅持運動二十多年，期間很少生病，之前的病症也得到很大的緩解。問起陳生身體好的秘訣，陳生説沒什麼訣竅，就是堅持運動，中途中斷了也沒關喺，要相信自己有能力重新恢復運動鍛煉。只要長期堅持運動，身體一定會越來越好。
        </p>
        <img
          alt="圖表佔位"
          class="fw8"
          src="../../assets/img/pa_encourage5.jpg"
        />
      </div>

      <div v-if="encourage == 6">
      <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/pa/Week_7_MSE.mp3" ></audio>
        <p>
          跟住落黎同大家分享梁先生（62歲）嘅事例。梁先生在退休之前，工作一直很忙碌，幾乎不做運動。他還患有慢性肩周炎、三高，肚子也發福。家庭醫生建議梁生在生活中要多鍛煉身體。退休後，梁生決心開始做運動。他去過健身房健身，登過山，在公園裡跑過步，但是運動效果都不明顯。後來，梁生的兒子買了一隻狗給他，寵物狗很可愛，給他和老伴增添了不少樂趣。</p><p>每天遛狗的時候，用繩子牽著狗和狗一起跑，運動量都比較大。此外，有繩子的牽引，對緩解肩周炎效果也非常明顯。梁生經常遛狗。基本上每天3次，每次1小時左右。並且這樣堅持了好幾年。梁生現在肩周炎好了，也沒有三高，肚腩也細了很多。梁生表示，通過遛狗鍛煉身體，對他來説是一個非常合適的運動方式。他有信心一定會繼續堅持下去。
        </p>
        <img
          alt="圖表佔位"
          class="fw8"
          src="../../assets/img/pa_encourage6.jpg"
        />
      </div>
      <div v-if="encourage == 7">
      <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/pa/Week_8_RSE.mp3" ></audio>
        <p>
          跟住落黎同大家分享69歲尚先生堅持鍛煉嘅事例。尚先生十年如一日，每天堅持早晨鍛煉身體，拉伸半小時，慢跑半小時，很多年都沒進過醫院。尚先生説社區裡像他這樣堅持鍛煉的沒幾個。
          </p><p>尚先生還説，幾年前有一次開始慢跑的時候，因為沒有做好充分的熱身運動，結果不小心扭傷腳，在牀上躺了好幾個月。由於暫停了好久沒有運動，體力下降，後來人也不太想運動了。但是想到了運動的好處，尚先生還是恢復了晨操。為了預防運動扭傷，他每次運動前，拉伸都做得很充足。尚先生如今身體依然硬朗。他的長壽秘訣就是，堅持運動！只要有信心，堅持下去，身體就會越來越好，疾病也會越來越少！。
        </p>
        <img
          alt="圖表佔位"
          class="fw8"
          src="../../assets/img/pa_encourage7.png"
        />
      </div>
      <div v-if="encourage == 8">
      <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/pa/Week_9_RSE.mp3" ></audio>
        <p>
          跟住落黎同大家分享一位70歲劉女士嘅事例。劉女士在65歲時因為腦梗塞住院很長時間。出院的時候，醫生告訴她，病可以靠藥治，但身體的恢復還是要靠平常的運動鍛煉。劉女士經常去區內公園散步，有時候也會用公園裡的健身器械做拉伸。但是時間久了，就覺得一個人做運動很枯燥，沒有什麼樂趣。曾經有一段時間中途退出了運動。</p><p>
          後來劉女士的女兒幫她報名參加了社區老年中心舉辦的鍛煉工作坊。劉女士和其他老友記在運動教練的指導下，學會了椅子操和毛巾操。劉女士感覺大家一起做運動，好開心！鍛煉工作坊結束後，劉女士還經常約一兩個街坊一起去公園運動，有時候天氣不好，就會在家裡做椅子操和毛巾操。劉女士説自從她堅持做運動，身體輕鬆很多，體力充沛，快步行一個鐘也不覺得氣喘。好多人都説看不出她曾經腦梗塞。劉女士説，堅持運動是她的健康法寶。中途退出了也沒關喺，她有信心會重新運動並一直堅持下去的。
        </p>
        <img
          alt="圖表佔位"
          class="fw8"
          src="../../assets/img/pa_encourage8.jpg"
        />
      </div>
      <div v-if="encourage == 9">
        <p>
      <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/pa/Week_10_RSE.mp3" ></audio>
          跟住落黎同大家分享83歲高齡陳先生嘅事例。陳先生十幾年來一直堅持鍛煉，每天都是第一個到公園晨操的，陳先生經常在公園展示他驚人的臂力（雙手撐地腿腳懸空），每天圍觀人數非常多。大家説老先生雖然83歲高齡，身體還是非常靈活，比很多年輕人都厲害，看到他這樣的狀態都十分佩服，也想向他學習。</p><p>
          很多人擔心陳老先生這樣的年紀進行鍛煉容易受傷，陳先生直言：“剛開始那幾年會感覺手臂或者腿有點不適，中途因為拉傷，自己年紀大了又恢復的很慢，導致我好長時間不能運動。但是我知道再大的困難也要克服。身體康復後，我每次運動前都會做好熱身活動，之後從來沒有中斷過，現在已經堅持了十多年。”</p><p>
          陳先生如今身體依然硬朗。他的長壽秘訣就是，堅持運動！只要有信心，堅持下去，身體就會越來越好，疾病也會越來越少！
        </p>
        <img
          alt="圖表佔位"
          class="fw8"
          src="../../assets/img/pa_encourage9.png"
        />
      </div>
    </div>
  </wrapper>
</template>

<script>
import wrapper from "../wrapper.vue";
import echart from "@/components/echart";
import tools from "@/data/tools";
import { validErr } from "@/data/data";

export default {
  name: "pa_record",
  data() {
    return {
      page: 0,
      feedback: 1,
      encourage: 1,
      weekdays: [1, 2, 3, 4, 5, 6, 7],
      dayhours: ["0", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      hourminutes: ["0", 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
      record: {
        sum: 0,
        extreme_day: 0,
        extreme_none: false,
        extreme_hour: 0,
        extreme_minute: 0,
        normal_day: 0,
        normal_none: false,
        normal_hour: 0,
        normal_minute: 0,
        walk_day: 0,
        walk_none: false,
        walk_hour: 0,
        walk_minute: 0,
      },
      // 上週的運動記錄
      perweek: {},
      chartOption: {
        title: {
          show: true,
          text: "運動時長",
          left: "center",
          top: "center",
        },
        legend: {
          show: true,
          orient: "vertical",
          x: "left",
          data: ["劇烈運動", "中等強度運動", "行路"],
          textStyle: {
            fontSize: "18px",
          },
        },
        tooltip: {
          show: true,
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            label: {
              show: false,
              fontSize: 16,
              position: "outside",
              formatter: "{b}:{c}",
            },
            data: [
              {
                value: 0,
                name: "劇烈運動",
                label: {
                  show: true,
                  position: "inner",
                  formatter: "{c}",
                },
              },
              {
                value: 0,
                name: "中等強度運動",
                label: {
                  show: true,
                  position: "inner",
                  formatter: "{c}",
                },
              },
              {
                value: 0,
                name: "行路",
                label: {
                  show: true,
                  position: "inner",
                  formatter: "{c}",
                },
              },
            ],
            radius: ["40%", "70%"],
          },
        ],
      },
    };
  },
  computed: {
    type: function () {
      return this.$store.state.type;
    },
    num: function () {
      return this.$store.state.week;
    },
    user: function () {
      return this.$store.state.user;
    },
    pa: function () {
      return this.$store.state.pa;
    },
    debugbtn: function () {
      return this.$store.state.debugbtn;
    },

    extremeall: function () {
      return this.record.extreme_none
        ? 0
        : this.record.extreme_day *
            (parseInt(this.record.extreme_hour) * 60 +
              parseInt(this.record.extreme_minute));
    },
    normalall: function () {
      return this.record.normal_none
        ? 0
        : this.record.normal_day *
            (parseInt(this.record.normal_hour) * 60 +
              parseInt(this.record.normal_minute));
    },
    walkall: function () {
      return this.record.walk_none
        ? 0
        : this.record.walk_day *
            (parseInt(this.record.walk_hour) * 60 +
              parseInt(this.record.walk_minute));
    },
  },
  mounted() {
    console.log("record mounted");
  },
  created() {
    //首次進入時初始化起始頁面
    if (!this.page) {
      this.page = 1;
    }
    //初始化內容
    //先判斷是查看還是編輯
    if (this.$store.state.pa[this.num] != null) {
      //查看，不可編輯
      this.record = tools.copy(this.$store.state.pa[this.num].record);
    } else {
      //編輯，先查看是否有緩存
      if (this.$store.state.pa[0].record) {
        this.record = this.$store.state.pa[0].record;
      }
    }
    // 無論查看還是編輯， 初始化上週內容
    if (this.num > 1 && this.$store.state.pa[this.num - 1] != null) {
      this.perweek = tools.copy(this.$store.state.pa[this.num - 1].record);
    }
  },
  methods: {
    totalminute: function (record) {
      if (!record)
        return (
          (this.record.extreme_none
            ? 0
            : this.record.extreme_day *
              (parseInt(this.record.extreme_hour) * 60 +
                parseInt(this.record.extreme_minute))) +
          (this.record.normal_none
            ? 0
            : this.record.normal_day *
              (parseInt(this.record.normal_hour) * 60 +
                parseInt(this.record.normal_minute)))
        );
      else {
        return (
          (record.extreme_none
            ? 0
            : record.extreme_day *
              (parseInt(record.extreme_hour) * 60 +
                parseInt(record.extreme_minute))) +
          (record.normal_none
            ? 0
            : record.normal_day *
              (parseInt(record.normal_hour) * 60 +
                parseInt(record.normal_minute)))
        );
      }
    },
    getfeedback: function () {
      if (this.num > 1) {
        if (
          this.totalminute() > this.totalminute(this.perweek) &&
          this.totalminute() >= 150
        )
          return 3;
        else if (
          this.totalminute() > this.totalminute(this.perweek) &&
          this.totalminute() < 150
        )
          return 4;
        else if (
          this.totalminute() == this.totalminute(this.perweek) &&
          this.totalminute() >= 150
        )
          return 5;
        else if (
          this.totalminute() == this.totalminute(this.perweek) &&
          this.totalminute() < 150
        )
          return 6;
        else if (
          this.totalminute() < this.totalminute(this.perweek) &&
          this.totalminute() >= 150
        )
          return 7;
        else if (
          this.totalminute() < this.totalminute(this.perweek) &&
          this.totalminute() < 150
        )
          return 8;
      } else {
        // 第一週的情況
        if (this.totalminute() >= 150) {
          return 1;
        } else {
          return 2;
        }
      }
    },
    getEncourage: function () {
      return this.num - 1;
    },
    save: function () {
      this.record.sum = this.totalminute();

      if (this.$store.state.review) return;

      let xml = {};
      xml.key = ["劇烈運動時間", "中等強度運動時間", "散步時間"];
      xml.data = [];
      xml.data.push(this.extremeall, this.normalall, this.walkall);

      this.record.xml = xml;
      this.$store.state.pa[0].record = tools.copy(this.record);
    },
    pause: function () {
      this.save();
    },
    leave: function (page) {
      if (typeof this.page == "string") this.page = parseInt(this.page);

      if (page == 2) {
        this.feedback = this.getfeedback();
        this.chartOption.series[0].data[0].value = this.extremeall;
        if (this.extremeall == 0) {
          this.chartOption.series[0].data[0].label.show = false;
        }
        this.chartOption.series[0].data[1].value = this.normalall;
        if (this.normalall == 0) {
          this.chartOption.series[0].data[1].label.show = false;
        }
        this.chartOption.series[0].data[2].value = this.walkall;
        if (this.walkall == 0) {
          this.chartOption.series[0].data[2].label.show = false;
        }
        let _this = this;
        setTimeout(()=>{
            _this.$refs.chartt.focus();
        },1000);
      } else if (page == 3) {
        this.encourage = this.getEncourage();
      }
    },
    valid: function (page) {
      if (page == 2) {
        if (
          (!this.record.extreme_none &&
            (!this.record.extreme_day ||
              (!parseInt(this.record.extreme_hour) &&
                !parseInt(this.record.extreme_minute)))) ||
          (!this.record.normal_none &&
            (!this.record.normal_day ||
              (!parseInt(this.record.normal_hour) &&
                !parseInt(this.record.normal_minute)))) ||
          (!this.record.walk_none &&
            (!this.record.walk_day ||
              (!parseInt(this.record.walk_hour) &&
                !parseInt(this.record.walk_minute))))
        )
          return validErr.empty;
      }
    },
    next: function () {
      console.log("record do next");
      if (this.valid(this.page)) {
        this.$createDialog({
          type: "alert",
          title: "",
          content: this.valid(this.page),
        }).show();
        return;
      }

      this.leave(this.page);

      let done = false;
      switch (this.page) {
        //歡迎頁
        case 1:
          this.page = 2;
          break;
        //輸入鍛煉情況
        case 2:
          this.page = 3;
          break;
        // 鍛煉情況反饋
        case 3:
          this.page = 5;
          break;
        // 鍛煉情況鼓勵
        case 4:
          done = true;
          break;
        // 锻炼情况反馈续
        case 5:
        if (this.encourage > 0) this.page = 4;
          else done = true;
            break;
      }
      if (done) {
        this.save();
        return this.page;
      }
    },
  },
  components: { wrapper, echart },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../assets/css/learn_common.css" />
<style scoped>
button {
  width: 20px;
  height: 10px;
}
</style>
