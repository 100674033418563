
import {CHINESE_NUM} from '@/data/data'


export default {

    the_num:function(num){
        return CHINESE_NUM[num]?CHINESE_NUM[num]:num;
    },

    copy:function(obj){
        if(obj === undefined || obj === null)
            return {};
        return JSON.parse(JSON.stringify(obj));
    },

    isInt:function (obj) {
        return typeof obj === "number" && obj % 1 === 0;
    },
};