var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cube-page", class: _vm.type }, [
    _c("div", { ref: "window", staticClass: "wrapper" }, [
      _c(
        "main",
        { staticClass: "content" },
        [
          _vm._t("default", function () {
            return [_vm._v(_vm._s(_vm.content))]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }