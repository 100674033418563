<template>
  <wrapper>
    <div v-if="$store.state.debugbtn">
      <span>頁面編號調試：</span>
      <input v-model="page" />
    </div>
    <div v-if="page == 1" class="box">
      <p>你好 [{{ $store.state.user.uname }}],</p>
      <br />
      <p>歡迎你第{{ the_num }}次到訪“食”全十美，行大“運”嘅“運動鍛煉部份”。</p>
      <br />
      <p>今日嘅活動將佔用你15分鐘左右嘅時間，內容包括：</p>
      <div id="list" v-if="num==1">
        <p class="list">1. 建立個人化運動鍛煉檔案；</p>
        <p class="list">2. 制定運動鍛煉計劃；</p>
        <p class="list">3. 思考邊啲人可以幫你積極參與運動。</p>
      </div>
      <div id="list" v-if="num>=2 && num<=4">
        <p class="list">1. 建立個人化運動鍛煉檔案；</p>
        <p class="list">2. 評估同調整運動鍛煉計劃；</p>
        <p class="list">3. 思考邊啲人可以幫你積極參與運動。</p>
      </div>
      <div id="list" v-if="num==5">
        <p class="list">1. 建立個人化運動鍛煉檔案；</p>
        <p class="list">2. 搵出運動鍛煉障礙並制定對應計劃；</p>
        <p class="list">3. 學習使用電子運動日記，請允許錄音權限。</p>
      </div>
      <div id="list" v-if="num>=6 && num<=10">
        <p class="list">1. 建立個人化運動鍛煉檔案；</p>
        <p class="list">2. 評估同調整運動鍛煉障礙對應計劃；</p>
        <p class="list">3. 繼續使用電子運動日記，請允許錄音權限。</p>
      </div>
      <div><p class="b red">請點擊右上角嘅繼續以進入學習。</p></div>
      <div id="list" v-if="num>=5 && num<=10">
        <record
            v-show="false"
          :on-open-error="errHandle"
          :on-stop-is-end="errHandle"
          ref="recorder"
        ></record>
      </div>
    </div>
    <div v-if="page == 2" class="box">
      <div v-if="lock">
        <p>學習內容宜家處於鎖定狀態，請留意管理員通知您嘅開始學習時間</p>
      </div>
      <div v-else>
        <p class="title" v-if="$store.state.user.pastate<10">需要間隔一週才可以學習下個單元，請返回主頁。</p>
        <p class="title" v-else>您已完成全部運動鍛煉學習內容，請返回主頁。</p>
        <ul v-if="false">
          <li v-show="$store.state.user.pastate>=1"  @click="clickWeek(1) "><p class="bu">第一週</p></li>
          <li v-show="$store.state.user.pastate>=2"  @click="clickWeek(2) "><p class="bu">第二週</p></li>
          <li v-show="$store.state.user.pastate>=3"  @click="clickWeek(3) "><p class="bu">第三週</p></li>
          <li v-show="$store.state.user.pastate>=4"  @click="clickWeek(4) "><p class="bu">第四週</p></li>
          <li v-show="$store.state.user.pastate>=5"  @click="clickWeek(5) "><p class="bu">第五週</p></li>
          <li v-show="$store.state.user.pastate>=6"  @click="clickWeek(6) "><p class="bu">第六週</p></li>
          <li v-show="$store.state.user.pastate>=7"  @click="clickWeek(7) "><p class="bu">第七週</p></li>
          <li v-show="$store.state.user.pastate>=8"  @click="clickWeek(8) "><p class="bu">第八週</p></li>
          <li v-show="$store.state.user.pastate>=9"  @click="clickWeek(9) "><p class="bu">第九週</p></li>
          <li v-show="$store.state.user.pastate>=10" @click="clickWeek(10)"><p class="bu">第十週</p></li>
        </ul>
      </div>
    </div>
    </div>
  </wrapper>
</template>


<script>
import tools from "@/data/tools";
import wrapper from "@/components/wrapper";
import record from "@/components/record";

export default {
  name: "pa_welcome",
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    type: function () {
      return this.$store.state.type;
    },
    num: function () {
      return this.$store.state.week;
    },
    user: function () {
      return this.$store.state.user;
    },
    pa: function () {
      return this.$store.state.pa;
    },
    debugbtn: function () {
      return this.$store.state.debugbtn;
    },
    the_num: function () {
      return tools.the_num(this.num);
    },
    lock:function()
    {
      let ms = new Date(this.$store.state.configs.dateLimit.pa).getTime();

      return this.num == 1 && ms>new Date().getTime();
    }
  },

  created() {
    if(this.lock)
      this.page =2;
    else
    {
      if(this.num > 1)
      {
        let per = new Date(this.$store.state.pa[this.num-1].t).getTime();

        if((per + this.$store.state.interval > new Date().getTime()) || this.$store.state.user.pastate>=10)
          this.page = 2;
          else
          this.page = 1;
      }
      else
      this.page =1;
    }
  },

  mounted() {
    //   if(this.num>=5)
    //     this.$refs.recorder.init();
  },

  methods: {
    next: function () {
      console.log("welcome do next");

      
      if (this.page == 1) {
        return this.page;
      }
      else if(this.page == 2)
      {
        if(this.$store.state.review)
        return this.page;
        else
        {
          return null;
        }
      }

    },
        errHandle: function (err, byUser) {
      console.log("rec errHandle: "+ err);

    },
    clickWeek:function(no){
      this.$store.commit("setWeek", {
        data:no,
      });
      this.$store.commit("setReview", {
        data:true
      });
  },
  },
  components: {
    wrapper: wrapper,
        record: record,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../assets/css/learn_common.css" />
<style scoped>
.list {
  margin: 0 auto;
  color:rgb(119, 20, 248);
  font-weight: bold;
}

li:checked{
    color:red;
}
</style>