<template>
  <wrapper>
    <div v-if="debugbtn">
      <span>頁面編號調試：</span>
      <input v-model="page" />
    </div>
    <div v-if="page == 1" class="box">
      <div class="title">運動鍛煉的障礙</div>
      <div v-show="!complete">
        <p>
          通常，我哋喺進行運動鍛煉時會面臨好多困難，令到我哋唔可以堅持落去，而家等我哋黎學下點樣克服運動鍛煉嘅時候可能遇到嘅障礙。
        </p>

        <p>
          好多情況下，你會覺得完成足夠嘅運動（姐係每星期最少5日，每日最少30分鐘或者每星期加埋最少150分鐘）好困難。而家請你認真思考，然後回答以下問題。
        </p>

        <p>
          1. 就算我有<span class="blue">好多野要做</span
          >，我都會計劃好去做運動。
        </p>
        <p>不符合<cube-rate v-model="barrier.rate1"></cube-rate>符合我</p><br />
        <p>
          2. 就算<span class="blue">天氣唔好</span
          >，我都會計劃好去做運動。
        </p>
        <p>不符合<cube-rate v-model="barrier.rate2"></cube-rate>符合我</p><br />
        <p>
          3. 就算我<span class="blue">覺得運動好晒時間</span
          >，我都會計劃好去做運動。
        </p>
        <p>不符合<cube-rate v-model="barrier.rate3"></cube-rate>符合我</p><br />
        <p>
          4. 就算我<span class="blue">覺得好攰</span
          >，我都會計劃好去做運動。
        </p>
        <p>不符合<cube-rate v-model="barrier.rate4"></cube-rate>符合我</p><br />
        <p>
          5. 就算我<span class="blue">冇運動夥伴</span
          >，我都會計劃好去做運動。
        </p>
        <p>不符合<cube-rate v-model="barrier.rate5"></cube-rate>符合我</p><br />
        <p>
          6. 就算<span class="blue">缺乏運動場地</span
          >，我都會計劃好去做運動。
        </p>
        <p>不符合<cube-rate v-model="barrier.rate6"></cube-rate>符合我</p><br />
      </div>

      <div v-if="complete">
        <p v-if="barrier.rate1 > 3 || barrier.rate2 > 3|| barrier.rate3 > 3|| barrier.rate4 > 3|| barrier.rate5 > 3|| barrier.rate6 > 3">你啱先已經指出，喺呢啲情況下，你<span class="blue">仲係可以</span>做足夠的運動 ：</p>
        <p class="limegreen" v-if="barrier.rate1 > 3">有好多野要做</p>
        <p class="limegreen" v-if="barrier.rate2 > 3">天氣唔好</p>
        <p class="limegreen" v-if="barrier.rate3 > 3">覺得運動好晒時間</p>
        <p class="limegreen" v-if="barrier.rate4 > 3">覺得好攰</p>
        <p class="limegreen" v-if="barrier.rate5 > 3">冇運動夥伴</p>
        <p class="limegreen" v-if="barrier.rate6 > 3">缺乏運動場地</p>
        <br />
        <p v-if="barrier.rate1 < 4 ||barrier.rate2 < 4||barrier.rate3 < 4||barrier.rate4 < 4||barrier.rate5 < 4||barrier.rate6 < 4">但係喺呢啲情況下，你會覺得做足夠運動量有困難：</p>
        <p class="red" v-if="barrier.rate1 < 4">有好多野要做</p>
        <p class="red" v-if="barrier.rate2 < 4">天氣唔好</p>
        <p class="red" v-if="barrier.rate3 < 4">覺得活動好晒時間</p>
        <p class="red" v-if="barrier.rate4 < 4">覺得好攰</p>
        <p class="red" v-if="barrier.rate5 < 4">冇運動夥伴</p>
        <p class="red" v-if="barrier.rate6 < 4">缺乏運動場地</p>
      </div>
    </div>
    <div v-if="page == 2" class="box">
      <div class="title">運動障礙對應計劃</div>
      <p class="blue">
        唔好咁灰。針對困難情況諗出對應計劃會對你有幫助。其他人嘅例子可能會對你有所啟發。
      </p>
            <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/pa/Week_5_Barriers.mp3" ></audio>
      <p>
        黃先生（75歲）：我感覺自己年紀大了，不適合進行運動，也擔心自己運動的時候會受傷。在一次聚會中，我和以前的同事取得了聯繫。因為大家的身體狀況差不多，子女們比較忙，沒有時間陪伴我們。</p><p>現在大家早上就會相約一起去公園鍛鍊，星期六日大家也會一起去行山。和他們在一起我很開心，現在我也更願意堅持去進行運動鍛鍊。在這個過程中我感覺自己的身體越來越好，越來越健康。
      </p>
      <img  class="fw8" alt="pa_barrier_1.jpg" src="../../assets/img/pa_barrier_1.jpg" />
    </div>

    <div v-if="page == 3" class="box">
      <div class="title">運動障礙對應計劃</div>
      <p>
        跟住落黎請你認真思考下<span class="red b">阻礙你參加同堅持運動嘅原因</span>。如果你有做運動嘅諗法，但始終冇去做。諗一諗有啲咩原因阻礙你去運動呢？
      </p>
      <p>
        請用幾分鐘嘅時間諗下，揀出最多<span class="bu">兩種</span
        >障礙和困難。
      </p>

      <p>我最難應對嘅障礙喺</p>
      <p class="select_line">
        <cube-select
          class="inline_select"
          v-model="barrier.data[0].type"
          :options="barrierList(0)"
        >
        </cube-select>
        <span v-if="barrier.data[0].type == otherTxt"
          >請輸入<cube-input
            class="other_input"
            v-model="barrier.data[0].type_other"
          >
          </cube-input></span
        >。
      </p>

      <div v-if="!barrier.oneType">
        <p>另一個阻礙我運動的障礙喺</p>
        <p class="select_line">
          <cube-select
            class="inline_select"
            v-model="barrier.data[1].type"
            :options="barrierList(1)"
          >
          </cube-select>
          <span v-if="barrier.data[1].type == otherTxt"
            >請輸入<cube-input
              class="other_input"
              v-model="barrier.data[1].type_other"
            >
            </cube-input></span
          >。
        </p>
      </div>
      <cube-checkbox class="inline_checkbox font_large" v-model="barrier.oneType"
        >暫時只諗到一個障礙</cube-checkbox
      >
    </div>
    <div v-if="page == 4" class="box">
      <div class="title">運動障礙對應計劃</div>
      <p>
        同運動計劃相似，一個準確嘅計劃會對你有幫助。記住
        3大方法（適合性，有效性，易實現性）。
      </p>
        <p>
                訂下有效的對應計劃最方便嘅辦法就係<span class="bu">造句法</span>，用<span>“如果…….咁…….”</span>造句。</p>
            <p>對應計劃越貼近你嘅實際情況，越有效果。跟住落黎，請你寫低你嘅對應計劃</p>
      <p class="select_line">
        1． 如果{{ type1 }}，咁<cube-input
          class="other_input"
          v-model="barrier.data[0].then"
        >
        </cube-input>
      </p>
      <p v-if="!barrier.oneType" class="select_line">
        2． 如果{{ type2 }}，咁<cube-input
          class="other_input"
          v-model="barrier.data[1].then"
        >
        </cube-input>
      </p>

      <p class="b blue">如果你唔知點寫，請參考我哋俾嘅例子。</p>

      <ul>
        <li>
          <p v-if="showeg(0)" class="borange" >
            如果我有好多野要做，咁我會盡量合理安排各樣野，預留時間每日進行至少十分鐘嘅運動。
          </p>
        </li>
        <li>
          <p v-if="showeg(1)" class="borange" >如果天氣唔好，咁我就留喺屋企做運動。</p>
        </li>
        <li>
          <p v-if="showeg(2)" class="borange" >
            如果做運動太晒時間，
            咁我就將運動融入日常生活。比如提前一個巴士站或
            港鐵站落車，行路返屋企。
          </p>
        </li>
        <li>
          <p v-if="showeg(3)" class="borange" >
            如果覺得好攰，咁我就好好休息，第二日再做運動。
          </p>
        </li>
        <li>
          <p v-if="showeg(4)" class="borange" >
            如果冇運動夥伴，咁我就嘗試堅持一個人運動或者揀可以一個人進行嘅運動，比如喺公園慢跑。
          </p>
        </li>
        <li>
          <p v-if="showeg(5)" class="borange" >
            如果缺乏場地，咁我就嘗試進行唔需要特殊場地嘅運動，比如散步同慢跑。
          </p>
        </li>
        <li>
          <p v-if="showeg(6)" class="borange" >
            如果缺少運動設施，咁我就選擇方便易行嘅運動，比如散步和慢跑
          </p>
        </li>
      </ul>
      <p class="b">
        你的運動障礙對應計劃表將會儲存喺手機主頁“<span class="b red"
          >運動鍛煉</span
        >”內嘅“對應計劃”一欄中，你可以隨時查閲
      </p>
    </div>

    <div v-if="page == 5" class="box">
      <div class="title">運動障礙對應計劃總結</div>
      <p class="b">下低係你嘅“運動障礙對應計劃”</p>
      <p>1． <span class="blue">如果</span>{{ type1 }}，<span class="blue">咁</span>{{ barrier.data[0].then }}</p>
      <p v-if="!barrier.oneType">
        2． <span class="blue">如果</span>{{ type2 }}，<span class="blue">咁</span>{{ barrier.data[1].then }}
      </p>
      <div v-if="!reviewMode && !atEnd">
        <p>你可以選擇喺咪要進行調整。</p>
        <cube-radio-group v-model="modify">
          <cube-radio key="1" :option="{ value: 1 }"> 調整</cube-radio>
          <cube-radio key="0" :option="{ value: 0 }"> 唔調整</cube-radio>
        </cube-radio-group>
      </div>
    </div>

    <div v-if="page == 6" class="box">
      <div class="title">評估運動障礙對應計劃</div>
      <p>
        上個禮拜你已經制定咗運動障礙對應計劃，進而幫助你克服困難，堅持去做運動。跟住落黎，請你評估一下對應計劃嘅實行情況。如果呢啲對應策略對你幫助唔大，咁就進行調整。
      </p>

      <p>
        而家請回憶你上個禮拜制定嘅運動障礙對應計劃（<span
          class="bu"
          @click="reviewPlan(6)"
          >點擊查看</span
        >）同埋你完成呢個計劃嘅情況。請指出，喺最近7日內實行呢個運動障礙對應計劃嘅情況。
      </p>
      <div v-if="barrier.perEval.evl1 > 0">
        <p v-if="barrier.perEval.evl1 == 1" class="red b">
          改變習慣唔容易架，唔好氣餒，繼續加油！
        </p>
        <p v-if="barrier.perEval.evl1 == 2" class="red b">
          仲有進步的空間，繼續加油!
        </p>
        <p v-if="barrier.perEval.evl1 == 3" class="red b">
          做得非常好，請繼續保持！
        </p>
      </div>
      <cube-radio-group v-model="barrier.perEval.evl1">
        <cube-radio key="0" :option="{ value: 1 }">
          <p>不好</p>
        </cube-radio>
        <cube-radio key="1" :option="{ value: 2 }">
          <p>一般</p>
        </cube-radio>
        <cube-radio key="2" :option="{ value: 3 }">
          <p>好</p>
        </cube-radio>
      </cube-radio-group>

    </div>
    <div v-if="page == 7" class="box">
        <div class="title">評估運動障礙對應計劃</div>
      <p>
        而家請你運用以下2大方法黎評估你嘅運動障礙對應計劃。</p>
       <p> 1. 適合性 咁樣嘅對應計劃適合你嗎？
      </p>
      <cube-radio-group v-model="barrier.perEval.evl2">
        <cube-radio key="1" :option="{ value: '是' }"> 是</cube-radio>
        <cube-radio key="2" :option="{ value: '否' }"> 否</cube-radio>
      </cube-radio-group>
      <p>2. 有效性 咁樣嘅對應計劃對你有冇好處？</p>
      <cube-radio-group v-model="barrier.perEval.evl3">
        <cube-radio key="1" :option="{ value: '是' }"> 是</cube-radio>
        <cube-radio key="2" :option="{ value: '否' }"> 否</cube-radio>
      </cube-radio-group>
    </div>
  </wrapper>
</template>

<script>
import tools from "@/data/tools";
import wrapper from "@/components/wrapper";
import { allBarrier, validErr } from "@/data/data";

export default {
  name: "pa_barrier",
  data() {
    return {
      page: 0,
      otherTxt: "其他障礙",
      modify: 0,
      reviewMode: true,
      atEnd: false,
      reviewFrom: 0,
      barrier: {
        oneType: false,
        rate1: 0,
        rate2: 0,
        rate3: 0,
        rate4: 0,
        rate5: 0,
        rate6: 0,
        data: [
          {
            type: "",
            type_other: "",
            then: "",
          },
          {
            type: "",
            type_other: "",
            then: "",
          },
        ],
        perEval: {
          evl1: 0,
          evl2: "",
          evl3: "",
        },
      },
      perweek: {},
    };
  },
  computed: {
    type: function () {
      return this.$store.state.type;
    },
    num: function () {
      return this.$store.state.week;
    },
    user: function () {
      return this.$store.state.user;
    },
    pa: function () {
      return this.$store.state.pa;
    },
    debugbtn: function () {
      return this.$store.state.debugbtn;
    },

    the_num: function () {
      return tools.the_num(this.num);
    },
    complete: function () {
      for (let i = 1; i <= 6; i++) {
        if (this.barrier["rate" + i] == 0) return false;
      }
      return true;
    },
    type1: function () {
      return this.barrier.data[0].type == this.otherTxt
        ? this.barrier.data[0].type_other
        : this.barrier.data[0].type;
    },
    type2: function () {
      return this.barrier.data[1].type == this.otherTxt
        ? this.barrier.data[1].type_other
        : this.barrier.data[1].type;
    },
  },
  created() {
    if (!this.page) {
      if (this.num == 5 || this.num == 1) this.page = 1;
      else this.page = 6;
    }

    if (this.$store.state.pa[this.num] != null) {
      this.barrier = tools.copy(this.$store.state.pa[this.num].barrier);
    } else {
      //編輯，先查看是否有緩存
      if (this.$store.state.pa[0].barrier) {
        this.barrier = this.$store.state.pa[0].barrier;
      }
    }
    // 無論查看還是編輯， 初始化上週內容
    if (this.$store.state.pa[this.num - 1] != null) {
      this.perweek = tools.copy(this.$store.state.pa[this.num - 1].barrier);
    }
    if (!this.$store.state.review && this.num > 5) {
      this.barrier = tools.copy(this.perweek);
      this.barrier.perEval.evl1 = 0;
    }
  },
  methods: {
    reviewPlan: function (num) {
      this.reviewFrom = num;
      this.page = 5;
    },
    showeg: function (order) {
      if (
        this.barrier.data[0].type == this.otherTxt ||
        (!this.barrier.oneType && this.barrier.data[1].type == this.otherTxt)
      )
        return true;
      if (
        this.barrierOrder(this.barrier.data[0].type) == order ||
        (!this.barrier.oneType &&
          this.barrierOrder(this.barrier.data[1].type) == order)
      )
        return true;
      return false;
    },
    barrierOrder: function (val) {
      for (let i = 0; i < allBarrier.length; i++) {
        if (val == allBarrier[i]) return i;
      }
      return 7;
    },
    barrierList: function (order) {
      let list = [];
      if (order == 2) {
        list.push(
          this.barrier.data[0].type == this.otherTxt
            ? this.barrier.data[0].type_other
            : this.barrier.data[0].type
        );
        if (!this.barrier.oneType) {
          list.push(
            this.barrier.data[1].type == this.otherTxt
              ? this.barrier.data[1].type_other
              : this.barrier.data[1].type
          );
        }
        return list;
      }
      for (let item of allBarrier) {
        if (order === 0 && item != this.barrier.data[1].type) {
          list.push(item);
        } else if (order === 1 && item != this.barrier.data[0].type) {
          list.push(item);
        } else if (item == this.otherTxt) list.push(item);
      }
      return list;
    },
    save: function () {
      if (this.$store.state.review) return;
      let xml = {};
      xml.key = [
        "6種障礙評級",
        "應對方案1",
        "應對方案2",
        "上週實行情況",
        "適合性評價",
        "有效性評價",
      ];
      xml.data = [];
      xml.data.push(
        [
          this.barrier.rate1,
          this.barrier.rate2,
          this.barrier.rate3,
          this.barrier.rate4,
          this.barrier.rate5,
          this.barrier.rate6,
        ].join(";")
      );
      xml.data.push("如果" + this.type1 + "那麼" + this.barrier.data[0].then);
      if (this.barrier.oneType) {
        xml.data.push("用户只選擇了一種類型");
      } else {
        xml.data.push("如果" + this.type2 + "那麼" + this.barrier.data[1].then);
      }
      xml.data.push(
        ["未填寫", "不好", "一般", "好"][this.barrier.perEval.evl1]
      );
      xml.data.push(this.barrier.perEval.evl2);
      xml.data.push(this.barrier.perEval.evl3);
      this.barrier.xml = xml;

      this.$store.state.pa[0].barrier = tools.copy(this.barrier);
    },
    pause: function () {
      this.save();
    },
    leave: function (page) {
      if (typeof this.page == "string") this.page = parseInt(this.page);

      if (page == 7) {
        this.reviewMode = false;
      }
      if (page == 5) {
        this.reviewMode = true;
      }
      if (page == 4) {
        this.modify = 0;
      }
    },
    valid: function (page) {
      if (page == 1) {
        if (!this.complete) {
          return validErr.empty;
        }
      } else if (page == 3) {
        if (
          (this.barrier.data[0].type == this.otherTxt &&
            !this.barrier.data[0].type_other) ||
          !this.barrier.data[0].type
        )
          return validErr.empty;
        if (
          !this.barrier.oneType &&
          ((this.barrier.data[1].type == this.otherTxt &&
            !this.barrier.data[1].type_other) ||
            !this.barrier.data[1].type)
        )
          return validErr.empty;
      } else if (page == 4) {
        if (!this.barrier.data[0].then) return validErr.empty;
        if (!this.barrier.oneType && !this.barrier.data[1].then)
          return validErr.empty;
      } else if (page == 6) {
        if (this.barrier.perEval.evl1 == 0) return validErr.empty;
      } else if (page == 7) {
        if (!this.barrier.perEval.evl2 || !this.barrier.perEval.evl3)
          return validErr.empty;
      }
    },
    next: function () {
      console.log("barrier do next");
      if (this.valid(this.page)) {
        this.$createDialog({
          type: "alert",
          title: "",
          content: this.valid(this.page),
        }).show();
        return;
      }
      this.leave(this.page);

      let done = false;
      switch (this.page) {
        // 打星
        case 1:
          this.page = 2;
          break;
        // 鼓勵
        case 2:
          this.page = 3;
          break;
        // 選出兩項障礙
        case 3:
          this.page = 4;
          break;
        // 如果，那麼
        case 4:
          this.page = 5;
          break;
        // 總結
        case 5:
          if (this.atEnd) done = true;
          else if (this.reviewFrom !== 0) {
            this.page = this.reviewFrom;
            this.reviewFrom = 0;
          } else if (this.modify == 1) this.page = 3;
          else done = true;
          break;
        // 回顧上週，評價
        case 6:
          this.page = 7;
          break;
        // 回顧上週，4大法則評價
        case 7:
          this.page = 5;
          break;
      }
      if (done) {
        this.save();
        return this.page;
      }
    },
  },

  components: {
    wrapper: wrapper,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../assets/css/learn_common.css" />
<style scoped>
</style>