var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "main" } }, [
    _c("div", { staticClass: "btnLine" }, [
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.state == "ready",
              expression: "state == 'ready'",
            },
          ],
          staticClass: "recButton bttn-unite bttn-md bttn-danger bttn-block",
          on: { click: _vm.start },
        },
        [_vm._m(0)]
      ),
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.state == "rec",
              expression: "state == 'rec'",
            },
          ],
          staticClass: "recButton bttn-unite bttn-md bttn-warning bttn-block",
          on: { click: _vm.pause },
        },
        [_vm._m(1)]
      ),
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.state == "pause",
              expression: "state == 'pause'",
            },
          ],
          staticClass: "recButton bttn-unite bttn-md bttn-success bttn-block",
          on: { click: _vm.pause },
        },
        [_vm._m(2)]
      ),
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.state == "rec" || _vm.state == "pause",
              expression: "state == 'rec' || state == 'pause'",
            },
          ],
          staticClass: "recButton bttn-unite bttn-md bttn-primary bttn-block",
          on: { click: _vm.stop },
        },
        [_vm._m(3)]
      ),
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.state == "stop",
              expression: "state == 'stop'",
            },
          ],
          staticClass: "recButton bttn-unite bttn-md bttn-success bttn-block",
          attrs: { id: "btnplay" },
          on: { click: _vm.play },
        },
        [_vm._m(4)]
      ),
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.state == "stop",
              expression: "state == 'stop'",
            },
          ],
          staticClass: "recButton bttn-unite bttn-md bttn-danger bttn-block",
          on: { click: _vm.drop },
        },
        [_vm._m(5)]
      ),
    ]),
    false
      ? _c("div", { staticClass: "btnLine" }, [
          _c(
            "button",
            {
              ref: "btntest",
              staticClass:
                "recButton bttn-unite bttn-md bttn-primary bttn-block",
              on: { click: _vm.upload },
            },
            [_vm._m(6)]
          ),
        ])
      : _vm._e(),
    false
      ? _c("div", { ref: "rmvtest", staticClass: "bu" }, [
          _vm._v("這是一個加粗粗的文本"),
        ])
      : _vm._e(),
    _c("audio", { attrs: { id: "recAudio" } }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btnText" }, [
      _c("img", {
        staticClass: "recImg",
        attrs: { alt: "", src: require("../assets/img/rec.png") },
      }),
      _vm._v("錄製 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btnText" }, [
      _c("img", {
        staticClass: "recImg",
        attrs: { alt: "", src: require("../assets/img/pause.png") },
      }),
      _vm._v("暫停 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btnText" }, [
      _c("img", {
        staticClass: "recImg",
        attrs: { alt: "", src: require("../assets/img/rec.png") },
      }),
      _vm._v("繼續 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btnText" }, [
      _c("img", {
        staticClass: "recImg",
        attrs: { alt: "", src: require("../assets/img/stop.png") },
      }),
      _vm._v("完成 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btnText" }, [
      _c("img", {
        staticClass: "recImg",
        attrs: { alt: "", src: require("../assets/img/play.png") },
      }),
      _vm._v("播放 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btnText" }, [
      _c("img", {
        staticClass: "recImg",
        attrs: { alt: "", src: require("../assets/img/rec.png") },
      }),
      _vm._v("重錄 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btnText" }, [
      _c("img", {
        staticClass: "recImg",
        attrs: { alt: "", src: require("../assets/img/play.png") },
      }),
      _vm._v("上傳 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }