import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/home.vue'
import User from '@/views/user.vue'
import Learn from '@/views/learn.vue'
import Diary from '@/views/diary.vue'


Vue.use(VueRouter)

// TODO: 将未登录的用户发配会home页面

const routes = [{
        path: '/',
        component: Home
    },
    {
        path: '/index',
        component: Home
    },
    {
        path: '/home',
        component: Home,
        props: true
    },
    {
        path: '/user',
        component: User,
        children: []
    },
    {
        path: '/learn',
        component: Learn,
        children: []
    },
    {
        path: '/diary',
        component: Diary
    },

]

const router = new VueRouter({
    routes
})

import store from '../store/index.js'
router.beforeEach((to, from, next) => {

    if (store.state.user.openid == 0 && to.path !== '/home') {
        next({ path: "/home" });
    } else {
        next();
    }
});

export default router