<template>
  <div class="container vflex">
    <div class="diary_no"
      v-if="
        (user.pastate < 5 && type == 'pa') ||
        (user.dietstate < 5 && type == 'diet')
      "
    >
      <p>完成五星期嘅學習後，你將開始呢個部分嘅學習</p>
    </div>
    <div v-else>
      <div v-if="type == 'pa'">
        <ul class="list-wrapper vflex">
          <li v-for="item in padiary" class="list-item" style="display: flex">
            <div class = "list-left">
              {{ item.date }}
            </div>
            :
            <div class = "list-right" style="color: blue">
                              <span v-show="item.rec"> <button
                                        @click="playRec($event, item.text)"
                                        class="playBtn bttn-pill bttn-sm bttn-success"
                                    >播放
                                    </button>
                                    </span>
                <span v-show="!item.rec"> {{ item.text }}</span>
            </div>
          </li>
        </ul>
      </div>
      <div v-else>
        <ul class="list-wrapper vflex">
          <li v-for="item in dietdiary" class="list-item" style="display: flex">
            <div class = "list-left">
              {{ item.date }}
            </div>
            :
            <div class = "list-right"  style="color: blue">
                <span v-show="item.rec"> <button
                                        @click="playRec($event, item.text)"
                                        class="playBtn bttn-pill bttn-sm bttn-success"
                                    >播放
                                    </button>
                                    </span>
                <span v-show="!item.rec"> {{ item.text }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <audio ref="playAudio"></audio>
  </div>
</template>


<script>
//import Some from "@/components/some.vue";
// 抽取用户之前歷史每週的運動情況並做柱狀圖顯示

export default {
  name: "userdiary",
  data: function () {
    return {
      padiary: [],
      dietdiary: [],
    };
  },
  computed: {
    type: function () {
      return this.$store.state.type;
    },
    num: function () {
      return this.$store.state.week;
    },
    user: function () {
      return this.$store.state.user;
    },
    pa: function () {
      return this.$store.state.pa;
    },
    diet: function () {
      return this.$store.state.diet;
    },
    checkin:function(){
      return this.$store.state.checkin;
    }
  },
  methods: {
      playRec:function(e, src)
      {
          let audio = this.$refs.playAudio;
          audio.pause();
          audio.src = src;
          audio.play();
          //audio.controls = true;
          this.$createToast({
            txt: '開始播放，請調大設備音量',
            type: 'txt'
          }).show();
      }
  },
  components: {
    //Some,
  },
  created() {
    console.log("uDiary create");
    // 從checkin中讀取日記
    this.padiary = [];
    for(let checkin of this.checkin.pa)
    {
      let obj={};
      obj.date= checkin.y + "年\n"+ checkin.m+"月" + checkin.d+ "日";
      obj.text = checkin.txt;
      if(obj.text.startsWith(this.$store.state.recHead))
      {
          obj.rec = true;
          obj.text= checkin.txt.substr(this.$store.state.recHead.length);
      }
      else
      {
          obj.rec = false;
      }
      this.padiary.push(obj);
    }
    this.dietdiary = [];
    for(let checkin of this.checkin.diet)
    {
      let obj={};
      obj.date= checkin.y + "年\n"+ checkin.m+"月" + checkin.d+ "日";
      obj.text = checkin.txt;
      if(obj.text.startsWith(this.$store.state.recHead))
      {
          obj.rec = true;
          obj.text= checkin.txt.substr(this.$store.state.recHead.length);
      }
      else
      {
          obj.rec = false;
      }
      this.dietdiary.push(obj);
    }
    
  },
  beforeDestroy() {
      let audio = this.$refs.playAudio;
      audio.pause();
  },
};
</script>

<style scoped src="@/assets/css/bttn.css" />

<style scoped src="@/assets/css/common.css" />

<style scoped>
.custom {
  color: lightblue;
}
.list-wrapper {
  padding: 20px 10px 0px 10px;
}

.list-item {
  min-height: 100px;
}

.list-left {
  min-width: 120px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #5b5b5b;
  white-space: pre-line;
}

.list-right {
  width: 100%;
  border: 1px dashed red;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 20px;
  line-height:24px;
    word-wrap: break-word;
    word-break: break-all;
}

.playBtn{
    margin: 2px auto;
}

.diary_no{
  padding:30px;
}

.diary_no p{
  font-size:16px;
  font-weight:bold;
}

</style>

