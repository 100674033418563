var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container flexcolumn" }, [
    _c(
      "div",
      { staticClass: "top btngroup" },
      [
        _c("cube-button", { staticClass: "topbtn", on: { click: _vm.back } }, [
          _vm._v(" 返回主頁"),
        ]),
        _c("cube-button", { staticClass: "topbtn", on: { click: _vm.next } }, [
          _vm._v(" 提交打卡"),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "middlebox" },
      [
        _c("wrapper", [
          _c("div", [
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.type == "pa",
                    expression: "type == 'pa'",
                  },
                ],
              },
              [
                _c("span", { staticClass: "b" }, [
                  _vm._v("“運動鍛煉日記”嘅內容包括"),
                ]),
                _vm._v("：當日你喺"),
                _c("span", { staticClass: "bu" }, [_vm._v("咩時間")]),
                _vm._v("， "),
                _c("span", { staticClass: "bu" }, [_vm._v("咩地點")]),
                _vm._v("，"),
                _c("span", { staticClass: "bu" }, [_vm._v("同邊啲人")]),
                _vm._v("，進行咗"),
                _c("span", { staticClass: "bu" }, [_vm._v("咩強度")]),
                _vm._v("， "),
                _c("span", { staticClass: "bu" }, [_vm._v("咩類型嘅運動")]),
                _vm._v("，"),
                _c("span", { staticClass: "bu" }, [_vm._v("心情如何")]),
                _vm._v("。 "),
              ]
            ),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.type == "diet",
                    expression: "type == 'diet'",
                  },
                ],
              },
              [
                _c("span", { staticClass: "b" }, [
                  _vm._v("“健康飲食日記”嘅內容包括"),
                ]),
                _vm._v("：當日你喺"),
                _c("span", { staticClass: "bu" }, [_vm._v("咩時間")]),
                _vm._v("， "),
                _c("span", { staticClass: "bu" }, [_vm._v("咩地點")]),
                _vm._v("，"),
                _c("span", { staticClass: "bu" }, [_vm._v("同邊啲人")]),
                _vm._v("，食咗 "),
                _c("span", { staticClass: "bu" }, [_vm._v("咩食物")]),
                _vm._v("，"),
                _c("span", { staticClass: "bu" }, [_vm._v("心情如何")]),
                _vm._v("。 "),
              ]
            ),
          ]),
          _c("div", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.txtDiary,
                    expression: "txtDiary",
                  },
                ],
              },
              [
                _c("cube-textarea", {
                  staticClass: "larget",
                  attrs: { maxlength: _vm.max, placeholder: _vm.placeholder },
                  model: {
                    value: _vm.diaryText,
                    callback: function ($$v) {
                      _vm.diaryText = $$v
                    },
                    expression: "diaryText",
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: true,
                        expression: "true",
                      },
                    ],
                  },
                  [
                    _c(
                      "cube-button",
                      {
                        staticClass: "btnChange",
                        on: {
                          click: function ($event) {
                            return _vm.changeType(false)
                          },
                        },
                      },
                      [_vm._v("切換錄入方式")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.txtDiary,
                    expression: "!txtDiary",
                  },
                ],
              },
              [
                _c("p", [_vm._v("請點擊下低嘅“錄製”按鈕，錄製你嘅電子日記")]),
                _c("record", {
                  ref: "recorder",
                  attrs: {
                    "on-open-error": _vm.errHandle,
                    "on-stop-is-end": _vm.stopHandle,
                  },
                }),
                _c(
                  "cube-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: true,
                        expression: "true",
                      },
                    ],
                    staticClass: "btnChange",
                    on: {
                      click: function ($event) {
                        return _vm.changeType(true)
                      },
                    },
                  },
                  [_vm._v("切換錄入方式")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }