<template>
  <div class="container center">
    <div class="welpage center fw" v-show="user.openid <= 0 || !user.openid">
      <div class="logo">
        <img alt="“食”全十美，行大“運”" src="../assets/img/panel_top.jpg" />
      </div>

      <div class="content center">
        <span class="text"
          >請使用為您分配嘅專屬鏈接進入網站，如您還沒有您嘅專屬鏈接，
          請聯繫管理員。</span
        >
        <div v-show="debugbtn">
          <cube-input
            id="userinput"
            v-model="userinputid"
            class="codeinput"
          ></cube-input>
          <cube-button id="loginbtn" class="enterbtn" @click="login"
            >進入</cube-button
          >
        </div>
      </div>
    </div>

    <div class="selectpage center" v-show="user.openid > 0">
      <div class="logo">
        <img alt="運動i健康" src="../assets/img/panel_top.jpg" />
      </div>

      <div class="content center">
        <div class="btnbox">
          <cube-button class="typebtn" @click="goPa">運動鍛煉</cube-button>
          <cube-button class="typebtn" @click="goDiet">健康飲食</cube-button>
        </div>
        <div class="btnbox" v-show="user.pastate >= 5 || user.dietstate >= 5">
          <cube-button class="typebtn" @click="goPaDiary" :disabled="todayPa">{{
            !todayPa ? "電子運動日記" : "今日已完成運動日記"
          }}</cube-button>
          <cube-button
            class="typebtn"
            @click="goDietDiary"
            :disabled="todayDiet"
            >{{ !todayDiet ? "電子飲食日記" : "今日已完成飲食日記" }}</cube-button
          >
        </div>
        <div class="gobtndiv" v-show="false">
          <cube-button class="gobtn" @click="enter">進入</cube-button>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import tools from "@/data/tools";

function toggleFullScreen() {
  var doc = window.document;
  var docEl = doc.documentElement;

  var requestFullScreen =
    docEl.requestFullscreen ||
    docEl.mozRequestFullScreen ||
    docEl.webkitRequestFullScreen ||
    docEl.msRequestFullscreen;
  var cancelFullScreen =
    doc.exitFullscreen ||
    doc.mozCancelFullScreen ||
    doc.webkitExitFullscreen ||
    doc.msExitFullscreen;

  if (
    !doc.fullscreenElement &&
    !doc.mozFullScreenElement &&
    !doc.webkitFullscreenElement &&
    !doc.msFullscreenElement
  ) {
    requestFullScreen.call(docEl);
  } else {
    cancelFullScreen.call(doc);
  }
}

export default {
  name: "home",
  data() {
    return {
      waitText: "正在登錄，請稍後",
      loginSucceed: "登錄成功",
      loginFailed: "登錄失敗，無效的用户鏈接",
      noDiary:"完成第五週學習後才可以進行打卡",
      noLearn:"還未到開始學習的日期，請留意管理員通知",
      userinputid: "nT5yz5yQdEmrScnTeTGftFdzc8GZWRZA",
      imgs: [
        {
          image: require("../assets/img/bottom1.jpg"),
        },
        {
          image: require("../assets/img/bottom2.jpg"),
        },
        {
          image: require("../assets/img/bottom3.jpg"),
        },
      ],
    };
  },
  computed: {
    type: function () {
      return this.$store.state.type;
    },
    num: function () {
      return this.$store.state.week;
    },
    user: function () {
      return this.$store.state.user;
    },
    debugbtn: function () {
      return this.$store.state.debugbtn;
    },
    note: function () {
      return this.$store.state.configs.note;
    },
    limit:function()
    {
        return this.$store.state.configs.dateLimit;
    },
    todayPa: function () {
      return this.$store.state.checkin.todayPa;
    },
    todayDiet: function () {
      return this.$store.state.checkin.todayDiet;
    },
  },
  components: {},
  created() {
    console.log("created");
    let query = this.$route.query;
    if (query && query.token) {
      console.log("have query");
      console.log(query);
      this.getUserInfo(query.token);
    }
    //this.imgs[0] = {image:require("../assets/img/bottom1.jpg")};
    //this.imgs[1] = {image:require("../assets/img/bottom2.jpg")};
    //this.imgs[2] = {image:require("../assets/img/bottom3.jpg")};

    document.documentElement.addEventListener(
      "touchstart",
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      false
    );

    var lastTouchEnd = 0;
    document.documentElement.addEventListener(
      "touchend",
      function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      false
    );

    // 阻止雙指放大
    document.addEventListener(
      "gesturestart",
      function (event) {
        event.preventDefault();
      },
      false
    );

    //toggleFullScreen();
  },
  methods: {
    login() {
      this.$createToast({
        time: 1000,
        txt: this.waitText,
      }).show();
      //$('#loginbtn')[0].disabled = true;
      this.getUserInfo(this.userinputid);
    },

    getUserInfo: function (token) {
      this.$store
        .dispatch("login", token)
        .then((openid) => {
          if (
            this.note.note &&
            this.note.useNote == 1 &&
            this.note.note.length > 0
          ) {
            this.$createDialog({
              type: "alert",
              title: "系統公告:",
              content: this.note.note,
            }).show();
          } else
            this.$createToast({
                type:"correct",
              txt: this.loginSucceed,
            }).show();
        })
        .catch((err) => {
          this.$createToast({
              type: "error",
            txt: this.loginFailed,
          }).show();
        });
    },
    goPa() {
      if(this.user.pastate==0 && new Date(this.limit.pa).getTime() > new Date().getTime())
      {
          this.$createToast({
              type: "error",
            txt: this.noLearn,
          }).show();
        return;
      }
      console.log(this.$store.state);
      this.$store.commit("setType", { data: "pa" });
      this.$store.commit("setWeek", { data: this.user.pastate + 1 });
      this.$router.push({ path: "/user" });
    },
    goPaDiary() {
      console.log(this.$store.state);
      if(this.user.pastate<5)
      {
        this.$createToast({
            type: "error",
            txt: this.noDiary,
          }).show();
        return;
      }
      this.$store.commit("setType", { data: "pa" });
      this.$store.commit("setWeek", { data: this.user.pastate + 1 });
      this.$router.push({ path: "/diary" });
    },
    goDiet() {
      if(this.user.dietstate==0 && new Date(this.limit.diet).getTime() > new Date().getTime())
      {
          this.$createToast({
              type: "error",
            txt: this.noLearn,
          }).show();
        return;
      }
      console.log(this.$store.state);
      this.$store.commit("setType", { data: "diet" });
      this.$store.commit("setWeek", { data: this.user.dietstate + 1 });
      this.$router.push({ path: "/user" });
    },
    goDietDiary() {
      console.log(this.$store.state);
      if(this.user.dietstate<5)
      {
        this.$createToast({
            type: "error",
            txt: this.noDiary,
          }).show();
        return;
      }
      this.$store.commit("setType", { data: "diet" });
      this.$store.commit("setWeek", { data: this.user.dietstate + 1 });
      this.$router.push({ path: "/diary" });
    },
    enter() {
      console.log(this.$store.state);

      this.$router.push({ path: "/diary" });
    },
  },

  metaInfo: {
    title: "“食”全十美，行大“運”",
    meta: [
      {
        name: "viewport",
        content:
          "width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, viewport-fit=cover",
      },
      {
        name: "apple-mobile-web-app-capable",
        content: "yes",
      },
      {
        name: "mobile-web-app-capable",
        content: "yes",
      },
    // 提升權限會導致訪問異常
    //  {
    //     name: "Content-Security-Policy",
    //     content: "upgrade-insecure-requests",
    //   },
    ],
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="@/assets/css/common.css" />

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  background: #efeade;
}

.welpage {
  margin-top: -10%;
  height: 50%;
  width: 100%;
}

.logo {
  width: 80%;
  height: auto;
}

.logo img {
  width: 100%;
}

.enterbtn {
  margin-top: 10px;
  width: 40%;
  padding: 10px;
  font-size: 15px;
}

.codeinput {
  width: 90%;
}

.content {
  margin-top: 10px;
  width: 80%;
  line-height: 30px;
  font-size: 16px;
}

.btnbox {
  margin-top: 8%;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.typebtn {
  padding: 10px;
  font-size: 15px;
}

.selectpage {
  margin-top: -10%;
  height: 50%;
  width: 100%;
}

#userinput {
  width: 16px;
}

.gobtndiv {
  margin-top: 8%;
  width: 100%;
}

.gobtn {
  width: 100%;
  padding: 12px;
  font-size: 15px;
}
</style>
