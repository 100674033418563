<template>
  <div class="container vflex cscroll">
    <div
      class="barrier_no"
      v-if="
        (user.pastate < 5 && type == 'pa') ||
        (user.dietstate < 5 && type == 'diet')
      "
    >
      <p>完成五星期嘅學習後，你將開始呢個部分嘅學習</p>
    </div>
    <div v-else>
      <div class="barrier_box" v-if="type == 'pa'">
        <span class="title">運動障礙對應記錄 </span>
        <div class="barrier_context">
          <div class="barrier_line ">
            1.<span class="bu txtrg">如果:</span>
            <div class="red u">
              {{ data[0].type }}
            </div>
            <br />
            <span class="bu barrery_gap">咁:</span>
            <div class = "blue u">
              {{ data[0].then }}
            </div>
          </div>
          <div class="barrier_line" v-show="!oneType">
            2.<span class="bu txtrg">如果:</span>
            <div class="red u">
              {{ data[1].type }}
            </div>
            <br />
            <span class="bu barrery_gap">咁:</span>
            <div class = "blue u">
              {{ data[1].then }}
            </div>
          </div>
        </div>
      </div>
      <div class="barrier_box" v-else>
        <span class="title">飲食障礙對應記錄 </span>
        <div class="barrier_context">
          <div class="barrier_line">
            1.<span class="bu txtrg">如果:</span>
            <div class="red u">
              {{ data2[0].type }}
            </div>
            <span class="bu barrery_gap">咁:</span>
            <div class = "blue u">
              {{ data2[0].then }}
            </div>
          </div>
          <div class="barrier_line" v-show="!oneType2">
            2.<span class="bu txtrg">如果:</span>
            <div class="red u">
              {{ data2[1].type }}
            </div>
            <span class="bu barrery_gap">咁:</span>
            <div class = "blue u">
              {{ data2[1].then }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import Some from "@/components/some.vue";
// 抽取用户之前歷史每週的運動情況並做柱狀圖顯示

export default {
  name: "userdiary",
  data: function () {
    return {
      data: [
        { type: "天氣不好", type_other: "", then: "", then2: "" },
        { type: "天氣不好", type_other: "", then: "", then2: "" },
      ],
      data2: [
        {
          kind: "vg",
          type: "很忙",
          type_other: "",
          then: "",
          then2: "",
        },
        {
          kind: "vg",
          type: "很忙",
          type_other: "",
          then: "",
          then2: "",
        },
        {
          kind: "meat",
          type: "很忙",
          type_other: "",
          then: "",
          then2: "",
        },
        {
          kind: "meat",
          type: "很忙",
          type_other: "",
          then: "",
          then2: "",
        },
      ],
    };
  },
  computed: {
    type: function () {
      return this.$store.state.type;
    },
    num: function () {
      return this.$store.state.week;
    },
    user: function () {
      return this.$store.state.user;
    },
    debugbtn: function () {
      return this.$store.state.debugbtn;
    },
    pa: function () {
      return this.$store.state.pa;
    },
    diet: function () {
      return this.$store.state.diet;
    },
  },
  methods: {},
  components: {
    //Some,
  },
  created() {
    if (this.user.pastate > 4) {
      let index = this.user.pastate;
      this.data = this.pa[index].barrier.data;
      this.oneType = this.pa[index].barrier.oneType;
    }
    if (this.user.dietstate > 4) {
      let index2 = this.user.dietstate;
      this.data2 = this.diet[index2].barrier.data;
      this.oneType2 = this.diet[index2].barrier.oneType;
    }
  },
  beforeDestroy() {},
};
</script>

<style scoped src="@/assets/css/common.css" />
<style scoped>
.cscroll {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.barrier_box {
  padding: 20px 15px 0px 15px;
}

.barrier_context {
  margin-top: 20px;
}

.barrier_line {
  display: flex;
  font-size: 16px;
  height: 30px;
  margin-top: 22px;
}

.barrier_no {
  padding: 30px;
}

.barrier_no p {
  font-size: 16px;
  font-weight: bold;
}

.txtrg{
  min-width:40px;
}

.barrery_gap
{
margin-left:5px;
margin-right:5px;
}

</style>

