<template>
  <div class="container vflex">
    <div
      class="rec_no"
      v-if="
        (user.pastate == 0 && type == 'pa') ||
        (user.dietstate == 0 && type == 'diet')
      "
    >
      <!-- 請點擊“進入課程”，完成第一星期嘅學習。 -->
      <p>請點擊“進入課程”，完成第一星期嘅學習</p>
    </div>
    <div v-else>
      <div class="chart_box">
        <echart ref="chart" class= "chart_img" :option="chartOption"></echart>
      </div>
      <div class="record_box">
          <p  v-show="num >= 5">
              可以喺上圖中左右拖動查看唔同嘅星期嘅記錄情況。
          </p>
        <div v-if="type == 'pa'">
          <p>
            WHO推薦嘅運動鍛煉標準係，每星期進行中等強度或以上嘅運動至少五日，每日至少30分鐘（或每星期加埋至少150分鐘）。
          </p>
        </div>
        <div v-else>
          <p>
            世界衞生組織（WHO）飲食營養與慢性病預防機構建議：為咗促進健康，長者應該
            <span class="bu red"
              >平均每日食至少5份生果蔬菜，同埋5-6兩肉、蛋及替代品（例如：豆腐,
              堅果）</span
            >。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echart from "@/components/echart.vue";
// 抽取用户之前歷史每週的運動情況並做柱狀圖顯示

let data_normal = [];
let data_vg = [];
let data_meat = [];

export default {
  name: "userrecord",
  data: function () {
    return {
      chartOption: null,
    };
  },
  computed: {
    type: function () {
      return this.$store.state.type;
    },
    num: function () {
      return this.$store.state.week;
    },
    user: function () {
      return this.$store.state.user;
    },
    pa: function () {
      return this.$store.state.pa;
    },
    diet: function () {
      return this.$store.state.diet;
    },
  },
  methods: {},
  components: {
    echart,
  },
  created() {
    console.log("uRecord create");
    for (let i = 1; i <= 10; i++) {
      if (this.pa[i] && this.pa[i].record) {
        data_normal[i - 1] = this.pa[i].record.sum;
      }

      if (this.diet[i] && this.diet[i].record) {
        data_vg[i - 1] = this.diet[i].record.sumVg;
        data_meat[i - 1] = this.diet[i].record.sumMeat;
      }
    }

    if (this.type == "pa") {
      this.chartOption = {
        title: {
          text: "中等強度以上運動時長",
          left: "center",
          top: "top",
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
        dataZoom: {
          start: 0,
          end: 33,
          type: "inside",
        },
        xAxis: {
          data: [
            "第一週",
            "第二週",
            "第三週",
            "第四周",
            "第五週",
            "第六週",
            "第七週",
            "第八週",
            "第九周",
            "第十週",
          ],
          nameTextStyle: {
            fontSize: "18px",
          },
          axisLabel: {
            fontWeight: "bold",
            fontSize: "16px",
          },
        },
        grid: {  
            left: "13%",  
        },
        yAxis: {
          name: "分鍾",
          min: 0,
          max: function (value) {
            return value.max > 200 ? value.max : 200;
          },
          nameTextStyle: {
            fontWeight: "bold",
            fontSize: "16px",
            overflow:"breakAll", 
          },
          axisLabel: {
            interval: 0,
            fontWeight: "bold",
            fontSize: "14px",
            overflow:"none", 
            //width:"35",
          },
          axisLine: {
            show: true,
            symbol: ["none", "arrow"],
            lineStyle: {
              width: 2.5,
            },
          },
        },
        series: [
          {
            type: "bar",
            data: data_normal,
            label: {
              show: true,
              fontWeight: "bold",
              fontSize: 16,
              position: "top",
            },
            markLine: {
              show: true,
              data: [
                {
                  yAxis: 150,
                },
              ],
              silent: true,
              lineStyle: {
                type: "dashed",
                width: 3,
                color: "rgb(255,0,0)",
              },
            },
          },
        ],
        textStyle: {
          fontSize: "18px",
        },
      };
    } else {
      this.chartOption = {
        title: {
          text: "飲食食用情況",
          left: "center",
          top: "top",
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
        dataZoom: {
          start: 0,
          end: 33,
          type: "inside",
        },
        label: {
          show: true,
        },
        legend: {
          show: true,
          orient: "vertical",
          x: "right",
          textStyle: {
            fontSize: "18px",
          },
        },
        xAxis: {
          data: [
            "第一週",
            "第二週",
            "第三週",
            "第四周",
            "第五週",
            "第六週",
            "第七週",
            "第八週",
            "第九周",
            "第十週",
          ],
          nameTextStyle: {
            fontSize: "18px",
          },
        },
        yAxis: {
          name: "份/兩",
          min: 0,
          max: 10,
          nameTextStyle: {
            fontWeight: "bold",
            fontSize: "18px",
          },
          axisLine: {
            show: true,
            symbol: ["none", "arrow"],
            lineStyle: {
              width: 2.5,
            },
          },
        },
        series: [
          {
            type: "bar",
            name: "果蔬",
            data: data_vg,
            label: {
              show: true,
              fontWeight: "bold",
              fontSize: 16,
              position: "top",
            },
            markLine: {
              show: true,
              data: [
                {
                  yAxis: 5,
                },
              ],
              silent: true,
              lineStyle: {
                type: "dashed",
                width: 3,
                color: "rgb(255,0,0)",
              },
            },
          },
          {
            type: "bar",
            name: "肉類",
            data: data_meat,
            label: {
              show: true,
              fontWeight: "bold",
              fontSize: 16,
              position: "top",
            },
            markLine: {
              show: true,
              data: [
                {
                  yAxis: 6,
                },
              ],
              silent: true,
              lineStyle: {
                type: "dashed",
                width: 3,
                color: "rgb(0,0,255)",
              },
            },
          },
        ],
        textStyle: {
          fontSize: "18px",
        },
      };
    }
  },
  beforeDestroy() {},
};
</script>

<style scoped src="@/assets/css/common.css" />
<style scoped>
.custom {
  color: lightblue;
}

.record_box {
  padding: 15px 15px 0px 15px;
}

.chart_box {
  padding: 20px 15px 0px 15px;
}

.chart_img {
    width: 360px;
    height: 450px;
}

.welcome_title_div {
  padding-top: 100px;
}

.welcome_title_div p {
  text-align: center;
}

.rec_no{
  padding:30px;
}

.rec_no p{
  font-size:16px;
  font-weight:bold;
}
</style>

